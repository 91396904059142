import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { MessageType } from './MessageType';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
    message: string,
    isMessageVisible: boolean,
    messageType?: MessageType,
    setIsMessageVisible: (isVisible: boolean) => void
}

export default function Message({message, isMessageVisible, messageType, setIsMessageVisible} : Props) {

    function getAlertSeverity(messageType: MessageType | undefined) : AlertColor {
        switch(messageType) {
            default:
            case MessageType.Success:
                return 'success';
            case MessageType.Error:
                return 'error';
            case MessageType.Info:
                return 'info';
            case MessageType.Warning:
                return 'warning';
        }
    }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsMessageVisible(false);
  };

  return (
      <Snackbar open={isMessageVisible} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={getAlertSeverity(messageType)}>
            {message}
        </Alert>
      </Snackbar>
  );
}