import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import EditForm from '../../../components/EditForm';
import SideElementLayout from '../../../layouts/SideElementLayout';
import ActionButton from '../../../components/ActionButton';
import { useApiContext } from '../../../contexts/ApiContext';
import { PlatformManagementApi } from '../../../api';
import AccXTextField from '../../../components/fields/AccXTextField';
import { useAppContext } from '../../../contexts/AppContext';
import { MessageType } from '../../../components/snackbar/MessageType';

export default function AttachUserToNewCustomerPanel() {

    const [customerLogin, setCustomerLogin] = useState('');


    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();

    const platformManagementApi = new PlatformManagementApi();

    function textChange(id: string, value: string) {
        setCustomerLogin(value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        request(platformManagementApi.platformManagementUserToCustomerGet(customerLogin)).then((response) => {
            if(response.ok) {
                showMessage(labels.successSaveAttachUserToCustomerMessage);
                setIsLoading(false);
            }else{
                showMessage(labels.errorSaveAttachUserToCustomerMessage, MessageType.Error);
                setIsLoading(false);
            }
        })
    }

    const reset = (resetData = true) => {
        if(resetData) {
            setCustomerLogin('');
        }
    }

    return <SideElementLayout>
        <EditForm>
            <h4>{labels.enterUserDataToAttachNewCustomer}</h4>
            <Divider style={{marginBottom: '20px'}}></Divider>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AccXTextField id='customerLogin' 
                            value={customerLogin}
                            label={labels.userLoginAttachUserToCustomer} 
                            onChange={textChange}/>
                    </Grid>     
                    <Grid item xs={6}>
                    </Grid>        
                    <Grid item xs={12}>
                        <ActionButton type='submit' 
                            style={{float: 'right'}}>
                            {labels.attachUserToNewCustomerAction}
                        </ActionButton>
                        <ActionButton onClick={() => reset()} className="resetButton">
                            {labels.clean}
                        </ActionButton>
                    </Grid>
                </Grid>
            </form>
        </EditForm>
    </SideElementLayout> 
}
