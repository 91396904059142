import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AccXTabs from "../../../components/tabs/AccXTabs";
import AccountingPolicyParameters from "./parameters/AccountingPolicyParameters";
import AccountingPolicyTopicGroups from "./topicGroups/AccountingPolicyTopicGroups";
import { useAppContext } from "../../../contexts/AppContext";

export default function AccountingPolicyPanel() {

    const {labels} = useAppContext();

    const accountingPolicyTabs = {
        parameters: 'parameters',
        topicGroups: 'topicGroups',
    }

    const [selectedTab, setSelectedTab] = useState(accountingPolicyTabs.parameters);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var currentTab = searchParams.get('tab');
        
        if (currentTab) {
            setSelectedTab(currentTab);
        }
        else {
            searchParams.set('tab', accountingPolicyTabs.parameters);
        }

    }, []);

    function onTabChange(id: string) {
        setSelectedTab(id);

        var newSearchParams = new URLSearchParams();
        newSearchParams.set('tab', id);
        
        setSearchParams(newSearchParams);
    };

    function getSeletedTab() {
        switch(selectedTab) {
            default:
            case accountingPolicyTabs.parameters:
                return <AccountingPolicyParameters/>
            case accountingPolicyTabs.topicGroups:
                return <AccountingPolicyTopicGroups/>
        }
    }

    return <>
        <AccXTabs selectedTab={selectedTab} onTabChange={onTabChange} tabs={[
            {id: accountingPolicyTabs.parameters, label: labels.parameters},
            {id: accountingPolicyTabs.topicGroups, label: labels.topicGroups},
        ]}/>
        {getSeletedTab()}
    </> 
}