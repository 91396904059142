import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useFilters(syncWithQueryString=true) {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const [filtersData, setFilters] = useState<any>({});
    
    useEffect(() => {
        if (syncWithQueryString) {
            const params = Object.fromEntries(searchParams.entries());
            setFilters(params);
        }
    }, []);

    function addFilter(paramName: string, paramValue: any) {
        setFilters({...filtersData, [paramName]: paramValue});
    
        if (syncWithQueryString) {
            if((paramValue || paramValue === '0' || paramValue === false)) {
                searchParams.set(paramName, paramValue.toString());
            }
            else {
                searchParams.delete(paramName);
            }
            
            setSearchParams(searchParams);
        }
    }

    function resetFilters() {
        setFilters({});
    }
    
    return [filtersData, addFilter, resetFilters]
}
