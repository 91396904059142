export const enTranslations : ITranslations = {
    aboutProjectButtonLabel: 'About the Project',
    accountForCategoryLabel: 'Accounting Account',
    accountingCategories: 'Accounting Categories',
    accountingPolicy: 'Accounting Policy',
    accountingRules: 'Accounting Rules',
    add: 'Add',
    addAlternativeProductNameAction: 'Add Alternative Name',
    addAlternativeProductNameError: 'An error occurred while adding alternative product name',
    addAlternativeProductNamePopupTitle: 'Add Alternative Name for Product',
    addAlternativeProductNameSuccess: 'Alternative product name has been added',
    addCategoryLabel: 'New category added',
    addCategoryRule: 'Add accounting rule',
    addExampleErrorLabel: 'An error occurred while adding an example',
    addExampleLabel: 'New example added',
    additionalData: 'Additional Data',
    additionalDataLabel: 'Additional Data',
    additionalDataPanel: 'Additional Data',
    additionalInfoContains: 'Additional Information Contains',
    additionalInfoNotContains: 'Additional Information Does Not Contain',
    additionalInformation: 'Additional Information',
    addNewDecree: 'New Decree',
    addPurchasePurposeLabel: 'New purchase purpose added',
    addTag: 'Add Tag',
    addVatRegisterLabel: 'New VAT register added',
    admin: 'Admin',
    all: 'All',
    alternativeNames: 'Alternative Names',
    alternativeProductName: 'Alternative Name',
    api: 'API',
    apiDocumentation: 'API Documentation',
    apiDocumentationMarketing: 'To access the API documentation, please contact us:',
    appErrorOccurred: 'An application error occurred',
    appName: 'AccountantX',
    attachUserToExistingCustomerAction: 'Attach',
    attachUserToExistingCustomerPageName: 'Attaching User to Company',
    attachUserToNewCustomerAction: 'Create',
    attachUserToNewCustomerPageName: 'Activating "sandbox" for customer',
    bcPrediction: 'Industry Prediction',
    BCPredictionLoading: 'Currently unable to save company data - industry prediction in progress',
    bestPrediction: 'Best Matched Product',
    betterDecreeResultWithFullInfo: 'To improve prediction accuracy, enter item content along with seller name or tax identification number',
    bool: 'Yes / No',
    businessClassification: 'Industry',
    businessClassificationExplain: 'If the following industry were chosen:',
    buyerRuleInfo: 'Rule applies to the buyer whose',
    cantEditCategory: 'Cannot edit predefined category',
    category: 'AX Category',
    categoryAccount: 'Accounting Account',
    categoryAccountNonDeductible: 'Non-Deductible Account',
    categoryCostDeduction: 'Cost Deduction',
    categoryDeletingSuccess: 'Category deleted',
    categoryName: 'Name',
    categoryPanelTitle: 'Accounting Category',
    categoryRuleAdded: 'Rule has been added',
    categoryRuleAddError: 'An error occurred while adding accounting rule',
    categoryRuleChanged: 'Rule changes have been saved',
    categoryRuleChangeError: 'An error occurred while changing accounting rule',
    categoryRuleDeleted: 'Rule has been deleted',
    categoryRuleDeletingError: 'An error occurred while deleting the rule',
    categoryRuleHaveToSetSomething: 'Rule must set something',
    categoryRuleNameRequired: 'Rule name is required',
    categoryRuleProcessingOrderInfo: 'The lower the value, the earlier the rule will execute',
    categoryVatDeduction: 'VAT Deductions',
    categoryVatRegister: 'VAT Register',
    change: 'Change',
    clean: 'Clean',
    closeActionConfirmationPopupTitle: 'Cancel',
    cnCode: 'CN Code',
    companyConfiguration: 'Company Configuration',
    companyConfigurationSettingButton: 'Company Configuration',
    companyData: 'Company Data',
    companyName: 'Company Name',
    companyName_optional: 'Company Name',
    companyTaxId: 'Tax ID',
    conditionsBuyer: 'Conditions - Buyer',
    conditionsMaterialsAndGoods: 'Conditions - Materials/Goods',
    conditionsParameters: 'Conditions - Parameters',
    conditionsPositions: 'Conditions - Positions',
    conditionsPurposes: 'Conditions - Purchase Purpose/Vehicles/Other',
    conditionsSeller: 'Conditions - Seller',
    conditionsTopics: 'Conditions - Topics',
    confirmActionConfirmationPopupTitle: 'Save',
    confirmationPopupText: 'Changing the industry may result in setting other values related to the industry in the Accounting Policy tab',
    confirmationPopupTitle: 'Attention, industry has been changed',
    contactUsToDeleteAccount: 'To delete your account, please contact us at',
    contains: 'Contains',
    contractorPanelTitle: 'Customer Data',
    costDeduction_100: '100% KUP',
    costDeduction_20: '20% KUP',
    costDeduction_75: '75% KUP',
    costDeduction_NKUP: 'NKUP',
    costDeduction_noInfo: 'No information',
    costDeduction_ToClarify: 'To clarify',
    costDeductionLabel: 'Cost Deduction',
    customerManagement: 'Customer Management',
    decree: 'Decree',
    decreePageButtonLabel: 'Decree',
    defaultValue: 'Default Value',
    delete: 'Delete',
    deleteAlternativeProductNameAction: 'Delete Alternative Name',


    deleteAlternativeProductNamePopupTitle: 'Delete alternative product name',
    deleteExampleErrorLabel: 'An error occurred while deleting the example',
    deleteExampleLabel: 'Example deleted',
    deletingError: 'An error occurred while deleting',
    edit: 'Edit',
    editCategory: 'Edit category',
    editCategoryLabel: 'Category edited',
    editCategoryRule: 'Edit accounting rule',
    editParameter: 'Edit parameter',
    editProduct: 'Edit product',
    editPurchasePurposeLabel: 'Purchase purpose changed',
    editPurchaseTarget: 'Edit purchase target',
    editTopicGroup: 'Edit topic group',
    editVatRegister: 'Edit VAT register',
    editVatRegisterLabel: 'VAT register changed',
    email: 'Email',
    empty: 'Empty',
    endConfigurationButtonLabel: 'Finish configuration',
    enterBCDetails: 'Enter contractor details',
    enterCompanyDetails: 'Enter company details',
    enterInvoiceDataToDecree: 'Fill in invoice data to decree',
    enterInvoiceDetails: 'Enter invoice details',
    enterTestData: 'Test goods and materials',
    enterUserDataToAttachExistingCustomer: 'Attach user account to company',
    enterUserDataToAttachNewCustomer: 'Convert user account to company - activate "sandbox"',
    equal: 'Equal',
    errorLabel: 'An unexpected error occurred',
    errorOccurred: 'An error occurred',
    errorSaveAttachUserToCustomerMessage: 'An error occurred while setting up data structures for the customer',
    errorSaveAttachUserToExistingCustomerMessage: 'An error occurred while attaching the user to the customer',
    exampleName: 'Example name',
    examples: 'Examples',
    exclamationElementPanelTitle: 'Possible other accounting categories',
    explainModeLabel: 'Work in presentation mode showing various accounting options',
    externalId: 'Supplier product identifier',
    fieldRequired: 'Field required',
    filledIn: 'Filled in',
    general: 'General',
    greaterOrQqual: 'Greater or equal',
    greaterThan: 'Greater than',
    gtinCode: 'GTIN code',
    gtu: 'GTU code',
    gtuCode: 'GTU',
    gtuCode_optional: 'GTU code',
    gusName: 'Company name in GUS',
    hasRegNum: 'Vehicle registration number',
    hasWarehouse: 'My company has warehouses',
    history: 'History',
    historyIsEmpty: 'No entries',
    idxTagContains: 'Tag contains',
    idxTagNotContains: 'Tag does not contain',
    ignore: 'Ignore',
    indeksomat: 'Indeksomat',
    indexContains: 'Index contains',
    indexNotContains: 'Index does not contain',
    invoiceTopic: 'Topic',
    isActive: 'Is active',
    isCommodity: 'Is commodity?',
    isFixedAsset: 'Is fixed asset?',
    isGtuEmpty: 'Is GTU empty',
    isIntangibleAsset: 'Is intangible?',
    isItemTextEmpty: 'Is item empty',
    isLowValueEquipment: 'Is low-value?',
    isPredefined: 'Is predefined',
    isPurchasePurposeEmpty: 'Is purchase purpose empty',
    isStoppingProcessing: 'Is stopping processing',
    isTaxIdEmpty: 'Is tax ID empty',
    isVehicleUsagePurposeEmpty: 'Is vehicle usage purpose empty',
    itemTextContains: 'Item contains',
    itemTextNotContains: 'Item does not contain',
    key: 'Key',
    keywords: 'Keywords:',
    ksefFileLoadingError: 'An error occurred while loading the file. Invoice required in XML format',
    ksefInvoicePosition: 'KSeF invoice position',
    ksefInvoicePositionNotFound: 'Invoice does not have a position with the given number',
    lackOf: 'Lack of',
    lessOrQqual: 'Less or equal',
    lessThan: 'Less than',
    likeIt: 'I like it',
    limitExceededTryLater: 'Limit exceeded, log in or decree again in:',
    loadedXMLFile: 'Loaded XML file',
    loadKSeFInvoice: 'Load invoice',
    marketingIDXText: "You don't have an active Indeksomat. Contact us",
    materialsAndGoods: 'Goods and materials',
    materialsAndGoodsMapping: 'Goods and materials mapping',
    myCompany: 'My company',
    name: 'Name',
    nameContains: 'Name contains',
    nameNotContains: 'Name does not contain',
    negativeRate: 'You rated negatively',
    newCategory: 'New category',
    newParameter: 'Add parameter',
    newProduct: 'New product',
    newPurchaseTarget: 'New purchase target',
    newTopicGroup: 'Add topic group',
    newVatRegister: 'New VAT register',
    nip: "Seller's tax identification number",
    no: 'No',
    noAuthority: 'You have no authority',
    noData: 'No data',
    noDataResultInfo: 'No results',
    noDataResultMessage: 'An error occurred',
    noResult: 'No results',
    notBusinessClassification: 'Not business',
    notContains: 'Does not contain',
    note: 'Note',
    notEqual: 'Not equal',
    notFilledIn: 'Not filled in',
    notGtu: 'Not GTU code',
    notPurchasePurposeId: 'Not purchase purpose',
    notTopicGroupKey: 'Invoice topic not in topic group',
    notTopicIds: 'Invoice topic is not',
    number: 'Number',
    ok: 'Ok',
    onBoardingExplain: 'If the following parameter were selected:',
    operatorType: 'Parameter function',
    overwrite: 'Set',
    parameter: 'Parameter',
    parameter1: 'Parameter 1',
    parameter2: 'Parameter 2',
    parameterAdded: 'Parameter has been added',
    parameterAddingError: 'Parameter was not added - an error occurred',
    parameterAddingErrorNotUniqueKey: 'Parameter key must be unique',
    parameterChanged: 'Parameter has been changed',
    parameterChangingError: 'Parameter was not changed - an error occurred',
    parameterDeleted: 'Parameter has been deleted',
    parameterDeletingError: 'An error occurred while deleting the parameter',
    parameters: 'Parameters',
    phone: 'Phone',
    pkd: 'PKD',
    pkdTitle: 'PKD Name',
    pkwiu: 'PKWiU',
    pkwiuCode: 'PKWiU Code',
    popupTitle: 'Company Data',
    position: 'Invoice position',
    positionInfo: 'Position data',
    positionMeetsConditionsInfo: 'Position meets conditions',
    positiveRate: 'You rated the result positively',
    potentialCategoryNameExplainElementTitle: 'Potential category',
    predefinedIconTooltip: 'Predefined',
    predictContractorButtonLabel: 'Recognize industry',
    predictedTopic: 'Detected topic:',
    predictions: 'Predictions',
    processingOrder: 'Processing order',
    product: 'Product',
    productAddError: 'An error occurred while adding the product',
    productAddSuccess: 'Product has been added',
    productAlternativeName: 'Alternative product name',
    productAlternativeNameDeleted: 'Alternative product name has been deleted',
    productAlternativeNameDeletingError: 'An error occurred while deleting the alternative product name',
    productDeleted: 'Product has been deleted',
    productDeletingError: 'An error occurred while deleting the product',
    productEditError: 'An error occurred while editing the product',
    productEditSuccess: 'Product has been edited',
    productGroupContains: 'Product group contains',
    productGroupName: 'Product group',
    productGroupNameProductPanelLabel: 'Product group',
    productGroupNotContains: 'Product group does not contain',
    productHasNoAlternativeNames: 'Product has no alternative names',
    productId: 'Product identifier',
    productIndex: 'Index',
    productIndexNotUniqueError: 'Index is not unique',
    productIndexProductPanelLabel: 'Product index',
    productName: 'Product name',
    productNameNotUniqueError: 'Product name is not unique',
    productPanelTitle: 'Product',
    productsData: 'Products',
    productService: 'Type of product/service',
    productTags: 'Product tags',
    productType: 'Product type',
    purchasePurpose: 'Purchase purposes',
    purchasePurposeDeletingSuccess: 'Purchase purpose deleted',
    purchasePurposeId: 'Purchase purpose',
    purchasePurposeName: 'Name',
    purchasePurposeNameNotUnique: 'Purchase purpose is not unique',
    purchasePurposes: 'Purchase purposes',
    purchaseTargetCode: 'Code',
    purchaseTargetDefaultCategory: 'Default category',
    purchaseTargetExplain: 'If the following purchase purpose were selected:',
    purchaseTargetName: 'Name',
    purchaseTargetNote: 'Note',
    purchaseTargets: 'Purchase purposes',
    rateUs: 'Rate our result',
    report: 'Report an issue',
    reset: 'Reset',
    resultNotSureLabel: 'Result unsure',
    returnStopMessage: 'Return message instead of result',
    ruleName: 'Rule name',
    ruleNameExplain: 'Rule name:',
    ruleNameExplainElementTitle: 'Additional condition for selecting this category',
    rules: 'Rules:',
    save: 'Save',
    saveExample: 'Save example',
    saveSuccessMessage: 'Company data has been saved',
    search: 'Search',
    selectProduct: 'Select correct product',
    selectTopic: 'Select correct topic',
    sellerIndex: 'Seller index',
    sellerRuleInfo: 'The rule concerns the seller whose',
    sendFeedback: 'Send',
    service: 'Service',
    sessionExpired: 'Your session has expired - you have been logged out',
    set: 'Set',
    setDefault: 'Set default',
    signIn: 'Sign in',
    signInMarketing: 'Sign in',
    signInMarketingDescription: 'After signing in, you will have access to additional features and be able to set your own preferences',
    signInMarketingMoreOptions: ' and gain more possibilities.',
    signInMarketingQuestion: 'Would you like to see the proposed booking method?',
    signOut: 'Sign out',
    signOutSuccessfully: 'You have been successfully logged out',
    stopMessage: 'Stop message',
    successSaveAttachUserToCustomerMessage: 'Data structures for the customer have been created',
    successSaveAttachUserToExistingCustomerMessage: 'User attached to the customer',
    successSaveMessage: 'Company data has been saved',
    systemIsUpdating: 'System is updating, please wait',
    tag: 'Tag',
    tagRelated: 'Rule concerns tags on the invoice',
    targetValue: 'Value',
    taxId: 'Tax ID',
    taxIdToAttach: 'Customer tax ID to attach user',
    test: 'Test',
    testing: 'Testing',
    text: 'Text',
    thanks: 'Thank you',
    topicAndProductPrediction: 'Topic and product prediction',
    topicExplain: 'If the purchase topic is: ',
    topicGroupAdded: 'Topic group has been added',
    topicGroupAddingError: 'Error occurred while adding topic group',
    topicGroupAddingErrorNotUniqueKey: 'Group key must be unique',
    topicGroupChanged: 'Topic group has been changed',
    topicGroupChangingError: 'Error occurred while changing topic group',
    topicGroupDeleted: 'Topic group has been deleted',
    topicGroupDeletingError: 'Error occurred while deleting topic group',
    topicGroupExplain: ' would be assigned to group:',
    topicGroupKey: 'Invoice topic in topic group',
    topicGroups: 'Topics related to goods and materials',
    topicIds: 'Invoice topic is',
    topicPanelTitle: 'Invoice topic',
    topicPrediction: 'Topic prediction',
    topicRelated: 'Rule concerns topics',
    topics: 'Topics',
    topicsRelatedToMaterialsAndGoods: 'Topics related to goods and materials',
    topicTagContains: 'Invoice tag contains',
    topicTagNotContains: 'Invoice tag does not contain',
    trainProcuctPredictionError: 'Error occurred during training',
    trainProductPredictionSuccess: 'Thank you! Product mapping will be more effective thanks to you',
    tryAgainAdding: 'Try again by entering',
    type: 'Type',
    unempty: 'Not empty',
    unit: 'Unit',
    unitNetPrice: 'Net unit price - amount',
    unitPriceGeValueLabel: 'Unit cost >= [PLN]',
    unitPriceGTExplain: 'If the unit price entered is greater than or equal to:',
    unitPriceLTExplain: 'If the unit price entered is less than:',
    unitPriceLtValueLabel: 'Unit cost < [PLN]',
    unitPriceParameter: 'Parameter',
    unitPriceParameterLabel: 'Parameter',
    unitPriceType: 'Type',
    unitPriceValue: 'Value',
    userLoginAttachUserToCustomer: 'User login',
    value: 'Value',
    vat: 'VAT',
    vatDeduction_0: '0% VAT deduction',
    vatDeduction_100: '100% VAT deduction',
    vatDeduction_50: '50% VAT deduction',
    vatDeduction_noInfo: 'No information',
    vatDeduction_ToClarify: 'To clarify',
    vatDeductionLabel: 'VAT deduction',
    vatId: 'Tax ID',
    vatRate: 'VAT rate',
    vatRegister_ComodityImport:'Goods import',
    vatRegister_ComodityInside:'Internal purchase of goods',
    vatRegister_CountryBuy: 'Domestic purchase',
    vatRegister_CountryBuy_ST:'Domestic purchase - fixed assets',
    vatRegister_ServiceImport:'Service import',
    vatRegister_ToClarify: 'To clarify',
    vatRegisterAccount: 'Account',
    vatRegisterAccountLabel: 'VAT register account',
    vatRegisterDeletingSuccess: 'VAT register deleted successfully',
    vatRegisterLabel: 'VAT register',
    vatRegisterName: 'Name',
    vatRegisterNameNotUnique: 'VAT register name is not unique',
    vatRegisters: 'VAT registers',
    vehiclePurposeExplain: 'If the following vehicle usage purpose were selected:',
    vehicleRealted: 'vehicle-related purchase',
    vehicles: 'Vehicles',
    vehicleUsagePurpose: 'Vehicle used as',
    vehicleUsagePurposeId: 'Vehicle usage purpose',
    whatRuleSets: 'What the rule sets',
    yes: 'Yes',
}