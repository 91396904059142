import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import './accXTabs.css';

type Props = {
    tabs: AccXTabInfo[],
    selectedTab: string,
    onTabChange: (id: string) => void,
}

export type AccXTabInfo = {
    id: string,
    label: string,
    hasErrors?: boolean,
}

export default function AccXTabs({tabs, selectedTab, onTabChange} : Props) {

    function isTabSelected(id: string) {
        var result =  selectedTab === id;

        return result;
    }

    function getClassName(tab: AccXTabInfo) : string {

        var result = 'tab';

        if (isTabSelected(tab.id)) {
            result += ' selectedTab';            
        }

        if (tab.hasErrors) {
            result += ' hasErrors';
        }

        return result;
    }

    function getTabIcon(tab: AccXTabInfo) {
        if (tab.hasErrors) {
            return <FontAwesomeIcon 
            // style={{marginLeft: '-10px', marginBottom: '-2px'}}
            icon={faCircleExclamation} className='hasErrors'/>
        }

        return undefined;
    }

    return <div>
        {tabs.map((tab, tabIndex) => {
            return <span key={tabIndex}>
                <Tab id={tab.id} 
                className={getClassName(tab)}
                label={tab.label}
                icon={getTabIcon(tab)}
                iconPosition="end"
                onClick={() => onTabChange(tab.id)}/>
                {/* {getTabIcon(tab)} */}
            </span>
        })}
    </div> 
}