import { Button, Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";
import { useAppContext } from "../contexts/AppContext";

type Props = {
    text?: string,
    tooltipText?: string
}

export default function PredefinedTag({text, tooltipText, children}: PropsWithChildren<Props>) {
    
  const {labels} = useAppContext(); 

  if (!tooltipText) {
    tooltipText = labels.predefinedIconTooltip
  }
    
    return <span>{text}
    <Tooltip title={tooltipText} placement="bottom">
          <span className="predefinedIcon">
                <i className="predefinedIconText">P</i>
            </span>
          </Tooltip>
            </span>
}