import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXCategoryRule, AccXCategoryRuleOperatorEnum, AccXOnboardingParameterResult, AccXOnboardingParamRule, OnboardingApi, OnboardingParameterTypeEnum } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import AccXTextField from "../../../../../components/fields/AccXTextField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import DropdownField from "../../../../../components/fields/DropdownField";
import NumericField from "../../../../../components/fields/NumericField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

type Props = {
    parameterId: string,
    parameterData?: AccXOnboardingParamRule,
    parameters?: AccXOnboardingParameterResult[],
    categoryRule: AccXCategoryRule,
    setCategoryRule: React.Dispatch<React.SetStateAction<AccXCategoryRule>>,
}

export default function CategoryRuleParametersTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {
    
    const {request} = useApiContext();
    const {labels} = useAppContext();

    const [parameters, setParameters] = useState<AccXOnboardingParameterResult[]>([]);
    const [isLoadingParameters, setIsLoadingParameters] = useState(true);

    const isLoadingData = isLoadingParameters;

    const onboardingApi = new OnboardingApi();

    useEffect(() => {
        request(onboardingApi.onboardingParametersGet()).then((response) => {
            if (response.ok && response.data) {
                setParameters(response.data);
            }
            setIsLoadingParameters(false);
        })
    }, []);

    var param1 = categoryRule.conditions?.onboarding?.paramRule1;
    var param2 = categoryRule.conditions?.onboarding?.paramRule2;

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{labels.parameter1}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Parameter parameterId='paramRule1' parameterData={param1} parameters={parameters} categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            </Grid>
            <Grid item xs={12}>
                <Typography>{labels.parameter2}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Parameter parameterId='paramRule2' parameterData={param2} parameters={parameters} categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            </Grid>
        </Grid>
    </EditForm>
}

function Parameter({parameterId, parameterData, parameters, categoryRule, setCategoryRule} : Props) {

    const {labels} = useAppContext();

    const [innerParameterData, setInnerParameterData] = useState(parameterData);
    const [possibleOperators, setPossibleOperators] = useState<{text: string, value: AccXCategoryRuleOperatorEnum}[]>();
    const [parameterType, setParameterType] = useState(OnboardingParameterTypeEnum.Unknown);
    const [targetValueField, setTargetValueField] = useState<JSX.Element>();

    useEffect(() => {
        const selectedParameter = parameters?.find(x => x.parameterKey === innerParameterData?.onboardingParamKey);
        setParameterType(selectedParameter?.parameterType ?? OnboardingParameterTypeEnum.Text);
        setPossibleOperators(getPossibleOperators(selectedParameter?.parameterType));
    }, [parameters])

    useEffect(() => {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, onboarding: {...categoryRule.conditions?.onboarding, [parameterId]: innerParameterData}}});
    }, [innerParameterData])

    useEffect(() => {
        setTargetValueField(getTargetValueField());
    }, [parameterType, innerParameterData?.targetValue])

    function getTargetValueField() {

        const isTargetValueFieldDisabled = !innerParameterData?.onboardingParamKey ?? true;

        switch(parameterType) {
            default:
            case OnboardingParameterTypeEnum.Text:
                return <AccXTextField id='targetValue'
                    value={innerParameterData?.targetValue ?? ''}
                    label={labels.value}
                    onChange={taregetValueTextChange}
                    disabled={isTargetValueFieldDisabled}/>
            case OnboardingParameterTypeEnum.Number:
                return <NumericField id='targetValue'
                    value={innerParameterData?.targetValue ?? '0,00'}
                    label={labels.value}
                    onChange={taregetValueTextChange}
                    disabled={isTargetValueFieldDisabled}/>
            case OnboardingParameterTypeEnum.Bool:
                return <DropdownField id='targetValue'
                    value={innerParameterData?.targetValue ?? '1'}
                    label={labels.value}
                    items={[
                        {text: labels.yes, value: '1'},
                        {text: labels.no, value: '0'},
                    ]}
                    hasDefaultItem={false}
                    onChange={targetValueDropdownChange}
                    disabled={isTargetValueFieldDisabled}/>
        }
    }

    function getPossibleOperators(parameterType?: OnboardingParameterTypeEnum) {

        switch(parameterType) {
            default:
            case OnboardingParameterTypeEnum.Text:
                return [
                    {text: labels.equal, value: AccXCategoryRuleOperatorEnum.Equal},
                    {text: labels.notEqual, value: AccXCategoryRuleOperatorEnum.NotEqual},
                    {text: labels.contains, value: AccXCategoryRuleOperatorEnum.Contains},
                    {text: labels.notContains, value: AccXCategoryRuleOperatorEnum.NotContains},
                ];
            case OnboardingParameterTypeEnum.Number:
                return [
                    {text: labels.equal, value: AccXCategoryRuleOperatorEnum.Equal},
                    {text: labels.lessThan, value: AccXCategoryRuleOperatorEnum.LessThan},
                    {text: labels.greaterThan, value: AccXCategoryRuleOperatorEnum.GreaterThan},
                    {text: labels.lessOrQqual, value: AccXCategoryRuleOperatorEnum.LessOrQqual},
                    {text: labels.greaterOrQqual, value: AccXCategoryRuleOperatorEnum.GreaterOrQqual},
                ];
            case OnboardingParameterTypeEnum.Bool:
                return [
                    {text: labels.equal, value: AccXCategoryRuleOperatorEnum.Equal},
                    {text: labels.notEqual, value: AccXCategoryRuleOperatorEnum.NotEqual},
                ];
        }
    }

    function paramKeyChange(id: string, value: any) {

        const selectedParameter = parameters?.find(x => x.parameterKey === value);
        const selectedParameterPossibleOperators = getPossibleOperators(selectedParameter?.parameterType);

        setParameterType(selectedParameter?.parameterType ?? OnboardingParameterTypeEnum.Text);
        setPossibleOperators(selectedParameterPossibleOperators);
        setInnerParameterData({...innerParameterData, 
            onboardingParamKey: value,
            operatorType: selectedParameterPossibleOperators[0].value,
            targetValue: selectedParameter?.parameterValue,
        });
    }

    function operatorTypeChange(id: string, value: any) {
        setInnerParameterData({...innerParameterData, operatorType: value});
    }

    function taregetValueTextChange(id: string, value: string) {
        setInnerParameterData({...innerParameterData, targetValue: value});
    }

    function targetValueDropdownChange(id: string, value: any) {
        setInnerParameterData({...innerParameterData, targetValue: value});
    }
    
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <ComboBoxField id='onboardingParamKey'
                value={innerParameterData?.onboardingParamKey}
                label={labels.parameter}
                items={parameters}
                onChange={paramKeyChange}
                //itemLabelField='parameterName'
                //itemValueField='parameterKey'
                />
        </Grid>
        <Grid item xs={12}>
            <DropdownField id='operatorType'
                value={innerParameterData?.operatorType}
                label={labels.operatorType}
                items={possibleOperators}
                hasDefaultItem={false}
                onChange={operatorTypeChange}
                disabled={!innerParameterData?.onboardingParamKey}/>
        </Grid>
        <Grid item xs={12}>
            {targetValueField}
        </Grid>
    </Grid>
}
