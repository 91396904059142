import { Grid, Typography } from "@mui/material";
import EditForm from "../../../../../components/EditForm";
import AccXTextField from "../../../../../components/fields/AccXTextField";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import NumericField from "../../../../../components/fields/NumericField";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleGeneralTab({categoryRule, setCategoryRule, categoryRuleErrors} : CategoryRuleTabProps) {
    
    const {labels} = useAppContext();

    const generalData = categoryRule.general;

    function textChange(id: string, value: string) {
        setCategoryRule({...categoryRule, general: {...categoryRule.general, [id]: value}});
    }

    function checkboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, general: {...categoryRule.general, [id]: checked}});
    }

    return <EditForm>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AccXTextField id='categoryRuleName' 
                    value={generalData?.categoryRuleName}
                    label={labels.ruleName}
                    onChange={textChange}
                    error={categoryRuleErrors.general.categoryRuleNameError !== ''}
                    helperText={categoryRuleErrors.general.categoryRuleNameError}
                    />
            </Grid>
            <Grid item xs={6}>
                <NumericField id='processingOrder' 
                    value={generalData?.processingOrder}
                    label={labels.processingOrder}
                    onChange={textChange}
                    minValue={1}
                    decimals={0}
                    hasArrows={true}/>
            </Grid>
            <Grid item xs={6}>
                <Typography>{labels.categoryRuleProcessingOrderInfo}</Typography>
            </Grid>
            <Grid item xs={12} marginTop='20px'/>
            <Grid item xs={6}>
                <CheckboxField id='isEnabled' isSwitch
                    checked={generalData?.isEnabled}
                    label={labels.isActive}
                    onChange={checkboxChange}/>
            </Grid>
            <Grid item xs={6}>
                <CheckboxField id='isStoppingProcessing' isSwitch
                    checked={generalData?.isStoppingProcessing} 
                    label={labels.isStoppingProcessing}
                    onChange={checkboxChange}/>
            </Grid>
        </Grid>
    </EditForm>
}
