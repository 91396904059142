import './custom.css'
import '../node_modules/flag-icons/css/flag-icons.min.css';
import DecreePage from './pages/decree/DecreePage';
import { Route, Routes } from 'react-router-dom';
import { pagesNames } from './constants/pageNavigationConstans';
import { UserMode, useUserContext } from './contexts/UserContext';
import CompanyConfigurationPage from './pages/companyConfiguration/CompanyConfigurationPage';
import CompanyDataPanel from './pages/companyConfiguration/companyData/CompanyDataPanel';
import AccountingPolicyPanel from './pages/companyConfiguration/accountingPolicy/AccountingPolicyPanel';
import PurchaseTargetsPanel from './pages/companyConfiguration/purchasePurposes/PurchaseTargetsPanel';
import CategoriesPanel from './pages/companyConfiguration/categories/CategoriesPanel';
import CategoriesRulesPanel from './pages/companyConfiguration/categoriesRules/CategoriesRulesPanel';
import MaterialsAndGoodsMappingPanel from './pages/companyConfiguration/productsMapping/ProductMappingPanel';
import APIDocumentationPanel from './pages/companyConfiguration/apiDocumentation/APIDocumentationPanel';
import TopicPredictionPanel from './pages/companyConfiguration/topicPrediction/TopicPredictionPanel';
import Loader from './components/loader/Loader';
import BusinessClassificationPredictionPanel from './pages/companyConfiguration/businessClassificationPrediction/BusinessClassificationPredictionPanel';
import AboutProjectPanel from './pages/about/AboutProjectPanel';
import DeleteAccountPage from './pages/deleteAccount/DeleteAccountPage';
import TopicAndProductPredictionPanel from './pages/companyConfiguration/topicAndProductPrediction/TopicAndProductPredictionPanel';
import PlatformManagementPage from './pages/platformManagement/PlatformManagementPage';
import AttachUserToNewCustomerPanel from './pages/platformManagement/attachUserToCustomer/AttachUserToNewCustomerPanel';
import AttachUserToExistingCustomerPanel from './pages/platformManagement/attachUserToCustomer/AttachUserToExistingCustomerPanel';
import { useEffect } from 'react';
import { WrapperGA, useAnalyics } from './components/WrapperGA';
import VatRegistersPanel from './pages/companyConfiguration/vatRegisters/VatRegistersPanel';
import MaterialsAndGoodsPanel from './pages/companyConfiguration/materialsAndGoods/MaterialsAndGoodsPanel';


export default function App() {

  // return <>
  //   <Routes>
  //     <Route path='*' Component={DecreePage}/>
  //     <Route path={pagesNames.companyConfiguration} Component={CompanyConfigurationPage}>
  //       <Route path={pagesNames.companyData} Component={CompanyDataPanel}/>
  //       <Route path={pagesNames.accountingPolicy}  Component={AccountingPolicyPanel}/>
  //       <Route path={pagesNames.purchaseTargets} Component={PurchaseTargetsPanel}/>
  //       <Route path={pagesNames.materialsAndGoods} Component={MaterialsAndGoodsPanel}/>
  //       <Route path={pagesNames.accountingCategories} Component={CategoriesPanel}/>
  //       <Route path={pagesNames.accountingRules} Component={CategoriesRulesPanel}/>
  //       <Route path={pagesNames.materialsAndGoodsMapping} Component={MaterialsAndGoodsMappingPanel}/>
  //       <Route path={pagesNames.apiDocumentation} Component={APIDocumentationPanel}/>
  //       </Route>:
  //     <></>
  //   </Routes>
  // </>


  const {userMode, customerProfile, isLoadingData} = useUserContext();
  const {initialized} = useAnalyics();
  
  if (isLoadingData) {
    return <Loader isFullOverlay={true}></Loader>
  }

  return <>
  <WrapperGA initialized={initialized}>
  <Routes>
      <Route path='*' Component={DecreePage}/>
      <Route path={pagesNames.demo} Component={DecreePage}/>
      <Route path={pagesNames.aboutProject} Component={AboutProjectPanel}/>
      <Route path={pagesNames.deleteAccount} Component={DeleteAccountPage}/>
      {(customerProfile?.isAdmin ?? false) && <Route path={pagesNames.platformManagement} Component={PlatformManagementPage}>
            <Route path={pagesNames.attachUserToNewCustomer} Component={AttachUserToNewCustomerPanel}/>
            <Route path={pagesNames.attachUserToExistingCustomer} Component={AttachUserToExistingCustomerPanel}/>
          </Route>}
      {userMode === UserMode.LoggedInWithBC ? 
      <>
        <Route path= {pagesNames.companyConfiguration} Component={CompanyConfigurationPage}>
          <Route path={pagesNames.companyData} Component={CompanyDataPanel}/>
          <Route path={pagesNames.accountingPolicy} Component={AccountingPolicyPanel}/>
          <Route path={pagesNames.vatRegisters} Component={VatRegistersPanel}/>
          <Route path={pagesNames.purchaseTargets} Component={PurchaseTargetsPanel}/>
          <Route path={pagesNames.materialsAndGoods} Component={MaterialsAndGoodsPanel}/>
          <Route path={pagesNames.accountingCategories} Component={CategoriesPanel}/>
          <Route path={pagesNames.accountingRules} Component={CategoriesRulesPanel}/>
          <Route path={pagesNames.materialsAndGoodsMapping} Component={MaterialsAndGoodsMappingPanel}/>
          <Route path={pagesNames.topicPrediction} Component={TopicPredictionPanel}/>
          {(customerProfile?.isAdmin ?? false) && <Route path={pagesNames.topicAndProductPrediction} Component={TopicAndProductPredictionPanel}/>}
          {(customerProfile?.isAdmin ?? false) && <Route path={pagesNames.bcPrediction} Component={BusinessClassificationPredictionPanel}/>}
          
          <Route path={pagesNames.apiDocumentation} Component={APIDocumentationPanel}/>
          </Route> 
      </>
      :
      <></>
      }
    </Routes>
  </WrapperGA>
  </>
}
