import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import EditForm from '../../../components/EditForm';
import SideElementLayout from '../../../layouts/SideElementLayout';
import ActionButton from '../../../components/ActionButton';
import { useApiContext } from '../../../contexts/ApiContext';

import { PlatformManagementApi } from '../../../api';
import AccXTextField from '../../../components/fields/AccXTextField';
import { useAppContext } from '../../../contexts/AppContext';
import { MessageType } from '../../../components/snackbar/MessageType';

export default function AttachUserToExistingCustomerPanel() {

    const [customerLogin, setCustomerLogin] = useState('');
    const [customerTaxId, setCustomerTaxId] = useState('');

    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();

    const platformManagementApi = new PlatformManagementApi();

    function customerLoginChange(id: string, value: string) {
        setCustomerLogin(value);
    }

    function customerTaxIdChange(id: string, value: string) {
        setCustomerTaxId(value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        request(platformManagementApi.platformManagementUserToExistingCustomerGet(customerLogin, customerTaxId)).then((response) => {
            if(response.ok) {
                showMessage(labels.successSaveAttachUserToExistingCustomerMessage);
                setIsLoading(false);
            }else{
                showMessage(labels.errorSaveAttachUserToExistingCustomerMessage, MessageType.Error);
                setIsLoading(false);
            }
        })
    }

    const reset = (resetData = true) => {
        if(resetData) {
            setCustomerLogin('');
            setCustomerTaxId('');
        }
    }

    return <SideElementLayout>
        <EditForm>
            <h4>{labels.enterUserDataToAttachExistingCustomer}</h4>
            <Divider style={{marginBottom: '20px'}}></Divider>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AccXTextField id='customerLogin' 
                            value={customerLogin}
                            label={labels.userLoginAttachUserToCustomer} 
                            onChange={customerLoginChange}/>
                    </Grid>     
                    <Grid item xs={6}>
                    <AccXTextField id='customerTaxId' 
                            value={customerTaxId}
                            label={labels.taxIdToAttach} 
                            onChange={customerTaxIdChange}/>
                    </Grid>        
                    <Grid item xs={12}>
                        <ActionButton type='submit' 
                            style={{float: 'right'}}>
                            {labels.attachUserToExistingCustomerAction}
                        </ActionButton>
                        <ActionButton onClick={() => reset()} className="resetButton">
                            {labels.clean}
                        </ActionButton>
                    </Grid>
                </Grid>
            </form>
        </EditForm>
    </SideElementLayout> 
}
