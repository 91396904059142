import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXPurchasePurposeResult, AccXVehicleUsagePurposeResult, PurchasePurposesApi } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import DropdownField from "../../../../../components/fields/DropdownField";
import NumericField from "../../../../../components/fields/NumericField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRulePurposesTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const purposesAndOtherData = categoryRule.conditions?.purposesAndOther;

    const [purchasePurposes, setPurchasePurposes] = useState<AccXPurchasePurposeResult[]>([]);
    const [isLoadingPurchasePurposes, setIsLoadingPurchasePurposes] = useState(true);

    const [vehicleUsagePurpose, setVehicleUsagePurpose] = useState<AccXVehicleUsagePurposeResult[]>([]);
    const [isLoadingVehicleUsagePurpose, setIsLoadingVehicleUsagePurpose] = useState(true);

    const isLoadingData = isLoadingPurchasePurposes || isLoadingVehicleUsagePurpose; 

    const {request, fetchVehicleUsagePurposes} = useApiContext();
    const {labels} = useAppContext();
    const purchasePurposesApi = new PurchasePurposesApi();

    useEffect(() => {
        request(purchasePurposesApi.purchasePurposesAllGet()).then((response) => {
            if (response.ok && response.data) {
                setPurchasePurposes(response.data);
            }
            setIsLoadingPurchasePurposes(false);
        });

        fetchVehicleUsagePurposes().then((repsonse) => {
            setVehicleUsagePurpose(repsonse);
            setIsLoadingVehicleUsagePurpose(false);
        });
    }, []);

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, purposesAndOther: {...categoryRule.conditions?.purposesAndOther, [id]: checked}}});
    }

    function dropdownChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, purposesAndOther: {...categoryRule.conditions?.purposesAndOther, [id]: value}}});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, purposesAndOther: {...categoryRule.conditions?.purposesAndOther, [id]: value}}});
    }

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{labels.purchasePurpose}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="purchasePurposeIdSpecified" 
                    checked={purposesAndOtherData?.purchasePurposeIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='purchasePurposeId'
                    value={purposesAndOtherData?.purchasePurposeId}
                    label={labels.purchasePurposeId}
                    items={purchasePurposes}
                    //itemLabelField='purchasePurposeName'
                    onChange={comboBoxChange}
                    disabled={!purposesAndOtherData?.purchasePurposeIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="notPurchasePurposeIdSpecified" 
                    checked={purposesAndOtherData?.notPurchasePurposeIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='notPurchasePurposeId'
                    value={purposesAndOtherData?.notPurchasePurposeId}
                    label={labels.notPurchasePurposeId}
                    items={purchasePurposes}
                    //itemLabelField='purchasePurposeName'
                    onChange={comboBoxChange}
                    disabled={!purposesAndOtherData?.notPurchasePurposeIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="isPurchasePurposeEmptySpecified" 
                    checked={purposesAndOtherData?.isPurchasePurposeEmptySpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isPurchasePurposeEmpty'
                    value={purposesAndOtherData?.isPurchasePurposeEmpty}
                    label={labels.isPurchasePurposeEmpty}
                    items={[
                        {text: labels.empty, value: true},
                        {text: labels.unempty, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!purposesAndOtherData?.isPurchasePurposeEmptySpecified}/>
            </Grid>
         
            <Grid item xs={12}>
                <Typography>{labels.vehicles}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="vehicleUsagePurposeIdSpecified" 
                    checked={purposesAndOtherData?.vehicleUsagePurposeIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='vehicleUsagePurposeId'
                    value={purposesAndOtherData?.vehicleUsagePurposeId}
                    label={labels.vehicleUsagePurposeId}
                    items={vehicleUsagePurpose}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!purposesAndOtherData?.vehicleUsagePurposeIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="isVehicleUsagePurposeEmptySpecified" 
                    checked={purposesAndOtherData?.isVehicleUsagePurposeEmptySpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isVehicleUsagePurposeEmpty'
                    value={purposesAndOtherData?.isVehicleUsagePurposeEmpty}
                    label={labels.isVehicleUsagePurposeEmpty}
                    items={[
                        {text: labels.empty, value: true},
                        {text: labels.unempty, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!purposesAndOtherData?.isVehicleUsagePurposeEmptySpecified}/>
            </Grid>

            <Grid item xs={12}>
                <Typography>{labels.additionalInformation}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="additionalInfoContainsSpecified" 
                    checked={purposesAndOtherData?.additionalInfoContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='additionalInfoContains'
                    value={purposesAndOtherData?.additionalInfoContains}
                    label={labels.additionalInfoContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!purposesAndOtherData?.additionalInfoContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="additionalInfoNotContainsSpecified" 
                    checked={purposesAndOtherData?.additionalInfoNotContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
            <ComboBoxField id='additionalInfoNotContains'
                    value={purposesAndOtherData?.additionalInfoNotContains}
                    label={labels.additionalInfoNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!purposesAndOtherData?.additionalInfoNotContainsSpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}