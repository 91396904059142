import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../../../contexts/UserContext';
import { useApiContext } from '../../../contexts/ApiContext';
import EditForm from '../../../components/EditForm';
import ActionButton from '../../../components/ActionButton';
import AccXTextField from '../../../components/fields/AccXTextField';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    correlationId?: string,
    reportData?: any,
}

export default function ReportPanel({correlationId, reportData} : Props) {

    const [searchParams] = useSearchParams();

    const {labels} = useAppContext();
    const {updateReportStatus} = useUserContext();
    const {fetchTopics} = useApiContext();

    const initValues = {
        correlationId: correlationId,
        topicId: 0,
        notes: '',
    };

    const [internalReportData, setInternalReportData] = useState(initValues);
    const [isAlreadyReported, setIsAlreadyReported] = useState(false);
    const [isAlreadyReportedPositive, setIsAlreadyReportedPositive] = useState(false);
    const [isAlreadyReportedNegative, setIsAlreadyReportedNegative] = useState(false);
    const [isAlreadyIgnored, setIsAlreadyIgnored] = useState(false);
    
    const [canIgnoreReport, setCanIgnoreReport] = useState(false);

    const [topics, setTopics] = useState<any[]>([]);
    const [isGetingTopics, setIsGetingTopics] = useState(false);

    const [thanks, setThanks] = useState(false);

    useEffect(() => {
        getTopics();
        
        var canIgnoreReportParam = searchParams.get("canIgnoreReport");
        if (canIgnoreReportParam) {
            setCanIgnoreReport(true);
        }
    }, [searchParams])

    useEffect(() => {
        setIsAlreadyReported(false);
        setIsAlreadyIgnored(false);
        setIsAlreadyReportedNegative(false);
        setIsAlreadyReportedPositive(false);

        if (reportData) {
            setInternalReportData(internalReportData => ({...internalReportData, 
                correlationId: correlationId,
                topicId: reportData.topicId ?? 0,
                notes: reportData.notes ?? ''
            }));

            if (reportData.status === 1) {
                setIsAlreadyReported(true);
                setIsAlreadyReportedPositive(true);
            }
    
            if (reportData.status === 2) {
                setIsAlreadyReported(true);
                setIsAlreadyReportedNegative(true);
            }
    
            if (reportData.status === 3) {
                setIsAlreadyReported(true);
                setIsAlreadyReportedNegative(true);
                setIsAlreadyIgnored(true);
            }
        }
    }, [correlationId, reportData]);

    const getTopics = () => {
        setIsGetingTopics(true);

        fetchTopics().then((response) => {
            setTopics(response);
            setIsGetingTopics(false);
        });
    };

    const topicIdChange = (e: React.ChangeEvent<{}>, newValue: any | null) => {
        setInternalReportData({...internalReportData, topicId: newValue?.id});
    };
    
    function textChange(id: string, value: string) {
        setInternalReportData({...internalReportData, [id]: value});
    };

    const onRedClicked = () => {
        setIsAlreadyReported(true);
        setIsAlreadyReportedNegative(true);
        
        sendReport(2);
    }
    
    const onGreenClicked = () => {
        setIsAlreadyReported(true);
        setIsAlreadyReportedPositive(true);

        sendReport(1);
    }
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendReport(2);

        setThanks(true);

        setTimeout(() => {
            setThanks(false);
        }, 3000);
    }

    const ignoreReport = () => {
        sendReport(3);
    }

    const sendReport = (reportStatusId: number) => {
        if (correlationId) {
            updateReportStatus(correlationId, reportStatusId);
        }

        // request(decreeValidatePost(requestData));
    }

    return <EditForm className='sidePanel reportPanel'>
        <p style={{fontSize: '13px', color: '#42414d'}}>{reportData?.executionTime}</p>
        {!isAlreadyReported && <h4 style={{marginBottom: '20px'}}>{labels.rateUs}</h4>}
        {isAlreadyReportedPositive && <div style={{marginBottom: '20px', fontSize: '16px'}}>
            <h5>
                <span style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon={faCircleCheck} className='green'/>
                </span>
                {labels.positiveRate}
            </h5>
            <p>{labels.thanks}</p>
        </div>}
        {isAlreadyReportedNegative && <div style={{marginBottom: '20px', fontSize: '16px'}}>
            <h5>
                <span style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon={faCircleMinus} className='red'/>
                </span>
                {labels.negativeRate}
            </h5>
        </div> }
        {!isAlreadyReported && <Grid container spacing={2}>
            <Grid item xs={6}>
                <ActionButton className='bg-red'
                    style={{float: 'right'}}
                    type='submit' 
                    onClick={onRedClicked}>
                    {labels.report}
                </ActionButton>
            </Grid>
            <Grid item xs={6}>
                <ActionButton type='submit' onClick={onGreenClicked}>
                    {labels.likeIt}
                </ActionButton>
            </Grid>
        </Grid>}
        {isAlreadyReportedNegative && !isGetingTopics && <form onSubmit={handleSubmit} style={{marginTop: '10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        renderInput={(params) => <TextField {...params} label={labels.selectTopic}/>}
                        value={topics.find((option: any) => option?.id === internalReportData.topicId)}
                        onChange={topicIdChange}
                        options={topics}
                        getOptionLabel={(option) => option.categoryName}
                        />
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='notes' 
                        label={labels.note} 
                        value={internalReportData.notes}
                        onChange={textChange}
                        multiline
                        rows={2}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!isAlreadyIgnored && <ActionButton type='submit' style={{float: 'right'}}>
                        {labels.sendFeedback}
                    </ActionButton>}
                    {canIgnoreReport && isAlreadyReported && !isAlreadyIgnored && <ActionButton type='submit' 
                        style={{float: 'right', marginRight: '10px'}}
                        onClick={ignoreReport}>
                        {labels.ignore}
                    </ActionButton>}
                </Grid>
                {thanks && <Grid item xs={12}>
                    {labels.thanks}
                </Grid>}
            </Grid>
        </form>}
    </EditForm>
}


