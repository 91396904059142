import { Box, Grid, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import NavMenu from "../components/navMenu/NavMenu";
import React from 'react';
import { useAppContext } from "../contexts/AppContext";
import MobileNavMenu from "../components/navMenu/MobileNavMenu";
import Footer from "../components/footer/Footer";
import MobileFooter from "../components/footer/MobileFooter";

type Props = {
    navMenuAction?: JSX.Element,
    pageName?: string
}

export default function MasterLayout({navMenuAction, pageName, children} : PropsWithChildren<Props>) {

    const { isMobile } = useAppContext();

    const childrenArray = React.Children.toArray(children);

    if (isMobile) {
        return <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <div style={{flex: 1}}>
                <MobileNavMenu/>
                {childrenArray[0]}
                {childrenArray[1]}
            </div>
            <MobileFooter/>
        </div>
    }

    return <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <div style={{flex: 1}}>
            <NavMenu contextAction={navMenuAction}/>
            <Grid columns={30} container>
                <Grid item xs={6} style={{padding: '10px', paddingRight: '5px'}}>
                    {childrenArray[0]}
                </Grid>
                <Grid item xs={24} style={{padding: '10px', paddingLeft: '5px'}}>
                    {pageName && pageName != '' && <div className="pageNameBox"><span className="pageNameIcon"></span><span className="pageName">{pageName}</span></div>}
                    {childrenArray[1]}
                </Grid>
            </Grid>
        </div>
        <Footer/>
    </div> 
}