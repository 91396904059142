import { useEffect, useMemo, useState } from "react";
import { AccXCategoryResult, CategoryApi } from "../../../api";
import ActionGridButton from "../../../components/ActionGridButton";
import DropdownFilter from "../../../components/filters/DropdownFilter";
import GridInfo from "../../../components/GridInfo";
import PredefinedTag from "../../../components/PredefinedTag";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import useFilters from "../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../models/ActionDefinition";
import CategoryPopup from "./CategoryPopup";

export default function CategoriesPanel() {

    const [categories, setCategories] = useState<AccXCategoryResult[]>([]);
    
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [isFetchingCategories, setIsFetchingCategories] = useState(false);
    
    const { request } = useApiContext();
    const { labels, showMessage } = useAppContext();

    const [filtersData, addFilter] = useFilters();
    const categoriesApi = new CategoryApi();

    //popup edycji i dodawania
    const [openNewCategoryPopup, setOpenNewCategoryPopup] = useState(false);
    const [openEditCategoryPopup, setOpenEditCategoryPopup] = useState(false);
    
    useEffect(() => {
        fetchCategories();
    }, []);

    const visibleCategories = useMemo(() => {

        var temp = [...categories];

        if (temp.length > 0 && filtersData) {
            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(category => category.categoryName?.toLowerCase().includes(filtersData.searchText.toLowerCase())
                                                || category.account?.toLowerCase().includes(filtersData.searchText.toLowerCase())
                                                || category.accountNonDeductible?.toLowerCase().includes(filtersData.searchText.toLowerCase()))
            };
            
            if (typeof(filtersData.isPredefined) !== 'undefined' && filtersData.isPredefined !== '') {
                var isPredefinedFilter = filtersData.isPredefined === 'true' || filtersData.isPredefined === true;

                temp = temp.filter(category => category.isPredefined === isPredefinedFilter);
            }

            if (typeof(filtersData.hasAccount) !== 'undefined' && filtersData.hasAccount !== '') {
                var hasAccountFilter = filtersData.hasAccount === 'true' || filtersData.hasAccount === true;
            
                if (hasAccountFilter) {
                    temp = temp.filter(category => category.account);
                }
                else {
                    temp = temp.filter(category => !category.account);
                }
            }
        }
        
        return temp;

    }, [categories, filtersData]);
    
    function fetchCategories() {
        setIsFetchingCategories(true);
        
        request(categoriesApi.categoryAllGet()).then((response => {
            if (response.ok && response.data) {
                setCategories(response.data);
            }
            setIsFetchingCategories(false);
        }));
    }

    const closePopups = (refresh=false) => {
        setOpenNewCategoryPopup(false);
        setOpenEditCategoryPopup(false);
        if(refresh){
            fetchCategories();
        }
    }

    function deleteCategory (categoryId : number) {
        request(categoriesApi.categoryCategoryIdDelete(categoryId)).then((response => {
            if (response.ok) {
                showMessage(labels.categoryDeletingSuccess, MessageType.Success);
                fetchCategories();
            }else{
                showMessage(labels.deletingError, MessageType.Error);
            }
        }));
    } 

    var columns = [
        {header: labels.categoryName, field: 'categoryName', customTemplate: (row: any) => {
            var isPredefined = row['isPredefined'];
            var categoryName = row['categoryName'];

            var element = <span>{categoryName}</span>; 
            
            if (isPredefined) {
                element = <PredefinedTag text={categoryName}></PredefinedTag>
            }
            
            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditCategoryPopup(true)}>{element}</a>
        }},
        {header: labels.categoryVatDeduction, field: 'vatDeduction', customTemplate: (row: any) => {
            var vatDeduction = row['vatDeduction'];
            if(vatDeduction){
                return <span>{vatDeduction}%</span>; 
            }
        }},
        {header: labels.categoryCostDeduction, field: 'costDeduction', customTemplate: (row: any) => {
            var costDeduction = row['costDeduction'];

            if (costDeduction === 0) {
                return <span>{labels.costDeduction_NKUP}</span>
            }

            if(costDeduction){
                return <span>{costDeduction}%</span>; 
            }
        }},
        {header: labels.categoryVatRegister, field: 'vatRegisterInfo.vatRegisterName'},
        {header: labels.categoryAccount, field: 'account'},
        {header: labels.categoryAccountNonDeductible, field: 'accountNonDeductible'},
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton className="actionButton" onClick={() => setOpenNewCategoryPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditCategoryPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteCategory(selectedCategories[0])}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
    ];

    const filters = [
        <DropdownFilter fieldName={'isPredefined'} 
            label={labels.isPredefined}
            gridData={categories}  
            items={[
                {label: labels.yes, value: true},
                {label: labels.no, value: false},
            ]}
            filtersData={filtersData}
            addFilter={addFilter}
            />,
        <DropdownFilter fieldName={'hasAccount'} 
            label={labels.categoryAccount}
            gridData={categories}  
            items={[
                {label: labels.filledIn, value: true},
                {label: labels.notFilledIn, value: false},
            ]}
            filtersData={filtersData}
            addFilter={addFilter}
            />
    ];

    return <>
        {openNewCategoryPopup && <CategoryPopup onClose={(refresh = false) => closePopups(refresh)}/>}
        {openEditCategoryPopup && <CategoryPopup categoryId={selectedCategories[0]} onClose={(refresh = false) => closePopups(refresh)}/>}
        
        <GridInfo columns={columns} 
            data={visibleCategories} 
            actions={actions} 
            filters={filters}
            hasSearchBar={true} 
            onSelect={(selected) => setSelectedCategories(selected as number[])} 
            isLoading={isFetchingCategories}
            filtersData={filtersData}
            addFilter={addFilter}
            rowPerPage={100}/>
    </> 
}