import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXOnboardingParameterResult, AccXUnitPriceType, OnboardingApi } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import AccXTextField from "../../../../../components/fields/AccXTextField";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import DropdownField from "../../../../../components/fields/DropdownField";
import NumericField from "../../../../../components/fields/NumericField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { gtuCodes } from "../../../../../dictionaries/gtuCodes";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRulePositionsTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const itemData = categoryRule.conditions?.itemRelated;

    const [parameters, setParameters] = useState<AccXOnboardingParameterResult[]>([]);
    const [isLoadingParameters, setIsLoadingParameters] = useState(true);

    const isLoadingData = isLoadingParameters;

    const {request} = useApiContext();
    const {labels} = useAppContext();
    const onboardingApi = new OnboardingApi();

    useEffect(() => {
        request(onboardingApi.onboardingParametersGet()).then((response) => {
            if (response.ok && response.data) {
                setParameters(response.data);
            }
            setIsLoadingParameters(false);
        });
    }, []);

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: checked}}});
    }
    
    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: value}}});
    }

    function dropdownChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: value}}});
    }

    function textChange(id: string, value: string) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: value}}});
    }

    function dropdownChangeUnitPriceLt(id: string, value: string){
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: value, unitPriceLt: ''}}});
    }

    function dropdownChangeUnitPriceGe(id: string, value: string){
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, itemRelated: {...categoryRule.conditions?.itemRelated, [id]: value, unitPriceGe: ''}}});
    }

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2} columns={24}>
            <Grid item xs={24}>
                <Typography>{labels.positionMeetsConditionsInfo}</Typography>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id="unitPriceLtSpecified" 
                    checked={itemData?.unitPriceLtSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='unitPriceLtType'
                    label={labels.unitPriceType}
                    value={itemData?.unitPriceLtType}
                    items={[
                        {text: labels.unitPriceParameter, value: AccXUnitPriceType.Parameter},
                        {text: labels.unitPriceValue, value: AccXUnitPriceType.Value},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChangeUnitPriceLt}
                    disabled={!itemData?.unitPriceLtSpecified}/>
            </Grid>
            {itemData?.unitPriceLtType == AccXUnitPriceType.Value && <Grid item xs={11}>
                <NumericField id='unitPriceLt'
                    label={labels.unitPriceLtValueLabel}
                    hasArrows={true}
                    placeholder='0,00'
                    decimals={2}
                    onChange={textChange}
                    value={itemData?.unitPriceLt}
                    disabled={!itemData?.unitPriceLtSpecified}/>
            </Grid>}
            {itemData?.unitPriceLtType == AccXUnitPriceType.Parameter && <Grid item xs={11}>
                <ComboBoxField id='unitPriceLt'
                    label={labels.unitPriceParameterLabel}
                    value={itemData?.unitPriceLt}
                    items={parameters}
                    //itemLabelField='parameterName'
                    onChange={comboBoxChange}
                    disabled={!itemData?.unitPriceLtSpecified}/>
            </Grid>}
            <Grid item xs={2}>
                <CheckboxField id="unitPriceGeSpecified" 
                    checked={itemData?.unitPriceGeSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='unitPriceGeType'
                    label={labels.unitPriceType}
                    value={itemData?.unitPriceGeType}
                    items={[
                        {text: labels.unitPriceParameter, value: AccXUnitPriceType.Parameter},
                        {text: labels.unitPriceValue, value: AccXUnitPriceType.Value},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChangeUnitPriceGe}
                    disabled={!itemData?.unitPriceGeSpecified}/>
            </Grid>
            {itemData?.unitPriceGeType == AccXUnitPriceType.Value && <Grid item xs={11}>
                <NumericField id='unitPriceGe'
                    label={labels.unitPriceGeValueLabel}
                    hasArrows={true}
                    placeholder='0,00'
                    decimals={2}
                    onChange={textChange}
                    value={itemData?.unitPriceGe}
                    disabled={!itemData?.unitPriceGeSpecified}/>
            </Grid>}
            {itemData?.unitPriceGeType == AccXUnitPriceType.Parameter && <Grid item xs={11}>
                <ComboBoxField id='unitPriceGe'
                    label={labels.unitPriceParameterLabel}
                    value={itemData?.unitPriceGe}
                    items={parameters}
                    //itemLabelField='parameterName'
                    onChange={comboBoxChange}
                    disabled={!itemData?.unitPriceGeSpecified}/>
            </Grid>}
            <Grid item xs={24}>
                <Typography>{labels.positionInfo}</Typography>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id="itemTextContainsSpecified" 
                    checked={itemData?.itemTextContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <ComboBoxField id='itemTextContains'
                    label={labels.itemTextContains}
                    value={itemData?.itemTextContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!itemData?.itemTextContainsSpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id="itemTextNotContainsSpecified" 
                    checked={itemData?.itemTextNotContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <ComboBoxField id='itemTextNotContains'
                    label={labels.itemTextNotContains}
                    value={itemData?.itemTextNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!itemData?.itemTextNotContainsSpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id='isItemTextEmptySpecified' 
                    checked={itemData?.isItemTextEmptySpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <DropdownField id='isItemTextEmpty'
                    value={itemData?.isItemTextEmpty}
                    label={labels.isItemTextEmpty}
                    items={[
                        {text: labels.empty, value: true},
                        {text: labels.unempty, value: false},
                    ]}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={!itemData?.isItemTextEmptySpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id='gtuSpecified' 
                    checked={itemData?.gtuSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <DropdownField id='gtu'
                    value={itemData?.gtu}
                    label={labels.gtu}
                    items={gtuCodes}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={!itemData?.gtuSpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id='notGtuSpecified' 
                    checked={itemData?.notGtuSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <DropdownField id='notGtu'
                    value={itemData?.notGtu}
                    label={labels.notGtu}
                    items={gtuCodes}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={!itemData?.notGtuSpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id='isGtuEmptySpecified' 
                    checked={itemData?.isGtuEmptySpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <DropdownField id='isGtuEmpty'
                    value={itemData?.isGtuEmpty}
                    label={labels.isGtuEmpty}
                    items={[
                        {text: labels.empty, value: true},
                        {text: labels.unempty, value: false},
                    ]}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={!itemData?.isGtuEmptySpecified}/>
            </Grid>
            <Grid item xs={2}>
                <CheckboxField id='vatRateSpecified' 
                    checked={itemData?.vatRateSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={22}>
                <AccXTextField id='vatRate'
                    value={itemData?.vatRate}
                    label={labels.vatRate}
                    onChange={textChange}
                    disabled={!itemData?.vatRateSpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}