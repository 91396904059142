import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, AutocompleteValue, Chip, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";

type Props = {
    id?: string,
    value?: any,
    label?: string,
    items?: any[]
    // itemLabelField?: string,
    // itemValueField?: string,
    onChange?: (id: string, value: any) => void,
    disableClearable?: boolean,
    isLoading?: boolean,
    disabled?: boolean,
    multiple?: boolean,
    freeSolo?: boolean,
    error?: boolean,
    helperText?: string,
    required?: boolean,
    hasEmptyOption?: boolean,
    filterSelectedOptions?: boolean,
}

export interface ComboBoxItem {
    text: string,
    value: any,
    isCustomValue: boolean,
}

export default function ComboBoxField({
        id, 
        value, 
        label, 
        items = [],
        disableClearable, 
        isLoading = false, 
        disabled=false, 
        multiple=false, 
        freeSolo=false, 
        error=false, 
        helperText, 
        required=false, 
        hasEmptyOption=false,
        filterSelectedOptions=false,
        onChange, 
    } : Props) {
    
    const [innerValue, setInnerValue] = useState<any[] | any>(multiple ? [] : null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (multiple) {
            if (!freeSolo) {
                var tempArray: any[] = [];
                
                if (value) {
                    (value as any[]).forEach((x) => {
                        // var temp = items?.find((y) => y[itemValueField] === x);
        
                        // if (temp) {
                        //     tempArray.push(temp[itemLabelField]);
                        // }

                        var temp = items?.find((y) => y.value === x);
        
                        if (temp) {
                            tempArray.push(temp.text);
                        }
                    });
                }
    
                setInnerValue(tempArray);
            }
            else {
                if (value) {
                    setInnerValue(value);
                }
            }
        }
        else {  
            var initValue: any = null;

            if (value || hasEmptyOption) {
                var item = items?.find(x => x.value == value);
    
                if (item) {
                    initValue = item;
                }
                else if (freeSolo) {
                    initValue = value;
                }
            }

            setInnerValue(initValue);
        }
    }, [value, items])

    function handleChange(event: any, incomeValue: any) {
        var outerValue = incomeValue;
        var innerValue = incomeValue;

        if (!multiple) {
            if (incomeValue) {
                if (typeof incomeValue !== 'string')
                {
                    innerValue = incomeValue.text;
                    outerValue = incomeValue.value;
                }
            }
            else {
                if (hasEmptyOption) {
                    var item = items?.find(x => x.value == value);
                    
                    if (item) {
                        innerValue = item.text;
                    }
                }
            }
        }

        if (multiple && innerValue.length > 0) {
            if (freeSolo) {
                var lastItem = innerValue.pop();
    
                if (typeof lastItem === 'string') {
                    if (lastItem.includes(';')) {
                        var cleanItem = lastItem.replace(/\s/g, '');
        
                        var splitted = cleanItem.split(';');
                        var splittedUnique: string[] = [];
        
                        splitted.forEach((x) => {
                            if (!innerValue.includes(x) && x !== '') {
                                splittedUnique.push(x);
                            }
                        });
        
                        innerValue = innerValue.concat(splittedUnique);
                    }
                    else {
                        if (!innerValue.includes(lastItem)) {
                            innerValue.push(lastItem);
                        }
                    }
                    
                    outerValue = innerValue;
                }
            }
            else {
                var lastItem = innerValue.at(-1);
                
                if (typeof lastItem !== 'string') {
                    innerValue.pop();
                    //innerValue.push(lastItem[itemLabelField]);
                    innerValue.push(lastItem.text);
                }
            
                outerValue = [];

                (innerValue as any[]).forEach((x) => {
                    // var item = items.find((y) => y[itemLabelField] === x);
                
                    // outerValue.push(item[itemValueField]);

                    var item = items.find((y) => y.text === x);
                
                    outerValue.push(item?.value);
                });
            }
        }

        setInnerValue(innerValue);
        if(onChange) onChange(id ?? '', outerValue);
    }

    function handleInputChange(event: any, newValue: string) {

        if (!multiple && freeSolo) {
            setInnerValue(newValue);
            if(onChange) onChange(id ?? '', newValue);
        }

        setInputValue(newValue);
    }

    function handleOnKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    const autoCompleteField = <Autocomplete id={id}
        options={items}
        autoSelect={multiple && freeSolo}
        value={innerValue}
        inputValue={inputValue}
        onChange={handleChange}
        onKeyDown={handleOnKeyDown}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => {

            if (typeof option === 'string') {
                return option;
            }
            return option.text;
            //return option[itemLabelField];
        }}
        renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} required={required}/>}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
                {option.isCustomValue && <Box>
                    {option.text}<FontAwesomeIcon icon={faBolt} color='var(--details)' style={{marginLeft:'5px'}}></FontAwesomeIcon>
                  </Box>}
                {!option.isCustomValue && <Box>
                    {option.text}
                  </Box>} 
            </li>
        )}
        filterSelectedOptions={filterSelectedOptions}
        fullWidth
        disableClearable={hasEmptyOption ? true : disableClearable}
        disabled={disabled}
        multiple={multiple}
        freeSolo={freeSolo}
        renderTags={(value: readonly any[], getTagProps) =>
            value.map((option: string, index: number) => {
                return <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            })
        }
        isOptionEqualToValue={(option: any, value: any) => {
            var result = false;
        
            
            if (multiple) {
                var optionLabel = option.text;
                result = optionLabel === value;
            }
            else {
                if (typeof option !== 'string' && typeof value !== 'string') {
                    result = option.value == value.value;
                }
                else if (typeof option !== 'string') {
                    var optionLabel = option.text;
                    result = optionLabel === value;
                }
                else {
                    result = option == value;
                }
            }

            return result;
        }}
    />

    const loader = <Box style={{margin:'5px', textAlign: 'center'}}>
        <CircularProgress style={{color: 'var(--details)', width: '25px', height: '25px'}}/>
    </Box>

    return isLoading ?  loader : autoCompleteField
}