import { useState } from "react";
import { AccXBCPredictionDataResult, AccXPrediction, AccXProductPredictionResult, AccXTopicAndProductPredictionResult, AccXTopicPredictionResult, AccXTopTopicPredictionResult, PredictionApi } from "../../../api";
import PanelInfo from "../../../components/PanelInfo";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import SideElementLayout from "../../../layouts/SideElementLayout";
import ContractorPanel from "../../decree/contractor/ContractorPanel";
import DecreePanel, { DecreeMode } from "../../decree/DecreePanel";
import ProductPanel from "../../decree/product/ProductPanel";
import TopicPanel from "../../decree/topic/TopicPanel";


export default function TopicAndProductPredictionPanel() {

    const initValues : AccXPrediction = {
        taxId: '',
        contractorName: '',
        positionText: '',
        gtuCode: '',
        pkwiu: '',
        cn: '',
        vatRate: '',
        hasRegNum: false,
        vehicleUsagePurposeId: -1,
    }
    
    const [predictionData, setPredictionData] = useState(initValues);

    const [contractorData, setContractorData] = useState<AccXBCPredictionDataResult | null>(null);
    const [isContractorPanelActive, setIsContractorPanelActive] = useState(false);

    const [topTopicData, setTopTopicData] = useState<AccXTopTopicPredictionResult | null>(null);

    const [topicData, setTopicData] = useState<AccXTopicPredictionResult[] | null>(null);
    const [isTopicPanelActive, setIsTopicPanelActive] = useState(false);
    
    const [productPredictionData, setProductPredictionData] = useState<AccXProductPredictionResult | null>(null);
    const [isProductPanelActive, setIsProductPanelActive] = useState(false);

    const [topicTags, setTopicTags] = useState<Array<string>>([]);

    const [isNoResultPanelActive, setIsNoResultPanelActive] = useState(false);

    const {labels, setIsLoading} = useAppContext();
    const {request} = useApiContext();
    const predictionApi = new PredictionApi();

    function reset(resetPredictionData = true) {

        if (resetPredictionData) {
            setPredictionData(initValues);
        }
        
        setIsNoResultPanelActive(false);

        setContractorData(null);
        setIsContractorPanelActive(false);
        
        setTopicData(null);
        setIsTopicPanelActive(false);
      
        setProductPredictionData(null);
        setIsProductPanelActive(false);
    }

    function predict() {
        reset(false);
        setIsLoading(true);

        request(predictionApi.predictionTopicAndProductPost(predictionData)).then((response) =>{
            if (response.ok && response.data) {
                handleResponse(response.data);
            }
            setIsLoading(false);
        });
    }

    function handleResponse(data: AccXTopicAndProductPredictionResult) {
        //brak tematu, produktu, kategorii lub wyjasnienia
        if (!data.topicPrediction && !data.productPrediction) {
            setIsNoResultPanelActive(true);
        }

        //kontrahent
        if (data.topicPrediction && data.topicPrediction.accXBCPredictionDataResult) {
            setContractorData(data.topicPrediction.accXBCPredictionDataResult);
            setIsContractorPanelActive(true);
        }
        else {
            setContractorData(null);
            setIsContractorPanelActive(false);
        }

        //temat
        if (data.topicPrediction && data.topicPrediction.topicPredictionResult && data.topicPrediction.accXTopTopicPredictionResult) {
            setTopicData(data.topicPrediction.topicPredictionResult);
            setTopTopicData(data.topicPrediction.accXTopTopicPredictionResult);
            setIsTopicPanelActive(true);
        }
        else {
            setTopicData(null);
            setIsTopicPanelActive(false);
        }
        if(data.topicPrediction && data.topicPrediction.tags) {
            setTopicTags(data.topicPrediction.tags);
        }

        //produkt
        if(data.productPrediction){
            setProductPredictionData(data.productPrediction);
            setIsProductPanelActive(true);
        }
    }

    return <SideElementLayout>
        <DecreePanel decreeMode={DecreeMode.TopicAndProduct} decreeData={predictionData} initValues={initValues} setDecreeData={setPredictionData} reset={reset} decree={predict} showAddExample={false}/>
        {isContractorPanelActive && contractorData && <ContractorPanel contractorData={contractorData}/>}
        {isProductPanelActive && productPredictionData && productPredictionData.product != null && <ProductPanel productPrediction={productPredictionData}/>}
        {isTopicPanelActive && topicData && topTopicData && <TopicPanel topicData={topicData} topPrediction={topTopicData} tags={topicTags}/>}
        {isNoResultPanelActive && <PanelInfo text={labels.noDataResultInfo}></PanelInfo>}
    </SideElementLayout>
}