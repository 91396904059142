import { ListItemButton } from "@mui/material"
import { Link } from "react-router-dom"
  
type Props = {
    parentLocation?: string,
    to: string,
    text: string 
    target?: React.HTMLAttributeAnchorTarget,
    sndLevel?: boolean
}

export default function MenuListItemButton({parentLocation, to, text, target, sndLevel} : Props) {

    var toPath = `./${to}`;

    if (sndLevel) {
        toPath = '.' + toPath;
    }

    return <ListItemButton component={Link}
        target={target}
        to={toPath} 
        //selected={parentLocation ? "/"+parentLocation+"/"+to === window.location.pathname : "/"+to === window.location.pathname}
        >
            {text}
      </ListItemButton>
}
