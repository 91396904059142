import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccXCustomerProfileResult, AccXUser, AuthApi, OnboardingApi, UserApi } from '../api';
import { addHistoryItem, getHistoryItems, removeHistoryItems, updateHisotryItemReportStatus } from '../helpers/HistoryHelper';
import { handleResponse } from '../helpers/RequestHelper';
import { HistoryItem } from '../models/HistoryItem';
import { useAppContext } from './AppContext';

type UserContextType = {
    isSigned: boolean,
    user?: AccXUser | null,
    userMode: UserMode,
    historyItems: HistoryItem[],
    customerProfile?: AccXCustomerProfileResult | null,
    refreshCustomerProfile: () => void,
    signOut: () => void,
    addToHistory: (historyItem: HistoryItem) => void,
    cleanHistory: () => void,
    updateReportStatus: (correlationId: string, reportStatusId: number) => void,
    isLoadingData: boolean,
    resetAllData: boolean
};

enum UserMode {
    NotLoggedIn = 0,
    LoggedIn = 1,
    LoggedInWithBC = 2,
}

const UserContext = createContext<UserContextType | undefined>(undefined);
const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within an UserContextProvider');
    }
    return context;
};

function UserContextProvider({children} : PropsWithChildren) {

    const navigate = useNavigate();
    
    const [user, setUser] = useState<AccXUser | null>(null);
    const [userMode, setUserMode] = useState<UserMode>(UserMode.NotLoggedIn);
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);

    const [customerProfile, setCustomerProfile] = useState<AccXCustomerProfileResult | null>(null);
    const [isLoadingCustomerData, setIsLoadingCustomerData] = useState(true);

    const [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);
    const [resetAllData, setResetAllData] = useState(false);
    const isLoadingData = isLoadingUserData || isLoadingCustomerData;

    const isSigned = userMode > UserMode.NotLoggedIn;

    const userApi = new UserApi();
    const onboardingApi = new OnboardingApi();
    const authApi = new AuthApi();

    const {labels, showMessage, setIsUpdating} = useAppContext();

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        refreshHistory();
    }, [user]);

    useEffect(() => {
        if (user && customerProfile) {
            if (customerProfile.hasCustomerAssigned) {
                setUserMode(UserMode.LoggedInWithBC);
            }
            else {
                setUserMode(UserMode.LoggedIn);
            }
        } else {
            setUserMode(UserMode.NotLoggedIn);
        }

    }, [user, customerProfile])

    function refreshHistory() {
        var historyItems = getHistoryItems(user);
        setHistoryItems(historyItems);
    }

    function cleanHistory() {
        removeHistoryItems(user);
        refreshHistory();
    }

    function addToHistory(historyItem: HistoryItem) {
        addHistoryItem(user, historyItem);
        refreshHistory();
    }

    function updateReportStatus(correlationId: string, reportStatusId: number) {
        updateHisotryItemReportStatus(user, correlationId, reportStatusId);
        refreshHistory();
    }

    function fetchUser() {

        userApi.userGet().then((response) => {
            if (response.ok && response.data) {
                setIsLoadingUserData(false);

                setUser(response.data);
                fetchCustomerProfile();
            } else {
                setUser(null);
                setCustomerProfile(null);

                setIsLoadingUserData(false);
                setIsLoadingCustomerData(false);
            }
        }).catch((error) => {
            setUser(null);
            setIsLoadingUserData(false);
            setCustomerProfile(null);
            setIsLoadingCustomerData(false);
        })
    }

    function fetchCustomerProfile() {
        onboardingApi.onboardingProfilesGet().then((response) => { return handleResponse(response, setIsUpdating)}).then((response) => {
            if (response.ok && response.data) {
                setIsLoadingCustomerData(false);
                setCustomerProfile(response.data);
            } else {
                setIsLoadingCustomerData(false);
                setCustomerProfile(null);
            }
        }).catch((error) => {
            setCustomerProfile(null);
            setIsLoadingCustomerData(false);
        })
    }

    function refreshCustomerProfile() {
        fetchCustomerProfile();
    }

    function signOut() {
        authApi.authSignOutPost().then((response) => {
            if (response.ok) {
                setUser(null);
                setCustomerProfile(null);
                setUserMode(UserMode.NotLoggedIn);
                setResetAllData(true);
                navigate('/');
                showMessage(labels.signOutSuccessfully);
            }
        })
    }

    const userContextValue : UserContextType = {
        isSigned,
        user,
        userMode,
        customerProfile,
        refreshCustomerProfile,
        historyItems,
        signOut,
        addToHistory,
        cleanHistory,
        updateReportStatus,
        isLoadingData,
        resetAllData
    }

    return <UserContext.Provider value={userContextValue}>
        { children }
    </UserContext.Provider>
}

export { useUserContext, UserContextProvider, UserMode};