import { CircularProgress, Paper } from "@mui/material"
import { Box } from "@mui/system"
import { PropsWithChildren } from "react"

type Props = {
    text?: JSX.Element | string,
    isLoading?: boolean
}

export default function MarketingInfo({text, isLoading=false, children} : PropsWithChildren<Props>) {
    return <Paper style={{marginTop: '10px'}}> 
        
        {isLoading ? 
        <Box style={{margin:'20px', textAlign: 'center'}}>
            <CircularProgress style={{color: 'var(--details)'}}/>
        </Box>
        :
        <Box style={{padding: '20px'}}>
            <div>
                {text}
            </div>
            {children}
        </Box>}
        
    </Paper>
}