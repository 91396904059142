import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccXBusinessClassificationResult } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleBuyerTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const {labels} = useAppContext();

    const buyerData = categoryRule.conditions?.buyerRelated;

    const [businessClassifications, setBusinessClassifications] = useState<AccXBusinessClassificationResult[]>([]);
    const [isLoadingBusinessClassifications, setIsLoadingBusinessClassifications] = useState(true);

    const isLoadingData = isLoadingBusinessClassifications;

    const {fetchBusinessClassifications} = useApiContext();

    useEffect(() => {
        fetchBusinessClassifications().then((response) => {
            setBusinessClassifications(response);
            setIsLoadingBusinessClassifications(false);
        })
    }, []);

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, buyerRelated: {...categoryRule.conditions?.buyerRelated, [id]: checked}}});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, buyerRelated: {...categoryRule.conditions?.buyerRelated, [id]: value}}});
    }

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{labels.buyerRuleInfo}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="businessClassificationIdSpecified" 
                    checked={buyerData?.businessClassificationIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='businessClassificationId'
                    label={labels.businessClassification}
                    value={buyerData?.businessClassificationId}
                    items={businessClassifications}
                    //itemLabelField='businessClassificationName'
                    onChange={comboBoxChange}
                    disabled={!buyerData?.businessClassificationIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="notBusinessClassificationIdSpecified" 
                    checked={buyerData?.notBusinessClassificationIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='notBusinessClassificationId'
                    label={labels.notBusinessClassification}
                    value={buyerData?.notBusinessClassificationId}
                    items={businessClassifications}
                    //itemLabelField='businessClassificationName'
                    onChange={comboBoxChange}
                    disabled={!buyerData?.notBusinessClassificationIdSpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}