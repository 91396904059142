import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { plPL } from '@mui/material/locale';
import Loader from '../components/loader/Loader';
import Message from '../components/snackbar/Message';
import { MessageType } from '../components/snackbar/MessageType';
import { CommonApi } from '../api';
import { plTranslations } from '../resources/translations/pl_PL';
import { enTranslations } from '../resources/translations/en_EN';
import { languages } from '../constants/languages';
import { deTranslations } from '../resources/translations/de_DE';
import { useCookies } from 'react-cookie';
import { cookiesNames } from '../constants/cookiesNames';

type AppContextType = {
    isMobile: boolean,
    engineVersion: string,
    labels: ITranslations,
    language: languages,
    setIsLoading: (isLoading: boolean) => void,
    setIsUpdating: (isUpdating: boolean) => void,
    setLanguage: React.Dispatch<React.SetStateAction<languages>>,
    showMessage: (message: string, messageType?: MessageType) => void,
};

const AppContext = createContext<AppContextType | undefined>(undefined);

const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }
    return context;
};

function AppContextProvider({children} : PropsWithChildren) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
 
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<MessageType>(MessageType.Success);
 
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [engineVersion, setEngineVersion] = useState('');
    const commonApi = new CommonApi();

    function showMessage(message: string, messageType: MessageType = MessageType.Success) {
        setMessage(message);
        setMessageType(messageType);
        setIsMessageVisible(true);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

    useEffect(() => {
      // Update the window width in the state whenever the window is resized
      function handleResize() {
        setIsMobile(window.innerWidth < 1000);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        commonApi.commonEngineVersionGet().then((response) => {
            if (response.ok && response.data) {
                setEngineVersion(response.data);
            }
        });
    }, []);

    const theme = createTheme(
        {

            components: {
                MuiTextField: {
                    defaultProps:{
                        size: 'small',
                        fullWidth: true,
                        
                    },                    
                    styleOverrides: {
                        root: {
                            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
                                borderColor: 'var(--details) !important' 
                            },
                            '& label.Mui-focused': {
                                color: 'var(--details) !important',
                            },
                            
                        },
                       
                    },
                    
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'var(--lightGrey)'
                        }
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'var(--lightGrey)'
                        }
                    }
                },
                MuiOutlinedInput:{
                    styleOverrides:{
                        root:{
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "var(--details) !important"
                            },
                        }
                    }
                },
                MuiInputLabel:{
                    styleOverrides:{
                        root:{
                            "&.Mui-focused": {
                                color: "var(--details) !important"
                              },
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                      listbox: {
                        '& .MuiAutocomplete-option[aria-selected="true"]': { 
                          backgroundColor: 'var(--lightDetails)',
                        },
                        '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { 
                            backgroundColor: 'var(--lightDetails)',
                          },
                      },
                    },
                },
                MuiMenuItem:{
                    styleOverrides: { 
                        root: {
                          "&.Mui-selected": {       
                            backgroundColor: "var(--lightDetails) !important", 
                          },
                        },
                    }
                },
                MuiButton:{
                    styleOverrides:{
                        root:{
                            textTransform: 'none',
                            fontSize: '16px'
                        }
                    }
                },
                MuiTab: {
                    styleOverrides:{
                        root: {
                            minHeight: '48px',
                            height: '48px'
                        }
                    }
                },
                MuiListItemButton:{
                    styleOverrides: {
                        root: {
                            '&:hover':{
                                backgroundColor:  'var(--lightDetailsSelected) !important',
                                color: 'var(--appBar)'
                            }
                        }
                    }
                },
                MuiTableRow: {
                    styleOverrides: {
                      root: {
                        '&:hover': {
                          backgroundColor:  'var(--lightDetailsSelected) !important',
                        },
                        '&.Mui-selected': {
                          backgroundColor: 'var(--lightDetails) !important',
                        },
                        "& .MuiTableCell-head": {
                            backgroundColor: "var(--gridHeader)"
                        },
                      },
                    },
                },
                MuiSwitch:{
                    styleOverrides: {
                        root: {
                            "& .MuiSwitch-switchBase.Mui-checked": {
                                color: "var(--appBar)"
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                backgroundColor: "var(--appBar)"
                            }
                        },
                        
                    }
                },
                MuiCheckbox: {
                    styleOverrides:{
                        root: {
                            '&.Mui-checked': {
                                color: 'rgb(78, 195, 224)',
                              },
                        },
                    }
                },
                
            }
        },
        {
            palette: {
                success: {
                    main: '#A1E043',
                },
                error:{
                    main: '#e3574c'
                }
            },
        },
        plPL,
    );

    const [cookies, setCookie] = useCookies([cookiesNames.language]);
    const [language, setLanguage] = useState(cookies[cookiesNames.language]);

    useEffect(() => {
        setCookie(cookiesNames.language, language ?? languages.pl);
    }, [language]);

    const labels = useMemo(() => {
        switch(language) {
            default:
            case languages.pl:
                return plTranslations;
            case languages.de:
                return deTranslations;
            case languages.gb: 
                return enTranslations;
        }
    }, [language]);

    const appContextValue : AppContextType = {
        isMobile,
        engineVersion,
        labels,
        language,
        setIsLoading,
        setIsUpdating,
        showMessage,
        setLanguage,
    };

    return <AppContext.Provider value={appContextValue}>
        <ThemeProvider theme={theme}>
            <Message message={message} messageType={messageType} isMessageVisible={isMessageVisible} setIsMessageVisible={setIsMessageVisible}/>
            {isLoading && <Loader/>}
            {isUpdating && <Loader text={labels.systemIsUpdating}></Loader>}
            { children }
        </ThemeProvider>
    </AppContext.Provider>
}

export { useAppContext, AppContextProvider };