import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXBusinessClassificationResult } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import AccXTextField from "../../../../../components/fields/AccXTextField";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import DropdownField from "../../../../../components/fields/DropdownField";
import NumericField from "../../../../../components/fields/NumericField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleSellerTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const sellerData = categoryRule.conditions?.sellerRelated;

    const [businessClassifications, setBusinessClassifications] = useState<AccXBusinessClassificationResult[]>([]);
    const [isLoadingBusinessClassifications, setIsLoadingBusinessClassifications] = useState(true);
    
    const isLoadingData = isLoadingBusinessClassifications;

    const {fetchBusinessClassifications} = useApiContext();
    const {labels} = useAppContext();

    useEffect(() => {
        fetchBusinessClassifications().then((response) => {
            setBusinessClassifications(response);
            setIsLoadingBusinessClassifications(false);
        })
    }, []);

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, sellerRelated: {...categoryRule.conditions?.sellerRelated, [id]: checked}}});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, sellerRelated: {...categoryRule.conditions?.sellerRelated, [id]: value}}});
    }

    function textChange(id: string, value: string) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, sellerRelated: {...categoryRule.conditions?.sellerRelated, [id]: value}}});
    }

    function dropdownChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, sellerRelated: {...categoryRule.conditions?.sellerRelated, [id]: value}}});
    }

    return <EditForm isLoading={isLoadingData}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{labels.sellerRuleInfo}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="businessClassificationIdSpecified" 
                    checked={sellerData?.businessClassificationIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='businessClassificationId'
                    label={labels.businessClassification}
                    value={sellerData?.businessClassificationId}
                    items={businessClassifications}
                    //itemLabelField='businessClassificationName'
                    onChange={comboBoxChange}
                    disabled={!sellerData?.businessClassificationIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="notBusinessClassificationIdSpecified" 
                    checked={sellerData?.notBusinessClassificationIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='notBusinessClassificationId'
                    label={labels.notBusinessClassification}
                    value={sellerData?.notBusinessClassificationId}
                    items={businessClassifications}
                    //itemLabelField='businessClassificationName'
                    onChange={comboBoxChange}
                    disabled={!sellerData?.notBusinessClassificationIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="nameContainsSpecified" 
                    checked={sellerData?.nameContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='nameContains'
                    label={labels.nameContains}
                    value={sellerData?.nameContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!sellerData?.nameContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id="nameNotContainsSpecified" 
                    checked={sellerData?.nameNotContainsSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='nameNotContains'
                    label={labels.nameNotContains}
                    value={sellerData?.nameNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!sellerData?.nameNotContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='taxIdSpecified' 
                    checked={sellerData?.taxIdSpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <AccXTextField id='taxId'
                    value={sellerData?.taxId}
                    label={labels.taxId}
                    onChange={textChange}
                    disabled={!sellerData?.taxIdSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='isTaxIdEmptySpecified' 
                    checked={sellerData?.isTaxIdEmptySpecified} 
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isTaxIdEmpty'
                    value={sellerData?.isTaxIdEmpty}
                    label={labels.isTaxIdEmpty}
                    items={[
                        {text: labels.yes, value: true},
                        {text: labels.no, value: false},
                    ]}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={!sellerData?.isTaxIdEmptySpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}