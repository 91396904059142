import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faShop } from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/system';
import { AccXProductPredictionResult } from '../../../api';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    productPrediction: AccXProductPredictionResult,
}

export default function ProductPanel({productPrediction} : Props) {

    const {labels} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
            <Stack>
                <Typography>{<FontAwesomeIcon icon={faShop}/>} {labels.productPanelTitle}</Typography>
                <div style={{display: 'flex'}}>
                    {productPrediction.product && <Typography fontWeight='bold' display="inline"> {productPrediction.product?.productName} </Typography>} 
                    {productPrediction.product && productPrediction.product.productIndex && <Typography display="inline" style={{marginLeft: '5px'}}>({productPrediction.product.productIndex})</Typography>}
                </div>
                {productPrediction.confidence && <span className={getCertaintyLevelClass(productPrediction.confidence ?? 0)}></span>}
            </Stack>
        </AccordionSummary>
        <AccordionDetails>
                
            
            {productPrediction.product && productPrediction.product.productGroupName && <Typography>{labels.productGroupNameProductPanelLabel}: {productPrediction.product.productGroupName}</Typography>}
            {productPrediction.product?.productTags && productPrediction.product.productTags.map((globalTag: any, globalTagIndex: number) => {
                        return <span className='tag small' style={{backgroundColor: 'var(--details)'}} key={globalTagIndex}>{globalTag}</span>
            })}
            {productPrediction.why && productPrediction.why.map((reason: any, reasonIndex: number) => {
                return <Typography key={reasonIndex} style={{fontSize:'12px'}}>{reason}</Typography>
            })}
        </AccordionDetails>
    </Accordion>
}

function getCertaintyLevelClass(certaintyLevel: number) {
    if (certaintyLevel > 0) {
        return "tag bg-green";
    } 
    if (certaintyLevel === 0) {
        return "tag bg-yellow";
    } 
    return "tag bg-red";
}