import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

type Props = {
    fieldName: string,
    label: string,
    filtersData: any,
    addFilter: (paramName: string, paramValue: any) => void,
    isNotCurved?: boolean
}

export default function TextFilter({fieldName, label, filtersData, addFilter, isNotCurved = false} : Props) {

    const [searchText, setSearchText] = useState('');
    
    useEffect(() => {
        setSearchText(filtersData?.searchText ?? '');
    }, [filtersData]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        addFilter(fieldName, e.target.value);
    }

    return <TextField value={searchText} label={searchText === '' || searchText == null ? label : ''} style={{backgroundColor: 'white', borderRadius: '4px'}}
        onChange={handleChange}
        InputLabelProps={{shrink: false}}
        variant="outlined"/>
}
