import { Grid, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CategoryApi, AccXCategory, AccXCategoryResult, AccXVatRegisterResult, VatRegisterApi } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import AccXTextField from "../../../components/fields/AccXTextField";
import ComboBoxField from "../../../components/fields/ComboBoxField";
import DropdownField from "../../../components/fields/DropdownField";
import NumericField from "../../../components/fields/NumericField";
import Popup from "../../../components/Popup";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import { costDeduction } from "../../../dictionaries/costDeduction";
import { vatDeduction } from "../../../dictionaries/vatDeduction";

type Props = {
    categoryId?: number,
    onClose: (refresh: boolean) => void,
};

export default function CategoryPopup({categoryId, onClose}: Props) : JSX.Element {
    
    const isEditMode = categoryId && true;
    const initValues: AccXCategory = {
        categoryName: '',
        account: '',
        costDeduction: undefined,
        vatDeduction: undefined,
        vatRegisterId: undefined,
        accountNonDeductible: '',
    };

    const { request } = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    
    const [categoryData, setCategoryData] = useState<AccXCategory>(initValues);
    const [isFetchingCategory, setIsFetchingCategory] = useState(false);
    
    const [vatRegisters, setVatRegisters] = useState<AccXVatRegisterResult[]>([]);
    const [isFetchingVatRegisters, setIsFetchingVatRegisters] = useState(false);

    const [isPredefinedCategory, setIsPredefinedCategory] = useState(false);
    const [categoryOriginalName, setCategoryOriginalName] = useState('');
    
    const isLoadingData = isFetchingCategory || isFetchingVatRegisters;

    const categoryApi = new CategoryApi();
    const vatRegisterApi = new VatRegisterApi();

    useEffect(() => {
        if (isEditMode) {
            setIsFetchingCategory(true);
            request(categoryApi.categoryCategoryIdGet(categoryId)).then((response) => {
                if(response.ok && response.data) {
                    setCategoryData(response.data);
                    setCategoryOriginalName(response.data.categoryName ?? '');
                    setIsPredefinedCategory(response.data?.isPredefined ?? false);
                }
                setIsFetchingCategory(false);
            });
        }

        fetchVatRegisters();
    }, []);

    function fetchVatRegisters() {
        setIsFetchingVatRegisters(true);
        request(vatRegisterApi.vatRegisterAllGet()).then((response) => {
            if(response.ok && response.data) {
                setVatRegisters(response.data);
            }
            setIsFetchingVatRegisters(false);
        });
    }

    function dropdownChange(id: string, value: any) {
        setCategoryData({...categoryData, [id]: value});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryData({...categoryData, [id]: value});
    }

    const isAccountNonDeductibleVisible = useMemo(() => {

        var value = categoryData.costDeduction;

        if (value && 0 < value && value < 100) {
            return true;
        }
        else {
            setCategoryData({...categoryData, accountNonDeductible: ''})
            return false;
        }

    }, [categoryData.costDeduction])

    const textChange = (id: string, value: string) => {
        setCategoryData({...categoryData, [id]: value});
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        if(isEditMode){
            request(categoryApi.categoryPut(categoryData)).then((response) => {
                setIsLoading(false);
                if(response.ok) {
                    onClose(true);
                    showMessage(labels.editCategoryLabel);
                } else {
                    showMessage(labels.errorLabel, MessageType.Error);
                }
            });
        } else {
            request(categoryApi.categoryPost(categoryData)).then((response) => {
                setIsLoading(false);
                if(response.ok) {
                    onClose(true);
                    showMessage(labels.addCategoryLabel);
                } else {
                    showMessage(labels.errorLabel, MessageType.Error);
                }
            });
        }
    }

    var popupTitle = isEditMode 
        ? <div>{labels.editCategory} <b>{categoryOriginalName}</b></div>
        : <div>{labels.newCategory}</div>;

    return <Popup title={popupTitle} onClose={() => onClose(false)} width={'800px'} isLoading={isLoadingData}>
    <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AccXTextField id='categoryName' 
                    value={categoryData.categoryName}
                    onChange={textChange} 
                    label={labels.categoryName} 
                    required
                    disabled={isPredefinedCategory}/>
            </Grid>
            <Grid item xs={4}>
                <ComboBoxField id='vatRegisterId'
                    value={categoryData.vatRegisterId}
                    label={labels.categoryVatRegister} 
                    items={vatRegisters}
                    onChange={comboBoxChange}
                    disabled={isPredefinedCategory}
                    />
            </Grid>
            <Grid item xs={4}>
                <DropdownField id='vatDeduction'
                    value={categoryData.vatDeduction}
                    label={labels.vatDeductionLabel} 
                    items={vatDeduction}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={isPredefinedCategory}/>
            </Grid>
            <Grid item xs={4}>
                <DropdownField id='costDeduction'
                    value={categoryData.costDeduction}
                    label={labels.categoryCostDeduction} 
                    items={costDeduction}
                    onChange={dropdownChange}
                    hasDefaultItem={false}
                    disabled={isPredefinedCategory}/>
            </Grid>
            <Grid item xs={6}>
                <AccXTextField id='account' 
                    value={categoryData.account}
                    onChange={textChange} 
                    label={labels.categoryAccount} 
                    />
            </Grid>
            {isAccountNonDeductibleVisible 
                ? <Grid item xs={6}>
                     <AccXTextField id='accountNonDeductible' 
                        value={categoryData.accountNonDeductible}
                        onChange={textChange} 
                        label={labels.categoryAccountNonDeductible}/>
                </Grid>
                : <Grid item xs={6}/>
            }
            <Grid item xs={12}>
                <ActionButton type='submit' style={{float: 'right'}} onClick={() => {}}>
                    {labels.save}
                </ActionButton>
            </Grid>
        </Grid>
    </form>
</Popup>
}