import { useState } from "react";
import { AccXBCPredictionDataResult, AccXTopicPrediction, AccXTopicPredictionDataResult, AccXTopicPredictionResult, AccXTopTopicPredictionResult, TopicPredictionApi } from "../../../api";
import PanelInfo from "../../../components/PanelInfo";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import SideElementLayout from "../../../layouts/SideElementLayout";
import ContractorPanel from "../../decree/contractor/ContractorPanel";
import DecreePanel, { DecreeMode } from "../../decree/DecreePanel";
import TopicPanel from "../../decree/topic/TopicPanel";

export default function TopicPredictionPanel() {

    const initValues : AccXTopicPrediction = {
        taxId: '',
        contractorName: '',
        positionText: '',
        gtuCode: '',
        hasRegNum: false,
        cn: '',
        pkwiu: '',
    }
    
    const [topicPredictionData, setTopicPredictionData] = useState(initValues);

    const [contractorData, setContractorData] = useState<AccXBCPredictionDataResult | null>(null);
    const [isContractorPanelActive, setIsContractorPanelActive] = useState(false);

    const [topTopicData, setTopTopicData] = useState<AccXTopTopicPredictionResult | null>(null);

    const [topicData, setTopicData] = useState<AccXTopicPredictionResult[] | null>(null);
    const [isTopicPanelActive, setIsTopicPanelActive] = useState(false);

    const [topicTags, setTopicTags] = useState<Array<string>>([]);

    const [isNoResultPanelActive, setIsNoResultPanelActive] = useState(false);

    const {labels, setIsLoading} = useAppContext();
    const {request} = useApiContext();
    const topicPredictionApi = new TopicPredictionApi();

    function reset(resetTopicPredictionData = true) {

        if (resetTopicPredictionData) {
            setTopicPredictionData(initValues);
        }
        
        setIsNoResultPanelActive(false);

        setContractorData(null);
        setIsContractorPanelActive(false);
        
        setTopicData(null);
        setIsTopicPanelActive(false);
    }

    function predictTopic() {
        reset(false);
        setIsLoading(true);

        request(topicPredictionApi.topicPredictionPost(topicPredictionData)).then((response) => {
            if (response.ok && response.data) {
                handleResponse(response.data);
            }
            setIsLoading(false);
        });
    }

    function handleResponse(data: AccXTopicPredictionDataResult) {

        if (!(data.accXBCPredictionDataResult && (data.accXBCPredictionDataResult.accXBCPredictionResults?.length ?? 0) > 0) && !data.accXTopTopicPredictionResult) {
            setIsNoResultPanelActive(true);
        }
      
        if (data.accXBCPredictionDataResult && (data.accXBCPredictionDataResult.accXBCPredictionResults?.length ?? 0) > 0) {
            setContractorData(data.accXBCPredictionDataResult);
            setIsContractorPanelActive(true);
        }
        else {
            setContractorData(null);
            setIsContractorPanelActive(false);
        }
      
        if (data.topicPredictionResult && data.accXTopTopicPredictionResult) {
            setTopicData(data.topicPredictionResult);
            setTopTopicData(data.accXTopTopicPredictionResult);
            setIsTopicPanelActive(true);
        }
        else {
            setTopicData(null);
            setIsTopicPanelActive(false);
        }
      
        if(data.tags) {
            setTopicTags(data.tags);
        }
    }

    return <SideElementLayout>
        <DecreePanel decreeMode={DecreeMode.Topic} decreeData={topicPredictionData} initValues={initValues} setDecreeData={setTopicPredictionData} reset={reset} decree={predictTopic} showAddExample={false}/>
        {isContractorPanelActive && contractorData && <ContractorPanel contractorData={contractorData}/>}
        {isTopicPanelActive && topicData && topTopicData && <TopicPanel topicData={topicData} topPrediction={topTopicData} tags={topicTags}/>}
        {isNoResultPanelActive && <PanelInfo text={labels.noDataResultInfo}></PanelInfo>}
    </SideElementLayout> 
    
}