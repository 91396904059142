import { Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ActionDefinition, ActionVisibilityType } from "../models/ActionDefinition";
import { ColumnDefinition } from "../models/ColumnDefinition";
import TextFilter from "./filters/TextFilter";
import React from 'react';
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../contexts/AppContext";

type Props = {
    columns: ColumnDefinition[],
    data: any[],
    selectField?: string,
    actions?: ActionDefinition[],
    filters?: JSX.Element[],
    hasCheckColumn?: boolean,
    onSelect?: (selected: number[] | string[]) => void,
    isLoading?: boolean,
    hasSearchBar?: boolean,
    filtersData?: any,
    addFilter?: (paramName: string, paramValue: any) => void,
    searchTextFieldName?: string,
    rowPerPage?: number
}

type Order = false | 'asc' | 'desc';


export default function GridInfo({
        columns, 
        data, 
        actions, 
        filters, 
        filtersData, 
        hasCheckColumn=true, 
        isLoading=false, 
        hasSearchBar=false, 
        selectField='entityId', 
        searchTextFieldName='searchText',
        onSelect, 
        addFilter,
        rowPerPage
    } : Props) {

    const rowsPerPageOptions = [10, 50, 100];

    const [selected, setSelected] = useState<number[]>([]);
    const [tableData, setTableData] = useState(data);
    const [rowsPerPage, setRowsPerPage] = useState(rowPerPage ?? rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [allowMultipleSelection, setAllowMultipleSelection] = useState(true);

    const {labels} = useAppContext(); 

    useEffect(() => {
        if (hasSearchBar) {
            if (!filtersData) {
                alert('If you are using searchBar set filtersData for gridInfo')
            }
            else if (!addFilter) {
                alert('If you are using searchBar set addFilter for gridInfo')
            }
        }
        if (actions) {
            let multipleSelection = actions.filter(action => checkActionMultipleSelection(action.visibilityType));
            if(multipleSelection && multipleSelection.length > 0){
                setAllowMultipleSelection(true);
            }else{
                setAllowMultipleSelection(false);
            }
        }


    }, []);

    useEffect(() => {
        setSelected([]);
        setTableData(data);
    }, [data])
    
    //#region Sorting

    const [order, setOrder] = useState<Order>(false);
    const [orderBy, setOrderBy] = useState('');
    
    const handleRequestSort = (e:  React.MouseEvent<unknown>, property: string) => {
        if (property) {
            
            if (order === false) {
                setOrderBy(property);
                setOrder('asc');
            }
            else if (order === 'asc') {
                setOrderBy(property);   
                setOrder('desc');
            }
            else {
                setOrderBy('');
                setOrder(false);
            }
        }
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {

        var aOrderBy: string;  
        var bOrderBy: string; 

        if (typeof orderBy === 'string') {
            var array = orderBy.split('.');
                
            var tempA: any = a;
            var tempB: any = b;
            
            array.forEach(element => {
                tempA = tempA[element];
                tempB = tempB[element]; 
            });

            aOrderBy = tempA;
            bOrderBy = tempB;

            if (aOrderBy !== null && bOrderBy !== null 
                && typeof aOrderBy === 'string' && typeof bOrderBy === 'string') {
                    return sortLocale(aOrderBy.toLowerCase(), bOrderBy.toLowerCase());
                }
            else {
                if (bOrderBy < aOrderBy) {
                    return -1;
                }
                if (bOrderBy > aOrderBy) {
                    return 1;
                }
            }
        }

        return 0;
    }

    function sortLocale(a: string, b:string){
        return a.localeCompare(b);
    }

    function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
      ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    //#endregion

    //#region Selecting

    const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            var newSelected = visibleData?.map((data) => {
                return data[selectField];
            });

            newSelected = newSelected ?? [];

            setSelected(newSelected);
            if(onSelect) onSelect(newSelected); 
            return;
        }
        setSelected([]);
        if(onSelect) onSelect([]); 
    };
    const isSelected = (selectedValue: number) => selected.indexOf(selectedValue) !== -1;
    // function isSelected = (selectedValue) => selected.indexOf(selectedValue) !== -1;

    function handleClick(event: React.MouseEvent<unknown>, selectedValue: number) {
        const selectedIndex = selected.indexOf(selectedValue);
        var newSelected: number[] = [];
        var setNewSelected = false;

        if (!allowMultipleSelection && selected.length >= 1 && selectedIndex === -1){
            newSelected = newSelected.concat([], selectedValue);
            setNewSelected = true;
        } else {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, selectedValue);
                setNewSelected = true;
            }
        }

        if (setNewSelected) {
            setSelected(newSelected);
            if(onSelect) onSelect(newSelected); 
        }
    };

    function handleSingleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, selectedValue: number) {
        const selectedIndex = selected.indexOf(selectedValue);
        var newSelected: number[] = [];

        if(!allowMultipleSelection && selected.length >= 1 && selectedIndex === -1){
            newSelected = newSelected.concat([], selectedValue);
        }else{
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, selectedValue);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
        }

        setSelected(newSelected);
        if(onSelect) onSelect(newSelected);
    }

    //#endregion
   
    //#region Paging
  
    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (e: unknown, newPage: number) => {
        setSelected([]);
        if(onSelect) onSelect([]); 
        setPage(newPage);
    };

    //#endregion

    const visibleData = useMemo(() => {
        
        var tempData = [...tableData];
        
        if (order) {
            tempData = tempData.sort(getComparator(order, orderBy));
        }

        return tempData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        }, [order, orderBy, page, rowsPerPage, tableData],
    );

    function isActionVisible(visibilityType: number, dependOnColumn?: string, dependOnColumnNegation?: boolean): boolean {

        return checkActionVisibilityType(visibilityType) && checkIsActionVisibleForSelectedRow(dependOnColumn, dependOnColumnNegation);
    }

    function checkActionMultipleSelection(visibilityType: ActionVisibilityType): boolean{
        if (visibilityType === ActionVisibilityType.OneOrMoreSelectedItems) {
            return true;
        }
        if (visibilityType === ActionVisibilityType.MoreThanOneSelectedItems) {
            return true;
        }
        return false;
    }

    function checkActionVisibilityType(visibilityType: ActionVisibilityType): boolean{
        if (visibilityType === ActionVisibilityType.SelectionIndependent) {
            return true;
        }
        if (visibilityType === ActionVisibilityType.OnlyOneSelectedItem && selected.length === 1) {
            return true;
        }
        if (visibilityType === ActionVisibilityType.OneOrMoreSelectedItems && selected.length >= 1) {
            return true;
        }
        if (visibilityType === ActionVisibilityType.MoreThanOneSelectedItems && selected.length > 1) {
            return true;
        }
        if (visibilityType === ActionVisibilityType.NothingSelected && selected.length === 0) {
            return true;
        }

        return false;
    }

    function checkIsActionVisibleForSelectedRow(dependOnColumn?: string, dependOnColumnNegation?: boolean): boolean {

        if (!dependOnColumn) {
            return true;
        }

        if (selected.length === 1) {

            var selectedRowId = selected[0];
            var selectedRow = tableData.find(x => x[selectField] === selectedRowId);

            var temp = selectedRow;

            var array = dependOnColumn.split('.');

            array.forEach(element => {
                temp = temp[element]; 
            });
            
            var isVisible = temp && true;
    
            if (dependOnColumnNegation) {
                return !isVisible;
            }

            return isVisible;
        }
        
        return false;
    }

    const visibleActions = useMemo(() => {
        if (actions) {
            let visible = actions.filter(action => isActionVisible(action.visibilityType, action.dependOnColumn, action.dependOnColumnNegation));
            if(visible && visible.length > 0){
                return visible;
            }
            return false; 
        }

        return false;
    }, [actions, selected]);

    function fakeAddFilter(paramName: string, paramValue: any) {
    }
    
    const searchBar = <span style={{float: 'right'}}>
        <TextFilter fieldName={searchTextFieldName} label={labels.search}
            filtersData={filtersData}
            isNotCurved={true}
            addFilter={addFilter ?? fakeAddFilter}/>
    </span>; 

    
  

    return <div>
        {visibleActions && <Paper className="gridActionBar">
            {visibleActions[0].definition}
            {visibleActions.slice(1).map((action, actionIndex) => {
                return <span key={actionIndex} style={{marginLeft: '15px'}}>
                    {action.definition}
                </span> ;
            })}
            {hasSearchBar && searchBar}
        </Paper>}
        {(!visibleActions || visibleActions.length === 0)  && <Paper className="gridActionBar" style={{minHeight: '60px'}}>
            {hasSearchBar && searchBar}
        </Paper>}
        {filters && <Paper className="gridFilterBar" style={{minHeight: '60px'}}>
            {filters[0]}
            {filters.slice(1).map((filter, filterIndex) => {
                return <span style={{marginLeft: '5px'}} key={filterIndex}>
                    {filter}
                </span> ;
            })}
        </Paper>}
        <Paper>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {hasCheckColumn && <TableCell padding="checkbox">
                                <Checkbox onChange={handleSelectAllClick} checked={(visibleData?.length ?? 0) > 0 && selected.length === visibleData?.length} disabled={!allowMultipleSelection}/>
                            </TableCell>}
                            {columns && columns.map((column, columnIndex) => {
                                return <TableCell key={columnIndex}
                                    sortDirection={orderBy === column.field ? order : false}
                                >
                                    {!column.isNotSortable && <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? (order ? order : 'asc') : 'asc'}
                                        onClick={(e) => handleRequestSort(e, column.field)}
                                    >
                                        <span style={{fontWeight: 'bold'}}>{column.header}</span>
                                    </TableSortLabel>}
                                    {column.isNotSortable && 
                                        <span style={{fontWeight: 'bold'}}>{column.header}</span>
                                    }
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {isLoading ?
                    <TableRow style={{height: '200px'}}>
                        <TableCell colSpan={hasCheckColumn ? columns.length + 1 : columns.length} style={{border: '0px', textAlign: 'center'}}>
                            <CircularProgress style={{color: 'var(--details)'}}/>
                        </TableCell>
                    </TableRow>
                            
                        : 
                        !visibleData || visibleData.length == 0 ?
                        <TableRow style={{height: '200px'}}>
                            <TableCell colSpan={hasCheckColumn ? columns.length + 1 : columns.length} style={{border: '0px', textAlign: 'center'}}>
                                <Typography variant="h5">{labels.noData} <FontAwesomeIcon icon={faFolderOpen}/></Typography>
                            </TableCell>
                        </TableRow>
                        :
                        visibleData.map((row, rowIndex) => {

                        const isItemSelected = isSelected(row[selectField]);

                        return <TableRow
                            key={rowIndex}
                            onClick={(event) => handleClick(event, row[selectField])}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            sx={{ cursor: 'pointer' }}
                            selected={isItemSelected} 
                        >
                            {hasCheckColumn && <TableCell padding="checkbox">
                                <Checkbox onChange={(event) => handleSingleCheckboxChange(event, row[selectField])} checked={isItemSelected}/>
                            </TableCell>}

                            {columns && columns.map((column, columnIndex) => {
                                var cellIndex = `${rowIndex}_${columnIndex}`;
                                
                                var cellValue 

                                if (column.field.includes('.')) {
                                    var array = column.field.split('.');
                                    var temp = row;
                                    
                                    array.forEach(element => {
                                        temp = temp[element]; 
                                    });

                                    cellValue = temp;
                                }
                                else {
                                    cellValue = row[column.field];
                                }

                                if (column.customTemplate) {
                                    cellValue = column.customTemplate(row);
                                }

                                return <TableCell key={cellIndex}>{cellValue}</TableCell>
                            })}
                        </TableRow>
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={tableData?.length ?? 0}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                sx={{
                    //https://github.com/mui/mui-x/issues/4076
                    '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                        'marginTop': '1em',
                        'marginBottom': '1em'
                    }
                }}
            />
        </Paper>
    </div> 
}