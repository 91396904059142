import { CircularProgress, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";

type Props = {
    className?: string,
    isLoading?: boolean
}

export default function EditForm({className, isLoading = false, children} : PropsWithChildren<Props>) {

return <Paper className={className} style={{padding: '20px', backgroundColor: 'var(--lightGrey)'}}>
        {isLoading ? <Box style={{margin:'20px', textAlign: 'center'}}>
                <CircularProgress style={{color: 'var(--details)'}}/>
            </Box> : children}
    </Paper>
}