import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Divider, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useUserContext } from "../../contexts/UserContext";
import ActionButton from "../ActionButton";
import accountantXLogo from '../../resources/ax_logo.png';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { faBars, faInfo, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import LoginOptionMenu from "../login/LoginOptionMenu";
import { Stack } from "@mui/system";
import { pagesNames } from "../../constants/pageNavigationConstans";
import { useAppContext } from "../../contexts/AppContext";


export default function MobileNavMenu() {

    const { labels } = useAppContext();
    const { isSigned, user, customerProfile, signOut } = useUserContext();

    const [navigationMenu, setNavigationMenu] = useState(null);
    const isNavigationMenuOpen = Boolean(navigationMenu);
    
    const [loginMenu, setLoginMenu] = useState(null);
    const isLoginMenuOpen = Boolean(loginMenu);

    const navigate = useNavigate();
  
    function openLoginMenu(event: any) {
        setLoginMenu(event.currentTarget);
    }

    function openNavigationMenu(event: any) {
        setNavigationMenu(event.currentTarget);
    };

    function closeNavigationMenu() {
        setNavigationMenu(null);
    }
    
    const goToHomePage = () => navigate('/')

    return <AppBar position="sticky" style={{
            marginBottom:'10px', 
            backgroundColor: 'var(--appBar)', 
            zIndex: '10',
            justifyContent: 'center',
    }}>
        <div style={{display: 'flex'}}>
            <div style={{flex: 1, padding: '10px'}}>
                <img height={40} src={accountantXLogo} alt='Logo AccountantX' onClick={goToHomePage}/>
            </div>
            <div style={{flex: 1, padding: '10px', textAlign: 'right'}}>
                {!isSigned && <>
                    <ActionButton onClick={openLoginMenu} className="actionButton">
                        {labels.signIn}
                    </ActionButton>
                    <LoginOptionMenu isOpen={isLoginMenuOpen} anchorElement={loginMenu} setAnchorEl={setLoginMenu}/>
                </>}
                {isSigned && <>
                    <IconButton onClick={openNavigationMenu}>
                        <FontAwesomeIcon icon={faBars} style={{color: 'var(--lightGrey)'}}/>
                    </IconButton>
                    <Menu open={isNavigationMenuOpen} anchorEl={navigationMenu} onClose={closeNavigationMenu}>
                        <Stack>
                            <div style={{padding: '10px'}}>{user?.userLogin}</div>
                            <div style={{padding: '10px'}}>{customerProfile?.companyName}</div>
                        </Stack>
                        <Divider/>
                        <MenuItem onClick={() => navigate(`/${pagesNames.aboutProject}`)}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faInfo}/>
                            </ListItemIcon>
                            {labels.aboutProjectButtonLabel}
                        </MenuItem>
                        <MenuItem onClick={signOut}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faRightFromBracket}/>
                            </ListItemIcon>
                            {labels.signOut}
                        </MenuItem>
                    </Menu>
                </>
                }
            </div>
        </div>
    </AppBar>
}
