import { useEffect, useMemo, useState } from "react";
import { AccXVatRegisterResult, VatRegisterApi } from "../../../api";
import ActionGridButton from "../../../components/ActionGridButton";
import GridInfo from "../../../components/GridInfo";
import PredefinedTag from "../../../components/PredefinedTag";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import useFilters from "../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../models/ActionDefinition";
import VatRegisterPopup from "./VatRegisterPopup";


export default function VatRegistersPanel() {

    const [openNewVatRegisterPopup, setOpenNewVatRegisterPopup] = useState(false);
    const [openEditVatRegisterPopup, setOpenEditVatRegisterPopup] = useState(false);

    const [vatRegisters, setVatRegisters] = useState<AccXVatRegisterResult[]>([]);
    const [selectedVatRegister, setSelectedVatRegister] = useState<number[]>([]);
    const [isFetchingVatRegisters, setIsFetchingVatRegisters] = useState(false);
    const [filtersData, addFilter] = useFilters();

    const { request } = useApiContext();
    const {labels, showMessage} = useAppContext();

    const vatRegisterApi = new VatRegisterApi()
    
    useEffect(() => {
        fetchVatRegisters();
    }, []);

    const visibleVatRegisters = useMemo(() => {

        var temp = [...vatRegisters];

        if (temp.length > 0 && filtersData) {

            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(vatRegister => vatRegister.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()));
            };
        }
        
        return temp;

    }, [vatRegisters, filtersData]);

    function fetchVatRegisters() {
        setIsFetchingVatRegisters(true);

        var serachText = filtersData['searchText'];

        request(vatRegisterApi.vatRegisterAllGet(serachText)).then((response => {
            if (response.ok && response.data) {
                setVatRegisters(response.data);
            }
            setIsFetchingVatRegisters(false);
        }));
    }

    function closePopups(refresh=false) {
        setOpenNewVatRegisterPopup(false);
        setOpenEditVatRegisterPopup(false);
        if(refresh){
            fetchVatRegisters();
        }
    }

    var columns = [
        {header: labels.vatRegisterName, field: 'vatRegisterName', customTemplate: (row: any) => {
            var isPredefined = row['isPredefined'];
            var vatRegisterName = row['vatRegisterName'];



            var element = <span>{vatRegisterName}</span>; 
            
            if (isPredefined) {
                element = <PredefinedTag text={vatRegisterName}></PredefinedTag>
            }
            
            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditVatRegisterPopup(true)}>{element}</a>
        }},
        {header: labels.vatRegisterAccount, field: 'account'},
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewVatRegisterPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditVatRegisterPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteVatRegister(selectedVatRegister[0])}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
    ];

    function deleteVatRegister(vatRegisterId : number){
        request(vatRegisterApi.vatRegisterVatRegisterIdDelete(vatRegisterId)).then((response => {
            if (response.ok) {
                showMessage(labels.vatRegisterDeletingSuccess, MessageType.Success);
                fetchVatRegisters();
            } else {
                showMessage(labels.deletingError, MessageType.Error);
            }
        }));
    } 

    return <>
        {openNewVatRegisterPopup && <VatRegisterPopup allData={vatRegisters} onClose={(refresh = false) => closePopups(refresh)}/>}
        {openEditVatRegisterPopup && <VatRegisterPopup vatRegisterId={selectedVatRegister[0]} onClose={(refresh = false) => closePopups(refresh)}/>}
        <GridInfo columns={columns} data={visibleVatRegisters} actions={actions} hasSearchBar={true} isLoading={isFetchingVatRegisters} onSelect={(selected) => setSelectedVatRegister(selected as number[])}
            filtersData={filtersData}
            addFilter={addFilter}
            />
    </>
}