import { Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ActionButton from "../../../components/ActionButton";
import Popup from "../../../components/Popup";
import React from 'react';
import { useApiContext } from "../../../contexts/ApiContext";
import { AccXPurchasePurposeResult, PurchasePurposesApi } from "../../../api";
import { useAppContext } from "../../../contexts/AppContext";
import { MessageType } from "../../../components/snackbar/MessageType";
import AccXTextField from "../../../components/fields/AccXTextField";

type Props = {
    purchaseTargetId?: number,
    onClose: (refresh: boolean) => void,
    allData?: AccXPurchasePurposeResult[]
};

export default function PurchaseTargetsPopup({purchaseTargetId, onClose, allData}: Props) : JSX.Element {
    
    const { request } = useApiContext();
    const { labels, showMessage } = useAppContext();
    const purchaseTargetApi = new PurchasePurposesApi();

    const isEditMode = purchaseTargetId && true;
    const initValues: AccXPurchasePurposeResult = {
        purchasePurposeName: '',
    };

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [purchaseTargetData, setPurchaseTargetData] = useState(initValues);
    const [purchaseTargetOriginalName, setPurchaseTargetOriginalName] = useState('');
    const [isPurchaseTargetNameNotUnique, setIsPurchaseTargetNameNotUnique] = useState(false);


    useEffect(() => {
        if (isEditMode) {
            setIsLoadingData(true);
            request(purchaseTargetApi.purchasePurposesPurchasePurposeIdGet(purchaseTargetId)).then((response) => {
                if(response.ok && response.data) {
                    setPurchaseTargetData(response.data);
                    setPurchaseTargetOriginalName(response.data.purchasePurposeName ?? '');
                }
                setIsLoadingData(false);
            });
        }
    }, []);

    const purchasePurposeNameChange = (id: string, value: string) => {
        setPurchaseTargetData({...purchaseTargetData, [id]: value});
        if(isPurchaseTargetNameNotUnique){
            setIsPurchaseTargetNameNotUnique(false);
        }
    }

    function isDataValid(dataToSave: AccXPurchasePurposeResult){
        if(allData != null){
            let usedNames = allData.map(x => x.purchasePurposeName);
            return usedNames.indexOf(dataToSave.purchasePurposeName) == -1;
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(isEditMode){
            request(purchaseTargetApi.purchasePurposesPut(purchaseTargetData)).then((response) => {
                if(response.ok) {
                    onClose(true);
                    showMessage(labels.editPurchasePurposeLabel);
                } else {
                    showMessage(labels.errorLabel, MessageType.Error);
                }
            });

            
        }else{
            if(isDataValid(purchaseTargetData)){
                request(purchaseTargetApi.purchasePurposesPost(purchaseTargetData)).then((response) => {
                    if(response.ok) {
                        onClose(true);
                        showMessage(labels.addPurchasePurposeLabel);
                    } else {
                        showMessage(labels.errorLabel, MessageType.Error);
                    }
                });
            }else{
                setIsPurchaseTargetNameNotUnique(true);
            }
        }
    }

    var popupTitle = isEditMode 
        ? <div>{labels.editPurchaseTarget} <b>{purchaseTargetOriginalName}</b></div>
        : <div>{labels.newPurchaseTarget}</div>;

    return <Popup title={popupTitle} onClose={onClose} width={'800px'} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccXTextField id='purchasePurposeName' 
                        value={purchaseTargetData.purchasePurposeName}
                        onChange={purchasePurposeNameChange} 
                        label={labels.purchasePurposeName} 
                        required
                        error={isPurchaseTargetNameNotUnique}
                        helperText = {isPurchaseTargetNameNotUnique ? labels.purchasePurposeNameNotUnique : ''}
                        />
                </Grid>
                <Grid item xs={12}>
                    <ActionButton type='submit' style={{float: 'right'}} onClick={() => {}}>
                        {labels.save}
                    </ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}