export const pagesNames = {
    companyConfiguration: 'companyConfiguration',
    companyData: 'companyData',
    accountingPolicy: 'accountingPolicy',
    purchaseTargets: 'purchaseTargets',
    materialsAndGoods : 'materialsAndGoods',
    accountingCategories : 'accountingCategories',
    accountingRules : 'accountingRules',
    materialsAndGoodsMapping : 'materialsAndGoodsMapping',
    topicPrediction : 'topicPrediction',
    bcPrediction : 'bcPrediction',
    apiDocumentation: 'apiDocumentation',
    aboutProject: 'aboutProject',
    demo: 'demo',
    deleteAccount: 'deleteAccount',
    topicAndProductPrediction : 'topicAndProductPrediction',
    swagger: 'swagger',
    platformManagement: 'platformManagement',
    attachUserToNewCustomer: 'attachUserToNewCustomer',
    attachUserToExistingCustomer: 'attachUserToExistingCustomer',
    vatRegisters: 'vatRegisters',
}