import { Grid, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { useAppContext } from "../contexts/AppContext";

type Props = {
    mainElementSize?: number,
    sideElementSize?: number,
    sideElements?: JSX.Element | null,
}

export default function SideElementLayout({mainElementSize = 18, sideElementSize = 6, sideElements, children} : PropsWithChildren<Props>) {

    const { isMobile } = useAppContext();

    if (!sideElements) {
        sideElements = <></>;
    }

    if (isMobile) {
        return <Stack>
            {children}
            {sideElements}
        </Stack>
    }

    return <Grid columns={24} container>
        <Grid item xs={mainElementSize} style={{paddingRight: '5px'}}>
            {children}
        </Grid>
        <Grid item xs={sideElementSize} style={{paddingLeft: '5px'}}>
            {sideElements}
        </Grid>
    </Grid>
}