import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faFileInvoice} from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/system';
import { AccXTopicPredictionResult, AccXTopTopicPredictionResult } from '../../../api';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    topPrediction: AccXTopTopicPredictionResult,
    topicData: AccXTopicPredictionResult[],
    tags: any
}

export default function TopicPanel({topicData, topPrediction, tags} : Props) {
    
    const {labels, isMobile} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
            <div style={{display: 'block'}}>
                <Typography>{<FontAwesomeIcon icon={faFileInvoice}/>} {labels.topicPanelTitle}</Typography>
                <div style={{display: 'flex'}}>
                    <div>
                        <div style={{display: 'flex'}}>
                            <Typography fontWeight='bold'> {topPrediction.topicName}</Typography>
                            {tags && tags.map((globalTag: any, globalTagIndex: number) => {
                                return <span className='tag small' style={{backgroundColor: 'var(--details)'}} key={globalTagIndex}>{globalTag}</span>
                            })}
                        </div>
                        <div className={getCertaintyLevelClass(topPrediction.certaintyLevel ?? 0)}></div>
                    </div>
                    <div>
                        {(topPrediction.certaintyLevel ?? 0) < 0 && <Typography style={{marginLeft: '10px', color: 'var(--error)', fontWeight: 'bold'}}>{labels.resultNotSureLabel}</Typography>}
                    </div>
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            {topicData && topicData.map((prediction: AccXTopicPredictionResult, predictionIndex: number) => {
                var fixedIndex = predictionIndex + 1;

                const topicName = `${fixedIndex}. ${prediction.topicName}`;
                const confidence = `${(prediction.confidence ?? 0).toFixed(2)} %`;

                return <Accordion key={predictionIndex}>
                    <AccordionSummary
                        expandIcon={prediction.logInfo && !isMobile && <FontAwesomeIcon icon={faChevronDown}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Grid container spacing={2}>
                            {isMobile ? <>
                                <Grid item xs={9}>{topicName}</Grid>
                                <Grid item xs={3}>{confidence}</Grid>
                            </>
                            : <>
                                <Grid item xs={6}>{topicName}</Grid>
                                <Grid item xs={2}>{confidence}</Grid>
                                <Grid item xs={4}>
                                    <ul>
                                        {prediction.sources?.map((source: string, sourceIndex: number) => {
                                            return <li key={sourceIndex} className='tag' style={{float: 'left', listStyleType: 'none'}}>{source}</li>
                                        })}
                                    </ul>
                                </Grid>
                            </>}
                        </Grid>
                    </AccordionSummary>
                    {prediction.logInfo && !isMobile && <AccordionDetails style={{fontSize: '12px'}}>
                        {prediction.keywords && prediction.keywords.length > 0 && <div>
                            <span>{`${labels.keywords} `}</span>
                            {prediction.keywords.map((keyword: any, keywordIndex: number) => {
                                return <span className='tag small' style={{backgroundColor: 'var(--decreeTag)'}} key={keywordIndex}>{keyword}</span>
                            })}
                        </div>}
                        {prediction.rules && prediction.rules.length > 0 && <div>
                            <span>{`${labels.rules} `}</span>
                            {prediction.rules.map((rule: any, ruleIndex: number) => {
                                return <span className='tag small' style={{backgroundColor: '#6290ba'}} key={ruleIndex}>{rule}</span>
                            })}
                        </div>}
                        {prediction.logInfo}
                    </AccordionDetails>}
                </Accordion>
            })}
        </AccordionDetails>
    </Accordion>
}

function getCertaintyLevelClass(certaintyLevel: number) {
    if (certaintyLevel > 0) {
        return "tag bg-green";
    } 
    
    if (certaintyLevel === 0) {
        return "tag bg-yellow";
    } 
    
    return "tag bg-red";
}