import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { parseString } from 'xml2js';
import { AccXCategoryPrediction, AccXPurchasePurposeResult, AccXTopicPrediction, AccXVehicleUsagePurposeResult, PurchasePurposesApi, ExampleApi } from '../../api';
import ActionButton from '../../components/ActionButton';
import EditForm from '../../components/EditForm';
import AccXTextField from '../../components/fields/AccXTextField';
import CheckboxField from '../../components/fields/CheckboxField';
import ComboBoxField from '../../components/fields/ComboBoxField';
import DropdownField, { DropdownItem } from '../../components/fields/DropdownField';
import NumericField from '../../components/fields/NumericField';
import { MessageType } from '../../components/snackbar/MessageType';
import { useApiContext } from '../../contexts/ApiContext';
import { useAppContext } from '../../contexts/AppContext';
import { useUserContext } from '../../contexts/UserContext';
import { gtuCodes } from '../../dictionaries/gtuCodes';
import prettifyXml from 'prettify-xml';

type Props = {
    decreeMode: DecreeMode,
    decreeData: AccXTopicPrediction | AccXCategoryPrediction,
    setDecreeData: React.Dispatch<React.SetStateAction<AccXTopicPrediction | AccXCategoryPrediction>>,
    decree: () => void,
    reset: () => void,
    isAdditionalDataPanelOpenMaster?: boolean,
    setIsAdditionalDataPanelOpenMaster?: (isOpen: boolean) => void;
    initValues: AccXTopicPrediction | AccXCategoryPrediction,
    setKsefInvoiceContent?: React.Dispatch<React.SetStateAction<string>>,
    setRefreshExamples?: React.Dispatch<React.SetStateAction<boolean>>,
    refreshExamples?: boolean,
    additionalDataPanelRef?: any,
    showAddExample?: boolean,
    resetKsefInvoice?: boolean
}

export enum DecreeMode {
    Topic = 0,
    Category = 1,
    TopicAndProduct = 2,
}

export default function DecreePanel({decreeMode, decreeData, initValues, setDecreeData, decree, reset, setIsAdditionalDataPanelOpenMaster, isAdditionalDataPanelOpenMaster = false, setKsefInvoiceContent, setRefreshExamples, refreshExamples, additionalDataPanelRef, showAddExample = true, resetKsefInvoice = false} : Props) {
    const [isAdditionalDataPanelOpen, setIsAdditionalDataPanelOpen] = useState(isAdditionalDataPanelOpenMaster);
    const [vehicleUsagePurposes, setVehicleUsagePurpose] = useState<AccXVehicleUsagePurposeResult[]>([]);
    const [isLoadingVehicleUsagePurposes, setIsLoadingVehicleUsagePurposes] = useState(false);

    const [purchasePurposes, setPurchasePurposes] = useState<AccXPurchasePurposeResult[]>([]);
    const [isLoadingPurchasePurposes, setIsLoadingPurchasePurposes] = useState(false);
    
    const isLoadingData = isLoadingVehicleUsagePurposes || isLoadingPurchasePurposes;

    const [ksefInvoice, setKsefInvoice] = useState<any | null>(null);
    const [ksefInvoicePosition, setKsefInvoicePosition] = useState(1);
    const [ksefInvoicePositions, setKsefInvoicePositions] = useState<DropdownItem[]>([]);

    const [exampleName, setExampleName] = useState('');
    const [exampleNameEmptyError, setExampleNameEmptyError] = useState(false);

    const {labels, showMessage,setIsLoading, isMobile} = useAppContext();
    const {request, fetchVehicleUsagePurposes} = useApiContext();
    const {isSigned, customerProfile} = useUserContext();
    
    const purchasePurposesApi = new PurchasePurposesApi();
    const exampleApi = new ExampleApi();

    
    useEffect(()=> {
        setKsefInvoice(null);
    },[resetKsefInvoice])

    useEffect(()=> {
        setIsAdditionalDataPanelOpen(isAdditionalDataPanelOpenMaster);
    },[isAdditionalDataPanelOpenMaster])

    useEffect(() => {
        if (isSigned) {
            if (decreeMode === DecreeMode.Category) {
                setIsLoadingPurchasePurposes(true);
    
                request(purchasePurposesApi.purchasePurposesAllGet()).then((response) => {
                    if (response.ok && response.data) {
                        setPurchasePurposes(response.data);
                    }
                    setIsLoadingPurchasePurposes(false);
                });
            }

            if (decreeMode === DecreeMode.Category || decreeMode === DecreeMode.TopicAndProduct) {
                setIsLoadingVehicleUsagePurposes(true);
                fetchVehicleUsagePurposes().then((repsonse) => {
                    setVehicleUsagePurpose(repsonse);
                    setIsLoadingVehicleUsagePurposes(false);
                });
            }
        }
    }, [isSigned]);

    function exampleNameChange(id: string, value: string){
        setExampleName(value);
        if(exampleNameEmptyError){
            setExampleNameEmptyError(false);
        }
    }

    function textChange(id: string, value: string) {
        setDecreeData({...decreeData, [id]: value});
    }

    function dropdownChange(id: string, value: any) {
        setDecreeData({...decreeData, [id]: value});
    }

    function hasRegNumChange(id: string, checked: boolean) {
        setDecreeData({...decreeData, [id]: checked, vehicleUsagePurposeId: (initValues as AccXCategoryPrediction)?.vehicleUsagePurposeId });
    }

    function comboBoxChange(id: string, value: any) {
        setDecreeData({...decreeData, [id]: value});
    }

    const handleSubmit = (e: React.FormEvent | React.KeyboardEvent) => {
        e.preventDefault();
        setExampleNameEmptyError(false);
        decree();
    }

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {

        reset();
        setKsefInvoice(null);
        setKsefInvoicePosition(0);

        const file = event.target.files?.[0];
    
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (e) => {
            const content = e.target?.result as string;
            
            parseString(content, (err, result) => {
              if (!err) {
                if (result && result.Faktura) {
                    setKsefInvoice(result);

                    if (result.Faktura.Podmiot1 && result.Faktura.Podmiot1[0].DaneIdentyfikacyjne && result.Faktura.Podmiot1[0].DaneIdentyfikacyjne[0]) {
                        if (result.Faktura.Podmiot1[0].DaneIdentyfikacyjne[0].NIP) {
                            var ksefTaxId = result?.Faktura?.Podmiot1[0]?.DaneIdentyfikacyjne[0]?.NIP[0];
                            setDecreeData((prevState) => ({...prevState, taxId: ksefTaxId}));
                        }

                        if (result.Faktura.Podmiot1[0].DaneIdentyfikacyjne[0].Nazwa) {
                            var ksefContractorName = result?.Faktura?.Podmiot1[0]?.DaneIdentyfikacyjne[0]?.Nazwa[0];
                            setDecreeData((prevState) => ({...prevState, contractorName: ksefContractorName}));
                        }
                    }

                    if (result.Faktura.Fa && result.Faktura.Fa[0].FaWiersz) {

                        var ksefPositions: DropdownItem[] = result?.Faktura?.Fa[0]?.FaWiersz.map((position: any, index: number) => {
                            return ({text: position.P_7, value: index});
                        });

                        setKsefInvoicePositions(ksefPositions);
                    }
                }
    
                if (setKsefInvoiceContent) {
                    
                    const options = {indent: 2, newline: '\n'} 
                    const prettyXml = prettifyXml(content, options);

                    setKsefInvoiceContent(prettyXml);
                }
              } else {
                showMessage(labels.ksefFileLoadingError, MessageType.Error);
              }
            });
          };
    
          reader.readAsText(file);
        }
        event.target.value = '';
    }

    function onKsefInvoicePositionChange(id: string, value: string) {
        var valueAsNumber = Number(value);
        setKsefInvoicePosition(valueAsNumber);
    }

    useEffect(() => {
        setDecreeData((prevState) => ({...prevState, positionText: '', gtuCode: '', cn: '', pkwiu: '', vatRate: '', unitPrice: ''}));
        if (ksefInvoice && ksefInvoice.Faktura && ksefInvoice.Faktura.Fa && ksefInvoice.Faktura.Fa[0].FaWiersz) {
            var ksefPosition = ksefInvoice?.Faktura?.Fa[0]?.FaWiersz[ksefInvoicePosition];

            if (ksefPosition) {
                setIsAdditionalDataPanelOpen(true);

                if (ksefPosition.P_7) {
                    var ksefPositionText = ksefPosition.P_7[0];
                    setDecreeData((prevState) => ({...prevState, positionText: ksefPositionText}));
                }
                if (ksefPosition.GTU) {
                    var ksefGTU = ksefPosition.GTU[0]; 
                    setDecreeData((prevState) => ({...prevState, gtuCode: ksefGTU}));
                }
                if (ksefPosition.CN) {
                    var ksefCN = ksefPosition.CN[0];
                    setDecreeData((prevState) => ({...prevState, cn: ksefCN}));
                }
                if (ksefPosition.PKWiU) {
                    var ksefPKWiU = ksefPosition.PKWiU[0];
                    setDecreeData((prevState) => ({...prevState, pkwiu: ksefPKWiU}));
                }
                if (ksefPosition.P_12) {
                    var ksefVatRate = ksefPosition.P_12[0];
                    setDecreeData((prevState) => ({...prevState, vatRate: ksefVatRate}));
                }
                if (ksefPosition.P_9A) {
                    var ksefUnitPrice = ksefPosition.P_9A[0];
                    setDecreeData((prevState) => ({...prevState, unitPrice: ksefUnitPrice}));
                }
            }
            else {
                showMessage(labels.ksefInvoicePositionNotFound, MessageType.Warning);
            }
        }
    }, [ksefInvoicePosition, ksefInvoicePositions]);

    const vatRateInput = customerProfile && customerProfile.hasIDX ? <AccXTextField id='vatRate'
    label={labels.vatRate}
    value={(decreeData as AccXCategoryPrediction).vatRate}
    onChange={textChange}/> : <></>

    function resetDecreePanel() {
        reset();
        setKsefInvoice(null);
        setExampleNameEmptyError(false);
        setExampleName('');
    }

    function saveExampleDecreePanel(){
        if(exampleName == null || exampleName == ''){
            setExampleNameEmptyError(true);
        }else{
            setIsLoading(true);
            request(exampleApi.examplePost(decreeData, exampleName)).then((response) => {
                if(response.ok) {
                    if(setRefreshExamples){
                        setRefreshExamples(!refreshExamples);
                    }
                    showMessage(labels.addExampleLabel);
                } else {
                    showMessage(labels.addExampleErrorLabel, MessageType.Error);
                }
                setIsLoading(false);
                setExampleName('');
            });
        }
    }

    const additionalDataColumns = isMobile ? 12 : 4;

    return <EditForm isLoading={isLoadingData}>
        <h4>{labels.enterInvoiceDetails}</h4>
        <Divider style={{marginBottom: '20px'}}></Divider>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AccXTextField id='taxId' 
                        value={decreeData.taxId}
                        onChange={textChange} 
                        label={labels.nip}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='contractorName'
                        value={decreeData.contractorName}
                        onChange={textChange}
                        label={labels.companyName_optional}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='positionText' 
                        value={decreeData.positionText}
                        onChange={textChange}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleSubmit(event);
                            }
                        }}
                        label={labels.position} 
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion ref={additionalDataPanelRef} className= {isAdditionalDataPanelOpenMaster ? "selectedAccordion" : ""} expanded={isAdditionalDataPanelOpen} onChange={() => {setIsAdditionalDataPanelOpenMaster && setIsAdditionalDataPanelOpenMaster(isAdditionalDataPanelOpenMaster && !isAdditionalDataPanelOpen); setIsAdditionalDataPanelOpen(!isAdditionalDataPanelOpen)}}>
                        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
                            <Typography className='additionalDataPanelDecree' variant='subtitle1'>{labels.additionalData}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={additionalDataColumns}>
                                    <DropdownField id='gtuCode'
                                        label={labels.gtuCode_optional}
                                        items={gtuCodes}
                                        value={decreeData.gtuCode}
                                        defaultItemValue=''
                                        onChange={dropdownChange}/>
                                </Grid>
                                <Grid item xs={additionalDataColumns}>
                                    <AccXTextField id='cn'
                                        label={labels.cnCode}
                                        value={decreeData.cn}
                                        onChange={textChange}/>
                                </Grid>
                                <Grid item xs={additionalDataColumns}>
                                    <AccXTextField id='pkwiu'
                                        label={labels.pkwiu}
                                        value={decreeData.pkwiu}
                                        onChange={textChange}/>
                                </Grid>
                                {isSigned && decreeMode === DecreeMode.Category && <>
                                    <Grid item xs={additionalDataColumns}>
                                        <ComboBoxField id='purchasePurposeId'
                                            value={(decreeData as AccXCategoryPrediction).purchasePurposeId}
                                            label={labels.purchasePurposes}
                                            items={purchasePurposes}
                                            onChange={comboBoxChange}/>
                                    </Grid>
                                    <Grid item xs={additionalDataColumns}>
                                        <NumericField id='unitPrice'
                                            value={(decreeData as AccXCategoryPrediction).unitPrice}
                                            label={labels.unitNetPrice} 
                                            onChange={textChange}
                                            decimals={2}
                                            placeholder='0,00'/>
                                    </Grid>
                                </>}
                                {isSigned && (decreeMode === DecreeMode.Category || decreeMode === DecreeMode.TopicAndProduct) && <Grid item xs={additionalDataColumns}>
                                    {vatRateInput}
                                </Grid>}
                                <Grid item xs={additionalDataColumns}>
                                    <CheckboxField id='hasRegNum' 
                                        checked={decreeData.hasRegNum}
                                        label={labels.vehicleRealted}
                                        onChange={hasRegNumChange}
                                        isSwitch/>
                                </Grid>
                                {isSigned && (decreeMode === DecreeMode.Category || decreeMode === DecreeMode.TopicAndProduct) && decreeData.hasRegNum && <Grid item xs={additionalDataColumns}>
                                    <DropdownField id='vehicleUsagePurposeId'
                                        value={(decreeData as AccXCategoryPrediction).vehicleUsagePurposeId}
                                        label={labels.vehicleUsagePurpose}
                                        items={vehicleUsagePurposes}
                                        hasDefaultItem={false}
                                        onChange={dropdownChange}/>
                                </Grid>}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {!isMobile && 
                <Grid item xs={3}>
                    <div>
                        <label className='loadKsefInvoiceLabel'>
                            <span>{labels.loadKSeFInvoice} <b style={{letterSpacing: '0.5px'}}>KS<span style={{color: '#dc0932'}}>e</span>F</b></span>
                            <input type="file" accept=".xml" onChange={handleFileChange} style={{opacity: 0, position: 'absolute', zIndex:-1}}/>
                        </label>
                    </div>
                </Grid>}
                {!isMobile && ksefInvoice && <>
                    <Grid item xs={5}>
                        <DropdownField
                            value={ksefInvoicePosition}
                            label={labels.ksefInvoicePosition}
                            items={ksefInvoicePositions}
                            onChange={onKsefInvoicePositionChange}
                            hasDefaultItem={false}/>
                    </Grid>
                    <Grid item xs={4}/>
                </>}
                <Grid item xs={12}>
                    <ActionButton type='submit'
                        style={{float: 'right'}}>
                        {labels.decree}
                    </ActionButton>
                    <ActionButton onClick={resetDecreePanel} className="resetButton">
                        {labels.clean}
                    </ActionButton>
                    {customerProfile?.isAdmin && !isMobile && showAddExample &&
                    <ActionButton onClick={saveExampleDecreePanel} style={{marginLeft:'10px'}}>
                            {labels.saveExample}
                        </ActionButton>}
                    {customerProfile?.isAdmin && !isMobile && showAddExample&& <AccXTextField id='exampleName' 
                            style={{marginLeft:'10px'}}
                            label={labels.exampleName}
                            fullWidth={false}
                            value={exampleName}
                            error={exampleNameEmptyError}
                            onChange={exampleNameChange}/>}
                </Grid>
            </Grid>
        </form>
    </EditForm>
}