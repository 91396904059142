import { Button } from "@mui/material";
import { PropsWithChildren } from "react";

type Props = {
    className?: string,
    type?: any,
    style?: any,
    isFromGrid?: boolean
    onClick?: (e: any) => void,
}

export default function ActionButton({className='', type, style, onClick, isFromGrid, children}: PropsWithChildren<Props>) {
    if(isFromGrid){
        var innerClassName = 'actionButton';
    }else{
        var innerClassName = 'button';
    }
    
    if(className) {
        if(className.toLowerCase().indexOf('button') == -1){
            innerClassName += ` ${className}`;
        }else{
            innerClassName = className
        }
    }

    return <Button className={innerClassName} variant='contained' onClick={onClick} style={style} type={type}>
        {children}
    </Button>
}