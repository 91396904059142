import { Chip } from "@mui/material";
import { margin } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { ProductApi, AccXProductResult } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import ActionGridButton from "../../../../components/ActionGridButton";
import DropdownFilter from "../../../../components/filters/DropdownFilter";
import GridInfo from "../../../../components/GridInfo";
import MarketingInfo from "../../../../components/MarketingInfo";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { useUserContext } from "../../../../contexts/UserContext";
import useFilters from "../../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../../models/ActionDefinition";
import ProductAlternativeNameDeletePopup from "./ProductAlternativeNameDeletePopup";
import ProductAlternativeNamePopup from "./ProductAlternativeNamePopup";
import ProductPopup from "./ProductPopup";

export default function ProductsPanel() {

    const [openNewProductPopup, setOpenNewProductPopup] = useState(false);
    const [openEditProductPopup, setOpenEditProductPopup] = useState(false);
    const [openAddAlternativeNameProductPopup, setOpenAddAlternativeNameProductPopup] = useState(false);
    const [openDeleteAlternativeNameProductPopup, setOpenDeleteAlternativeNameProductPopup] = useState(false);

    const [products, setProducts] = useState<AccXProductResult[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
    const [filtersData, addFilter] = useFilters();

    const {customerProfile} = useUserContext();
    const {request} = useApiContext();
    const {labels, showMessage} = useAppContext();
    const productApi = new ProductApi();

    useEffect(() => {
        fetchProducts();
    }, [])

    const visibleProducts = useMemo(() => {

        var temp = [...products];

        if (temp.length > 0 && filtersData) {
            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(product => product.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()))
            };

            if (filtersData.productGroupName) {
                temp = temp.filter(product => product.productGroupName === filtersData.productGroupName);
            }
        }
        
        return temp;

    }, [products, filtersData])


    function fetchProducts() {
        setIsLoadingData(true);

        request(productApi.productAllGet()).then((response) => {
            if (response.ok && response.data) {
                setProducts(response.data);
            }
            setIsLoadingData(false);
        });
    }

    function deleteProduct(productId : number) {
        setIsLoadingData(true);

        request(productApi.productProductIdDelete(productId)).then((response) => {
            setIsLoadingData(false);
            if (response.ok) {
                fetchProducts();
                showMessage(labels.productDeleted);
            }
            else {
                showMessage(labels.productDeletingError, MessageType.Error);
            }
        });
    }
    
    function closePopups(refresh: boolean = false) {
        
        setOpenNewProductPopup(false);
        setOpenEditProductPopup(false);
        setOpenAddAlternativeNameProductPopup(false);
        setOpenDeleteAlternativeNameProductPopup(false);

        if (refresh) {
            fetchProducts();
        }
    }

    var columns = [
        {header: labels.productIndex, field: 'productIndex'}, 
        {header: labels.productName, field: 'productName', customTemplate: (row: any) => {
            const productName = row['productName'];
            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditProductPopup(true)}>{productName}</a>
        }},
        {header: labels.productGroupName, field: 'productGroupName'},
        {header: labels.sellerIndex, field: 'sellerIndex'},
        {header: labels.alternativeNames, field: 'alternativeNames', customTemplate: (row: any) => {

            const alternativeNames = row['alternativeNames'];

            return <>
                {(alternativeNames as string[]).map((alternativeName, index) => {
                    return <Chip style={{margin: '1px'}} key={index} label={alternativeName}/>
                })}
            </>
        }},
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewProductPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditProductPopup(true)}>
                {labels.edit}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteProduct(selectedProducts[0])}>
                {labels.delete}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenAddAlternativeNameProductPopup(true)}>
                {labels.addAlternativeProductNameAction}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            dependOnColumn: 'hasAlternativeNames',
            definition: <ActionGridButton onClick={() => setOpenDeleteAlternativeNameProductPopup(true)}>
                {labels.deleteAlternativeProductNameAction}
            </ActionGridButton>
        },
    ];
    
    var filters = [
        <DropdownFilter gridData={products} 
            fieldName={'productGroupName'} 
            label={labels.productGroupName}
            filtersData={filtersData}
            addFilter={addFilter}/>
    ];

    var showIDX = customerProfile && customerProfile?.hasIDX;

    return <>
        {openNewProductPopup && <ProductPopup onClose={closePopups} allData={products}/>}
        {openEditProductPopup && <ProductPopup productId={selectedProducts[0]} onClose={closePopups}/>}
        {openAddAlternativeNameProductPopup && <ProductAlternativeNamePopup productId={selectedProducts[0]} onClose={closePopups}/>}
        {openDeleteAlternativeNameProductPopup && <ProductAlternativeNameDeletePopup productId={selectedProducts[0]} onClose={closePopups}/>}
        {showIDX && 
            <GridInfo columns={columns} 
                data={visibleProducts} 
                actions={actions} 
                filters={filters} 
                hasSearchBar={true} 
                onSelect={(selected: any[]) => setSelectedProducts(selected)}
                isLoading={isLoadingData}
                filtersData={filtersData}
                addFilter={addFilter}/>
        }
        {!showIDX && <MarketingInfo text={labels.marketingIDXText}></MarketingInfo>}
    </> 
}