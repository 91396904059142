import { useEffect, useMemo, useState } from "react";
import { AccXOnboardingParameterResult, OnboardingApi, OnboardingParameterTypeEnum } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import ActionGridButton from "../../../../components/ActionGridButton";
import GridInfo from "../../../../components/GridInfo";
import PredefinedTag from "../../../../components/PredefinedTag";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { stringToEnum } from "../../../../helpers/CommonHelper";
import useFilters from "../../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../../models/ActionDefinition";
import AccountingPolicyParameterPopup from "./AccountingPolicyParameterPopup";

export default function AccountingPolicyParameters() {

    const [parameters, setParameters] = useState<AccXOnboardingParameterResult[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState<string[]>([]);
    const [openNewPopup, setOpenNewPopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);

    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const onboardingApi = new OnboardingApi();
    const [filtersData, addFilter] = useFilters();

    useEffect(() => {
        fetchParameters();
    }, []);

    function fetchParameters() {
        setIsLoadingData(true);
        request(onboardingApi.onboardingParametersGet()).then((response) => {
            if (response.ok && response.data) {
                setParameters(response.data);
                setIsLoadingData(false);
            }
        })
    }


    const visibleOnboardingParameters = useMemo(() => {

        var temp = [...parameters];

        if (temp.length > 0 && filtersData) {

            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(parameter => parameter.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()));
            };
        }
        
        return temp;

    }, [parameters, filtersData]);


    var columns = [
        {header: labels.name, field: 'parameterName', customTemplate: (row: any) => {

            const isPredefined = row['isPredefined'];
            const parameterName = row['parameterName'];

            var element = <span>{parameterName}</span>;

            if (isPredefined) {
                element= <PredefinedTag text={parameterName}></PredefinedTag>;
            }

            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditPopup(true)}>{element}</a>
        }},
        {header: labels.value, field: 'parameterValue', isNotSortable: true ,customTemplate: (row: any) => {
            
            const type = row['parameterType'];
            var value = row['parameterValue'];

            if (stringToEnum(type, OnboardingParameterTypeEnum) === OnboardingParameterTypeEnum.Bool) {
                if (value === '1') {
                    value = labels.yes;
                }
                else if (value === '0') {
                    value = labels.no;
                }
            }

            return <span>{value}</span>
        }},
        {header: labels.key, field: 'parameterKey'}, 
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteParameter()}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteParameter()}>
                {labels.setDefault}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
        },
    ];

    function deleteParameter() {
        setIsLoading(true);

        const parameterKey = selectedParameters[0];

        request(onboardingApi.onboardingParametersParameterKeyDelete(parameterKey)).then((response) => {
            setIsLoading(false);

            if (response.ok) {
                fetchParameters();
                showMessage(labels.parameterDeleted);
            }
            else {
                showMessage(labels.parameterDeletingError, MessageType.Error);
            }
        });
    }

    function closePopups(refresh: boolean) {
        setOpenNewPopup(false);
        setOpenEditPopup(false);

        if(refresh) {
            fetchParameters();
        }
    }

    return <>
        {openNewPopup && <AccountingPolicyParameterPopup allData={parameters} onClose={closePopups}/>}
        {openEditPopup && <AccountingPolicyParameterPopup  parameterKey={selectedParameters[0]} onClose={closePopups}/>}
        
        <GridInfo columns={columns} 
            data={visibleOnboardingParameters} 
            filtersData={filtersData}
            addFilter={addFilter}
            hasSearchBar={true} 
            actions={actions} 
            onSelect={(selected) => setSelectedParameters(selected as string[])} 
            isLoading={isLoadingData}/>
    </>
}