import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Icon, IconButton, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { createRef } from "react";
import { useRef } from "react";
import { Button } from "reactstrap";
import ActionButton from "../ActionButton";

type Props = {
    id?: string,
    value?: string | number | null,
    label?: string,
    disabled?: boolean,
    onChange?: (id: string, value: string) => void,
    decimals?: number,
    step?: number,
    minValue?: number,
    maxValue?: number,
    placeholder?: string,
    hasArrows?: boolean,
}

export default function NumericField({id, value, label, disabled=false, decimals, step=1, minValue, maxValue, placeholder, hasArrows=false, onChange} : Props) {

    const [innerValue, setInnerValue] = useState<string | null>('');

    const observed = useRef<HTMLInputElement | null>(null);
    const [arrowsHeight, setArrowsHeight] = useState(40);

    useEffect(() => {
        if (value !== undefined && value !== null) {
            setInnerValue(value.toString().replaceAll('.', ','));
        }
        else {
            setInnerValue('');
        }
    }, [value]);

    useEffect(() => {
        setArrowsHeight(observed.current?.offsetHeight ?? 40);
    }, [observed]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        var inputValue = event.target.value;

        if (inputValue === '') {
            setInnerValue('');
            if (onChange) onChange(id ?? '', '');
            return;
        }

        inputValue = inputValue.replaceAll(',', '.');

        const tempValue = Number(inputValue);
        const dotCount = (inputValue.match(/\./g) || []).length;
        const dotCondition = inputValue !== '.' && (inputValue.at(-1) === '.' && dotCount === 1);

        if (!isNaN(tempValue) || dotCondition) {
            fixAndSetInnerValue(tempValue, false, dotCondition);
        }
    } 

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
        if (innerValue) {
            var tempValue = getInnerValue();
            fixAndSetInnerValue(tempValue);
        }
    }

    function handleOnKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function increase() {
        if (innerValue) {
            var tempValue = getInnerValue();
    
            tempValue += step;
    
            fixAndSetInnerValue(tempValue);
        }
    }

    function decrease() {
        if (innerValue) {
            var tempValue = getInnerValue();
    
            tempValue -= step;

            fixAndSetInnerValue(tempValue);
        }
    }

    function fixAndSetInnerValue(tempValue: number, fixValue=true, addDot=false) {

        var result : string;         

        if (minValue !== undefined && tempValue < minValue) {
            tempValue = minValue;
        }

        if (maxValue !== undefined && tempValue > maxValue) {
            tempValue = maxValue;
        }

        if (decimals !== undefined && decimals >= 0 && fixValue) {
            result = tempValue.toFixed(decimals);
        }
        else {
            result = tempValue.toString();
        }

        if (addDot) {
            result += '.';
        }

        if (onChange) onChange(id ?? '', result);
        
        result = result.replaceAll('.', ',');
        setInnerValue(result);
    }

    function getInnerValue() : number {

        if (innerValue) {
            return parseFloat(innerValue.toString().replaceAll(',', '.'));
        }
        return 0;
    }

    const numericField = <TextField id={id}
        ref={observed}
        value={innerValue}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder}
        inputProps={{
            inputMode: 'numeric', 
            style: { textAlign: 'right' },
        }}/>;

    return hasArrows ? <div style={{display: 'flex'}}>
            <div style={{flex: 9, width: '90%', maxWidth: 'calc(90%'}}>
                {numericField}
            </div>
            <div style={{flex: 1, minWidth: '50px'}}>
                <div className='numericFieldArrows' style={{height: arrowsHeight}}>
                    <div className='arrow' onClick={increase}><FontAwesomeIcon icon={faCaretUp}/></div>
                    <div className='arrow' onClick={decrease}><FontAwesomeIcon icon={faCaretDown}/></div>
                </div>
            </div>
        </div> 
        : numericField;
}

