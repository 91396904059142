import { Paper } from "@mui/material";
import LinkField from "../../../components/fields/LinkField";
import { config } from "../../../constants/config";
import SideElementLayout from "../../../layouts/SideElementLayout";
import { useAppContext } from "../../../contexts/AppContext";

export default function APIDocumentationPanel() {

    const {labels} = useAppContext();

    return <SideElementLayout>
        <Paper style={{padding: '20px'}}>
            {labels.apiDocumentationMarketing} <LinkField href={`mailto:${config.apiEmail}?Subject=${config.apiEmailSubject}`} isTextBold={true}>{config.apiEmail}</LinkField> 
        </Paper>
    </SideElementLayout> 
}