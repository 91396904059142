import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AccXTabs from "../../../components/tabs/AccXTabs";
import AccountingPolicyTopicGroups from "../accountingPolicy/topicGroups/AccountingPolicyTopicGroups";
import ProductsPanel from "./products/ProductsPanel";
import { useAppContext } from "../../../contexts/AppContext";

export default function MaterialsAndGoodsPanel() {

    const {labels} = useAppContext();

    const materialsTabsAndGoods = {
        products: 'products',
        topicGroups: 'topicGroups',
    }

    const [selectedTab, setSelectedTab] = useState(materialsTabsAndGoods.products);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        var currentTab = searchParams.get('tab');
        
        if (currentTab) {
            setSelectedTab(currentTab);
        }
        else {
            searchParams.set('tab', materialsTabsAndGoods.products);
        }
    }, []);

    function onTabChange(id: string) {
        setSelectedTab(id);

        var newSearchParams = new URLSearchParams();
        newSearchParams.set('tab', id);
        
        setSearchParams(newSearchParams);
    };

    function getSeletedTab() {
        switch(selectedTab) {
            default:
            case materialsTabsAndGoods.products:
                return <ProductsPanel/>
            case materialsTabsAndGoods.topicGroups:
                return <AccountingPolicyTopicGroups/>
        }
    }

    return <>
        <AccXTabs selectedTab={selectedTab} onTabChange={onTabChange} tabs={[
            {id: materialsTabsAndGoods.products, label: labels.indeksomat},
            {id: materialsTabsAndGoods.topicGroups, label: labels.topicsRelatedToMaterialsAndGoods},
        ]}/>
        {getSeletedTab()}
    </> 
}