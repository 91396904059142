import {Link} from "@mui/material";
import { PropsWithChildren } from "react";


type Props = {
    href?: string,
    target?: React.HTMLAttributeAnchorTarget,
    onClick?: (e: React.MouseEvent) => void,
    isTextBold?: boolean,
    style?: any,
}

export default function LinkField({href, onClick, isTextBold = false, style, target, children} :  PropsWithChildren<Props>) {
    let finalClassName = 'linkField';
    if(isTextBold){
        finalClassName+=' textBold';
    }

    return <Link
        target={target}
        style={style}
        href={href}
        className={finalClassName}
        onClick={onClick}>
            {children}
        </Link>
}