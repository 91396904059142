import { Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { config } from "../../constants/config";
import { pagesNames } from "../../constants/pageNavigationConstans";
import { useAppContext } from "../../contexts/AppContext";
import LinkField from "../fields/LinkField";
import Footer, { getLanguageButtons } from "./Footer";
import { languages } from "../../constants/languages";

export default function MobileFooter() {

    const {engineVersion, labels, language, setLanguage} = useAppContext();
    const navigate = useNavigate();

    const mobileFooterItems: React.CSSProperties = {
        fontSize: '10px', 
        color: 'gray', 
        marginLeft: '10px'
    };

    const mobileFooterRow : React.CSSProperties = {
        textAlign: 'center',
    };
    
    const mobileFooterLink : React.CSSProperties = {
        padding: '0 10px'
    };

    return <Paper style={{marginTop: '30px'}}>

        <div style={mobileFooterRow}>
            {getLanguageButtons(language, setLanguage)}
            <span style={mobileFooterLink}>
                <LinkField onClick={() => navigate(`/${pagesNames.aboutProject}`)}>
                    {labels.aboutProjectButtonLabel}
                </LinkField>
            </span>
            <span style={mobileFooterLink}>
                <LinkField href={`/${pagesNames.swagger}`} target='_blank'>
                    {labels.api}
                </LinkField>
            </span>
            <span style={mobileFooterLink}>
                <LinkField href={`mailto:${config.contactEmail}?Subject=${config.contactEmailSubject}`}>
                    {config.contactEmail}
                </LinkField>
            </span>
        </div>
        <div>
            <span style={mobileFooterItems}>{engineVersion}</span>
            <span style={mobileFooterItems}>{config.copyrightInfo}</span>
        </div>
    </Paper>
}