import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { useAppContext } from "../../../contexts/AppContext";

type Props = {
    invoiceContent: string,
}

export default function KSeFInvoicePanel({invoiceContent} : Props) {

    const {labels} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary>
            <Typography>{labels.loadedXMLFile}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <pre>{invoiceContent}</pre>
        </AccordionDetails>
    </Accordion>
}