import { Grid } from "@mui/material";
import EditForm from "../../../../../components/EditForm";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleMaterialAndGoodsTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const {labels} = useAppContext();

    const idxRelatedData = categoryRule.conditions?.idxRelated;

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, idxRelated: {...categoryRule.conditions?.idxRelated, [id]: checked}}});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, idxRelated: {...categoryRule.conditions?.idxRelated, [id]: value}}});
    }

    return <EditForm>
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <CheckboxField id='indexContainsSpecified'
                    checked={idxRelatedData?.indexContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='indexContains'
                    label={labels.indexContains}
                    value={idxRelatedData?.indexContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.indexContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='indexNotContainsSpecified'
                    checked={idxRelatedData?.indexNotContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='indexNotContains'
                    label={labels.indexNotContains}
                    value={idxRelatedData?.indexNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.indexNotContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='productGroupContainsSpecified'
                    checked={idxRelatedData?.productGroupContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='productGroupContains'
                    label={labels.productGroupContains}
                    value={idxRelatedData?.productGroupContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.productGroupContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='productGroupNotContainsSpecified'
                    checked={idxRelatedData?.productGroupNotContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='productGroupNotContains'
                    label={labels.productGroupNotContains}
                    value={idxRelatedData?.productGroupNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.productGroupNotContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='idxTagContainsSpecified'
                    checked={idxRelatedData?.idxTagContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='idxTagContains'
                    label={labels.idxTagContains}
                    value={idxRelatedData?.idxTagContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.idxTagContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='idxTagNotContainsSpecified'
                    checked={idxRelatedData?.idxTagNotContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='idxTagNotContains'
                    label={labels.idxTagNotContains}
                    value={idxRelatedData?.idxTagNotContains}
                    freeSolo
                    multiple
                    onChange={comboBoxChange}
                    disabled={!idxRelatedData?.idxTagNotContainsSpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}