import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/system';
import AccXTextField from '../../../components/fields/AccXTextField';
import { AccXBCPredictionDataResult, AccXBCPredictionResult } from '../../../api';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    contractorData: AccXBCPredictionDataResult,
}

export default function ContractorPanel({contractorData} : Props) {

    const {labels, isMobile} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
            <Stack>
                <Typography>{<FontAwesomeIcon icon={faUserTie}/>} {labels.contractorPanelTitle} </Typography>
                <Typography fontWeight='bold'> {contractorData.accXBCPredictionExtractedDataResults?.nameFromGUS ?? ''}</Typography>
                <Typography marginTop='5px'>{labels.businessClassification} {contractorData.accXBCPredictionResults && contractorData.accXBCPredictionResults.length > 0 ? contractorData.accXBCPredictionResults[0]?.businessClassificationName ?? '' : ''}</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccXTextField id='nameFromGUS' 
                        value={contractorData.accXBCPredictionExtractedDataResults?.nameFromGUS} 
                        label={labels.gusName} 
                        disabled={true}
                        inputProps={{
                            readOnly: true,
                        }} 
                    />
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='pkdTitle' 
                        value={contractorData.accXBCPredictionExtractedDataResults?.pkdTitle} 
                        label={labels.pkd} 
                        disabled={true}
                        inputProps={{
                            readOnly: true,
                        }} 
                    />
                </Grid>
                <Grid item xs={12}>
                    {contractorData.accXBCPredictionResults && contractorData.accXBCPredictionResults.map((prediction: AccXBCPredictionResult, predictionIndex: number) => {
                        var fixedIndex = predictionIndex + 1;

                        const businessClassificationName = `${fixedIndex}. ${prediction.businessClassificationName}`;
                        const confidence = `${(prediction.confidence ?? 0).toFixed(2)} %`;

                        return <Accordion key={predictionIndex}>
                            <AccordionSummary
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Grid container spacing={2}>
                                    {isMobile ? <>
                                        <Grid item xs={9}>{businessClassificationName}</Grid>
                                        <Grid item xs={3}>{confidence}</Grid>
                                    </>
                                    : <>
                                        <Grid item xs={6}>{businessClassificationName}</Grid>
                                        <Grid item xs={2}>{confidence}</Grid>
                                        <Grid item xs={4}>
                                            <ul>
                                                {prediction.sources?.map((source: string, sourceIndex: number) => {
                                                    return <li key={sourceIndex} className='tag' style={{float: 'left', listStyleType: 'none'}}>{source}</li>
                                                })}
                                            </ul>
                                        </Grid>
                                    </>}
                                </Grid>
                            </AccordionSummary>
                        </Accordion>
                    })}
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}
