import PanelInfo from '../../../components/PanelInfo';
import { Typography } from '@mui/material';
import React from 'react';
import LoginOptionMenu from '../../../components/login/LoginOptionMenu';
import LinkField from '../../../components/fields/LinkField';
import { useAppContext } from '../../../contexts/AppContext';


export default function NotLogInPanel() {

    const {labels} = useAppContext();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const SignInLink = <LinkField onClick={handleClick} >{labels.signInMarketing}</LinkField>

    return <PanelInfo>
        <Typography className='signInMarketingText'>{labels.signInMarketingQuestion} {SignInLink} {labels.signInMarketingMoreOptions}</Typography>
            <LoginOptionMenu setAnchorEl={setAnchorEl} anchorElement={anchorEl} isOpen={open} ></LoginOptionMenu>
        <Typography>{labels.signInMarketingDescription}</Typography>
    </PanelInfo>
}

