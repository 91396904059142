import { Divider, Grid } from "@mui/material";
import { useState } from "react";
import { AccXBCPrediction, AccXBCPredictionDataResult, BusinessClassificationPredictionApi } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import EditForm from "../../../components/EditForm";
import AccXTextField from "../../../components/fields/AccXTextField";
import PanelInfo from "../../../components/PanelInfo";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import SideElementLayout from "../../../layouts/SideElementLayout";
import ContractorPanel from "../../decree/contractor/ContractorPanel";
import DecreePanel, { DecreeMode } from "../../decree/DecreePanel";

export default function BusinessClassificationPredictionPanel() {

    const initValues : AccXBCPrediction = {
        taxId: '',
        contractorName: '',
    }
    
    const [bcData, setBCData] = useState(initValues);
    const [contractorData, setContractorData] = useState<AccXBCPredictionDataResult | null>(null);
    const [isContractorPanelActive, setIsContractorPanelActive] = useState(false);
    const [isNoResultPanelActive, setIsNoResultPanelActive] = useState(false);

    const {labels, setIsLoading} = useAppContext();
    const {request} = useApiContext();
    const bcPredictionApi = new BusinessClassificationPredictionApi();


    function textChange(id: string, value: string) {
        setBCData({...bcData, [id]: value});
    }

    function reset(resetBCPredictionData = true) {

        if (resetBCPredictionData) {
            setBCData(initValues);
        }
        
        setIsNoResultPanelActive(false);

        setContractorData(null);
        setIsContractorPanelActive(false);
    }

    const predictBC = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        reset(false);
        setIsLoading(true);
       
        request(bcPredictionApi.businessClassificationPredictionPredictPost(bcData)).then((response) => {
            setIsLoading(false);
            if (response.ok && response.data) {
                handleResponse(response.data);
            }
        })
    }

    function handleResponse(data: AccXBCPredictionDataResult) {

        setIsLoading(false);


        if (!(data && (data.accXBCPredictionResults?.length ?? 0) > 0)) {
            setIsNoResultPanelActive(true);
        }
      
        if (data && (data.accXBCPredictionResults?.length ?? 0) > 0) {
            setContractorData(data);
            setIsContractorPanelActive(true);
        }
        else {
            setContractorData(null);
            setIsContractorPanelActive(false);
        }
    }

    return <SideElementLayout>
        <EditForm>
        <h4>{labels.enterBCDetails}</h4>
        <Divider style={{marginBottom: '20px'}}></Divider>
        <form onSubmit={predictBC}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AccXTextField id='taxId' 
                        value={bcData.taxId}
                        onChange={textChange} 
                        label={labels.nip}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='contractorName'
                        value={bcData.contractorName}
                        onChange={textChange}
                        label={labels.companyName_optional}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionButton type='submit'
                        style={{float: 'right'}}>
                        {labels.predictContractorButtonLabel}
                    </ActionButton>
                    <ActionButton onClick={reset} className="resetButton">
                        {labels.clean}
                    </ActionButton>
                </Grid>
            </Grid>
        </form>
    </EditForm>
        {isContractorPanelActive && contractorData && <ContractorPanel contractorData={contractorData}/>}
        {isNoResultPanelActive && <PanelInfo text={labels.noDataResultInfo}></PanelInfo>}
    </SideElementLayout> 
    
}