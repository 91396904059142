/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * AccX-Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface APIResponse
 */
export interface APIResponse {
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof APIResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof APIResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum APIStatusResponse {
    Ok = <any> 'Ok',
    Error = <any> 'Error',
    SystemUpdate = <any> 'SystemUpdate'
}
/**
 * 
 * @export
 * @interface AccXBCPrediction
 */
export interface AccXBCPrediction {
    /**
     * 
     * @type {string}
     * @memberof AccXBCPrediction
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPrediction
     */
    contractorName?: string;
}
/**
 * 
 * @export
 * @interface AccXBCPredictionDataResult
 */
export interface AccXBCPredictionDataResult {
    /**
     * 
     * @type {AccXBCPredictionExtractedDataResult}
     * @memberof AccXBCPredictionDataResult
     */
    accXBCPredictionExtractedDataResults?: AccXBCPredictionExtractedDataResult;
    /**
     * 
     * @type {Array<AccXBCPredictionResult>}
     * @memberof AccXBCPredictionDataResult
     */
    accXBCPredictionResults?: Array<AccXBCPredictionResult>;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionDataResult
     */
    responseMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionDataResult
     */
    logInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXBCPredictionDataResult
     */
    logs?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof AccXBCPredictionDataResult
     */
    cacheEntityTimeStamp?: Date;
}
/**
 * 
 * @export
 * @interface AccXBCPredictionDataResultAPIDataResponse
 */
export interface AccXBCPredictionDataResultAPIDataResponse {
    /**
     * 
     * @type {AccXBCPredictionDataResult}
     * @memberof AccXBCPredictionDataResultAPIDataResponse
     */
    data?: AccXBCPredictionDataResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXBCPredictionDataResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXBCPredictionDataResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionDataResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXBCPredictionExtractedDataResult
 */
export interface AccXBCPredictionExtractedDataResult {
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    taxId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    isValidTaxId?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    nameFromGUS?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    nameToPrediction?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    pkd?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionExtractedDataResult
     */
    pkdTitle?: string;
}
/**
 * 
 * @export
 * @interface AccXBCPredictionResult
 */
export interface AccXBCPredictionResult {
    /**
     * 
     * @type {number}
     * @memberof AccXBCPredictionResult
     */
    businessClassificationId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXBCPredictionResult
     */
    confidence?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionResult
     */
    businessClassificationName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXBCPredictionResult
     */
    sources?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccXBCPredictionResult
     */
    clusterId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXBCPredictionResult
     */
    logInfo?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXBCPredictionResult
     */
    invoiceTopicId?: number;
}
/**
 * 
 * @export
 * @interface AccXBusinessClassificationResult
 */
export interface AccXBusinessClassificationResult {
    /**
     * 
     * @type {number}
     * @memberof AccXBusinessClassificationResult
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXBusinessClassificationResult
     */
    businessClassificationName?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXBusinessClassificationResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXBusinessClassificationResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXBusinessClassificationResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXBusinessClassificationResult
     */
    isCustomValue?: boolean;
}
/**
 * 
 * @export
 * @interface AccXBusinessClassificationResultListAPIDataResponse
 */
export interface AccXBusinessClassificationResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXBusinessClassificationResult>}
     * @memberof AccXBusinessClassificationResultListAPIDataResponse
     */
    data?: Array<AccXBusinessClassificationResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXBusinessClassificationResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXBusinessClassificationResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXBusinessClassificationResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXCategory
 */
export interface AccXCategory {
    /**
     * 
     * @type {number}
     * @memberof AccXCategory
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategory
     */
    categoryName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCategory
     */
    vatDeduction?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategory
     */
    costDeduction?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategory
     */
    vatRegisterId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategory
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategory
     */
    accountNonDeductible?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryPrediction
 */
export interface AccXCategoryPrediction {
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPrediction
     */
    unitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPrediction
     */
    purchasePurposeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    contractorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    positionText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    sellerIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    gtuCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPrediction
     */
    vehicleUsagePurposeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryPrediction
     */
    hasRegNum?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    cn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    pkwiu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    gtinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    vatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPrediction
     */
    additionalData?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryPredictionDataResult
 */
export interface AccXCategoryPredictionDataResult {
    /**
     * 
     * @type {AccXTopicPredictionDataResult}
     * @memberof AccXCategoryPredictionDataResult
     */
    topicPrediction?: AccXTopicPredictionDataResult;
    /**
     * 
     * @type {AccXProductPredictionResult}
     * @memberof AccXCategoryPredictionDataResult
     */
    productPrediction?: AccXProductPredictionResult;
    /**
     * 
     * @type {AccXCategoryPredictionResult}
     * @memberof AccXCategoryPredictionDataResult
     */
    categoryPrediction?: AccXCategoryPredictionResult;
    /**
     * 
     * @type {Array<AccXCategoryPredictionExplainElementResult>}
     * @memberof AccXCategoryPredictionDataResult
     */
    explainElement?: Array<AccXCategoryPredictionExplainElementResult>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryPredictionDataResult
     */
    logs?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXCategoryPredictionDataResultAPIDataResponse
 */
export interface AccXCategoryPredictionDataResultAPIDataResponse {
    /**
     * 
     * @type {AccXCategoryPredictionDataResult}
     * @memberof AccXCategoryPredictionDataResultAPIDataResponse
     */
    data?: AccXCategoryPredictionDataResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCategoryPredictionDataResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryPredictionDataResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionDataResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryPredictionExplainElementResult
 */
export interface AccXCategoryPredictionExplainElementResult {
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    explainOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ruleId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ruleName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {AccXCategory}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    potentialCategory?: AccXCategory;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifBusinessClassificationId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifPurchasePurposeId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifVehicleUsagePurposeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifTopicGroupKey?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifTopicGroupKeyContainsTopicId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifUnitPriceLT?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifUnitPriceGE?: number;
    /**
     * 
     * @type {AccXOnboardingParamRule}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifOnBoardingParamRule1?: AccXOnboardingParamRule;
    /**
     * 
     * @type {AccXOnboardingParamRule}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifOnBoardingParamRule2?: AccXOnboardingParamRule;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    ifMatch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    businnessClassification?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    purchasePurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    vehicleUsagePurpose?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    topicGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionExplainElementResult
     */
    topicName?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryPredictionResult
 */
export interface AccXCategoryPredictionResult {
    /**
     * 
     * @type {AccXCategoryResult}
     * @memberof AccXCategoryPredictionResult
     */
    category?: AccXCategoryResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryPredictionResult
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryPredictionResult
     */
    stopMessage?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryResult
 */
export interface AccXCategoryResult {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {AccXVatRegister}
     * @memberof AccXCategoryResult
     */
    vatRegisterInfo?: AccXVatRegister;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResult
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXCategoryResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXCategoryResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryResult
     */
    isCustomValue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryResult
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResult
     */
    categoryName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryResult
     */
    vatDeduction?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryResult
     */
    costDeduction?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryResult
     */
    vatRegisterId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResult
     */
    account?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResult
     */
    accountNonDeductible?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryResultAPIDataResponse
 */
export interface AccXCategoryResultAPIDataResponse {
    /**
     * 
     * @type {AccXCategoryResult}
     * @memberof AccXCategoryResultAPIDataResponse
     */
    data?: AccXCategoryResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCategoryResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryResultListAPIDataResponse
 */
export interface AccXCategoryResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXCategoryResult>}
     * @memberof AccXCategoryResultListAPIDataResponse
     */
    data?: Array<AccXCategoryResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCategoryResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryRule
 */
export interface AccXCategoryRule {
    /**
     * 
     * @type {AccXCategoryRuleGeneralSection}
     * @memberof AccXCategoryRule
     */
    general?: AccXCategoryRuleGeneralSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsSection}
     * @memberof AccXCategoryRule
     */
    conditions?: AccXCategoryRuleConditionsSection;
    /**
     * 
     * @type {AccXCategoryRuleSetSection}
     * @memberof AccXCategoryRule
     */
    set?: AccXCategoryRuleSetSection;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRule
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXCategoryRule
     */
    entityId?: any;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleAPIDataResponse
 */
export interface AccXCategoryRuleAPIDataResponse {
    /**
     * 
     * @type {AccXCategoryRule}
     * @memberof AccXCategoryRuleAPIDataResponse
     */
    data?: AccXCategoryRule;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCategoryRuleAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsBuyerRelatedSection
 */
export interface AccXCategoryRuleConditionsBuyerRelatedSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsBuyerRelatedSection
     */
    businessClassificationIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsBuyerRelatedSection
     */
    businessClassificationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsBuyerRelatedSection
     */
    notBusinessClassificationIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsBuyerRelatedSection
     */
    notBusinessClassificationId?: number;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsIdxRelatedSection
 */
export interface AccXCategoryRuleConditionsIdxRelatedSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    indexContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    indexContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    indexNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    indexNotContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    productGroupContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    productGroupContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    productGroupNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    productGroupNotContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    idxTagContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    idxTagContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    idxTagNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsIdxRelatedSection
     */
    idxTagNotContains?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsInvoiceItemRelatedSection
 */
export interface AccXCategoryRuleConditionsInvoiceItemRelatedSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceLtSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceLt?: string;
    /**
     * 
     * @type {AccXUnitPriceType}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceLtType?: AccXUnitPriceType;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceGeSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceGe?: string;
    /**
     * 
     * @type {AccXUnitPriceType}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    unitPriceGeType?: AccXUnitPriceType;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    itemTextContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    itemTextContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    itemTextNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    itemTextNotContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    isItemTextEmptySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    isItemTextEmpty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    gtuSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    gtu?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    notGtuSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    notGtu?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    isGtuEmptySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    isGtuEmpty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    vatRateSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsInvoiceItemRelatedSection
     */
    vatRate?: string;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsOnboardingSection
 */
export interface AccXCategoryRuleConditionsOnboardingSection {
    /**
     * 
     * @type {AccXOnboardingParamRule}
     * @memberof AccXCategoryRuleConditionsOnboardingSection
     */
    paramRule1?: AccXOnboardingParamRule;
    /**
     * 
     * @type {AccXOnboardingParamRule}
     * @memberof AccXCategoryRuleConditionsOnboardingSection
     */
    paramRule2?: AccXOnboardingParamRule;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsPurposesAndOtherSection
 */
export interface AccXCategoryRuleConditionsPurposesAndOtherSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    purchasePurposeIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    purchasePurposeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    notPurchasePurposeIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    notPurchasePurposeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    isPurchasePurposeEmptySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    isPurchasePurposeEmpty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    vehicleUsagePurposeIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    vehicleUsagePurposeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    isVehicleUsagePurposeEmptySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    isVehicleUsagePurposeEmpty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    additionalInfoContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    additionalInfoContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    additionalInfoNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsPurposesAndOtherSection
     */
    additionalInfoNotContains?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsSection
 */
export interface AccXCategoryRuleConditionsSection {
    /**
     * 
     * @type {AccXCategoryRuleConditionsTopicRelatedSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    topicRelated?: AccXCategoryRuleConditionsTopicRelatedSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsBuyerRelatedSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    buyerRelated?: AccXCategoryRuleConditionsBuyerRelatedSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsSellerRelatedSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    sellerRelated?: AccXCategoryRuleConditionsSellerRelatedSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsInvoiceItemRelatedSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    itemRelated?: AccXCategoryRuleConditionsInvoiceItemRelatedSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsOnboardingSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    onboarding?: AccXCategoryRuleConditionsOnboardingSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsIdxRelatedSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    idxRelated?: AccXCategoryRuleConditionsIdxRelatedSection;
    /**
     * 
     * @type {AccXCategoryRuleConditionsPurposesAndOtherSection}
     * @memberof AccXCategoryRuleConditionsSection
     */
    purposesAndOther?: AccXCategoryRuleConditionsPurposesAndOtherSection;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsSellerRelatedSection
 */
export interface AccXCategoryRuleConditionsSellerRelatedSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    businessClassificationIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    businessClassificationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    notBusinessClassificationIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    notBusinessClassificationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    nameContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    nameContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    nameNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    nameNotContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    taxIdSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    taxId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    isTaxIdEmptySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsSellerRelatedSection
     */
    isTaxIdEmpty?: boolean;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleConditionsTopicRelatedSection
 */
export interface AccXCategoryRuleConditionsTopicRelatedSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicIdsSpecified?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    notTopicIdsSpecified?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    notTopicIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicGroupKeySpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicGroupKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    notTopicGroupKeySpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    notTopicGroupKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isCommoditySpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isCommodity?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isFixedAssetSpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isFixedAsset?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isLowValueEquipmentSpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isLowValueEquipment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isIntangibleAssetSpecified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    isIntangibleAsset?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    productServiceSpecified?: boolean;
    /**
     * 
     * @type {AccXCategoryRuleProductServiceEnum}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    productService?: AccXCategoryRuleProductServiceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicTagContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicTagContains?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicTagNotContainsSpecified?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXCategoryRuleConditionsTopicRelatedSection
     */
    topicTagNotContains?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleGeneralSection
 */
export interface AccXCategoryRuleGeneralSection {
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleGeneralSection
     */
    categoryRuleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleGeneralSection
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleGeneralSection
     */
    categoryRuleName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleGeneralSection
     */
    processingOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleGeneralSection
     */
    isStoppingProcessing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleGeneralSection
     */
    isEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleListAPIDataResponse
 */
export interface AccXCategoryRuleListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXCategoryRule>}
     * @memberof AccXCategoryRuleListAPIDataResponse
     */
    data?: Array<AccXCategoryRule>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCategoryRuleListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccXCategoryRuleOperatorEnum {
    Unknown = <any> 'Unknown',
    Equal = <any> 'Equal',
    NotEqual = <any> 'NotEqual',
    LessThan = <any> 'LessThan',
    GreaterThan = <any> 'GreaterThan',
    LessOrQqual = <any> 'LessOrQqual',
    GreaterOrQqual = <any> 'GreaterOrQqual',
    Contains = <any> 'Contains',
    NotContains = <any> 'NotContains'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccXCategoryRuleProductServiceEnum {
    Product = <any> 'Product',
    Service = <any> 'Service'
}
/**
 * 
 * @export
 * @interface AccXCategoryRuleSetSection
 */
export interface AccXCategoryRuleSetSection {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleSetSection
     */
    categoryIdSpecified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXCategoryRuleSetSection
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleSetSection
     */
    categoryName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleSetSection
     */
    tagsSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleSetSection
     */
    tag?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCategoryRuleSetSection
     */
    stopMessageSpecified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCategoryRuleSetSection
     */
    stopMessage?: string;
}
/**
 * 
 * @export
 * @interface AccXCustomerProfile
 */
export interface AccXCustomerProfile {
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfile
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfile
     */
    companyTaxId?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCustomerProfile
     */
    businessClassificationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfile
     */
    explainMode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfile
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfile
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfile
     */
    hasWarehouse?: boolean;
}
/**
 * 
 * @export
 * @interface AccXCustomerProfileResult
 */
export interface AccXCustomerProfileResult {
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResult
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResult
     */
    hasCustomerAssigned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResult
     */
    hasIDX?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfileResult
     */
    companyTaxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfileResult
     */
    companyName?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXCustomerProfileResult
     */
    businessClassificationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResult
     */
    explainMode?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfileResult
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfileResult
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResult
     */
    hasWarehouse?: boolean;
}
/**
 * 
 * @export
 * @interface AccXCustomerProfileResultAPIDataResponse
 */
export interface AccXCustomerProfileResultAPIDataResponse {
    /**
     * 
     * @type {AccXCustomerProfileResult}
     * @memberof AccXCustomerProfileResultAPIDataResponse
     */
    data?: AccXCustomerProfileResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXCustomerProfileResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXCustomerProfileResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXCustomerProfileResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXExampleResult
 */
export interface AccXExampleResult {
    /**
     * 
     * @type {string}
     * @memberof AccXExampleResult
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXExampleResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXExampleResult
     */
    example?: string;
}
/**
 * 
 * @export
 * @interface AccXExampleResultAPIDataResponse
 */
export interface AccXExampleResultAPIDataResponse {
    /**
     * 
     * @type {AccXExampleResult}
     * @memberof AccXExampleResultAPIDataResponse
     */
    data?: AccXExampleResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXExampleResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXExampleResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXExampleResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXExampleResultListAPIDataResponse
 */
export interface AccXExampleResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXExampleResult>}
     * @memberof AccXExampleResultListAPIDataResponse
     */
    data?: Array<AccXExampleResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXExampleResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXExampleResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXExampleResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXOnboardingParamRule
 */
export interface AccXOnboardingParamRule {
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParamRule
     */
    onboardingParamKey?: string;
    /**
     * 
     * @type {AccXCategoryRuleOperatorEnum}
     * @memberof AccXOnboardingParamRule
     */
    operatorType?: AccXCategoryRuleOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParamRule
     */
    targetValue?: string;
}
/**
 * 
 * @export
 * @interface AccXOnboardingParameter
 */
export interface AccXOnboardingParameter {
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameter
     */
    parameterName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameter
     */
    parameterValue?: string;
    /**
     * 
     * @type {OnboardingParameterTypeEnum}
     * @memberof AccXOnboardingParameter
     */
    parameterType?: OnboardingParameterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameter
     */
    parameterKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameter
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXOnboardingParameter
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingParameter
     */
    isCustomValue?: boolean;
}
/**
 * 
 * @export
 * @interface AccXOnboardingParameterResult
 */
export interface AccXOnboardingParameterResult {
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingParameterResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResult
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXOnboardingParameterResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResult
     */
    parameterName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResult
     */
    parameterValue?: string;
    /**
     * 
     * @type {OnboardingParameterTypeEnum}
     * @memberof AccXOnboardingParameterResult
     */
    parameterType?: OnboardingParameterTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResult
     */
    parameterKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXOnboardingParameterResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingParameterResult
     */
    isCustomValue?: boolean;
}
/**
 * 
 * @export
 * @interface AccXOnboardingParameterResultAPIDataResponse
 */
export interface AccXOnboardingParameterResultAPIDataResponse {
    /**
     * 
     * @type {AccXOnboardingParameterResult}
     * @memberof AccXOnboardingParameterResultAPIDataResponse
     */
    data?: AccXOnboardingParameterResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXOnboardingParameterResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingParameterResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXOnboardingParameterResultListAPIDataResponse
 */
export interface AccXOnboardingParameterResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXOnboardingParameterResult>}
     * @memberof AccXOnboardingParameterResultListAPIDataResponse
     */
    data?: Array<AccXOnboardingParameterResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXOnboardingParameterResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingParameterResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingParameterResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXOnboardingTopicGroup
 */
export interface AccXOnboardingTopicGroup {
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroup
     */
    topicGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroup
     */
    topicGroupKey?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccXOnboardingTopicGroup
     */
    topicIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface AccXOnboardingTopicGroupResult
 */
export interface AccXOnboardingTopicGroupResult {
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingTopicGroupResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {any}
     * @memberof AccXOnboardingTopicGroupResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXOnboardingTopicGroupResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingTopicGroupResult
     */
    isCustomValue?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResult
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResult
     */
    topicGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResult
     */
    topicGroupKey?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AccXOnboardingTopicGroupResult
     */
    topicIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface AccXOnboardingTopicGroupResultAPIDataResponse
 */
export interface AccXOnboardingTopicGroupResultAPIDataResponse {
    /**
     * 
     * @type {AccXOnboardingTopicGroupResult}
     * @memberof AccXOnboardingTopicGroupResultAPIDataResponse
     */
    data?: AccXOnboardingTopicGroupResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXOnboardingTopicGroupResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingTopicGroupResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXOnboardingTopicGroupResultListAPIDataResponse
 */
export interface AccXOnboardingTopicGroupResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXOnboardingTopicGroupResult>}
     * @memberof AccXOnboardingTopicGroupResultListAPIDataResponse
     */
    data?: Array<AccXOnboardingTopicGroupResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXOnboardingTopicGroupResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXOnboardingTopicGroupResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXOnboardingTopicGroupResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXPrediction
 */
export interface AccXPrediction {
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    contractorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    positionText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    gtuCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    cn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    pkwiu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXPrediction
     */
    vatRate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXPrediction
     */
    hasRegNum?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXPrediction
     */
    vehicleUsagePurposeId?: number;
}
/**
 * 
 * @export
 * @interface AccXProduct
 */
export interface AccXProduct {
    /**
     * 
     * @type {number}
     * @memberof AccXProduct
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    productIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    sellerIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    productGroupName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXProduct
     */
    productTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXProduct
     */
    alternativeNames?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccXProduct
     */
    invoiceTopicId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    vatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    gtu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    pkWiUCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    cnCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    gtinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProduct
     */
    additionalData?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXProduct
     */
    hasAlternativeNames?: boolean;
}
/**
 * 
 * @export
 * @interface AccXProductAlternative
 */
export interface AccXProductAlternative {
    /**
     * 
     * @type {number}
     * @memberof AccXProductAlternative
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProductAlternative
     */
    alternativeName?: string;
}
/**
 * 
 * @export
 * @interface AccXProductPrediction
 */
export interface AccXProductPrediction {
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    productIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    gtu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    vatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    pkWiUCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    cnCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPrediction
     */
    gtinCode?: string;
}
/**
 * 
 * @export
 * @interface AccXProductPredictionResult
 */
export interface AccXProductPredictionResult {
    /**
     * 
     * @type {AccXProductResult}
     * @memberof AccXProductPredictionResult
     */
    product?: AccXProductResult;
    /**
     * 
     * @type {number}
     * @memberof AccXProductPredictionResult
     */
    confidence?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXProductPredictionResult
     */
    why?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXProductPredictionResultListAPIDataResponse
 */
export interface AccXProductPredictionResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXProductPredictionResult>}
     * @memberof AccXProductPredictionResultListAPIDataResponse
     */
    data?: Array<AccXProductPredictionResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXProductPredictionResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXProductPredictionResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPredictionResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXProductPredictionTrain
 */
export interface AccXProductPredictionTrain {
    /**
     * 
     * @type {number}
     * @memberof AccXProductPredictionTrain
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProductPredictionTrain
     */
    productName?: string;
}
/**
 * 
 * @export
 * @interface AccXProductResult
 */
export interface AccXProductResult {
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXProductResult
     */
    entityId?: any;
    /**
     * 
     * @type {number}
     * @memberof AccXProductResult
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    productIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    sellerIndex?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    productGroupName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXProductResult
     */
    productTags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXProductResult
     */
    alternativeNames?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccXProductResult
     */
    invoiceTopicId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    vatRate?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    gtu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    pkWiUCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    cnCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    gtinCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResult
     */
    additionalData?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXProductResult
     */
    hasAlternativeNames?: boolean;
}
/**
 * 
 * @export
 * @interface AccXProductResultAPIDataResponse
 */
export interface AccXProductResultAPIDataResponse {
    /**
     * 
     * @type {AccXProductResult}
     * @memberof AccXProductResultAPIDataResponse
     */
    data?: AccXProductResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXProductResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXProductResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXProductResultListAPIDataResponse
 */
export interface AccXProductResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXProductResult>}
     * @memberof AccXProductResultListAPIDataResponse
     */
    data?: Array<AccXProductResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXProductResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXProductResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXProductResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXPurchasePurpose
 */
export interface AccXPurchasePurpose {
    /**
     * 
     * @type {number}
     * @memberof AccXPurchasePurpose
     */
    purchasePurposeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurpose
     */
    purchasePurposeName?: string;
}
/**
 * 
 * @export
 * @interface AccXPurchasePurposeResult
 */
export interface AccXPurchasePurposeResult {
    /**
     * 
     * @type {number}
     * @memberof AccXPurchasePurposeResult
     */
    purchasePurposeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurposeResult
     */
    purchasePurposeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXPurchasePurposeResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurposeResult
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXPurchasePurposeResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurposeResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXPurchasePurposeResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXPurchasePurposeResult
     */
    isCustomValue?: boolean;
}
/**
 * 
 * @export
 * @interface AccXPurchasePurposeResultAPIDataResponse
 */
export interface AccXPurchasePurposeResultAPIDataResponse {
    /**
     * 
     * @type {AccXPurchasePurposeResult}
     * @memberof AccXPurchasePurposeResultAPIDataResponse
     */
    data?: AccXPurchasePurposeResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXPurchasePurposeResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXPurchasePurposeResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurposeResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXPurchasePurposeResultListAPIDataResponse
 */
export interface AccXPurchasePurposeResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXPurchasePurposeResult>}
     * @memberof AccXPurchasePurposeResultListAPIDataResponse
     */
    data?: Array<AccXPurchasePurposeResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXPurchasePurposeResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXPurchasePurposeResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXPurchasePurposeResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXTopTopicPredictionResult
 */
export interface AccXTopTopicPredictionResult {
    /**
     * 
     * @type {number}
     * @memberof AccXTopTopicPredictionResult
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXTopTopicPredictionResult
     */
    certaintyLevel?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXTopTopicPredictionResult
     */
    topicName?: string;
}
/**
 * 
 * @export
 * @interface AccXTopicAndProductPredictionResult
 */
export interface AccXTopicAndProductPredictionResult {
    /**
     * 
     * @type {AccXTopicPredictionDataResult}
     * @memberof AccXTopicAndProductPredictionResult
     */
    topicPrediction?: AccXTopicPredictionDataResult;
    /**
     * 
     * @type {AccXProductPredictionResult}
     * @memberof AccXTopicAndProductPredictionResult
     */
    productPrediction?: AccXProductPredictionResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicAndProductPredictionResult
     */
    logs?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXTopicAndProductPredictionResultAPIDataResponse
 */
export interface AccXTopicAndProductPredictionResultAPIDataResponse {
    /**
     * 
     * @type {AccXTopicAndProductPredictionResult}
     * @memberof AccXTopicAndProductPredictionResultAPIDataResponse
     */
    data?: AccXTopicAndProductPredictionResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXTopicAndProductPredictionResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicAndProductPredictionResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicAndProductPredictionResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXTopicPredictRequestSectionResult
 */
export interface AccXTopicPredictRequestSectionResult {
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    contractorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    positionText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    gtuCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    hasRegNum?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    cn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    pkwiu?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    additionalInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    preset?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictRequestSectionResult
     */
    userLogin?: string;
}
/**
 * 
 * @export
 * @interface AccXTopicPrediction
 */
export interface AccXTopicPrediction {
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    taxId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    contractorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    positionText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    gtuCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    cn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPrediction
     */
    pkwiu?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicPrediction
     */
    hasRegNum?: boolean;
}
/**
 * 
 * @export
 * @interface AccXTopicPredictionDataResult
 */
export interface AccXTopicPredictionDataResult {
    /**
     * 
     * @type {AccXTopicPredictRequestSectionResult}
     * @memberof AccXTopicPredictionDataResult
     */
    accXTopicPredictRequestSectionResult?: AccXTopicPredictRequestSectionResult;
    /**
     * 
     * @type {AccXBCPredictionDataResult}
     * @memberof AccXTopicPredictionDataResult
     */
    accXBCPredictionDataResult?: AccXBCPredictionDataResult;
    /**
     * 
     * @type {AccXTopTopicPredictionResult}
     * @memberof AccXTopicPredictionDataResult
     */
    accXTopTopicPredictionResult?: AccXTopTopicPredictionResult;
    /**
     * 
     * @type {Array<AccXTopicPredictionResult>}
     * @memberof AccXTopicPredictionDataResult
     */
    topicPredictionResult?: Array<AccXTopicPredictionResult>;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionDataResult
     */
    responseMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionDataResult
     */
    logInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicPredictionDataResult
     */
    logs?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof AccXTopicPredictionDataResult
     */
    cacheEntityTimeStamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionDataResult
     */
    timeElapsed?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicPredictionDataResult
     */
    tags?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXTopicPredictionDataResultAPIDataResponse
 */
export interface AccXTopicPredictionDataResultAPIDataResponse {
    /**
     * 
     * @type {AccXTopicPredictionDataResult}
     * @memberof AccXTopicPredictionDataResultAPIDataResponse
     */
    data?: AccXTopicPredictionDataResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXTopicPredictionDataResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicPredictionDataResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionDataResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXTopicPredictionResult
 */
export interface AccXTopicPredictionResult {
    /**
     * 
     * @type {number}
     * @memberof AccXTopicPredictionResult
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccXTopicPredictionResult
     */
    confidence?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionResult
     */
    topicName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicPredictionResult
     */
    sources?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AccXTopicPredictionResult
     */
    clusterId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicPredictionResult
     */
    hasRegNum?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionResult
     */
    gtuCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AccXTopicPredictionResult
     */
    accountingCategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicPredictionResult
     */
    logInfo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicPredictionResult
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccXTopicPredictionResult
     */
    rules?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccXTopicResult
 */
export interface AccXTopicResult {
    /**
     * 
     * @type {number}
     * @memberof AccXTopicResult
     */
    topicId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicResult
     */
    topicName?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXTopicResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXTopicResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicResult
     */
    isCustomValue?: boolean;
}
/**
 * 
 * @export
 * @interface AccXTopicResultListAPIDataResponse
 */
export interface AccXTopicResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXTopicResult>}
     * @memberof AccXTopicResultListAPIDataResponse
     */
    data?: Array<AccXTopicResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXTopicResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXTopicResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXTopicResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccXUnitPriceType {
    Value = <any> 'Value',
    Parameter = <any> 'Parameter'
}
/**
 * 
 * @export
 * @interface AccXUser
 */
export interface AccXUser {
    /**
     * 
     * @type {string}
     * @memberof AccXUser
     */
    userLogin?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXUser
     */
    companyName?: string;
}
/**
 * 
 * @export
 * @interface AccXUserAPIDataResponse
 */
export interface AccXUserAPIDataResponse {
    /**
     * 
     * @type {AccXUser}
     * @memberof AccXUserAPIDataResponse
     */
    data?: AccXUser;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXUserAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXUserAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXUserAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXVatRegister
 */
export interface AccXVatRegister {
    /**
     * 
     * @type {number}
     * @memberof AccXVatRegister
     */
    vatRegisterId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegister
     */
    vatRegisterName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegister
     */
    account?: string;
}
/**
 * 
 * @export
 * @interface AccXVatRegisterResult
 */
export interface AccXVatRegisterResult {
    /**
     * 
     * @type {boolean}
     * @memberof AccXVatRegisterResult
     */
    isPredefined?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResult
     */
    searchText?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXVatRegisterResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXVatRegisterResult
     */
    value?: any;
    /**
     * 
     * @type {boolean}
     * @memberof AccXVatRegisterResult
     */
    isCustomValue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccXVatRegisterResult
     */
    vatRegisterId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResult
     */
    vatRegisterName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResult
     */
    account?: string;
}
/**
 * 
 * @export
 * @interface AccXVatRegisterResultAPIDataResponse
 */
export interface AccXVatRegisterResultAPIDataResponse {
    /**
     * 
     * @type {AccXVatRegisterResult}
     * @memberof AccXVatRegisterResultAPIDataResponse
     */
    data?: AccXVatRegisterResult;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXVatRegisterResultAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXVatRegisterResultAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResultAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXVatRegisterResultListAPIDataResponse
 */
export interface AccXVatRegisterResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXVatRegisterResult>}
     * @memberof AccXVatRegisterResultListAPIDataResponse
     */
    data?: Array<AccXVatRegisterResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXVatRegisterResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXVatRegisterResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXVatRegisterResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface AccXVehicleUsagePurposeResult
 */
export interface AccXVehicleUsagePurposeResult {
    /**
     * 
     * @type {number}
     * @memberof AccXVehicleUsagePurposeResult
     */
    vehicleUsagePurposeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccXVehicleUsagePurposeResult
     */
    vehicleUsagePurposeName?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXVehicleUsagePurposeResult
     */
    entityId?: any;
    /**
     * 
     * @type {string}
     * @memberof AccXVehicleUsagePurposeResult
     */
    text?: string;
    /**
     * 
     * @type {any}
     * @memberof AccXVehicleUsagePurposeResult
     */
    value?: any;
}
/**
 * 
 * @export
 * @interface AccXVehicleUsagePurposeResultListAPIDataResponse
 */
export interface AccXVehicleUsagePurposeResultListAPIDataResponse {
    /**
     * 
     * @type {Array<AccXVehicleUsagePurposeResult>}
     * @memberof AccXVehicleUsagePurposeResultListAPIDataResponse
     */
    data?: Array<AccXVehicleUsagePurposeResult>;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof AccXVehicleUsagePurposeResultListAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AccXVehicleUsagePurposeResultListAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccXVehicleUsagePurposeResultListAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface BooleanAPIDataResponse
 */
export interface BooleanAPIDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAPIDataResponse
     */
    data?: boolean;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof BooleanAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanAPIDataResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OnboardingParameterTypeEnum {
    Unknown = <any> 'Unknown',
    Bool = <any> 'Bool',
    Text = <any> 'Text',
    Number = <any> 'Number'
}
/**
 * 
 * @export
 * @interface StringAPIDataResponse
 */
export interface StringAPIDataResponse {
    /**
     * 
     * @type {string}
     * @memberof StringAPIDataResponse
     */
    data?: string;
    /**
     * 
     * @type {APIStatusResponse}
     * @memberof StringAPIDataResponse
     */
    status?: APIStatusResponse;
    /**
     * 
     * @type {boolean}
     * @memberof StringAPIDataResponse
     */
    ok?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringAPIDataResponse
     */
    message?: string;
}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Auth/signIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options: any = {}): FetchArgs {
            const localVarPath = `/Auth/signOut`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authSignInPost(provider, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authSignOutPost(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInPost(provider?: string, options?: any) {
            return AuthApiFp(configuration).authSignInPost(provider, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignOutPost(options?: any) {
            return AuthApiFp(configuration).authSignOutPost(options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignInPost(provider?: string, options?: any) {
        return AuthApiFp(this.configuration).authSignInPost(provider, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignOutPost(options?: any) {
        return AuthApiFp(this.configuration).authSignOutPost(options)(this.fetch, this.basePath);
    }

}
/**
 * BusinessClassificationApi - fetch parameter creator
 * @export
 */
export const BusinessClassificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/BusinessClassification/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessClassificationApi - functional programming interface
 * @export
 */
export const BusinessClassificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXBusinessClassificationResultListAPIDataResponse> {
            const localVarFetchArgs = BusinessClassificationApiFetchParamCreator(configuration).businessClassificationAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BusinessClassificationApi - factory interface
 * @export
 */
export const BusinessClassificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationAllGet(options?: any) {
            return BusinessClassificationApiFp(configuration).businessClassificationAllGet(options)(fetch, basePath);
        },
    };
};

/**
 * BusinessClassificationApi - object-oriented interface
 * @export
 * @class BusinessClassificationApi
 * @extends {BaseAPI}
 */
export class BusinessClassificationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessClassificationApi
     */
    public businessClassificationAllGet(options?: any) {
        return BusinessClassificationApiFp(this.configuration).businessClassificationAllGet(options)(this.fetch, this.basePath);
    }

}
/**
 * BusinessClassificationPredictionApi - fetch parameter creator
 * @export
 */
export const BusinessClassificationPredictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXBCPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationPredictionPredictPost(body?: AccXBCPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/BusinessClassificationPrediction/predict`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXBCPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessClassificationPredictionApi - functional programming interface
 * @export
 */
export const BusinessClassificationPredictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXBCPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationPredictionPredictPost(body?: AccXBCPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXBCPredictionDataResultAPIDataResponse> {
            const localVarFetchArgs = BusinessClassificationPredictionApiFetchParamCreator(configuration).businessClassificationPredictionPredictPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BusinessClassificationPredictionApi - factory interface
 * @export
 */
export const BusinessClassificationPredictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccXBCPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessClassificationPredictionPredictPost(body?: AccXBCPrediction, options?: any) {
            return BusinessClassificationPredictionApiFp(configuration).businessClassificationPredictionPredictPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * BusinessClassificationPredictionApi - object-oriented interface
 * @export
 * @class BusinessClassificationPredictionApi
 * @extends {BaseAPI}
 */
export class BusinessClassificationPredictionApi extends BaseAPI {
    /**
     * 
     * @param {AccXBCPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessClassificationPredictionApi
     */
    public businessClassificationPredictionPredictPost(body?: AccXBCPrediction, options?: any) {
        return BusinessClassificationPredictionApiFp(this.configuration).businessClassificationPredictionPredictPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CategoryApi - fetch parameter creator
 * @export
 */
export const CategoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/Category/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdDelete(categoryId: number, options: any = {}): FetchArgs {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling categoryCategoryIdDelete.');
            }
            const localVarPath = `/Category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdGet(categoryId: number, options: any = {}): FetchArgs {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling categoryCategoryIdGet.');
            }
            const localVarPath = `/Category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPost(body?: AccXCategory, options: any = {}): FetchArgs {
            const localVarPath = `/Category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategory" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPut(body?: AccXCategory, options: any = {}): FetchArgs {
            const localVarPath = `/Category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategory" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCategoryResultListAPIDataResponse> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).categoryAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdDelete(categoryId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).categoryCategoryIdDelete(categoryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdGet(categoryId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCategoryResultAPIDataResponse> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).categoryCategoryIdGet(categoryId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPost(body?: AccXCategory, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).categoryPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPut(body?: AccXCategory, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).categoryPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryAllGet(options?: any) {
            return CategoryApiFp(configuration).categoryAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdDelete(categoryId: number, options?: any) {
            return CategoryApiFp(configuration).categoryCategoryIdDelete(categoryId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCategoryIdGet(categoryId: number, options?: any) {
            return CategoryApiFp(configuration).categoryCategoryIdGet(categoryId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPost(body?: AccXCategory, options?: any) {
            return CategoryApiFp(configuration).categoryPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCategory} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPut(body?: AccXCategory, options?: any) {
            return CategoryApiFp(configuration).categoryPut(body, options)(fetch, basePath);
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryAllGet(options?: any) {
        return CategoryApiFp(this.configuration).categoryAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryCategoryIdDelete(categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).categoryCategoryIdDelete(categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryCategoryIdGet(categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).categoryCategoryIdGet(categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCategory} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryPost(body?: AccXCategory, options?: any) {
        return CategoryApiFp(this.configuration).categoryPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCategory} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryPut(body?: AccXCategory, options?: any) {
        return CategoryApiFp(this.configuration).categoryPut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CategoryPredictionApi - fetch parameter creator
 * @export
 */
export const CategoryPredictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPredictionPost(body?: AccXCategoryPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/CategoryPrediction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategoryPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryPredictionApi - functional programming interface
 * @export
 */
export const CategoryPredictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPredictionPost(body?: AccXCategoryPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCategoryPredictionDataResultAPIDataResponse> {
            const localVarFetchArgs = CategoryPredictionApiFetchParamCreator(configuration).categoryPredictionPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoryPredictionApi - factory interface
 * @export
 */
export const CategoryPredictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryPredictionPost(body?: AccXCategoryPrediction, options?: any) {
            return CategoryPredictionApiFp(configuration).categoryPredictionPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * CategoryPredictionApi - object-oriented interface
 * @export
 * @class CategoryPredictionApi
 * @extends {BaseAPI}
 */
export class CategoryPredictionApi extends BaseAPI {
    /**
     * 
     * @param {AccXCategoryPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryPredictionApi
     */
    public categoryPredictionPost(body?: AccXCategoryPrediction, options?: any) {
        return CategoryPredictionApiFp(this.configuration).categoryPredictionPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CategoryRuleApi - fetch parameter creator
 * @export
 */
export const CategoryRuleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/CategoryRule/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdDelete(categoryRuleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'categoryRuleId' is not null or undefined
            if (categoryRuleId === null || categoryRuleId === undefined) {
                throw new RequiredError('categoryRuleId','Required parameter categoryRuleId was null or undefined when calling categoryRuleCategoryRuleIdDelete.');
            }
            const localVarPath = `/CategoryRule/{categoryRuleId}`
                .replace(`{${"categoryRuleId"}}`, encodeURIComponent(String(categoryRuleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdGet(categoryRuleId: number, options: any = {}): FetchArgs {
            // verify required parameter 'categoryRuleId' is not null or undefined
            if (categoryRuleId === null || categoryRuleId === undefined) {
                throw new RequiredError('categoryRuleId','Required parameter categoryRuleId was null or undefined when calling categoryRuleCategoryRuleIdGet.');
            }
            const localVarPath = `/CategoryRule/{categoryRuleId}`
                .replace(`{${"categoryRuleId"}}`, encodeURIComponent(String(categoryRuleId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePost(body?: AccXCategoryRule, options: any = {}): FetchArgs {
            const localVarPath = `/CategoryRule`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategoryRule" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePut(body?: AccXCategoryRule, options: any = {}): FetchArgs {
            const localVarPath = `/CategoryRule`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategoryRule" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryRuleApi - functional programming interface
 * @export
 */
export const CategoryRuleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCategoryRuleListAPIDataResponse> {
            const localVarFetchArgs = CategoryRuleApiFetchParamCreator(configuration).categoryRuleAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdDelete(categoryRuleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryRuleApiFetchParamCreator(configuration).categoryRuleCategoryRuleIdDelete(categoryRuleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdGet(categoryRuleId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCategoryRuleAPIDataResponse> {
            const localVarFetchArgs = CategoryRuleApiFetchParamCreator(configuration).categoryRuleCategoryRuleIdGet(categoryRuleId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePost(body?: AccXCategoryRule, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryRuleApiFetchParamCreator(configuration).categoryRulePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePut(body?: AccXCategoryRule, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = CategoryRuleApiFetchParamCreator(configuration).categoryRulePut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoryRuleApi - factory interface
 * @export
 */
export const CategoryRuleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleAllGet(options?: any) {
            return CategoryRuleApiFp(configuration).categoryRuleAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdDelete(categoryRuleId: number, options?: any) {
            return CategoryRuleApiFp(configuration).categoryRuleCategoryRuleIdDelete(categoryRuleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} categoryRuleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRuleCategoryRuleIdGet(categoryRuleId: number, options?: any) {
            return CategoryRuleApiFp(configuration).categoryRuleCategoryRuleIdGet(categoryRuleId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePost(body?: AccXCategoryRule, options?: any) {
            return CategoryRuleApiFp(configuration).categoryRulePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCategoryRule} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryRulePut(body?: AccXCategoryRule, options?: any) {
            return CategoryRuleApiFp(configuration).categoryRulePut(body, options)(fetch, basePath);
        },
    };
};

/**
 * CategoryRuleApi - object-oriented interface
 * @export
 * @class CategoryRuleApi
 * @extends {BaseAPI}
 */
export class CategoryRuleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryRuleApi
     */
    public categoryRuleAllGet(options?: any) {
        return CategoryRuleApiFp(this.configuration).categoryRuleAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} categoryRuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryRuleApi
     */
    public categoryRuleCategoryRuleIdDelete(categoryRuleId: number, options?: any) {
        return CategoryRuleApiFp(this.configuration).categoryRuleCategoryRuleIdDelete(categoryRuleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} categoryRuleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryRuleApi
     */
    public categoryRuleCategoryRuleIdGet(categoryRuleId: number, options?: any) {
        return CategoryRuleApiFp(this.configuration).categoryRuleCategoryRuleIdGet(categoryRuleId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCategoryRule} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryRuleApi
     */
    public categoryRulePost(body?: AccXCategoryRule, options?: any) {
        return CategoryRuleApiFp(this.configuration).categoryRulePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCategoryRule} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryRuleApi
     */
    public categoryRulePut(body?: AccXCategoryRule, options?: any) {
        return CategoryRuleApiFp(this.configuration).categoryRulePut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CommonApi - fetch parameter creator
 * @export
 */
export const CommonApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonEngineVersionGet(options: any = {}): FetchArgs {
            const localVarPath = `/Common/engineVersion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonStatusGet(options: any = {}): FetchArgs {
            const localVarPath = `/Common/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonEngineVersionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringAPIDataResponse> {
            const localVarFetchArgs = CommonApiFetchParamCreator(configuration).commonEngineVersionGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonStatusGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanAPIDataResponse> {
            const localVarFetchArgs = CommonApiFetchParamCreator(configuration).commonStatusGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonEngineVersionGet(options?: any) {
            return CommonApiFp(configuration).commonEngineVersionGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonStatusGet(options?: any) {
            return CommonApiFp(configuration).commonStatusGet(options)(fetch, basePath);
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonEngineVersionGet(options?: any) {
        return CommonApiFp(this.configuration).commonEngineVersionGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonStatusGet(options?: any) {
        return CommonApiFp(this.configuration).commonStatusGet(options)(this.fetch, this.basePath);
    }

}
/**
 * ExampleApi - fetch parameter creator
 * @export
 */
export const ExampleApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/Example/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleKeyDelete(key: string, options: any = {}): FetchArgs {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling exampleKeyDelete.');
            }
            const localVarPath = `/Example/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examplePost(body?: AccXCategoryPrediction, name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Example`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCategoryPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleRandomGet(options: any = {}): FetchArgs {
            const localVarPath = `/Example/random`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExampleApi - functional programming interface
 * @export
 */
export const ExampleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXExampleResultListAPIDataResponse> {
            const localVarFetchArgs = ExampleApiFetchParamCreator(configuration).exampleAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleKeyDelete(key: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ExampleApiFetchParamCreator(configuration).exampleKeyDelete(key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examplePost(body?: AccXCategoryPrediction, name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ExampleApiFetchParamCreator(configuration).examplePost(body, name, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleRandomGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXExampleResultAPIDataResponse> {
            const localVarFetchArgs = ExampleApiFetchParamCreator(configuration).exampleRandomGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExampleApi - factory interface
 * @export
 */
export const ExampleApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleAllGet(options?: any) {
            return ExampleApiFp(configuration).exampleAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleKeyDelete(key: string, options?: any) {
            return ExampleApiFp(configuration).exampleKeyDelete(key, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCategoryPrediction} [body] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examplePost(body?: AccXCategoryPrediction, name?: string, options?: any) {
            return ExampleApiFp(configuration).examplePost(body, name, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exampleRandomGet(options?: any) {
            return ExampleApiFp(configuration).exampleRandomGet(options)(fetch, basePath);
        },
    };
};

/**
 * ExampleApi - object-oriented interface
 * @export
 * @class ExampleApi
 * @extends {BaseAPI}
 */
export class ExampleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExampleApi
     */
    public exampleAllGet(options?: any) {
        return ExampleApiFp(this.configuration).exampleAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExampleApi
     */
    public exampleKeyDelete(key: string, options?: any) {
        return ExampleApiFp(this.configuration).exampleKeyDelete(key, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCategoryPrediction} [body] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExampleApi
     */
    public examplePost(body?: AccXCategoryPrediction, name?: string, options?: any) {
        return ExampleApiFp(this.configuration).examplePost(body, name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExampleApi
     */
    public exampleRandomGet(options?: any) {
        return ExampleApiFp(this.configuration).exampleRandomGet(options)(this.fetch, this.basePath);
    }

}
/**
 * OnboardingApi - fetch parameter creator
 * @export
 */
export const OnboardingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersGet(options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/parameters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyDelete(parameterKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'parameterKey' is not null or undefined
            if (parameterKey === null || parameterKey === undefined) {
                throw new RequiredError('parameterKey','Required parameter parameterKey was null or undefined when calling onboardingParametersParameterKeyDelete.');
            }
            const localVarPath = `/Onboarding/parameters/{parameterKey}`
                .replace(`{${"parameterKey"}}`, encodeURIComponent(String(parameterKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyGet(parameterKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'parameterKey' is not null or undefined
            if (parameterKey === null || parameterKey === undefined) {
                throw new RequiredError('parameterKey','Required parameter parameterKey was null or undefined when calling onboardingParametersParameterKeyGet.');
            }
            const localVarPath = `/Onboarding/parameters/{parameterKey}`
                .replace(`{${"parameterKey"}}`, encodeURIComponent(String(parameterKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPost(body?: AccXOnboardingParameter, options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/parameters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXOnboardingParameter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPut(body?: AccXOnboardingParameter, options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/parameters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXOnboardingParameter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesGet(options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXCustomerProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesPut(body?: AccXCustomerProfile, options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXCustomerProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsGet(options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/topicGroups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPost(body?: AccXOnboardingTopicGroup, options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/topicGroups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXOnboardingTopicGroup" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPut(body?: AccXOnboardingTopicGroup, options: any = {}): FetchArgs {
            const localVarPath = `/Onboarding/topicGroups`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXOnboardingTopicGroup" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'topicGroupKey' is not null or undefined
            if (topicGroupKey === null || topicGroupKey === undefined) {
                throw new RequiredError('topicGroupKey','Required parameter topicGroupKey was null or undefined when calling onboardingTopicGroupsTopicGroupKeyDelete.');
            }
            const localVarPath = `/Onboarding/topicGroups/{topicGroupKey}`
                .replace(`{${"topicGroupKey"}}`, encodeURIComponent(String(topicGroupKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey: string, options: any = {}): FetchArgs {
            // verify required parameter 'topicGroupKey' is not null or undefined
            if (topicGroupKey === null || topicGroupKey === undefined) {
                throw new RequiredError('topicGroupKey','Required parameter topicGroupKey was null or undefined when calling onboardingTopicGroupsTopicGroupKeyGet.');
            }
            const localVarPath = `/Onboarding/topicGroups/{topicGroupKey}`
                .replace(`{${"topicGroupKey"}}`, encodeURIComponent(String(topicGroupKey)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXOnboardingParameterResultListAPIDataResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingParametersGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyDelete(parameterKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingParametersParameterKeyDelete(parameterKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyGet(parameterKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXOnboardingParameterResultAPIDataResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingParametersParameterKeyGet(parameterKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPost(body?: AccXOnboardingParameter, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingParametersPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPut(body?: AccXOnboardingParameter, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingParametersPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXCustomerProfileResultAPIDataResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingProfilesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXCustomerProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesPut(body?: AccXCustomerProfile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingProfilesPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXOnboardingTopicGroupResultListAPIDataResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingTopicGroupsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPost(body?: AccXOnboardingTopicGroup, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingTopicGroupsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPut(body?: AccXOnboardingTopicGroup, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingTopicGroupsPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXOnboardingTopicGroupResultAPIDataResponse> {
            const localVarFetchArgs = OnboardingApiFetchParamCreator(configuration).onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersGet(options?: any) {
            return OnboardingApiFp(configuration).onboardingParametersGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyDelete(parameterKey: string, options?: any) {
            return OnboardingApiFp(configuration).onboardingParametersParameterKeyDelete(parameterKey, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} parameterKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersParameterKeyGet(parameterKey: string, options?: any) {
            return OnboardingApiFp(configuration).onboardingParametersParameterKeyGet(parameterKey, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPost(body?: AccXOnboardingParameter, options?: any) {
            return OnboardingApiFp(configuration).onboardingParametersPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXOnboardingParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingParametersPut(body?: AccXOnboardingParameter, options?: any) {
            return OnboardingApiFp(configuration).onboardingParametersPut(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesGet(options?: any) {
            return OnboardingApiFp(configuration).onboardingProfilesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXCustomerProfile} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingProfilesPut(body?: AccXCustomerProfile, options?: any) {
            return OnboardingApiFp(configuration).onboardingProfilesPut(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsGet(options?: any) {
            return OnboardingApiFp(configuration).onboardingTopicGroupsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPost(body?: AccXOnboardingTopicGroup, options?: any) {
            return OnboardingApiFp(configuration).onboardingTopicGroupsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXOnboardingTopicGroup} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsPut(body?: AccXOnboardingTopicGroup, options?: any) {
            return OnboardingApiFp(configuration).onboardingTopicGroupsPut(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey: string, options?: any) {
            return OnboardingApiFp(configuration).onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} topicGroupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey: string, options?: any) {
            return OnboardingApiFp(configuration).onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey, options)(fetch, basePath);
        },
    };
};

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingParametersGet(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingParametersGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parameterKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingParametersParameterKeyDelete(parameterKey: string, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingParametersParameterKeyDelete(parameterKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} parameterKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingParametersParameterKeyGet(parameterKey: string, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingParametersParameterKeyGet(parameterKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXOnboardingParameter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingParametersPost(body?: AccXOnboardingParameter, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingParametersPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXOnboardingParameter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingParametersPut(body?: AccXOnboardingParameter, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingParametersPut(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingProfilesGet(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingProfilesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXCustomerProfile} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingProfilesPut(body?: AccXCustomerProfile, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingProfilesPut(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingTopicGroupsGet(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingTopicGroupsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXOnboardingTopicGroup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingTopicGroupsPost(body?: AccXOnboardingTopicGroup, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingTopicGroupsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXOnboardingTopicGroup} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingTopicGroupsPut(body?: AccXOnboardingTopicGroup, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingTopicGroupsPut(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} topicGroupKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey: string, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} topicGroupKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey: string, options?: any) {
        return OnboardingApiFp(this.configuration).onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey, options)(this.fetch, this.basePath);
    }

}
/**
 * PlatformManagementApi - fetch parameter creator
 * @export
 */
export const PlatformManagementApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToCustomerGet(login?: string, options: any = {}): FetchArgs {
            const localVarPath = `/PlatformManagement/userToCustomer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [taxId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToExistingCustomerGet(login?: string, taxId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/PlatformManagement/userToExistingCustomer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (taxId !== undefined) {
                localVarQueryParameter['taxId'] = taxId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformManagementApi - functional programming interface
 * @export
 */
export const PlatformManagementApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToCustomerGet(login?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanAPIDataResponse> {
            const localVarFetchArgs = PlatformManagementApiFetchParamCreator(configuration).platformManagementUserToCustomerGet(login, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [taxId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToExistingCustomerGet(login?: string, taxId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanAPIDataResponse> {
            const localVarFetchArgs = PlatformManagementApiFetchParamCreator(configuration).platformManagementUserToExistingCustomerGet(login, taxId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PlatformManagementApi - factory interface
 * @export
 */
export const PlatformManagementApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToCustomerGet(login?: string, options?: any) {
            return PlatformManagementApiFp(configuration).platformManagementUserToCustomerGet(login, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [login] 
         * @param {string} [taxId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformManagementUserToExistingCustomerGet(login?: string, taxId?: string, options?: any) {
            return PlatformManagementApiFp(configuration).platformManagementUserToExistingCustomerGet(login, taxId, options)(fetch, basePath);
        },
    };
};

/**
 * PlatformManagementApi - object-oriented interface
 * @export
 * @class PlatformManagementApi
 * @extends {BaseAPI}
 */
export class PlatformManagementApi extends BaseAPI {
    /**
     * 
     * @param {string} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformManagementApi
     */
    public platformManagementUserToCustomerGet(login?: string, options?: any) {
        return PlatformManagementApiFp(this.configuration).platformManagementUserToCustomerGet(login, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [login] 
     * @param {string} [taxId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformManagementApi
     */
    public platformManagementUserToExistingCustomerGet(login?: string, taxId?: string, options?: any) {
        return PlatformManagementApiFp(this.configuration).platformManagementUserToExistingCustomerGet(login, taxId, options)(this.fetch, this.basePath);
    }

}
/**
 * PredictProductApi - fetch parameter creator
 * @export
 */
export const PredictProductApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXProductPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductPredictPost(body?: AccXProductPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/PredictProduct/predict`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProductPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXProductPredictionTrain} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductTrainPost(body?: AccXProductPredictionTrain, options: any = {}): FetchArgs {
            const localVarPath = `/PredictProduct/train`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProductPredictionTrain" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictProductApi - functional programming interface
 * @export
 */
export const PredictProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXProductPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductPredictPost(body?: AccXProductPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXProductPredictionResultListAPIDataResponse> {
            const localVarFetchArgs = PredictProductApiFetchParamCreator(configuration).predictProductPredictPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXProductPredictionTrain} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductTrainPost(body?: AccXProductPredictionTrain, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = PredictProductApiFetchParamCreator(configuration).predictProductTrainPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PredictProductApi - factory interface
 * @export
 */
export const PredictProductApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccXProductPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductPredictPost(body?: AccXProductPrediction, options?: any) {
            return PredictProductApiFp(configuration).predictProductPredictPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXProductPredictionTrain} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictProductTrainPost(body?: AccXProductPredictionTrain, options?: any) {
            return PredictProductApiFp(configuration).predictProductTrainPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * PredictProductApi - object-oriented interface
 * @export
 * @class PredictProductApi
 * @extends {BaseAPI}
 */
export class PredictProductApi extends BaseAPI {
    /**
     * 
     * @param {AccXProductPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictProductApi
     */
    public predictProductPredictPost(body?: AccXProductPrediction, options?: any) {
        return PredictProductApiFp(this.configuration).predictProductPredictPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXProductPredictionTrain} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictProductApi
     */
    public predictProductTrainPost(body?: AccXProductPredictionTrain, options?: any) {
        return PredictProductApiFp(this.configuration).predictProductTrainPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PredictionApi - fetch parameter creator
 * @export
 */
export const PredictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictionTopicAndProductPost(body?: AccXPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/Prediction/topicAndProduct`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictionApi - functional programming interface
 * @export
 */
export const PredictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictionTopicAndProductPost(body?: AccXPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXTopicAndProductPredictionResultAPIDataResponse> {
            const localVarFetchArgs = PredictionApiFetchParamCreator(configuration).predictionTopicAndProductPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PredictionApi - factory interface
 * @export
 */
export const PredictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccXPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        predictionTopicAndProductPost(body?: AccXPrediction, options?: any) {
            return PredictionApiFp(configuration).predictionTopicAndProductPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * PredictionApi - object-oriented interface
 * @export
 * @class PredictionApi
 * @extends {BaseAPI}
 */
export class PredictionApi extends BaseAPI {
    /**
     * 
     * @param {AccXPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionApi
     */
    public predictionTopicAndProductPost(body?: AccXPrediction, options?: any) {
        return PredictionApiFp(this.configuration).predictionTopicAndProductPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductApi - fetch parameter creator
 * @export
 */
export const ProductApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/Product/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativeDeletePost(body?: AccXProductAlternative, options: any = {}): FetchArgs {
            const localVarPath = `/Product/alternative/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProductAlternative" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativePost(body?: AccXProductAlternative, options: any = {}): FetchArgs {
            const localVarPath = `/Product/alternative`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProductAlternative" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPost(body?: AccXProduct, options: any = {}): FetchArgs {
            const localVarPath = `/Product`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdDelete(productId: number, options: any = {}): FetchArgs {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling productProductIdDelete.');
            }
            const localVarPath = `/Product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdGet(productId: number, options: any = {}): FetchArgs {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling productProductIdGet.');
            }
            const localVarPath = `/Product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPut(body?: AccXProduct, options: any = {}): FetchArgs {
            const localVarPath = `/Product`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXProductResultListAPIDataResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativeDeletePost(body?: AccXProductAlternative, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productAlternativeDeletePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativePost(body?: AccXProductAlternative, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productAlternativePost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPost(body?: AccXProduct, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdDelete(productId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productProductIdDelete(productId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdGet(productId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXProductResultAPIDataResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productProductIdGet(productId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPut(body?: AccXProduct, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = ProductApiFetchParamCreator(configuration).productPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAllGet(options?: any) {
            return ProductApiFp(configuration).productAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativeDeletePost(body?: AccXProductAlternative, options?: any) {
            return ProductApiFp(configuration).productAlternativeDeletePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXProductAlternative} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productAlternativePost(body?: AccXProductAlternative, options?: any) {
            return ProductApiFp(configuration).productAlternativePost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPost(body?: AccXProduct, options?: any) {
            return ProductApiFp(configuration).productPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdDelete(productId: number, options?: any) {
            return ProductApiFp(configuration).productProductIdDelete(productId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productProductIdGet(productId: number, options?: any) {
            return ProductApiFp(configuration).productProductIdGet(productId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXProduct} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPut(body?: AccXProduct, options?: any) {
            return ProductApiFp(configuration).productPut(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productAllGet(options?: any) {
        return ProductApiFp(this.configuration).productAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXProductAlternative} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productAlternativeDeletePost(body?: AccXProductAlternative, options?: any) {
        return ProductApiFp(this.configuration).productAlternativeDeletePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXProductAlternative} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productAlternativePost(body?: AccXProductAlternative, options?: any) {
        return ProductApiFp(this.configuration).productAlternativePost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXProduct} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productPost(body?: AccXProduct, options?: any) {
        return ProductApiFp(this.configuration).productPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productProductIdDelete(productId: number, options?: any) {
        return ProductApiFp(this.configuration).productProductIdDelete(productId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productProductIdGet(productId: number, options?: any) {
        return ProductApiFp(this.configuration).productProductIdGet(productId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXProduct} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public productPut(body?: AccXProduct, options?: any) {
        return ProductApiFp(this.configuration).productPut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PurchasePurposesApi - fetch parameter creator
 * @export
 */
export const PurchasePurposesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/PurchasePurposes/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPost(body?: AccXPurchasePurpose, options: any = {}): FetchArgs {
            const localVarPath = `/PurchasePurposes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXPurchasePurpose" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdDelete(purchasePurposeId: number, options: any = {}): FetchArgs {
            // verify required parameter 'purchasePurposeId' is not null or undefined
            if (purchasePurposeId === null || purchasePurposeId === undefined) {
                throw new RequiredError('purchasePurposeId','Required parameter purchasePurposeId was null or undefined when calling purchasePurposesPurchasePurposeIdDelete.');
            }
            const localVarPath = `/PurchasePurposes/{purchasePurposeId}`
                .replace(`{${"purchasePurposeId"}}`, encodeURIComponent(String(purchasePurposeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdGet(purchasePurposeId: number, options: any = {}): FetchArgs {
            // verify required parameter 'purchasePurposeId' is not null or undefined
            if (purchasePurposeId === null || purchasePurposeId === undefined) {
                throw new RequiredError('purchasePurposeId','Required parameter purchasePurposeId was null or undefined when calling purchasePurposesPurchasePurposeIdGet.');
            }
            const localVarPath = `/PurchasePurposes/{purchasePurposeId}`
                .replace(`{${"purchasePurposeId"}}`, encodeURIComponent(String(purchasePurposeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPut(body?: AccXPurchasePurpose, options: any = {}): FetchArgs {
            const localVarPath = `/PurchasePurposes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXPurchasePurpose" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasePurposesApi - functional programming interface
 * @export
 */
export const PurchasePurposesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXPurchasePurposeResultListAPIDataResponse> {
            const localVarFetchArgs = PurchasePurposesApiFetchParamCreator(configuration).purchasePurposesAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPost(body?: AccXPurchasePurpose, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = PurchasePurposesApiFetchParamCreator(configuration).purchasePurposesPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdDelete(purchasePurposeId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = PurchasePurposesApiFetchParamCreator(configuration).purchasePurposesPurchasePurposeIdDelete(purchasePurposeId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdGet(purchasePurposeId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXPurchasePurposeResultAPIDataResponse> {
            const localVarFetchArgs = PurchasePurposesApiFetchParamCreator(configuration).purchasePurposesPurchasePurposeIdGet(purchasePurposeId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPut(body?: AccXPurchasePurpose, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = PurchasePurposesApiFetchParamCreator(configuration).purchasePurposesPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurchasePurposesApi - factory interface
 * @export
 */
export const PurchasePurposesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesAllGet(options?: any) {
            return PurchasePurposesApiFp(configuration).purchasePurposesAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPost(body?: AccXPurchasePurpose, options?: any) {
            return PurchasePurposesApiFp(configuration).purchasePurposesPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdDelete(purchasePurposeId: number, options?: any) {
            return PurchasePurposesApiFp(configuration).purchasePurposesPurchasePurposeIdDelete(purchasePurposeId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} purchasePurposeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPurchasePurposeIdGet(purchasePurposeId: number, options?: any) {
            return PurchasePurposesApiFp(configuration).purchasePurposesPurchasePurposeIdGet(purchasePurposeId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXPurchasePurpose} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasePurposesPut(body?: AccXPurchasePurpose, options?: any) {
            return PurchasePurposesApiFp(configuration).purchasePurposesPut(body, options)(fetch, basePath);
        },
    };
};

/**
 * PurchasePurposesApi - object-oriented interface
 * @export
 * @class PurchasePurposesApi
 * @extends {BaseAPI}
 */
export class PurchasePurposesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePurposesApi
     */
    public purchasePurposesAllGet(options?: any) {
        return PurchasePurposesApiFp(this.configuration).purchasePurposesAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXPurchasePurpose} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePurposesApi
     */
    public purchasePurposesPost(body?: AccXPurchasePurpose, options?: any) {
        return PurchasePurposesApiFp(this.configuration).purchasePurposesPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} purchasePurposeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePurposesApi
     */
    public purchasePurposesPurchasePurposeIdDelete(purchasePurposeId: number, options?: any) {
        return PurchasePurposesApiFp(this.configuration).purchasePurposesPurchasePurposeIdDelete(purchasePurposeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} purchasePurposeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePurposesApi
     */
    public purchasePurposesPurchasePurposeIdGet(purchasePurposeId: number, options?: any) {
        return PurchasePurposesApiFp(this.configuration).purchasePurposesPurchasePurposeIdGet(purchasePurposeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXPurchasePurpose} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePurposesApi
     */
    public purchasePurposesPut(body?: AccXPurchasePurpose, options?: any) {
        return PurchasePurposesApiFp(this.configuration).purchasePurposesPut(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TopicApi - fetch parameter creator
 * @export
 */
export const TopicApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/Topic/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicApi - functional programming interface
 * @export
 */
export const TopicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXTopicResultListAPIDataResponse> {
            const localVarFetchArgs = TopicApiFetchParamCreator(configuration).topicAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TopicApi - factory interface
 * @export
 */
export const TopicApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicAllGet(options?: any) {
            return TopicApiFp(configuration).topicAllGet(options)(fetch, basePath);
        },
    };
};

/**
 * TopicApi - object-oriented interface
 * @export
 * @class TopicApi
 * @extends {BaseAPI}
 */
export class TopicApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicApi
     */
    public topicAllGet(options?: any) {
        return TopicApiFp(this.configuration).topicAllGet(options)(this.fetch, this.basePath);
    }

}
/**
 * TopicPredictionApi - fetch parameter creator
 * @export
 */
export const TopicPredictionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionPost(body?: AccXTopicPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/TopicPrediction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXTopicPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionUnauthorizedPost(body?: AccXTopicPrediction, options: any = {}): FetchArgs {
            const localVarPath = `/TopicPrediction/unauthorized`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXTopicPrediction" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopicPredictionApi - functional programming interface
 * @export
 */
export const TopicPredictionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionPost(body?: AccXTopicPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXTopicPredictionDataResultAPIDataResponse> {
            const localVarFetchArgs = TopicPredictionApiFetchParamCreator(configuration).topicPredictionPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionUnauthorizedPost(body?: AccXTopicPrediction, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXTopicPredictionDataResultAPIDataResponse> {
            const localVarFetchArgs = TopicPredictionApiFetchParamCreator(configuration).topicPredictionUnauthorizedPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TopicPredictionApi - factory interface
 * @export
 */
export const TopicPredictionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionPost(body?: AccXTopicPrediction, options?: any) {
            return TopicPredictionApiFp(configuration).topicPredictionPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXTopicPrediction} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topicPredictionUnauthorizedPost(body?: AccXTopicPrediction, options?: any) {
            return TopicPredictionApiFp(configuration).topicPredictionUnauthorizedPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * TopicPredictionApi - object-oriented interface
 * @export
 * @class TopicPredictionApi
 * @extends {BaseAPI}
 */
export class TopicPredictionApi extends BaseAPI {
    /**
     * 
     * @param {AccXTopicPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicPredictionApi
     */
    public topicPredictionPost(body?: AccXTopicPrediction, options?: any) {
        return TopicPredictionApiFp(this.configuration).topicPredictionPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXTopicPrediction} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopicPredictionApi
     */
    public topicPredictionUnauthorizedPost(body?: AccXTopicPrediction, options?: any) {
        return TopicPredictionApiFp(this.configuration).topicPredictionUnauthorizedPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options: any = {}): FetchArgs {
            const localVarPath = `/User`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXUserAPIDataResponse> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGet(options?: any) {
            return UserApiFp(configuration).userGet(options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGet(options?: any) {
        return UserApiFp(this.configuration).userGet(options)(this.fetch, this.basePath);
    }

}
/**
 * VatRegisterApi - fetch parameter creator
 * @export
 */
export const VatRegisterApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/VatRegister/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPost(body?: AccXVatRegister, options: any = {}): FetchArgs {
            const localVarPath = `/VatRegister`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXVatRegister" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPut(body?: AccXVatRegister, options: any = {}): FetchArgs {
            const localVarPath = `/VatRegister`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccXVatRegister" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdDelete(vatRegisterId: number, options: any = {}): FetchArgs {
            // verify required parameter 'vatRegisterId' is not null or undefined
            if (vatRegisterId === null || vatRegisterId === undefined) {
                throw new RequiredError('vatRegisterId','Required parameter vatRegisterId was null or undefined when calling vatRegisterVatRegisterIdDelete.');
            }
            const localVarPath = `/VatRegister/{vatRegisterId}`
                .replace(`{${"vatRegisterId"}}`, encodeURIComponent(String(vatRegisterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdGet(vatRegisterId: number, options: any = {}): FetchArgs {
            // verify required parameter 'vatRegisterId' is not null or undefined
            if (vatRegisterId === null || vatRegisterId === undefined) {
                throw new RequiredError('vatRegisterId','Required parameter vatRegisterId was null or undefined when calling vatRegisterVatRegisterIdGet.');
            }
            const localVarPath = `/VatRegister/{vatRegisterId}`
                .replace(`{${"vatRegisterId"}}`, encodeURIComponent(String(vatRegisterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VatRegisterApi - functional programming interface
 * @export
 */
export const VatRegisterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXVatRegisterResultListAPIDataResponse> {
            const localVarFetchArgs = VatRegisterApiFetchParamCreator(configuration).vatRegisterAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPost(body?: AccXVatRegister, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = VatRegisterApiFetchParamCreator(configuration).vatRegisterPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPut(body?: AccXVatRegister, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = VatRegisterApiFetchParamCreator(configuration).vatRegisterPut(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdDelete(vatRegisterId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<APIResponse> {
            const localVarFetchArgs = VatRegisterApiFetchParamCreator(configuration).vatRegisterVatRegisterIdDelete(vatRegisterId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdGet(vatRegisterId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXVatRegisterResultAPIDataResponse> {
            const localVarFetchArgs = VatRegisterApiFetchParamCreator(configuration).vatRegisterVatRegisterIdGet(vatRegisterId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VatRegisterApi - factory interface
 * @export
 */
export const VatRegisterApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterAllGet(options?: any) {
            return VatRegisterApiFp(configuration).vatRegisterAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPost(body?: AccXVatRegister, options?: any) {
            return VatRegisterApiFp(configuration).vatRegisterPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AccXVatRegister} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterPut(body?: AccXVatRegister, options?: any) {
            return VatRegisterApiFp(configuration).vatRegisterPut(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdDelete(vatRegisterId: number, options?: any) {
            return VatRegisterApiFp(configuration).vatRegisterVatRegisterIdDelete(vatRegisterId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} vatRegisterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vatRegisterVatRegisterIdGet(vatRegisterId: number, options?: any) {
            return VatRegisterApiFp(configuration).vatRegisterVatRegisterIdGet(vatRegisterId, options)(fetch, basePath);
        },
    };
};

/**
 * VatRegisterApi - object-oriented interface
 * @export
 * @class VatRegisterApi
 * @extends {BaseAPI}
 */
export class VatRegisterApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatRegisterApi
     */
    public vatRegisterAllGet(options?: any) {
        return VatRegisterApiFp(this.configuration).vatRegisterAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXVatRegister} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatRegisterApi
     */
    public vatRegisterPost(body?: AccXVatRegister, options?: any) {
        return VatRegisterApiFp(this.configuration).vatRegisterPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AccXVatRegister} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatRegisterApi
     */
    public vatRegisterPut(body?: AccXVatRegister, options?: any) {
        return VatRegisterApiFp(this.configuration).vatRegisterPut(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} vatRegisterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatRegisterApi
     */
    public vatRegisterVatRegisterIdDelete(vatRegisterId: number, options?: any) {
        return VatRegisterApiFp(this.configuration).vatRegisterVatRegisterIdDelete(vatRegisterId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} vatRegisterId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatRegisterApi
     */
    public vatRegisterVatRegisterIdGet(vatRegisterId: number, options?: any) {
        return VatRegisterApiFp(this.configuration).vatRegisterVatRegisterIdGet(vatRegisterId, options)(this.fetch, this.basePath);
    }

}
/**
 * VehicleUsagePurposeApi - fetch parameter creator
 * @export
 */
export const VehicleUsagePurposeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleUsagePurposeAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/VehicleUsagePurpose/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleUsagePurposeApi - functional programming interface
 * @export
 */
export const VehicleUsagePurposeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleUsagePurposeAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccXVehicleUsagePurposeResultListAPIDataResponse> {
            const localVarFetchArgs = VehicleUsagePurposeApiFetchParamCreator(configuration).vehicleUsagePurposeAllGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VehicleUsagePurposeApi - factory interface
 * @export
 */
export const VehicleUsagePurposeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vehicleUsagePurposeAllGet(options?: any) {
            return VehicleUsagePurposeApiFp(configuration).vehicleUsagePurposeAllGet(options)(fetch, basePath);
        },
    };
};

/**
 * VehicleUsagePurposeApi - object-oriented interface
 * @export
 * @class VehicleUsagePurposeApi
 * @extends {BaseAPI}
 */
export class VehicleUsagePurposeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleUsagePurposeApi
     */
    public vehicleUsagePurposeAllGet(options?: any) {
        return VehicleUsagePurposeApiFp(this.configuration).vehicleUsagePurposeAllGet(options)(this.fetch, this.basePath);
    }

}
