
import { CircularProgress, Divider, Modal, Paper, Typography } from "@mui/material";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";

type Props = {
    title?: JSX.Element | string,
    onClose: (refresh: boolean) => void,
    width?: string,
    height?: string,
    isLoading?: boolean,
    top?: string,
}

export default function Popup({title, onClose, width, height='auto', isLoading = false, top='40%', children} : PropsWithChildren<Props>) {
    return <Modal open={true}>
        <Paper style={{position: 'absolute', top: top, left: '50%', transform: 'translate(-50%, -50%)', width: width, height: height, padding: '10px'}}>
            <div style={{padding: '20px'}}>
                {title && <span style={{display: 'inline-block', marginRight: '5px', fontSize: '20px'}}>{title}</span>}
                <span style={{display: 'inline-block', fontSize: '26px', float: 'right'}}>
                    <FontAwesomeIcon className='popupCloseButton' icon={faCircleXmark} onClick={() => onClose(false) } />
                </span>
            </div>
            <Divider></Divider>
            {isLoading ? 
            <Box style={{margin:'20px', textAlign: 'center'}}>
                <CircularProgress style={{color: 'var(--details)'}}/>
            </Box>
            :
            <Box style={{padding: '20px'}}>
                {children}
            </Box>}
            
        </Paper>
    </Modal>
}
