import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXVatRegisterResult, VatRegisterApi } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import AccXTextField from "../../../components/fields/AccXTextField";
import Popup from "../../../components/Popup";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";


type Props = {
    vatRegisterId?: number,
    onClose: (refresh: boolean) => void,
    allData?: AccXVatRegisterResult[]
};

export default function VatRegisterPopup({vatRegisterId, onClose, allData}: Props) : JSX.Element {
    
    const { request } = useApiContext();
    const { labels, showMessage } = useAppContext();
    const vatRegisterApi = new VatRegisterApi();

    const isEditMode = vatRegisterId && true;
    const initValues: AccXVatRegisterResult = {
        vatRegisterName: '',
        account: '',
        isPredefined: false,
    };

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [vatRegisterData, setVatRegisterData] = useState(initValues);
    const [vatRegisterOriginalName, setVatRegisterOriginalName] = useState('');
    const [isVatRegisterNameNotUnique, setIsVatRegisterNameNotUnique] = useState(false);

    useEffect(() => {
        if (isEditMode) {
            setIsLoadingData(true);
            request(vatRegisterApi.vatRegisterVatRegisterIdGet(vatRegisterId)).then((response) => {
                if(response.ok && response.data) {
                    setVatRegisterData(response.data);
                    setVatRegisterOriginalName(response.data.vatRegisterName ?? '');
                }
                setIsLoadingData(false);
            });
        }
    }, []);

    const vatRegisterNameChange = (id: string, value: string) => {
        setVatRegisterData({...vatRegisterData, [id]: value});
        if(isVatRegisterNameNotUnique) {
            setIsVatRegisterNameNotUnique(false);
        }
    }

    function textChange(id: string, value: string) {
        setVatRegisterData({...vatRegisterData, [id]: value});
    }

    function isDataValid(dataToSave: AccXVatRegisterResult){
        if(allData != null){
            let usedNames = allData.map(x => x.vatRegisterName);
            return usedNames.indexOf(dataToSave.vatRegisterName) == -1;
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(isEditMode){
            request(vatRegisterApi.vatRegisterPut(vatRegisterData)).then((response) => {
                if(response.ok) {
                    onClose(true);
                    showMessage(labels.editVatRegisterLabel);
                } else {
                    showMessage(labels.errorLabel, MessageType.Error);
                }
            });

            
        } else {
            if (isDataValid(vatRegisterData)) {
                request(vatRegisterApi.vatRegisterPost(vatRegisterData)).then((response) => {
                    if(response.ok) {
                        onClose(true);
                        showMessage(labels.addVatRegisterLabel);
                    } else {
                        showMessage(labels.errorLabel, MessageType.Error);
                    }
                });
            } else {
                setIsVatRegisterNameNotUnique(true);
            }
        }
    }

    var popupTitle = isEditMode 
        ? <div>{labels.editVatRegister} <b>{vatRegisterOriginalName}</b></div>
        : <div>{labels.newVatRegister}</div>;

    return <Popup title={popupTitle} onClose={onClose} width={'800px'} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccXTextField id='vatRegisterName' 
                        value={vatRegisterData.vatRegisterName}
                        onChange={vatRegisterNameChange} 
                        label={labels.vatRegisterName} 
                        required
                        disabled={vatRegisterData.isPredefined}
                        error={isVatRegisterNameNotUnique}
                        helperText = {isVatRegisterNameNotUnique ? labels.vatRegisterNameNotUnique : ''}
                        />
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='account' 
                        value={vatRegisterData.account}
                        onChange={textChange} 
                        label={labels.vatRegisterAccount} 
                        />
                </Grid>
                <Grid item xs={12}>
                    <ActionButton type='submit' style={{float: 'right'}} onClick={() => {}}>
                        {labels.save}
                    </ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}