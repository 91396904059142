import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useAppContext } from "../../contexts/AppContext";

type Props = {
    id?: string,
    name?: string,
    label?: string,
    items?: any[],
    value?: any,
    hasDefaultItem?: boolean
    defaultItemValue?: any
    defaultItemText?: string,
    onChange?: (id: string, value: any) => void,
    disabled?: boolean,
}

export interface DropdownItem {
    text: string,
    value: any,
}

export default function DropdownField({id, name, label, items, value, hasDefaultItem= true, defaultItemValue=0, defaultItemText, onChange, disabled=false} : Props) {

    const {labels} = useAppContext(); 

    if (!defaultItemText) {
        defaultItemText = labels.lackOf;
    }

    function handleOnKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function handleOnChange(event: SelectChangeEvent) {
        const {value} = event.target;
        if(onChange) onChange(id ?? '', value);
    }

    return <FormControl fullWidth size='small' disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select id={id} 
            name={name} 
            label={label}
            style={{width: '100%'}}
            value={value}
            onKeyDown={handleOnKeyDown}
            onChange={handleOnChange}>
            {hasDefaultItem && <MenuItem value={defaultItemValue}>
                <em style={{fontStyle: 'normal'}}>{defaultItemText}</em>
            </MenuItem>}
            {items ? (items as DropdownItem[]).map((item, itemIndex) => {
                return <MenuItem key={itemIndex} value={item.value}>{item.text}</MenuItem>
            }) : <MenuItem value={0}>{labels.noData}</MenuItem>}
        </Select>
    </FormControl>
}