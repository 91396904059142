import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXCategoryRuleProductServiceEnum, AccXOnboardingTopicGroupResult, AccXTopicResult, OnboardingApi } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import DropdownField from "../../../../../components/fields/DropdownField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleTopicsTab({categoryRule, setCategoryRule} : CategoryRuleTabProps) {

    const topicRelatedData = categoryRule.conditions?.topicRelated;

    const [topics, setTopics] = useState<AccXTopicResult[]>([]);
    const [isLoadingTopics, setIsLoadingTopics] = useState(true);

    const [topicsGroups, setTopicsGroups] = useState<AccXOnboardingTopicGroupResult[]>([]);
    const [isLoadingTopicsGroups, setIsLoadingTopicsGroups] = useState(true);

    const {request, fetchTopics} = useApiContext();
    const {labels} = useAppContext();

    const onboardingApi = new OnboardingApi();

    const isLoadingData = isLoadingTopics || isLoadingTopicsGroups;

    useEffect(() => {
        fetchTopics().then((response) => {
            setTopics(response);
            setIsLoadingTopics(false);
        })

        request(onboardingApi.onboardingTopicGroupsGet()).then((response) => {
            setIsLoadingTopicsGroups(false);
            
            if (response.ok && response.data) {
                setTopicsGroups(response.data);
            };
        });
    }, []);

    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, topicRelated: {...categoryRule.conditions?.topicRelated, [id]: checked}}});
    }

    function dropdownChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, topicRelated: {...categoryRule.conditions?.topicRelated, [id]: value}}});
    }

    function comboBoxChange(id: string, value: any) {
        setCategoryRule({...categoryRule, conditions: {...categoryRule.conditions, topicRelated: {...categoryRule.conditions?.topicRelated, [id]: value}}});
    }

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{labels.topicRelated}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='topicIdsSpecified'
                    checked={topicRelatedData?.topicIdsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='topicIds'
                    value={topicRelatedData?.topicIds}
                    label={labels.topicIds}
                    items={topics}
                    //itemLabelField='topicName'
                    multiple
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.topicIdsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='notTopicIdsSpecified'
                    checked={topicRelatedData?.notTopicIdsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='notTopicIds'
                    value={topicRelatedData?.notTopicIds}
                    label={labels.notTopicIds}
                    items={topics}
                    //itemLabelField='topicName'
                    multiple
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.notTopicIdsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='topicGroupKeySpecified'
                    checked={topicRelatedData?.topicGroupKeySpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='topicGroupKey'
                    value={topicRelatedData?.topicGroupKey}
                    label={labels.topicGroupKey}
                    items={topicsGroups}
                    //itemLabelField='topicGroupName'
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.topicGroupKeySpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='notTopicGroupKeySpecified'
                    checked={topicRelatedData?.notTopicGroupKeySpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='notTopicGroupKey'
                    value={topicRelatedData?.notTopicGroupKey}
                    label={labels.notTopicGroupKey}
                    items={topicsGroups}
                    //itemLabelField='topicGroupName'
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.notTopicGroupKeySpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='productServiceSpecified'
                    checked={topicRelatedData?.productServiceSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='productService'
                    value={topicRelatedData?.productService}
                    label={labels.productService}
                    items={[
                        {text: labels.product, value: AccXCategoryRuleProductServiceEnum.Product},
                        {text: labels.service, value: AccXCategoryRuleProductServiceEnum.Service},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!topicRelatedData?.productServiceSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='isCommoditySpecified'
                    checked={topicRelatedData?.isCommoditySpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isCommodity'
                    value={topicRelatedData?.isCommodity}
                    label={labels.isCommodity}
                    items={[
                        {text: labels.yes, value: true},
                        {text: labels.no, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!topicRelatedData?.isCommoditySpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='isFixedAssetSpecified'
                    checked={topicRelatedData?.isFixedAssetSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isFixedAsset'
                    value={topicRelatedData?.isFixedAsset}
                    label={labels.isFixedAsset}
                    items={[
                        {text: labels.yes, value: true},
                        {text: labels.no, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!topicRelatedData?.isFixedAssetSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='isLowValueEquipmentSpecified'
                    checked={topicRelatedData?.isLowValueEquipmentSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isLowValueEquipment'
                    value={topicRelatedData?.isLowValueEquipment}
                    label={labels.isLowValueEquipment}
                    items={[
                        {text: labels.yes, value: true},
                        {text: labels.no, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!topicRelatedData?.isLowValueEquipmentSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='isIntangibleAssetSpecified'
                    checked={topicRelatedData?.isIntangibleAssetSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <DropdownField id='isIntangibleAsset'
                    value={topicRelatedData?.isIntangibleAsset}
                    label={labels.isIntangibleAsset}
                    items={[
                        {text: labels.yes, value: true},
                        {text: labels.no, value: false},
                    ]}
                    hasDefaultItem={false}
                    onChange={dropdownChange}
                    disabled={!topicRelatedData?.isIntangibleAssetSpecified}/>
            </Grid>
            <Grid item xs={12}>
                <Typography>{labels.tagRelated}</Typography>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='topicTagContainsSpecified'
                    checked={topicRelatedData?.topicTagContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='topicTagContains'
                    value={topicRelatedData?.topicTagContains}
                    label={labels.topicTagContains}
                    multiple
                    freeSolo
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.topicTagContainsSpecified}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='topicTagNotContainsSpecified'
                    checked={topicRelatedData?.topicTagNotContainsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='topicTagNotContains'
                    value={topicRelatedData?.topicTagNotContains}
                    label={labels.topicTagNotContains}
                    multiple
                    freeSolo
                    onChange={comboBoxChange}
                    disabled={!topicRelatedData?.topicTagNotContainsSpecified}/>
            </Grid>
        </Grid>
    </EditForm>
}