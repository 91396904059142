import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";

type Props = {
    gridData?: any[],
    fieldName: string,
    items?: any,
    label?: string,
    hasDefaultItem?: boolean,
    defaultItemLabel?: string,
    width?: string,
    filtersData: any,
    addFilter: (paramName: string, paramValue: any) => void,
}

type FilterItem = {
    label: string,
    value: string,
}

export default function DropdownFilter({gridData, fieldName, items, label, hasDefaultItem=true, defaultItemLabel, width='200px', filtersData, addFilter} : Props) {

    const [value, setValue] = useState('');
    const [filterItems, setFilterItems] = useState<FilterItem[]>([]);

    const {labels} = useAppContext();

    if (!defaultItemLabel) {
        defaultItemLabel = labels.all;
    }

    useEffect(() => {
        if (items) {
            setFilterItems(items);
        }
        else {
            const uniqueSet = new Set<string>();
            gridData?.forEach((item) => uniqueSet.add(item[fieldName ?? '']));
            const uniqueValues = Array.from(uniqueSet);

            var temp: FilterItem[] = [];

            uniqueValues.forEach((uniqueValue) => {
                temp.push({value: uniqueValue, label: uniqueValue});
            })

            setFilterItems(temp);
        }
    }, [items, gridData]);

    useEffect(() => {
        var valueFromFilter = filtersData[fieldName ?? ''] ?? '';

        setValue(valueFromFilter);
    }, [filtersData]);


    const handleChange = (e: SelectChangeEvent) => {
        setValue(e.target.value);
        addFilter(fieldName, e.target.value);
    }

    return <FormControl size='small' style={{backgroundColor: 'white', width: width}}>
        <InputLabel shrink>{label}</InputLabel>
        <Select
            displayEmpty
            input={<OutlinedInput notched label={label}/>}
            value={value}
            onChange={handleChange}
        >
            {hasDefaultItem && <MenuItem value="">
                <em style={{fontStyle: "normal"}}>{defaultItemLabel}</em>
            </MenuItem>}
            {filterItems && filterItems.map((filterItem, filterItemIndex) => {
                return <MenuItem key={filterItemIndex} value={filterItem.value}>{filterItem.label}</MenuItem>
            })}
        </Select>
    </FormControl>
}