import { Paper } from "@mui/material";
import LinkField from "../../components/fields/LinkField";
import { config } from "../../constants/config";
import MasterLayout from "../../layouts/MasterLayout"
import SideElementLayout from "../../layouts/SideElementLayout"
import { useAppContext } from "../../contexts/AppContext";

export default function DeleteAccountPage() {
  
    const {labels} = useAppContext();

    return <MasterLayout>
        <></>
        <SideElementLayout sideElements={null}>
            <Paper style={{padding: '20px'}}>
                <span>
                    {labels.contactUsToDeleteAccount} 
                    <LinkField href={`mailto:${config.deleteAccountEmail}?Subject=${config.deleteAccountEmailSubject}`} style={{marginLeft: '5px'}}>
                        {config.deleteAccountEmail}
                    </LinkField>
                </span>
            </Paper>
        </SideElementLayout>
    </MasterLayout>
}