import { UserMode, useUserContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { Stack } from '@mui/system';
import { useApiContext } from '../../contexts/ApiContext';
import SideElementLayout from '../../layouts/SideElementLayout';
import MasterLayout from '../../layouts/MasterLayout';
import ActionButton from '../../components/ActionButton';
import { ImageList, ImageListItem, Paper } from '@mui/material';
import logo_1 from '../../resources/about_logo_1.png';
import logo_2 from '../../resources/about_logo_2.jpg';
import logo_3 from '../../resources/about_logo_3.png';
import logo_4 from '../../resources/about_logo_4.jpg';
import logo_belka from '../../resources/logo_belka.png';

export default function AboutProjectPanel() {

  const {labels} = useAppContext() 
  var { userMode } = useUserContext();
  const navigate = useNavigate();

  
  const action = userMode === UserMode.LoggedInWithBC ?
    <ActionButton style={{marginLeft: '20px'}} className="actionButton"  onClick={() => navigate(`/`)}>
    {labels.decreePageButtonLabel}
  </ActionButton> : <></>

  return <MasterLayout navMenuAction={action}>
    <span>
    </span>
    <SideElementLayout sideElements={null}>
      <Stack className='centerPanel'> 
      <Paper style={{padding: '20px', textAlign: 'justify'}}>
        <div className='aboutSection'>
            <div className='aboutTitle'>
                Tytuł projektu
            </div>
            <p style={{fontWeight:'bold'}}>
                AccountantX - Inteligentny księgowy - automatyzacja procesów księgowych z wykorzystaniem sztucznej inteligencji
            </p>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
                Cel projektu
            </div>
            <p>
            Stworzenie opartego na sztucznej inteligencji Inteligentnego Asystenta Księgowego dedykowanego dla małych i średnich firm oraz biur rachunkowych.
            </p>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
            Model sztucznej inteligencji AccountantX
            </div>
            <p>
            W ramach projektu AccountantX został zbudowany największy w Polsce model sztucznej inteligencji, który automatyzuje proces klasyfikacji kosztowej i dekretacji faktur zakupu.
            </p>
            <p>
            Model powstał na bazie realnych 500.000 dokumentów, zaksięgowanych przez kilkuset księgowych w 20 różnych systemach. Model został wytrenowany na ponad 3700 planach kont oraz 5300 książek przychodów i rozchodów oraz danych pochodzących z film różnej wielkości, wszystkich branż i wszystkich województw. Dzięki temu może zostać wykorzystany do praktycznie każdej polskiej firmy w bardzo krótkim czasie.
            </p>
            <p>
            Model został opracowany na bazie analizy historycznych księgowań w firmach, analizy typowych operacji gospodarczych, analizy polityki rachunkowości oraz różnych parametrów decydujących o procesie księgowania dokumentów.
            </p>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
            Automatyzacja procesu księgowania kosztów dzięki AccountantX
            </div>
            <p>
            AccountantX umożliwia:
            </p>
            <ul>
                <li><span className='textBold'>wyznaczenie tematu dla każdej pozycji faktury</span> - czyli określenie przedmiotu zakupu (rodzaj produktu lub usługi)</li>
                <li><span className='textBold'>wyznaczenie kategorii księgowej dla każdej pozycji faktury</span> - czyli określenie sposobu zaksięgowania tej pozycji w oparciu o temat faktury oraz kontekst biznesowy firmy (branża, rodzaj prowadzonej działalności, polityka rachunkowości, prowadzenie gospodarki magazynowej oraz specyficzne dla danej firmy reguły postępowania w procesie dekretacji faktur)</li>
                <li><span className='textBold'>automatyczne dobranie właściwej dekretacji dla każdej faktury</span> - w aspekcie konta księgowego, zaliczenia zakupu w koszty uzyskania przychodów, odliczenia VAT, wyznaczenia  rejestru VAT</li>
            </ul>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
            Wykorzystanie modelu AccountantX w dowolnym systemie księgowym
            </div>
            <p style={{fontWeight:'bold'}}>
            AccountantX umożliwia pełną automatyzację procesu księgowego w firmach rozliczanych na pełnej księgowości oraz uproszczonej księgowości.
            </p>
            <p>
            Model może korzystać z danych faktur z różnych źródeł:
            </p>
            <ul>
                <li>faktury pobrane z <span className='textBold'>Krajowego Systemu e-Faktur</span> (XML)</li>
                <li>faktury odczytane ze <span className='textBold'>skanów</span> w procesie digitalizacji (OCR)</li>
            </ul>
            <p className='textBold'>
            Model może zostać połączony z dowolnym systemem księgowym lub systemem obiegu dokumentów - dzięki rozbudowanemu API:
            </p>
            <ul>
                <li>faktury zakupu mogą zostać przekierowane do AccountantX aby otrzymać automatycznie nadaną kategorię księgowej i mieć od razu wyznaczony szczegółowy sposobu dekretacji dokumentu (konta księgowe, rejestry VAT, tagi, dodatkowe ostrzeżenia)</li>
                <li>faktury mogą zostać automatycznie wprowadzone do bufora systemu księgowego, automatyzując w 80% dekretację tych dokumentów i przyspieszając wszystkie procesy księgowe i raportowe, które są oparte na danych z faktur</li>
            </ul>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
            Dostosowanie modelu do indywidualnych procesów księgowych w firmie
            </div>
            <p>
            Model AccountantX posiada mechanizm reguł księgowych - dzięki któremu można w dowolny sposób ukształtować i zautomatyzować proces księgowy w dowolnej firmie.
            </p>
            <p>
            Dostarczany jest z zestawem predefiniowanych reguł, natomiast można swobodnie dodać własne reguły które dostosują proces księgowania do dowolnej specyfiki.
            </p>
        </div>
        <div className='aboutSection'>
            <div className='aboutTitle'>
            Odporność modelu na zmiany przepisów podatkowych
            </div>
            <p>
            Model AccountantX łączy w sobie dwa elementy:
            </p>
            <ul>
                <li>sztuczną inteligencję - głębokie sieci neuronowe, które pozwalają na podstawie danych pozycji faktury wyznaczyć “temat” tej faktury - czyli co zostało zakupione</li>
                <li>zestaw reguł - które pozwalają na podstawie tematu faktury i kontekstu danej firmy - wyznaczyć sposób księgowania</li>
            </ul>
            <p>
            AccountantX posiada ponad 120 predefiniowanych reguł księgowych, które odpowiadają bieżącym przepisom podatkowym. W przypadku zmiany tych przepisów następuje zmiana reguł i proces księgowy zostaje dostosowany do zmian - bez konieczności “przeuczania” całego modelu sztucznej inteligencji.
            </p>
        </div>
        {/* <ImageList sx={{ width: '100%'}} cols={4} rowHeight={164} variant='masonry'>
            <ImageListItem key={1}>
            <img 
                src={logo_1}
                alt='Fundusze Europejskie'
                style={{objectFit: 'contain'}}
            />
            </ImageListItem>
            <ImageListItem key={2}>
            <img
                src={logo_2}
                alt='Rzeczpospolita Polska'
                style={{objectFit: 'contain'}}
            />
            </ImageListItem>
            <ImageListItem key={3}>
            <img
                src={logo_3}
                alt='Narodowe Centrum Badań i Rozwoju'
                style={{objectFit: 'contain'}}
            />
            </ImageListItem>
            <ImageListItem key={4}>
            <img
                src={logo_4}
                alt='Unia Europejska'
                style={{objectFit: 'contain'}}
            />
            </ImageListItem>
        </ImageList> */}
        <ImageList sx={{ width: '100%'}} cols={1} rowHeight={164}>
            <ImageListItem key={1}>
            <img 
                src={logo_belka}
                alt='Fundusze Europejskie, Rzeczpospolita Polska, Narodowe Centrum Badań i Rozwoju, Fundusze Europejskie'
                style={{objectFit: 'contain'}}
            />
            </ImageListItem>
        </ImageList>
      </Paper>
      </Stack>
    </SideElementLayout>
  </MasterLayout>
}
