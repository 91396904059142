import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXOnboardingTopicGroupResult, AccXTopicResult, OnboardingApi } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import AccXTextField from "../../../../components/fields/AccXTextField";
import ComboBoxField from "../../../../components/fields/ComboBoxField";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";

type Props = {
    topicGroupKey?: string,
    onClose: (refresh: boolean) => void,
    allData?: AccXOnboardingTopicGroupResult[]
}

export default function AccountingPolicyTopicGroupPopup({topicGroupKey, onClose, allData} : Props) {

    const isEditMode = topicGroupKey && true;
    
    const initValues: AccXOnboardingTopicGroupResult = {
        topicGroupKey: '',
        topicGroupName: '',
        topicIds: [],
    };

    const {labels, setIsLoading, showMessage} = useAppContext();
    const {request, fetchTopics} = useApiContext();
    
    const [topicGroup, setTopicGroup] = useState(initValues);
    const [topicGroupOriginalName, setTopicGroupOriginalName] = useState('');
    const [isFetchingTopicGroup, setIsFetchingTopicGroup] = useState(false);
    const [isGroupKeyUnique, setIsGroupKeyNotUnique] = useState(false);

    const popupTitle = isEditMode ? <span>{labels.editTopicGroup} <b>{topicGroupOriginalName}</b></span>
    : labels.newTopicGroup; 

    const [topics, setTopics] = useState<AccXTopicResult[]>([]);
    const [isFetchingTopics, setIsFetchingTopics] = useState(true);
    const onboardingApi = new OnboardingApi();

    const isLoadingData = isFetchingTopics || isFetchingTopicGroup;

    useEffect(() => {
        fetchTopics().then((response) => {
            setTopics(response);
            setIsFetchingTopics(false);
        });
        
        if (isEditMode) {
            setIsFetchingTopicGroup(true);
            request(onboardingApi.onboardingTopicGroupsTopicGroupKeyGet(topicGroupKey)).then((response) => {
                if (response.ok && response.data) {
                    setTopicGroup(response.data);
                    setTopicGroupOriginalName(response.data.topicGroupName ?? '');
                }
                setIsFetchingTopicGroup(false);
            });
        }
    }, []);

    function topicGroupKeyChange(id: string, value: string) {
        setTopicGroup({...topicGroup, [id]: value});
        if(isGroupKeyUnique){
            setIsGroupKeyNotUnique(false);
        }
    }

    function textChange(id: string, value: string) {
        setTopicGroup({...topicGroup, [id]: value});
    }

    function topicGroupTopicIdsChange(id: string, value: any) {
        setTopicGroup({...topicGroup, topicIds: value});
    }

    function isDataValid(dataToSave: AccXOnboardingTopicGroupResult){
        if(allData != null){
            let usedGroupKeys = allData.map(x => x.topicGroupKey);
            return usedGroupKeys.indexOf(dataToSave.topicGroupKey) == -1;
        }
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setIsLoading(true);

        if(isEditMode) {
            request(onboardingApi.onboardingTopicGroupsPut(topicGroup)).then((response) => {
                setIsLoading(false);
                
                if (response.ok) {
                    onClose(true);
                    showMessage(labels.topicGroupChanged);
                }
                else {
                    showMessage(labels.topicGroupChangingError, MessageType.Error);
                }
            });
        }
        else {
            if(isDataValid(topicGroup)){
                request(onboardingApi.onboardingTopicGroupsPost(topicGroup)).then((response) => {
                    setIsLoading(false);
                    
                    if (response.ok) {
                        onClose(true);
                        showMessage(labels.topicGroupAdded);
                    }
                    else {
                        showMessage(labels.topicGroupAddingError, MessageType.Error);
                    }
                });
            }else{
                setIsLoading(false);
                setIsGroupKeyNotUnique(true);
            }
            
        }
    }

    return <Popup title={popupTitle} onClose={onClose} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AccXTextField id='topicGroupKey' 
                        value={topicGroup.topicGroupKey} 
                        label={labels.key} 
                        onChange={topicGroupKeyChange} 
                        required
                        disabled={Boolean(isEditMode)}
                        error={isGroupKeyUnique}
                        helperText = {isGroupKeyUnique ? labels.topicGroupAddingErrorNotUniqueKey : ''}
                        />
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='topicGroupName'
                        value={topicGroup.topicGroupName} 
                        label={labels.name} 
                        onChange={textChange}
                        required
                        disabled={Boolean(isEditMode) && topicGroup.isPredefined}/>
                </Grid>
                <Grid item xs={12}>
                    <ComboBoxField value={topicGroup.topicIds}
                        label={labels.topics}
                        items={topics}
                        //itemLabelField='topicName'
                        multiple
                        onChange={topicGroupTopicIdsChange}
                        filterSelectedOptions={true}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionButton type={'submit'} style={{float: 'right'}}>{labels.save}</ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}