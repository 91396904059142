import { AccXUser } from "../api";
import { HistoryItem } from "../models/HistoryItem";

const historyKey = 'dekretomatHistory';

function getUserHistoryKey(user: AccXUser): string {
    return `${historyKey}-${user.userLogin}`;
}

function getUserHistoryItems(user: AccXUser): HistoryItem[]{

    var userHistoryKey = getUserHistoryKey(user);
    var storageValue = localStorage.getItem(userHistoryKey);

    var historyItems: HistoryItem[] = [];

    if (storageValue) {
        historyItems = JSON.parse(storageValue);
    }

    return historyItems;
} 

const setUserHistoryItems = (user: AccXUser, historyItems: HistoryItem[]) => {
    var userHistoryKey = getUserHistoryKey(user);

    var historyString = JSON.stringify(historyItems);

    localStorage.setItem(userHistoryKey, historyString);
} 

export function getHistoryItems(user: AccXUser | null): HistoryItem[] {
    if (user) {
        var historyItems = getUserHistoryItems(user);
    
        if (historyItems && historyItems.length > 0) {
            var reversedHistoryItems = historyItems.reverse();
    
            return reversedHistoryItems;
        }
    }

    return [];
} 

export function addHistoryItem(user: AccXUser | null, historyItem: HistoryItem) {
    if(!user) {
        return; 
    }

    var historyItems = getUserHistoryItems(user); 

    if (!historyItems) {
        historyItems = [];
    }

    historyItems.push(historyItem);

    if (historyItems.length > 10) {
        historyItems.shift();
    }

    setUserHistoryItems(user, historyItems);
}

export function removeHistoryItems(user: AccXUser | null) {
    if(user) {
        var userHistoryKey = getUserHistoryKey(user);
    
        localStorage.removeItem(userHistoryKey);
    }
}

export function updateHisotryItemReportStatus(user: AccXUser | null, correlationId: string, reportStatusId: number) {
    if (user) {
        var historyItems = getUserHistoryItems(user); 
    
        if (historyItems && historyItems.length > 0){
            var itemToSet = historyItems.find(x => x.id === correlationId);
        
            if (itemToSet && !itemToSet.reportStatus) {
                itemToSet.reportStatus = reportStatusId;
    
                setUserHistoryItems(user, historyItems);
            }
        }
    }
}