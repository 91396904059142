import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccXOnboardingParameterResult, OnboardingApi, OnboardingParameterTypeEnum } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import AccXTextField from "../../../../components/fields/AccXTextField";
import CheckboxField from "../../../../components/fields/CheckboxField";
import DropdownField, { DropdownItem } from "../../../../components/fields/DropdownField";
import NumericField from "../../../../components/fields/NumericField";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { stringToEnum } from "../../../../helpers/CommonHelper";

type Props = {
    parameterKey?: string,
    onClose: (refresh: boolean) => void,
    allData?: AccXOnboardingParameterResult[],
}

export default function AccountingPolicyParameterPopup({parameterKey, onClose, allData} : Props) {

    const isEditMode = parameterKey && true;
    
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isParamKeyNotUnique, setIsParamKeyNotUnique] = useState(false);
    
    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const onboardingApi = new OnboardingApi();
    
    const initValues: AccXOnboardingParameterResult = {
        parameterKey: '',
        parameterName: '',
        parameterValue: '',
        parameterType: OnboardingParameterTypeEnum.Text,
    };
    
    const [parameter, setParameter] = useState(initValues);
    const [parameterOriginalName, setParameterOriginalName] = useState('');
    
    const popupTitle = isEditMode ? <span>{labels.editParameter} <b>{parameterOriginalName}</b></span>
        : labels.newParameter; 

    function textChange(id: string, value: string) {
        setParameter({...parameter, [id]: value});
    }

    function paramKeyChange(id: string, value: string){
        setParameter({...parameter, [id]: value});
        if(isParamKeyNotUnique){
            setIsParamKeyNotUnique(false);
        }
    }

    function parameterTypeChange(id: string, value: any) {
        var newValue = stringToEnum(value, OnboardingParameterTypeEnum);
        setParameter({...parameter, parameterType: newValue, parameterValue: ''});
    }

    function parameterValueBoolChange(id: string, value: any) {
        setParameter({...parameter, parameterValue: value});
    }
    useEffect(() => {
        if (isEditMode) {
            setIsLoadingData(true);
            request(onboardingApi.onboardingParametersParameterKeyGet(parameterKey)).then((response) => {
                if (response.ok && response.data) {
                    setParameter(response.data);
                    setParameterOriginalName(response.data.parameterName ?? '');
                }
                setIsLoadingData(false);
            });
        }
    }, []);

    function getParameterTypeItems() {

        var items: DropdownItem[] = [
            {value: OnboardingParameterTypeEnum.Text as number, text: labels.text},
            {value: OnboardingParameterTypeEnum.Number as number, text: labels.number},
            {value: OnboardingParameterTypeEnum.Bool as number, text: labels.bool},
        ];

        return items;
    }

    function isDataValid(dataToSave: AccXOnboardingParameterResult){
        if(allData != null){
            let usedParameterKeys = allData.map(x => x.parameterKey);
            return usedParameterKeys.indexOf(dataToSave.parameterKey) == -1;
        }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);
        if(isEditMode) {
            request(onboardingApi.onboardingParametersPut(parameter)).then((response) => {
                setIsLoading(false);
                
                if (response.ok) {
                    onClose(true);
                    showMessage(labels.parameterChanged);
                }
                else {
                    showMessage(labels.parameterChangingError, MessageType.Error);
                }
            });
        }else{
            if(isDataValid(parameter)){
                    request(onboardingApi.onboardingParametersPost(parameter)).then((response) => {
                        setIsLoading(false);
                        
                        if (response.ok) {
                            onClose(true);
                            showMessage(labels.parameterAdded);
                        }
                        else {
                            showMessage(labels.parameterAddingError, MessageType.Error);
                        }
                    });
            }else{
                setIsLoading(false);
                setIsParamKeyNotUnique(true);
            }
        }
    }

    return <Popup title={popupTitle} onClose={() => onClose(false)} width={'800px'} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}> 
                    <AccXTextField id='parameterKey'
                        value={parameter.parameterKey} 
                        label={labels.key} 
                        onChange={paramKeyChange} 
                        required
                        disabled={Boolean(isEditMode)}
                        error={isParamKeyNotUnique}
                        helperText = {isParamKeyNotUnique ? labels.parameterAddingErrorNotUniqueKey : ''}
                        />
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='parameterName'
                        value={parameter.parameterName} 
                        label={labels.name} 
                        onChange={textChange} 
                        required
                        disabled={Boolean(isEditMode) && parameter.isPredefined}/>
                </Grid>
                <Grid item xs= {4}>
                    <DropdownField 
                        value={parameter.parameterType} 
                        label={labels.type} 
                        items={getParameterTypeItems()} 
                        hasDefaultItem={false} 
                        onChange={parameterTypeChange}
                        disabled={Boolean(isEditMode)}/>
                </Grid>
                <Grid item xs={8}>
                    {parameter.parameterType == OnboardingParameterTypeEnum.Text && <AccXTextField id='parameterValue'
                        value={parameter.parameterValue} 
                        label={labels.defaultValue} 
                        onChange={textChange}/>}
                    {parameter.parameterType == OnboardingParameterTypeEnum.Number && <NumericField id='parameterValue'
                        value={parameter.parameterValue}    
                        label={labels.defaultValue} 
                        onChange={textChange}/>}
                    {parameter.parameterType == OnboardingParameterTypeEnum.Bool && <DropdownField
                        value={parameter.parameterValue === '' ? 1 : parameter.parameterValue}
                        label={labels.defaultValue} 
                        items={[
                            {text: labels.yes, value: 1},
                            {text: labels.no, value: 0},
                        ]}
                        hasDefaultItem={false}
                        onChange={parameterValueBoolChange}
                    />} 
                </Grid>
                <Grid item xs={12}>
                    <ActionButton type={'submit'} style={{float: 'right'}}>{labels.save}</ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}
