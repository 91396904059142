import { Button } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { AccXPurchasePurposeResult, PurchasePurposesApi } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import ActionGridButton from "../../../components/ActionGridButton";
import GridInfo from "../../../components/GridInfo";
import PredefinedTag from "../../../components/PredefinedTag";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import useFilters from "../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../models/ActionDefinition";
import PurchaseTargetsPopup from "./PurchaseTargetsPopup";

export default function PurchaseTargetsPanel() {

    const [openNewPurchaseTargetPopup, setOpenNewPurchaseTargetPopup] = useState(false);
    const [openEditPurchaseTargetPopup, setOpenEditPurchaseTargetPopup] = useState(false);

    const [purchaseTargets, setPurchaseTargets] = useState<AccXPurchasePurposeResult[]>([]);
    const [selectedPurchaseTargets, setSelectedPurchaseTargets] = useState<number[]>([]);
    const [isFetchingPurchasePurposes, setIsFetchingPurchasePurposes] = useState(false);
    const [filtersData, addFilter] = useFilters();

    const { request } = useApiContext();
    const {labels, showMessage} = useAppContext();

    const purchaseTargetApi = new PurchasePurposesApi()
    
    useEffect(() => {
        fetchPurchasePurposes();
    }, []);

    const visiblePurchaseTargets = useMemo(() => {

        var temp = [...purchaseTargets];

        if (temp.length > 0 && filtersData) {

            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(purchaseTarget => purchaseTarget.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()));
            };
        }
        
        return temp;

    }, [purchaseTargets, filtersData]);

    function fetchPurchasePurposes() {
        setIsFetchingPurchasePurposes(true);

        var serachText = filtersData['searchText'];

        request(purchaseTargetApi.purchasePurposesAllGet(serachText)).then((response => {
            if (response.ok && response.data) {
                setPurchaseTargets(response.data);
            }
            setIsFetchingPurchasePurposes(false);
        }));
    }

    function closePopups(refresh=false) {
        setOpenNewPurchaseTargetPopup(false);
        setOpenEditPurchaseTargetPopup(false);
        if(refresh){
            fetchPurchasePurposes();
        }
    }

    var columns = [
        {header: labels.purchaseTargetName, field: 'purchasePurposeName', customTemplate: (row: any) => {
            var isPredefined = row['isPredefined'];
            var purchaseTargetName = row['purchasePurposeName'];
            
            if (isPredefined) {
                return <PredefinedTag text={purchaseTargetName}></PredefinedTag>;
            }

            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditPurchaseTargetPopup(true)}>{purchaseTargetName}</a>
        }},
        // {header: labels.purchaseTargetCode, field: 'purchaseTargetCode'},
        // {header: labels.purchaseTargetNote, field: 'purchaseTargetNote'},
        // {header: labels.purchaseTargetDefaultCategory, field: 'purchaseTargetDefaultCategoryName'},
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewPurchaseTargetPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditPurchaseTargetPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deletePurchaseTarget(selectedPurchaseTargets[0])}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
    ];

    function deletePurchaseTarget(purchaseTargetId : number){
        request(purchaseTargetApi.purchasePurposesPurchasePurposeIdDelete(purchaseTargetId)).then((response => {
            if (response.ok) {
                showMessage(labels.purchasePurposeDeletingSuccess, MessageType.Success);
                fetchPurchasePurposes();
            }else{
                showMessage(labels.deletingError, MessageType.Error);
            }
        }));
    } 

    return <>
        {openNewPurchaseTargetPopup && <PurchaseTargetsPopup allData={purchaseTargets} onClose={(refresh = false) => closePopups(refresh)}/>}
        {openEditPurchaseTargetPopup && <PurchaseTargetsPopup purchaseTargetId={selectedPurchaseTargets[0]} onClose={(refresh = false) => closePopups(refresh)}/>}
        <GridInfo columns={columns} data={visiblePurchaseTargets} actions={actions} hasSearchBar={true} isLoading={isFetchingPurchasePurposes} onSelect={(selected) => setSelectedPurchaseTargets(selected as number[])}
            filtersData={filtersData}
            addFilter={addFilter}
            />
    </>
}