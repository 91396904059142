import {TextField } from "@mui/material";
import { style } from "@mui/system";


type Props = {
    id?: string,
    value?: any,
    label?: string,
    onChange?: (id: string, value: string) => void,
    multiline?: boolean,
    disabled?: boolean,
    rows?: number,
    onBlur?: any,
    inputProps?: any,
    onKeyDown?: (e: React.KeyboardEvent) => void,
    error?: boolean,
    helperText?: string,
    required?: boolean,
    fullWidth?: boolean,
    style?: any
}

export default function AccXTextField({id, value, label, onChange, disabled, multiline, rows, onBlur, inputProps,onKeyDown,error,helperText,required, fullWidth = true, style} : Props) {
    
    function handleOnKeyDown(event: React.KeyboardEvent) {
        if(onKeyDown) {
            onKeyDown(event)
        }
        else {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        }
    }

    function innerOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        const {value} = event.target;
        if (onChange) onChange(id ?? '', value);
    }

    return <TextField id={id}
        value={value ?? ''}
        label={label}
        fullWidth={fullWidth}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        onBlur={onBlur}
        onChange={innerOnChange}
        onKeyDown={handleOnKeyDown}
        inputProps={inputProps}
        error={error}
        helperText={helperText}
        required={required}
        style={style}
    />
}