import { faL } from "@fortawesome/free-solid-svg-icons";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ProductApi, AccXProduct, OnboardingApi, AccXOnboardingTopicGroupResult, AccXTopicResult } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import AccXTextField from "../../../../components/fields/AccXTextField";
import ComboBoxField from "../../../../components/fields/ComboBoxField";
import DropdownField, { DropdownItem } from "../../../../components/fields/DropdownField";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { gtuCodes } from "../../../../dictionaries/gtuCodes";

type Props = {
    productId?: number,
    onClose: (refresh: boolean) => void,
    allData?: AccXProduct[]
}

// class AccXTopicResultFilter implements AccXTopicResult{
//     topicId?: number;
//     topicName?: string;
//     entityId?: any;
//     customSx: JSX.Element
// }

export default function ProductPopup({productId, onClose, allData} : Props) {

    const {request, fetchTopics} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const productApi = new ProductApi();
    const onboardingApi = new OnboardingApi();

    const isEditMode = productId && true;
    const initValues: AccXProduct= {
        productId: 0,
        productIndex: '',
        productName: '',
        sellerIndex: '',
        productGroupName: '',
        productTags: [],
        invoiceTopicId: undefined,
        notes: '',
        unit: '',
        vatRate: '',
        gtu: '0',
        pkWiUCode: '',
        cnCode: '',
        gtinCode: '',
        additionalData: '',
    };

    const productGroups = [...new Set(allData?.map(item => item.productGroupName))].map(item => ({text: item, value: item}));

    const [productData, setProductData] = useState(initValues);
    const [isLoadingProductData, setIsLoadingProductData] = useState(false);
    const [productOriginalName, setProductOriginalName] = useState('');
    const [isProductNameNotUnique, setIsProductNameNotUnique] = useState(false);
    const [isProductIndexNotUnique, setIsProductIndexNotUnique] = useState(false);
    
    const [topics, setTopics] = useState<AccXTopicResult[]>([]);
    const [isLoadingTopics, setIsLoadingTopics] = useState(false);

    const isLoadingData = isLoadingProductData || isLoadingTopics;

    var popupTitle = isEditMode 
        ? <span>{labels.editProduct} <b>{productOriginalName}</b></span>
        : labels.newProduct;

    useEffect(() => {
        if (isEditMode) {
            setIsLoadingProductData(true);
            request(productApi.productProductIdGet(productId)).then((response) => {
                if (response.ok && response.data) {
                    setProductData(response.data);
                    setProductOriginalName(response.data.productName ?? '');
                }
                setIsLoadingProductData(false);
            });
        }

        setIsLoadingTopics(true);
        fetchTopics().then((response) => {

            response.unshift({
                topicId: undefined,
                topicName: 'Ustaw automatycznie',
                text: 'Ustaw automatycznie',
                value: null,
                entityId: null,
                isCustomValue: true
            });

            setTopics(response);
            setIsLoadingTopics(false);
        })
    }, []);

    function textChange(id: string, value: string) {
        setProductData({...productData, [id]: value});
    }

    function productIndexChange(id: string, value: string) {
        setProductData({...productData, [id]: value});
        if(isProductIndexNotUnique){
            setIsProductIndexNotUnique(false);
        }
    }

    function productNameChange(id: string, value: string) {
        setProductData({...productData, [id]: value});
        if(isProductNameNotUnique){
            setIsProductNameNotUnique(false);
        }
    }

    function dropdownChange(id: string, value: any) {
        setProductData({...productData, [id]: value});
    }

    function comboBoxChange(id: string, value: any) {
        setProductData({...productData, [id]: value});
    }

    function setAndCheckIsDataValid(dataToSave: AccXProduct){
        if(allData != null){
            let isValid = true;
            let usedNames = allData.map(x => x.productName);
            let usedIndexes = allData.map(x => x.productIndex);
            if(usedNames.indexOf(dataToSave.productName) != -1){
                isValid = false;
                setIsProductNameNotUnique(true);
            }
            if(usedIndexes.indexOf(dataToSave.productIndex) != -1){
                isValid = false;
                setIsProductIndexNotUnique(true);
            }
            return isValid;
        }
        return true;
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsLoading(true);

        if (isEditMode) {
            request(productApi.productPut(productData)).then((response) => {
                setIsLoading(false);

                if (response.ok) {
                    onClose(true);
                    showMessage(labels.productEditSuccess);
                }
                else {
                    showMessage(labels.productEditError, MessageType.Error);
                }
            })
        }
        else {
            if(setAndCheckIsDataValid(productData)){
                request(productApi.productPost(productData)).then((response) => {
                    setIsLoading(false);
    
                    if (response.ok) {
                        onClose(true);
                        showMessage(labels.productAddSuccess);
                    }
                    else {
                        showMessage(labels.productAddError, MessageType.Error);
                    }
                })
            } else {
                setIsLoading(false);
            }
        }
    }

    return <Popup title={popupTitle} onClose={onClose} width={'800px'} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AccXTextField id='productIndex' 
                        value={productData.productIndex}
                        onChange={productIndexChange} 
                        label={labels.productIndex}
                        disabled={isEditMode as boolean}
                        required
                        error={isProductIndexNotUnique}
                        helperText={isProductIndexNotUnique ? labels.productIndexNotUniqueError : ''}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='productName' 
                        value={productData.productName}
                        onChange={productNameChange} 
                        label={labels.productName} 
                        disabled={isEditMode as boolean}
                        required
                        error={isProductNameNotUnique}
                        helperText= {isProductNameNotUnique ? labels.productNameNotUniqueError : ''}
                        />
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='sellerIndex' 
                        value={productData.sellerIndex}
                        onChange={textChange} 
                        label={labels.sellerIndex} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <ComboBoxField id='productGroupName'
                        value={productData.productGroupName}
                        label={labels.productGroupName}
                        items={productGroups}
                        freeSolo
                        onChange={comboBoxChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ComboBoxField id='productTags'
                        value={productData.productTags}
                        label={labels.productTags}
                        multiple
                        freeSolo
                        onChange={comboBoxChange}/>
                </Grid>
                <Grid item xs={12}>
                    <ComboBoxField id='invoiceTopicId'
                        value={productData.invoiceTopicId}
                        label={labels.invoiceTopic}
                        items={topics}
                        //itemLabelField='topicName'
                        onChange={comboBoxChange}
                        hasEmptyOption={true}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='notes'
                        value={productData.notes}
                        label={labels.note}
                        multiline
                        rows={2}
                        onChange={textChange}/>
                </Grid>
                
                <Grid item xs={12}/>

                <Grid item xs={6}>
                    <AccXTextField id='unit'
                        value={productData.unit}
                        label={labels.unit}
                        onChange={textChange}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='vatRate'
                        value={productData.vatRate}
                        label={labels.vatRate}
                        onChange={textChange}/>
                </Grid>
                <Grid item xs={6}>
                    <DropdownField id='gtu'
                        value={productData.gtu}
                        label={labels.gtu}
                        items={gtuCodes}
                        onChange={dropdownChange}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='cnCode'
                        value={productData.cnCode}
                        label={labels.cnCode}
                        onChange={textChange}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='pkWiUCode'
                        value={productData.pkWiUCode}
                        label={labels.pkwiu}
                        onChange={textChange}/>
                </Grid>
                <Grid item xs={6}>
                    <AccXTextField id='gtinCode'
                        value={productData.gtinCode}
                        label={labels.gtinCode}
                        onChange={textChange}/>
                </Grid>
                <Grid item xs={12}>
                    <AccXTextField id='additionalData'
                        value={productData.additionalData}
                        label={labels.additionalData}
                        multiline
                        rows={2}
                        onChange={textChange}/>
                </Grid>

                <Grid item xs={12}>
                    <ActionButton type='submit' 
                        style={{float: 'right'}}>
                        {labels.save}
                    </ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}