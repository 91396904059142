import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXBCPrediction, AccXBCPredictionDataResult, AccXBusinessClassificationResult, AccXCustomerProfile, BusinessClassificationPredictionApi, OnboardingApi } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import AccXTextField from "../../../components/fields/AccXTextField";
import CheckboxField from "../../../components/fields/CheckboxField";
import ComboBoxField from "../../../components/fields/ComboBoxField";
import Popup from "../../../components/Popup";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
    onClose: (refresh?: boolean) => void
}

export default function CustomerDataPopup({onClose} : Props) {
    const {labels, showMessage} = useAppContext();
    const {request, fetchBusinessClassifications} = useApiContext();
    const {customerProfile} = useUserContext();

    const initValues: AccXCustomerProfile = {
        companyName: customerProfile?.companyName ?? '',
        companyTaxId: customerProfile?.companyTaxId ?? '',
        businessClassificationId: customerProfile?.businessClassificationId ?? 0,
        explainMode: customerProfile?.explainMode ?? false,
        phone: customerProfile?.phone ?? '',
        email: customerProfile?.email ?? '',
        hasWarehouse: customerProfile?.hasWarehouse ?? false
    };

    const [customerProfileData, setCustomerProfileData] = useState(initValues);

    const [isBusinessClasificationsLoading, setIsBusinessClassificationsLoading] = useState(true);
    const [isBCPredictionLoading, setIsBCPredictionLoading] = useState(false);
    const [businessClassifications, setBusinessClassifications] = useState<AccXBusinessClassificationResult[]>();

    const onboardingApi = new OnboardingApi();
    const businessClassificationPredictionApi = new BusinessClassificationPredictionApi();

    useEffect(() => {

        fetchBusinessClassifications().then((response) => {
            setBusinessClassifications(response);
            setIsBusinessClassificationsLoading(false);
        })
    },[])

    function textChange(id: string, value: string) {
        setCustomerProfileData({...customerProfileData, [id]: value})
    }

    function hasWarehouseChange(id: string, checked: boolean) {
        setCustomerProfileData({...customerProfileData, hasWarehouse: checked})
    }

    function businessClassificationChange (event: any, newValue: any) {
        setCustomerProfileData({...customerProfileData, businessClassificationId: newValue});
    }

    const onBlurTaxId = () => {
        setIsBCPredictionLoading(true);

        var accXBCPrediction:AccXBCPrediction = {
            taxId: customerProfileData.companyTaxId,
            contractorName: customerProfileData.companyName,
        }
        request(businessClassificationPredictionApi.businessClassificationPredictionPredictPost(accXBCPrediction)).then((response => {
            if (response.ok && response.data) {
                let bcId = getBusinessClassificationFromPrediction(response.data);
                setCustomerProfileData({...customerProfileData, businessClassificationId: bcId});
            }
            setIsBCPredictionLoading(false);
        }));
    }

    function getBusinessClassificationFromPrediction(dataResponse: AccXBCPredictionDataResult){
        if(dataResponse && dataResponse.accXBCPredictionResults && dataResponse.accXBCPredictionResults.length > 0){
            var bcId = dataResponse.accXBCPredictionResults[0].businessClassificationId;
            if(bcId){
                return bcId;
            }
        }
        return customerProfileData?.businessClassificationId;
    }

    function saveCustomerData() {
        
        if (isBCPredictionLoading) {
            showMessage(labels.BCPredictionLoading, MessageType.Warning);
            return;
        }

        request(onboardingApi.onboardingProfilesPut(customerProfileData)).then((response) => {
            if(response.ok) {
                showMessage(labels.saveSuccessMessage);
                onClose(true);
            }
        })
    }

    return <Popup title={labels.popupTitle} onClose={onClose} isLoading={isBusinessClasificationsLoading}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <AccXTextField id='companyName' 
                    value={customerProfileData.companyName} 
                    label={labels.companyName} 
                    onChange={textChange}/>
            </Grid>
            <Grid item xs={6}>
                <AccXTextField id='companyTaxId' 
                    value={customerProfileData.companyTaxId} 
                    label={labels.companyTaxId} 
                    onBlur={() => onBlurTaxId()}
                    onChange={textChange}/>
            </Grid>
            <Grid item xs={6}>
                <ComboBoxField id='businessClassificationId'
                    label={labels.businessClassification}
                    value={customerProfileData.businessClassificationId}
                    items={businessClassifications}
                    isLoading={isBCPredictionLoading}
                    //itemLabelField='businessClassificationName'
                    onChange={businessClassificationChange}
                    />
            </Grid>
            <Grid item xs={6}>
                <CheckboxField 
                    isSwitch 
                    label={labels.hasWarehouse} 
                    checked={customerProfileData.hasWarehouse} 
                    onChange={hasWarehouseChange}/>
            </Grid>
            <Grid item xs={12}>
                <ActionButton style={{float: 'right'}} onClick={saveCustomerData}>
                    {labels.save}
                </ActionButton>
            </Grid>
        </Grid>
    </Popup>
}