import { Typography } from "@mui/material"
import { Box, margin } from "@mui/system"
import React, { PropsWithChildren } from "react"
import ActionButton from "./ActionButton"
import Popup from "./Popup"

type Props = {
    title?: JSX.Element | string,
    popupText?: JSX.Element | string,
    closeTitle?: JSX.Element | string,
    confirmationTitle?: JSX.Element | string,
    onClose: (refresh: boolean) => void,
    width?: string,
    height?: string,
    isLoading?: boolean,
    onActionButtonConfirmationClick?: (e: any) => void,
    onActionButtonCloseClick?: (e: any) => void,
}


export default function ConfirmationPopup({title, popupText, onClose,onActionButtonConfirmationClick,onActionButtonCloseClick, closeTitle, confirmationTitle, width, height='auto', isLoading = false, children} : PropsWithChildren<Props>) {
    const confirmationPopupBase = <Box>
        <Typography style={{padding: '0px 10px'}}>{popupText}</Typography>
        {(onActionButtonConfirmationClick || onActionButtonCloseClick) && <Box style={{marginTop: '30px'}}>
            {onActionButtonConfirmationClick && <ActionButton style={{float: 'right', margin: '0px 5px'}} onClick={onActionButtonConfirmationClick}>{confirmationTitle}</ActionButton>}
            {onActionButtonCloseClick && <ActionButton className='bg-red' style={{float: 'right', margin: '0px 5px'}} onClick={onActionButtonCloseClick}>{closeTitle}</ActionButton>}
        </Box>}
        
    </Box>
    

    return <Popup title={title} onClose={onClose} width={width} height={height} isLoading = {isLoading}>{confirmationPopupBase}</Popup>
}
