import { Checkbox, FormControlLabel, Switch } from "@mui/material";

type Props = {
    id?: string,
    label?: string,
    checked?: boolean,
    isSwitch?: boolean,
    disabled?: boolean
    onChange?: (id: string, checked: boolean) => void,
}

export default function CheckboxField({id, label, checked, isSwitch=false, disabled=false, onChange} : Props) {

    function handleOnKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function innerOnChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        if(onChange) onChange(id ?? '', checked);
    }

    const variant = isSwitch 
        ? <FormControlLabel disabled={disabled} label={label} control={
            <Switch id={id} checked={checked ?? false} onKeyDown={handleOnKeyDown} onChange={innerOnChange}/>
        }/>
        : <Checkbox id={id} 
            checked={checked ?? false} 
            onKeyDown={handleOnKeyDown} 
            onChange={innerOnChange}
            disabled={disabled}/>;

    return variant;
}