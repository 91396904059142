import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { UserContextProvider } from './contexts/UserContext';
import { AppContextProvider } from './contexts/AppContext';
import { ApiContextProvider } from './contexts/ApiContext';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as HTMLElement);
const baseUrl = document?.getElementsByTagName('base')[0]?.getAttribute('href') ?? '';

root.render(
  <BrowserRouter basename={baseUrl}>
    <AppContextProvider>
      <UserContextProvider>
        <ApiContextProvider>
            <App />
        </ApiContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  </BrowserRouter>
);

registerServiceWorker();
