import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';


interface WrapperProps{
    initialized: boolean;
    children: React.PropsWithChildren<any>;
}

export function WrapperGA(props: WrapperProps){
    const location = useLocation();

    useEffect(() => {
        if(props.initialized){
            // Send pageview with a custom path
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
            //ReactGA.pageview(location.pathname + location.search);
        }
    }, [props.initialized, location]);

    return props.children;
}

export function useAnalyics(){
    const [initialized, setInitialized] = useState(false);
    const TRACKING_ID = "G-52VR6VS4ZR"; 

    useEffect(() =>{
        //google anatylics
        ReactGA.initialize(TRACKING_ID);
        setInitialized(true);
    }, []);
    return {initialized};
};
