import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { AccXExampleResult, ExampleApi, AccXTopicPrediction, AccXCategoryPrediction } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import EditForm from "../../../components/EditForm";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
    setDecreeData: React.Dispatch<React.SetStateAction<AccXTopicPrediction | AccXCategoryPrediction>>,
    reset: () => void,
    refreshData: boolean,
    resetKSeFData: any,
}

export default function ExamplePanel({setDecreeData, reset, refreshData, resetKSeFData}: Props) {

    const exampleApi = new ExampleApi();
    const {request} = useApiContext();
    const {labels, showMessage} = useAppContext();
    const {customerProfile} = useUserContext();
    

    const [examples, setExamples] = useState<AccXExampleResult[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [refreshExamples, setRefeshExamples] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        request(exampleApi.exampleAllGet()).then((response) => {
            if (response.ok && response.data) {
                setExamples(response.data);
            }
            setIsLoading(false);
        });
    }, [refreshData, refreshExamples]);

    const onExampleClick = (example: AccXExampleResult) => {
        reset();
        resetKSeFData();
        try {
            let res = JSON.parse(JSON.parse(example.example ?? ''));
            if(res.TaxId){
                setDecreeData((prevState) => ({...prevState, taxId: res.TaxId}));
            }
            if(res.ContractorName){
                setDecreeData((prevState) => ({...prevState, contractorName: res.ContractorName}));
            }
            if(res.PositionText){
                setDecreeData((prevState) => ({...prevState, positionText: res.PositionText}));
            }
            if(res.GTUCode){
                setDecreeData((prevState) => ({...prevState, gtuCode: res.GTUCode}));
            }
            if(res.PKWIU){
                setDecreeData((prevState) => ({...prevState, pkwiu: res.PKWIU}));
            }
            if(res.CN){
                setDecreeData((prevState) => ({...prevState, cn: res.CN}));
            }
            if(res.PurchasePurposeId){
                setDecreeData((prevState) => ({...prevState, purchasePurposeId: res.PurchasePurposeId}));
            }
            if(res.UnitPrice){
                setDecreeData((prevState) => ({...prevState, unitPrice: res.UnitPrice}));
            }
            if(res.HasRegNum){
                setDecreeData((prevState) => ({...prevState, hasRegNum: res.HasRegNum}));
            }
            if(res.VehicleUsagePurposeId){
                setDecreeData((prevState) => ({...prevState, vehicleUsagePurposeId: res.VehicleUsagePurposeId}));
            }
            if(res.VatRate){
                setDecreeData((prevState) => ({...prevState, vatRate: res.VatRate}));
            }

        } catch (error) {
            console.error(error);
        }
    }

    function deleteExample(key: string){
        setIsLoading(true);
        request(exampleApi.exampleKeyDelete(key)).then((response) => {
            if (response && response.ok) {
                showMessage(labels.deleteExampleLabel, MessageType.Success);
                setRefeshExamples(!refreshExamples)
            }else{
                showMessage(labels.deleteExampleErrorLabel, MessageType.Error);
            }
            setIsLoading(false);
        });
    }

    const editForm = <EditForm className='sidePanel' isLoading={isLoading}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h4 style={{width: '80%'}}>{labels.examples}</h4>
            </div>
            <Stack>
                {examples && examples.map((exampleItem, exampleItemIndex) => {
                    return <ActionButton key={exampleItemIndex} 
                        className='examplesItemsButton'
                        onClick={() => {
                            onExampleClick(exampleItem);
                        }}>
                        <div style={{width:'100%'}}><div style={{fontSize: '14px', textAlign:'left', display: 'inline-block'}}>{exampleItem.name}</div> {customerProfile?.isAdmin && <div style={{display:'inline-block', float: 'right'}}><FontAwesomeIcon icon={faTrash} onClick={() => deleteExample(exampleItem.key ?? '')} color='var(--error)' style={{marginLeft:'5px'}}></FontAwesomeIcon></div>}</div>
                    </ActionButton>
                })}
            </Stack>
        </EditForm>

    return examples && examples.length > 0 ? editForm : <></>
    
}