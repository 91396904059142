import { List, Paper, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MenuListItemButton from "../../components/MenuListItemButton";
import { pagesNames } from "../../constants/pageNavigationConstans";
import { useAppContext } from "../../contexts/AppContext";
import { useUserContext } from "../../contexts/UserContext";
import MasterLayout from "../../layouts/MasterLayout";

export default function PlatformManagemetPage() {
    
    const navigate = useNavigate();
    const {labels, isMobile} = useAppContext();
    const {customerProfile} = useUserContext();

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
        setCurrentPageName(mapUrlToPageName(window.location.pathname));
    }, [url]);

    const [currentPageName, setCurrentPageName] = useState('');
    
    function mapUrlToPageName(url: string){
        switch(url){
            case `/${pagesNames.platformManagement}/${pagesNames.attachUserToNewCustomer}`:
                return labels.attachUserToNewCustomerPageName;
            case `/${pagesNames.platformManagement}/${pagesNames.attachUserToExistingCustomer}`:
                return labels.attachUserToExistingCustomerPageName;
        }
        return '';
    }

    function mapLocalizationToPageName(to: string, parentLocation: string){
        let url = `/${parentLocation}/${to}`;
        return mapUrlToPageName(url);
    }

    return <MasterLayout pageName={currentPageName}>
        {!isMobile && <Paper style={{padding: '10px', alignSelf:'start', position:'sticky', 
            //top:'74px'
            top:'110px'
            }} >
            <Typography fontWeight='bold'>{labels.customerManagement}</Typography>
            <List>
                <MenuListItemButton to={pagesNames.attachUserToNewCustomer} parentLocation={pagesNames.platformManagement} text={mapLocalizationToPageName(pagesNames.attachUserToNewCustomer, pagesNames.platformManagement)}/>
                <MenuListItemButton to={pagesNames.attachUserToExistingCustomer} parentLocation={pagesNames.platformManagement} text={mapLocalizationToPageName(pagesNames.attachUserToExistingCustomer, pagesNames.platformManagement)}/>
            </List>
        </Paper>}
        <Outlet/>
    </MasterLayout>
}