
export const gtuCodes = [
    { text: 'GTU_01', value: 'GTU_01' },
    { text: 'GTU_02', value: 'GTU_02' },
    { text: 'GTU_03', value: 'GTU_03' },
    { text: 'GTU_04', value: 'GTU_04' },
    { text: 'GTU_05', value: 'GTU_05' },
    { text: 'GTU_06', value: 'GTU_06' },
    { text: 'GTU_07', value: 'GTU_07' },
    { text: 'GTU_08', value: 'GTU_08' },
    { text: 'GTU_09', value: 'GTU_09' },
    { text: 'GTU_10', value: 'GTU_10' },
    { text: 'GTU_11', value: 'GTU_11' },
    { text: 'GTU_12', value: 'GTU_12' },
    { text: 'GTU_13', value: 'GTU_13' },
];