import { Chip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AccXCategoryRule, CategoryRuleApi } from "../../../api";
import ActionGridButton from "../../../components/ActionGridButton";
import GridInfo from "../../../components/GridInfo";
import PredefinedTag from "../../../components/PredefinedTag";
import { MessageType } from "../../../components/snackbar/MessageType";
import { useApiContext } from "../../../contexts/ApiContext";
import { useAppContext } from "../../../contexts/AppContext";
import useFilters from "../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../models/ActionDefinition";
import AccountingRulePopup from "./popup/CategoryRulePopup";

export default function CategoriesRulesPanel() {

    const [categoryRules, setcategoryRules] = useState<AccXCategoryRule[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedCategoryRules, setSelectedCategoryRules] = useState<number[]>([]);
    const [openNewPopup, setOpenNewPopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    
    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const categoryRuleApi = new CategoryRuleApi();
    const [filtersData, addFilter] = useFilters();

    useEffect(() => {
        fetchCategoryRules();
    }, []);

    const visibleCategoryRules = useMemo(() => {
        var temp = [...categoryRules];

        if (temp.length > 0 && filtersData) {
            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(categoryRule => categoryRule.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()))
            };
        }
        
        return temp;

    }, [categoryRules, filtersData]);

    function fetchCategoryRules() {
        setIsLoadingData(true);

        request(categoryRuleApi.categoryRuleAllGet()).then((response) => {
            if(response.ok && response.data) {
                setcategoryRules(response.data);
                setIsLoadingData(false);
            }
        });
    }

    const columns = [
        {header: labels.name, field: 'general.categoryRuleName', customTemplate: (row: any) => {
            const generalSection = row['general'];

            const isPredefined = generalSection['isPredefined'];
            const ruleName = generalSection['categoryRuleName'];

            if (isPredefined) {
                return <PredefinedTag text={ruleName}></PredefinedTag>;
            }
            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditPopup(true)}>{ruleName}</a>
        }},
        {header: labels.whatRuleSets, field: 'set.categoryName', customTemplate: (row: any) => {
            const setSection = row['set'];

            const setCategory = setSection['categoryIdSpecified'];
            const setStopMessage = setSection['stopMessageSpecified'];
            const setTag = setSection['tagsSpecified'];

            function getCategoryTemplate() {
                const categoryName = setSection['categoryName'];
                return <div>{categoryName}</div>
            }

            function getTagTemplate() {
                const tag = setSection['tag'];

                return <Chip label={tag}/>
            }
            
            function getStopMessageTemplate() {
                const stopMessage = setSection['stopMessage'];

                return <div>{labels.stopMessage}: {stopMessage}</div>
            }

            return <span>
                {setCategory && getCategoryTemplate()}
                {setStopMessage && getStopMessageTemplate()}
                {setTag && getTagTemplate()}
            </span>
        }},
        {header: labels.processingOrder, field: 'general.processingOrder'},
    ]

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
            dependOnColumn: 'general.isPredefined',
            dependOnColumnNegation: true,
        },
        {
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteCategoryRule()}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'general.isPredefined',
            dependOnColumnNegation: true,
        }
    ];


    function closePopups(refresh: boolean) {
        setOpenNewPopup(false);
        setOpenEditPopup(false);

        if(refresh) {
            fetchCategoryRules();
        }
    }

    function deleteCategoryRule() {
        setIsLoading(true);

        request(categoryRuleApi.categoryRuleCategoryRuleIdDelete(selectedCategoryRules[0])).then((response) => {
            setIsLoading(false);

            if (response.ok) {
                showMessage(labels.categoryRuleDeleted);
                fetchCategoryRules();
            }
            else {
                showMessage(labels.categoryRuleDeletingError, MessageType.Error);
            }

        })
    }

    return <>
        {openNewPopup && <AccountingRulePopup onClose={closePopups} />}
        {openEditPopup && <AccountingRulePopup categoryRuleId={selectedCategoryRules[0]} onClose={closePopups} />}
        <GridInfo columns={columns} 
            data={visibleCategoryRules} 
            filtersData={filtersData}
            hasSearchBar={true} 
            addFilter={addFilter}
            actions={actions} 
            onSelect={(selected) => setSelectedCategoryRules(selected as number[])} 
            isLoading={isLoadingData}/>
    </>
}