import { useEffect, useRef, useState } from 'react';
import ContractorPanel from './contractor/ContractorPanel';
import { UserMode, useUserContext } from '../../contexts/UserContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { Stack } from '@mui/system';
import { useApiContext } from '../../contexts/ApiContext';
import SideElementLayout from '../../layouts/SideElementLayout';
import MasterLayout from '../../layouts/MasterLayout';
import { pagesNames } from '../../constants/pageNavigationConstans';
import ActionButton from '../../components/ActionButton';
import { HistoryItem } from '../../models/HistoryItem';
import CustomerDataPanel from './customerData/CustomerDataPanel';
import { AccXBCPredictionDataResult, AccXCategoryPrediction, AccXCategoryPredictionDataResult, AccXCategoryPredictionDataResultAPIDataResponse, AccXCategoryPredictionExplainElementResult, AccXCategoryPredictionResult, AccXProductPredictionResult, AccXTopicPrediction, AccXTopicPredictionDataResult, AccXTopicPredictionDataResultAPIDataResponse, AccXTopicPredictionResult, AccXTopTopicPredictionResult, CategoryPredictionApi, ExampleApi, TopicPredictionApi } from '../../api';
import { MessageType } from '../../components/snackbar/MessageType';
import PanelInfo from '../../components/PanelInfo';
import ReportPanel from './report/ReportPanel';
import DecreePanel, { DecreeMode } from './DecreePanel';
import TopicPanel from './topic/TopicPanel';
import CategoryPanel from './category/CategoryPanel';
import ProductPanel from './product/ProductPanel';
import ExplainElementPanel from './explain/ExplainElementPanel';
import NotLogInPanel from './notLogIn/NotLogInPanel';
import KSeFInvoicePanel from './ksefInvoice/KSeFInvoicePanel';
import ExamplePanel from './example/ExamplePanel';

export default function DecreePage() {

  var { isSigned, userMode, resetAllData } = useUserContext();
  var { labels, showMessage, setIsLoading } = useAppContext();
  var { request } = useApiContext();

  const topicPredictionApi = new TopicPredictionApi();
  const categoryPredictionApi = new CategoryPredictionApi();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const additionalDataPanelRef = useRef<any>(null);

  const [isContractorPanelActive, setIsContractorPanelActive] = useState(false);
  const [isTopicPanelActive, setIsTopicPanelActive] = useState(false);
  const [isProductPanelActive, setIsProductPanelActive] = useState(false);
  const [isCategoryPanelActive, setIsCategoryPanelActive] = useState(false);
  const [isExplainPanelActive, setIsExplainPanelActive] = useState(false);
  const [isReportPanelActive, setIsReportPanelActive] = useState(false);
  const [isNoResultPanelActive, setIsNoResultPanelActive] = useState(false);
  const [isNoGoodResultPanelActive, setIsNoGoodResultPanelActive] = useState(false);
  
  const initValues: AccXTopicPrediction | AccXCategoryPrediction = {
    taxId: '',
    contractorName: '',
    positionText: '',
    gtuCode: '',
    pkwiu: '',
    cn: '',
    purchasePurposeId: 0,
    unitPrice: undefined,
    hasRegNum: false,
    vehicleUsagePurposeId: -1,
    vatRate: ''
  }

  

  const [decreeData, setDecreeData] = useState(initValues);
  const [isAdditionalDataPanelOpen, setIsAdditionalDataPanelOpen] = useState(false);

  const [contractorData, setContractorData] = useState<AccXBCPredictionDataResult | null>(null);
  const [topicData, setTopicData] = useState<AccXTopicPredictionResult[] | null>(null);
  const [topTopicData, setTopTopicData] = useState<AccXTopTopicPredictionResult | null>(null);
  const [topicTags, setTopicTags] = useState<Array<string>>([]);
  const [categoryPredictionData, setCategoryPredictionData] = useState<AccXCategoryPredictionResult | null>(null);
  const [categoryPredictionLogData, setCategoryPredictionLogData] = useState<Array<string>>([]);
  const [productPredictionData, setProductPredictionData] = useState<AccXProductPredictionResult | null>(null);
  const [explainElementData, setExplainElementData] = useState<AccXCategoryPredictionExplainElementResult[] | null>(null);
  const [reportData, setReportData] = useState<any>({});

  const [resetKsefData, setResetKSeFData] = useState(false);
  function changeResetKsefData() {
    setResetKSeFData(!resetKsefData);

  } 
  const [ksefInvoiceContent, setKsefInvoiceContent] = useState('');

  const [correlationId, setCorrelationId] = useState<string>('');

  const [refreshExamples, setRefreshExamples] = useState(false);
  
  const exampleApi = new ExampleApi();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if(currentPath.startsWith(`/${pagesNames.demo}`)){
      setIsLoading(true);
      request(exampleApi.exampleRandomGet()).then((response) => {
          if (response.ok && response.data) {
            try {
              let res = JSON.parse(JSON.parse(response.data.example ?? ''));
              let additionalPanelOpen = false;
              if(res.TaxId){
                  setDecreeData((prevState) => ({...prevState, taxId: res.TaxId}));
              }
              if(res.ContractorName){
                  setDecreeData((prevState) => ({...prevState, contractorName: res.ContractorName}));
              }
              if(res.PositionText){
                  setDecreeData((prevState) => ({...prevState, positionText: res.PositionText}));
              }
              if(res.GTUCode){
                  setDecreeData((prevState) => ({...prevState, gtuCode: res.GTUCode}));
                  additionalPanelOpen = true;
              }
              if(res.PKWIU){
                  setDecreeData((prevState) => ({...prevState, pkwiu: res.PKWIU}));
                  additionalPanelOpen = true;
              }
              if(res.CN){
                  setDecreeData((prevState) => ({...prevState, cn: res.CN}));
                  additionalPanelOpen = true;
              }
              if(res.PurchasePurposeId){
                  setDecreeData((prevState) => ({...prevState, purchasePurposeId: res.PurchasePurposeId}));
                  additionalPanelOpen = true;
              }
              if(res.UnitPrice){
                  setDecreeData((prevState) => ({...prevState, unitPrice: res.UnitPrice}));
                  additionalPanelOpen = true;
              }
              if(res.HasRegNum){
                  setDecreeData((prevState) => ({...prevState, hasRegNum: res.HasRegNum}));
                  additionalPanelOpen = true;
              }
              if(res.VehicleUsagePurposeId){
                  setDecreeData((prevState) => ({...prevState, vehicleUsagePurposeId: res.VehicleUsagePurposeId}));
                  additionalPanelOpen = true;
              }
              if(res.VatRate){
                  setDecreeData((prevState) => ({...prevState, vatRate: res.VatRate}));
                  additionalPanelOpen = true;
              }
              setIsAdditionalDataPanelOpen(additionalPanelOpen);
  
          } catch (error) {
              console.error(error);
          }
          }
          setIsLoading(false);
      });
    }
  }, [])

  function prepareHistoryItem(data: any): HistoryItem {
    var vatId = data.decreeData.vatId;
    var name = data.decreeData.name;
    var position = data.decreeData.position;

    var gtuCode = data.decreeData.gtuCode;
    var hasRegnum = data.decreeData.hasRegNum;

    var historyItemName = '';

    if (vatId) {
      historyItemName += vatId;
    }

    if (name) {
      if(historyItemName) {
        historyItemName += '-';  
      }

      historyItemName += name;
    }

    if (position) {
      if(historyItemName) {
        historyItemName += '-';  
      }
      
      historyItemName += position;
    }

    if (gtuCode) {
      if(historyItemName) {
        historyItemName += '-';  
      }
      
      historyItemName += gtuCode;
    }

    if (hasRegnum) {
      if(historyItemName) {
        historyItemName += '-';  
      }
      
      historyItemName += 'nr. rej.';
    }
    
    if (historyItemName.length > 52) {
      historyItemName = historyItemName.substring(0, 52);
      historyItemName += '...';
    }

    var historyItem: HistoryItem = {
      id: data.correlationId,
      name: historyItemName,
    };

    return historyItem;
  }

  //Zmieniliśmy logikę!
  // const getCorrelationData = (correlationIdParam: string | null) => {
  //   if (correlationIdParam) {
  //     if (!isSigned) {
  //       searchParams.delete('correlationId');
  //       setSearchParams(searchParams);
  //       retur.n;
  //     }

  //     setIsLoading(true);


  //     request(decreeGet(correlationIdParam))
  //       .then((response) => {
  //         if (response.ok && response.data) {
  //           var data = response.data

  //           if (data.correlationId) {
  //             setCorrelationId(data.correlationId);
  //           }
  //           if (data.decreeData) {
  //             setDecreeData(data.decreeData);
  //           }
  //           if (data.contractorData) {
  //             setContractorData(data.contractorData);
  //             setIsContractorPanelActive(true);
  //           }
  //           if (data.topicData) {
  //             setTopicData(data.topicData);
  //             setIsTopicPanelActive(true);
  //           }
  //           if (data.reportData) {
  //             setReportData(data.reportData);
  //             setIsReportPanelActive(true);
  //           }
  //         }
  //       });  

  //     setIsLoading(false);
  //   }
  // }

  // useEffect(() => {
  //   var correlationIdParam = searchParams.get("correlationId");
  //   getCorrelationData(correlationIdParam);
  // }, [searchParams]);

  function processDecreeData(data: AccXTopicPredictionDataResult) {
    //brak kontrahenta i tematu
    if (!data.accXBCPredictionDataResult && !data.accXTopTopicPredictionResult) {
      setIsNoResultPanelActive(true);
    }
    //kontrahent
    if (data.accXBCPredictionDataResult) {
      setContractorData(data.accXBCPredictionDataResult);
      setIsContractorPanelActive(true);
    }
    else {
      setContractorData(null);
      setIsContractorPanelActive(false);
    }
    //temat
    if (data.topicPredictionResult && data.accXTopTopicPredictionResult) {
      setTopicData(data.topicPredictionResult);
      setTopTopicData(data.accXTopTopicPredictionResult);
      setIsTopicPanelActive(true);
    }
    else {
      setTopicData(null);
      setIsTopicPanelActive(false);
    }
    if(data.tags) {
      setTopicTags(data.tags);
    }

    // if (data.correlationId) {
    //   setCorrelationId(data.correlationId);

    //   if (data.topicData) {
    //     setIsReportPanelActive(true);
    //   }
    // }
    // else {
    //   setCorrelationId('');
    //   setIsReportPanelActive(false);
    // }

    // if (data.correlationId && data.topicData) {
    //   var historyItem = prepareHistoryItem(data);
    //   addToHistory(historyItem);
    // }
  }


  function processDecreeDataForCategory(data: AccXCategoryPredictionDataResult) {
    //brak tematu, produktu, kategorii lub wyjasnienia
    if (!data.topicPrediction && !data.productPrediction && (!data.explainElement || !data.categoryPrediction)) {
      setIsNoResultPanelActive(true);
    }

    //kontrahent
    if (data.topicPrediction && data.topicPrediction.accXBCPredictionDataResult) {
      setContractorData(data.topicPrediction.accXBCPredictionDataResult);
      setIsContractorPanelActive(true);
    }
    else {
      setContractorData(null);
      setIsContractorPanelActive(false);
    }

    //temat
    if (data.topicPrediction && data.topicPrediction.topicPredictionResult && data.topicPrediction.accXTopTopicPredictionResult) {
      setTopicData(data.topicPrediction.topicPredictionResult);
      setTopTopicData(data.topicPrediction.accXTopTopicPredictionResult);
      setIsTopicPanelActive(true);
    }
    else {
      setTopicData(null);
      setIsTopicPanelActive(false);
    }
    if(data.topicPrediction && data.topicPrediction.tags) {
      setTopicTags(data.topicPrediction.tags);
    }

    //produkt
    if(data.productPrediction){
      setProductPredictionData(data.productPrediction);
      setIsProductPanelActive(true);
    }

    //kategoria
    if(data.categoryPrediction){
      setCategoryPredictionData(data.categoryPrediction);
      setIsCategoryPanelActive(true);
    }
    if(data.logs){
      setCategoryPredictionLogData(data.logs);
    }

    //explain
    if(data.explainElement){
      setExplainElementData(data.explainElement);
      setIsExplainPanelActive(true);
    }
  }

  function handleDecreeResponse(response: AccXTopicPredictionDataResultAPIDataResponse) {
    setIsLoading(false);
    if (response.ok && response.data) {
      processDecreeData(response.data);
    } else{
      setIsNoResultPanelActive(true);
      showMessage(labels.noDataResultMessage, MessageType.Error);
    }
  }


  function handleDecreeCategoryResponse(response: AccXCategoryPredictionDataResultAPIDataResponse) {
    setIsLoading(false);
    if (response.ok && response.data) {
      processDecreeDataForCategory(response.data);
    } else{
      setIsNoResultPanelActive(true);
      showMessage(labels.noDataResultMessage, MessageType.Error);
    }
  }

  useEffect(() => {
    if(resetAllData){
      reset();
    }
  }, [resetAllData])

  function decree() {
    if (validateDecreeData()) {
      validateEnoughtDecreeData();
      setIsLoading(true);
      reset(false);
      
      switch(userMode) {
        default:
        case UserMode.NotLoggedIn:
          request(topicPredictionApi.topicPredictionUnauthorizedPost(decreeData)).then((response) => {
            handleDecreeResponse(response);
          })
          break;
        case UserMode.LoggedIn:
          request(categoryPredictionApi.categoryPredictionPost(decreeData)).then((response) => {
            handleDecreeCategoryResponse(response);
          })
          break;
        case UserMode.LoggedInWithBC:
          request(categoryPredictionApi.categoryPredictionPost(decreeData)).then((response) => {
            handleDecreeCategoryResponse(response);
          });
          break;
      }
    }
  }

  function validateEnoughtDecreeData() {
    var isOk = true;
    if (decreeData) {
      if ((decreeData.taxId || decreeData.contractorName) && decreeData.positionText)
      {
        isOk = false;  
      } 
    }
    setIsNoGoodResultPanelActive(isOk);
  }

  function validateDecreeData() {
    if (decreeData) {
      if (decreeData.taxId) return true;
      if (decreeData.contractorName) return true;
      if (decreeData.positionText) return true;
      if (decreeData.gtuCode) return true;
      if (decreeData.cn) return true;
      if (decreeData.pkwiu) return true;
      if (decreeData.hasRegNum) return true;
      if (decreeData.purchasePurposeId) return true;
      if (decreeData.unitPrice) return true;
      if (decreeData.vatRate) return true;
    }

    showMessage(labels.enterInvoiceDataToDecree, MessageType.Warning);
    return false;
  }
 
  function reset(resetDecreeData = true) {
    searchParams.delete('correlationId');
    setSearchParams(searchParams);

    setCorrelationId('');
    
    if(resetDecreeData) {
      setDecreeData(initValues);
    }
    
    setContractorData(null);
    setIsContractorPanelActive(false);
    
    setTopicData(null);
    setIsTopicPanelActive(false);

    setCategoryPredictionLogData([]);
    setCategoryPredictionData(null);
    setIsCategoryPanelActive(false);

    setIsExplainPanelActive(false);
    setExplainElementData(null);

    setProductPredictionData(null);
    setIsProductPanelActive(false);

    setReportData(null);
    setIsReportPanelActive(false);

    setIsNoResultPanelActive(false);

    setIsAdditionalDataPanelOpen(false);

    setKsefInvoiceContent('');
  }

  // const action = userMode == UserMode.LoggedInWithBC ?
  const aboutProjectAction = <ActionButton style={{marginLeft: '20px'}} className="actionButton"  onClick={() => navigate(`/${pagesNames.aboutProject}`)}>
      {labels.aboutProjectButtonLabel}
    </ActionButton>;
  const goToConfigutationAction = <ActionButton style={{marginLeft: '20px'}} className="actionButton"  onClick={() => navigate(`/${pagesNames.companyConfiguration}/${pagesNames.companyData}`)}>
      {labels.companyConfigurationSettingButton}
    </ActionButton>;

  const actionWithoutLogin = aboutProjectAction;

  const action = userMode === UserMode.LoggedInWithBC ?
    goToConfigutationAction
     : <></>

  const decreeMode = userMode === UserMode.NotLoggedIn
    ? DecreeMode.Topic
    : DecreeMode.Category;

  return <MasterLayout navMenuAction={action}>
    <span>
      <ExamplePanel setDecreeData={setDecreeData} reset={reset} refreshData={refreshExamples} resetKSeFData={changeResetKsefData} />
      {/* {isSigned && <HistoryPanel getFromHistory={getCorrelationData} reset={reset}/>} */}
    </span>
    <SideElementLayout sideElements={isSigned && isReportPanelActive ? <ReportPanel correlationId={correlationId} reportData={reportData}/> : null}>
      <Stack className='centerPanel'> 
        {userMode === UserMode.LoggedIn && <CustomerDataPanel style={{marginBottom: '10px'}}/>}
        <DecreePanel decreeMode={decreeMode} 
          decreeData={decreeData} 
          initValues={initValues} 
          setDecreeData={setDecreeData} 
          decree={decree} 
          reset={reset} 
          setIsAdditionalDataPanelOpenMaster={setIsAdditionalDataPanelOpen} 
          isAdditionalDataPanelOpenMaster={isAdditionalDataPanelOpen} 
          setKsefInvoiceContent={setKsefInvoiceContent} 
          setRefreshExamples={setRefreshExamples} 
          refreshExamples={refreshExamples}
          additionalDataPanelRef={additionalDataPanelRef}
          resetKsefInvoice={resetKsefData}
        />
        {isNoGoodResultPanelActive && <PanelInfo text={labels.betterDecreeResultWithFullInfo}></PanelInfo>}
        {isNoResultPanelActive && <PanelInfo text={labels.noDataResultInfo}></PanelInfo>}
        {isContractorPanelActive && contractorData && <ContractorPanel contractorData={contractorData}/>}
        {isProductPanelActive && productPredictionData && productPredictionData.product != null && <ProductPanel productPrediction={productPredictionData}/>}
        {isTopicPanelActive && topicData && topTopicData && <TopicPanel topicData={topicData} topPrediction={topTopicData} tags={topicTags}/>}
        {userMode === UserMode.NotLoggedIn && <NotLogInPanel></NotLogInPanel>}
        {isCategoryPanelActive && categoryPredictionData && categoryPredictionData.category != null && <CategoryPanel categoryPrediction={categoryPredictionData} logs={categoryPredictionLogData} topicPredictionCertaintyLevel={topTopicData?.certaintyLevel}/>}
        {isExplainPanelActive && explainElementData && <ExplainElementPanel 
          explainElements={explainElementData} 
          setIsAdditionalDataPanelOpen={setIsAdditionalDataPanelOpen}
          additionalDataPanelRef={additionalDataPanelRef}
        />}
        {ksefInvoiceContent && <KSeFInvoicePanel invoiceContent={ksefInvoiceContent}/>}
      </Stack>
    </SideElementLayout>
  </MasterLayout>
}
