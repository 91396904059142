import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, CircularProgress, Divider, Grid, Icon, Paper, TextField, Typography } from '@mui/material';
import EditForm from '../../../components/EditForm';
import { faChevronDown, faCircle, faCircleCheck, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gtuCodes } from '../../../dictionaries/gtuCodes';
import { useApiContext } from '../../../contexts/ApiContext';
import SideElementLayout from '../../../layouts/SideElementLayout';
import ActionButton from '../../../components/ActionButton';
import { AccXProductPrediction, AccXProductPredictionResult, PredictProductApi, ProductApi, AccXProductPredictionTrain } from '../../../api';
import DropdownField from '../../../components/fields/DropdownField';
import { useAppContext } from '../../../contexts/AppContext';
import AccXTextField from '../../../components/fields/AccXTextField';
import { useUserContext } from '../../../contexts/UserContext';
import MarketingInfo from '../../../components/MarketingInfo';
import { MessageType } from '../../../components/snackbar/MessageType';
import { Box } from '@mui/system';

export default function ProductMappingPanel(){

    const initValues: AccXProductPrediction = {
        productName: '',
        productIndex: '',
        gtu: '',
        vatRate: '',
        unit: '',
        pkWiUCode: '',
        cnCode: '',
        gtinCode: '',
    };

    const initValuesInternalReport: AccXProductPredictionTrain = {
        productId: 0,
        productName: '',
    };

    const {labels, setIsLoading, showMessage} = useAppContext();
    const {request} = useApiContext();
    const {customerProfile} = useUserContext();
    const productApi = new ProductApi();
    const predictProductApi = new PredictProductApi();

    const [productPredictionData, setProductPredictionData] = useState<AccXProductPrediction>(initValues);
    const [productPredictionResult, setProductPredictionResult] = useState<AccXProductPredictionResult[]>([]);


    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
    const [reportData, setReportData] = useState<AccXProductPredictionTrain>(initValuesInternalReport);
    const [products, setProducts] = useState<any[]>([]);
    const [isAlreadyReported, setIsAlreadyReported] = useState(false);
    const [isAlreadyReportedPositive, setIsAlreadyReportedPositive] = useState(false);
    const [isAlreadyReportedNegative, setIsAlreadyReportedNegative] = useState(false);
    const [isLoadingReportPanelData, setIsLoadingReportPanelData] = useState(false);

    function gtuCodeChange(id: string, value: any) {
        setProductPredictionData({...productPredictionData,  gtu: value });
    }

    function reportProductIdChange(e: React.ChangeEvent<{}>, newValue: any | null) {
        setReportData({...reportData, productId: newValue?.productId});
    }

    function textChange(id: string, value: string) {
        setProductPredictionData({...productPredictionData, [id]: value});
    }

    useEffect(() => {
        fetchProducts();
    }, [isAlreadyReportedNegative]);

    const fetchProducts = () => {
        setIsLoadingReportPanelData(true);

        request(productApi.productAllGet()).then((response) => {
            if (response.ok && response.data) {
                setProducts(response.data);
                setIsLoadingReportPanelData(false);
            }
        });
    };

    const onRedClicked = () => {
        setIsAlreadyReportedNegative(true);
    }
    
    const onGreenClicked = () => {
        setIsAlreadyReportedPositive(true);
    }

    const handleSubmitReport = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoadingReportPanelData(true);
        request(predictProductApi.predictProductTrainPost(reportData)).then((response) => {
            if (response.ok) {
                showMessage(labels.trainProductPredictionSuccess);
            }
            else {
                showMessage(labels.trainProcuctPredictionError, MessageType.Error);
            }
            setIsLoadingReportPanelData(false);
            setIsAlreadyReported(true);
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetPredictionAndReportData();
        setIsLoading(true);
        request(predictProductApi.predictProductPredictPost(productPredictionData)).then((response) => {
            if (response.ok && response.data) {
                setProductPredictionResult(response.data);
                setReportData({...reportData, productName: productPredictionData?.productName});
                // let currentDate = new Date();
                // let monthString = (currentDate.getMonth()+1).toString().length == 2 ? (currentDate.getMonth()+1).toString() : '0'+(currentDate.getMonth()+1).toString() 
                // let dayString = (currentDate.getDate()).toString().length == 2 ? (currentDate.getDate()).toString() : '0'+(currentDate.getDate()).toString() 
                // let dateAsString = `${currentDate.getFullYear()}-${monthString}-${dayString} ${currentDate.getHours()}:${currentDate.getMinutes()}`;
                // setReportData({...reportData, executionTime: dateAsString});
            }
            setIsAlreadySubmitted(true);
            setIsLoading(false);
        });
    }

    const reset = (resetData = true) => {
        if(resetData) {
            setProductPredictionData(initValues);
            setProductPredictionResult([]);
            resetPredictionAndReportData();
        }
    }

    const resetPredictionAndReportData = () => {
        setIsAlreadyReported(false);
        setIsAlreadyReportedNegative(false);
        setIsAlreadyReportedPositive(false);
        setProductPredictionResult([]);
        setReportData(initValuesInternalReport);
        setIsAlreadySubmitted(false);
    };

    var showIDX = customerProfile && customerProfile?.hasIDX;

    const loader = <Box style={{margin:'5px', textAlign: 'center'}}>
        <CircularProgress style={{color: 'var(--details)',width: '25px', height: '25px'}}/>
    </Box>

    const ReportProductMapping = showIDX && isAlreadySubmitted && productPredictionResult && productPredictionResult.length > 0 ? <EditForm className='sidePanel reportPanel'>
    {/* <p style={{fontSize: '13px', color: '#42414d'}}> {reportData?.executionTime}</p> */}
    {!isAlreadyReportedPositive && !isAlreadyReportedNegative && <h4 style={{marginBottom: '20px'}}>{labels.rateUs}</h4>}
    {!isAlreadyReportedPositive && !isAlreadyReportedNegative && <Grid container spacing={2}>
        <Grid item xs={6}>
            <ActionButton className='bg-red'
                style={{float: 'right'}}
                type='submit' 
                onClick={onRedClicked}>
                {labels.report}
            </ActionButton>
        </Grid>
        <Grid item xs={6}>
            <ActionButton type='submit' className='confirmationButton' onClick={onGreenClicked}>
                {labels.likeIt}
            </ActionButton>
        </Grid>
    </Grid>}
    {isAlreadyReportedPositive && <div style={{marginBottom: '20px', fontSize: '16px'}}>
            <h5>
                <span style={{marginRight: '10px'}}>
                    <FontAwesomeIcon icon={faCircleCheck} className='green'/>
                </span>
                {labels.positiveRate}
            </h5>
            <p>{labels.thanks}</p>
        </div>}
    {isAlreadyReportedNegative && <div style={{marginBottom: '20px', fontSize: '16px'}}>
        <h5>
            <span style={{marginRight: '10px'}}>
                <FontAwesomeIcon icon={faCircleMinus} className='red'/>
            </span>
            {labels.negativeRate}
        </h5>
    </div> }
    {isAlreadyReportedNegative && isAlreadyReported && <Grid item xs={12}>
                {labels.thanks}
            </Grid>}
    {isAlreadyReportedNegative && !isAlreadyReported && isLoadingReportPanelData && loader}
    {isAlreadyReportedNegative && !isAlreadyReported && !isLoadingReportPanelData && <form onSubmit={handleSubmitReport} style={{marginTop: '10px'}}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} label={labels.selectProduct}/>}
                    value={products.find((option: any) => option.id === reportData.productId)}
                    onChange={reportProductIdChange}
                    options={products}
                    getOptionLabel={(option) => option.productName}
                    />
            </Grid>
            <Grid item xs={12}>
                <ActionButton type='submit' style={{float: 'right'}}>
                    {labels.sendFeedback}
                </ActionButton>           
            </Grid>
        </Grid>
    </form>}
    </EditForm> : <></>

    const ProductMappingEditForm = <EditForm>
    <h4>{labels.enterTestData}</h4>
    <Divider style={{marginBottom: '20px'}}></Divider>
    <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <AccXTextField id='productName'
                        value={productPredictionData.productName}
                        label={labels.productName}
                        onChange={textChange}/>
            </Grid>
            <Grid item xs={4}>
            <AccXTextField id='productIndex'
                        value={productPredictionData.productIndex}
                        label={labels.sellerIndex}
                        onChange={textChange}/>
            </Grid>
            <Grid item xs={4}/>
            <Grid item xs={12}>
            <Accordion>
                <AccordionSummary 
                expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx ={{"&.Mui-expanded": {
                    //borderBottom: "1px solid #48b9a3",
                }}}
                >
                <Typography>{labels.additionalDataPanel} </Typography>
                </AccordionSummary>
                <AccordionDetails style={{marginTop: '10px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                        <DropdownField id='gtuCode'
                            label={labels.gtuCode}
                            items={gtuCodes}
                            value={productPredictionData.gtu}
                            defaultItemValue=''
                            onChange={gtuCodeChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <AccXTextField id='vatRate'
                                value={productPredictionData.vatRate}
                                label={labels.vatRate}
                                onChange={textChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <AccXTextField id='unit'
                                value={productPredictionData.unit}
                                label={labels.unit}
                                onChange={textChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <AccXTextField id='cnCode'
                                value={productPredictionData.cnCode}
                                label={labels.cnCode}
                                onChange={textChange}/>
                        </Grid>
                        <Grid item xs={4}>
                            <AccXTextField id='pkwiuCode'
                                value={productPredictionData.pkWiUCode}
                                label={labels.pkwiuCode}
                                onChange={textChange}/>
                        </Grid>
                        <Grid item xs={4}>
                        <AccXTextField id='gtinCode'
                                value={productPredictionData.gtinCode}
                                label={labels.gtinCode}
                                onChange={textChange}/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </Grid>
            <Grid item xs={12}>
                <ActionButton type='submit' 
                    style={{float: 'right'}}>
                    {labels.test}
                </ActionButton>
                <ActionButton onClick={reset} className="resetButton">
                    {labels.clean}
                </ActionButton>
            </Grid>
            
        </Grid>
    </form>


    </EditForm>

    const MappingMarketingInfo = <MarketingInfo text={labels.marketingIDXText}></MarketingInfo>

return <SideElementLayout sideElements={ReportProductMapping}>
    {!showIDX && MappingMarketingInfo}
    {showIDX && ProductMappingEditForm}
    {showIDX && isAlreadySubmitted && productPredictionResult?.length === 0 && <Paper style={{marginTop: '10px', padding: '20px'}}>{labels.noResult}</Paper>}
    {showIDX && productPredictionResult && productPredictionResult?.length > 0 && <Accordion style={{marginTop: '10px'}}>
                    <AccordionSummary 
                            expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor: 'rgb(252,252,252)'}}>
                        <div>
                            <Typography> {labels.bestPrediction}</Typography>
                            {productPredictionResult.map((prediction: any, productIndex: number) => {
                                {return productIndex == 0 ? <Typography key={productIndex} fontWeight='bold'>{prediction.product.productName}</Typography> : <Typography key={productIndex}></Typography>}
                            })}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {productPredictionResult.map((prediction: any, predictionIndex: number) => {
                            var fixedIndex = predictionIndex + 1;
                                return <Accordion key={predictionIndex}>
                                <AccordionSummary 
                                    expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography>{`${fixedIndex}. ${prediction.product.productName}`}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {prediction.confidence.toFixed(2)} 
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {prediction && prediction.why && prediction.why.map((reason: any, reasonIndex: number) => {
                                        return <Typography key={reasonIndex} style={{fontSize:'12px'}}>{reason}</Typography>
                                    })}
                                </AccordionDetails>
                                </Accordion>
                        })}
                    </AccordionDetails>
            </Accordion>}
</SideElementLayout>    
}