import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AccXBusinessClassificationResult, AccXCustomerProfile } from "../../../api";
import ActionButton from "../../../components/ActionButton";
import { useApiContext } from "../../../contexts/ApiContext";
import CustomerDataPopup from "./CustomerDataPopup";
import AccXTextField from "../../../components/fields/AccXTextField";
import { useUserContext } from "../../../contexts/UserContext";
import CheckboxField from "../../../components/fields/CheckboxField";
import { useAppContext } from "../../../contexts/AppContext";

type Props = {
    style?: any
}

export default function CustomerDataPanel({style} : Props) {
    
    const {labels} = useAppContext();

    const initValues: AccXCustomerProfile = {
        companyName: '',
        companyTaxId: '',
        businessClassificationId: 0,
    }

    const [businessClassifications, setBusinessClassifications] = useState<AccXBusinessClassificationResult[]>();
    const [isLoadingBusinessClassifications, setIsLoadingBusinessClassifications] = useState(true);

    const [isCustomerDataPopupVisible, setIsCustomerDataPopupVisible] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const {fetchBusinessClassifications} = useApiContext();

    const { customerProfile, refreshCustomerProfile } = useUserContext();

    useEffect(() => {
        refreshCustomerProfile();

        fetchBusinessClassifications().then((response) => {
            setBusinessClassifications(response);
            setIsLoadingBusinessClassifications(false);
        });
    }, [])

    useEffect(() => {
        var hasAll = Boolean(customerProfile 
            && (customerProfile.companyName != null && customerProfile.companyName !== '')
            && (customerProfile.companyTaxId != null && customerProfile.companyTaxId !== '')
            && (customerProfile.businessClassificationId != null && customerProfile.businessClassificationId !== 0));

        setIsPanelOpen(!hasAll);

    }, [customerProfile]);

    function handleChange(event: React.SyntheticEvent, expanded: boolean) {
        setIsPanelOpen(expanded);
    } 

    function onPopupClose(refreshCustomerData?: boolean) {
        if (refreshCustomerData) {
            refreshCustomerProfile();
        }

        setIsCustomerDataPopupVisible(false);
    }

    function getBusinessClassificationName() : string {
        if (businessClassifications) {
            var name = businessClassifications.find(x => x.entityId === customerProfile?.businessClassificationId)?.businessClassificationName;

            return name ?? '';
        }

        return '';
    }

    return <Box style={style}>
        {isCustomerDataPopupVisible && <CustomerDataPopup onClose={onPopupClose}/>}
        <Accordion expanded={isPanelOpen} onChange={handleChange}>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}>
                {isPanelOpen 
                    ? <Typography variant='subtitle1'>{labels.myCompany}</Typography>
                    : <Typography variant='subtitle1'>{labels.myCompany} <b>{customerProfile?.companyName}</b></Typography>
                }
                </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AccXTextField id='companyName' 
                            value={customerProfile?.companyName} 
                            label={labels.companyName} 
                            disabled/>
                    </Grid>
                    <Grid item xs={6}>
                        <AccXTextField id='companyTaxId' 
                            value={customerProfile?.companyTaxId} 
                            label={labels.companyTaxId} 
                            disabled/>
                    </Grid>
                    <Grid item xs={6}>
                        <AccXTextField id='businessClassificationId' 
                            value={getBusinessClassificationName()}
                            label={labels.businessClassification}
                            disabled/>
                    </Grid>
                    <Grid item xs={6}>
                        <CheckboxField 
                            isSwitch 
                            label={labels.hasWarehouse} 
                            checked={customerProfile?.hasWarehouse} 
                            disabled/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionButton style={{float: 'right'}} onClick={() => setIsCustomerDataPopupVisible(true)}>
                            {labels.set}
                        </ActionButton>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </Box> 
}