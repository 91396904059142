import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXProduct, AccXProductAlternative, ProductApi } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import AccXTextField from "../../../../components/fields/AccXTextField";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";

type Props = {
    productId?: number,
    onClose: (refresh: boolean) => void,
}


export default function ProductAlternativeNamePopup({productId, onClose} : Props) {

    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const productApi = new ProductApi();

    const initValues: AccXProductAlternative = {
        productId: 0,
        alternativeName: '',
    }

    const initValuesProduct: AccXProduct= {
        productId: 0,
        productIndex: '',
        productName: '',
        sellerIndex: '',
        productGroupName: '0',
        productTags: [],
        invoiceTopicId: undefined,
        notes: '',
        unit: '',
        vatRate: '',
        gtu: '0',
        pkWiUCode: '',
        cnCode: '',
        gtinCode: '',
        additionalData: '',
    };

    const [productAlternativeData, setProductAlternativeData] = useState(initValues);
    const [productData, setProductData] = useState(initValuesProduct);

    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        setIsLoadingData(true);
        request(productApi.productProductIdGet(productId ?? 0)).then((response) => {
                if (response.ok && response.data) {
                    setProductData(response.data);
                    setProductAlternativeData({...productAlternativeData, productId: productId ?? 0, alternativeName: ''});
                }
            setIsLoadingData(false);
        });
    }, []);


    
    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setIsLoading(true);

        request(productApi.productAlternativePost(productAlternativeData)).then((response) => {
            setIsLoading(false);

            if (response.ok) {
                onClose(true);
                showMessage(labels.addAlternativeProductNameSuccess);
            }
            else {
                showMessage(labels.addAlternativeProductNameError, MessageType.Error);
            }
        })
    }

    function textChange(id: string, value: string) {
        setProductAlternativeData({...productAlternativeData, [id]: value})
    }

    var popupTitle =  <span>{labels.addAlternativeProductNamePopupTitle} <b>{productData?.productName ?? ''}</b></span>;

    return <Popup title={popupTitle} onClose={onClose} width={'800px'} isLoading={isLoadingData}>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AccXTextField id='alternativeName' 
                    value={productAlternativeData.alternativeName}
                    onChange={textChange} 
                    label={labels.productAlternativeName} 
                    required/>
            </Grid>
            <Grid item xs={12}>
                <ActionButton type='submit' style={{float: 'right'}} onClick={() => {}}>
                    {labels.save}
                </ActionButton>
            </Grid>
        </Grid>
    </form>
    </Popup>
}