import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Paper, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faFilePen, faQuestion, faStar } from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/system';
import { AccXCategoryPredictionExplainElementResult } from '../../../api';
import PredefinedTag from '../../../components/PredefinedTag';
import LinkField from '../../../components/fields/LinkField';
import { useNavigate } from 'react-router-dom';
import { pagesNames } from '../../../constants/pageNavigationConstans';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    explainElements: AccXCategoryPredictionExplainElementResult[],
    setIsAdditionalDataPanelOpen: (open: boolean) => void,
    additionalDataPanelRef?: any,
}

export default function ExplainElementPanel({explainElements, setIsAdditionalDataPanelOpen, additionalDataPanelRef} : Props) {

    const navigate = useNavigate();
    const {labels, isMobile} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
            <Stack>
                <Typography>{<FontAwesomeIcon icon={faFilePen}/>} {labels.exclamationElementPanelTitle}</Typography>
            </Stack>
        </AccordionSummary>
        <AccordionDetails>
            {explainElements && explainElements.length > 0 && 
                    <Paper
                        id="panel2a-header">
                        <Grid container spacing={2}>
                            <Grid item xs={6} style={{fontWeight: 'bold', marginLeft:'16px', padding: '5px 16px'}}>
                                {labels.potentialCategoryNameExplainElementTitle}
                            </Grid>
                            <Grid item xs={6} style={{fontWeight: 'bold', marginLeft:'-24px', padding: '5px 16px'}}>
                                {labels.ruleNameExplainElementTitle}
                            </Grid>
                        </Grid>
                    </Paper>}
            {explainElements && explainElements.map((explainElement: AccXCategoryPredictionExplainElementResult, explainIndex: number) => {
                var fixedIndex = explainIndex + 1;
                return <Accordion key={explainIndex}>
                    <AccordionSummary
                        expandIcon={!isMobile && <FontAwesomeIcon icon={faChevronDown}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Grid container spacing={2}>
                            <Grid item xs={6} >
                                {`${fixedIndex}. ${explainElement.potentialCategory?.categoryName}`} 
                            </Grid>
                            <Grid item xs={6}>
                            {explainElement.businnessClassification != null && <Typography>
                           {labels.businessClassificationExplain} <span className='textImportant'>{explainElement.businnessClassification}</span>
                            </Typography>}
                        {explainElement.purchasePurpose != null && <Typography>
                            {labels.purchaseTargetExplain} <span className='textImportant'>{explainElement.purchasePurpose}</span>
                            </Typography>}
                        {explainElement.vehicleUsagePurpose != null && <Typography>
                            {labels.vehiclePurposeExplain} <span className='textImportant'>{explainElement.vehicleUsagePurpose}</span>
                            </Typography>}
                        {explainElement.topicName != null && <Typography>
                            {labels.topicExplain}<span className='textImportant'>{explainElement.topicName}</span> {labels.topicGroupExplain} <span className='textImportant'>{explainElement.topicGroupName}</span>
                            </Typography>}
                        {explainElement.ifUnitPriceGE != null && <Typography>
                            {labels.unitPriceGTExplain} <span className='textImportant'>{explainElement.ifUnitPriceGE}</span>
                            </Typography>}
                        {explainElement.ifUnitPriceLT != null && <Typography>
                            {labels.unitPriceLTExplain} <span className='textImportant'>{explainElement.ifUnitPriceLT}</span>
                            </Typography>}
                        {explainElement.ifOnBoardingParamRule1 != null && explainElement.ifOnBoardingParamRule1.onboardingParamKey != null && <Typography>
                            {labels.onBoardingExplain} <span className='textImportant'>{explainElement.ifOnBoardingParamRule1.onboardingParamKey}</span>
                            </Typography>}
                        {explainElement.ifOnBoardingParamRule2 != null && explainElement.ifOnBoardingParamRule2.onboardingParamKey != null &&<Typography>
                            {labels.onBoardingExplain} <span className='textImportant'>{explainElement.ifOnBoardingParamRule2.onboardingParamKey}</span>
                            </Typography>}
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    {!explainElement.ifMatch && <AccordionDetails>
                        <Grid container spacing={2}>
                            {explainElement.ruleName != null && explainElement.ruleName != '' ? <Grid item xs={6}>
                                {labels.ruleNameExplain}
                                {isMobile ? explainElement.ruleName
                                    : <LinkField onClick={() => navigate(`/${pagesNames.companyConfiguration}/${pagesNames.accountingRules}?searchText=${explainElement.ruleName}`)}>{explainElement.ruleName}</LinkField> 
                                } 
                                {explainElement.isPredefined && <PredefinedTag></PredefinedTag>}
                            </Grid> : <Grid item xs={6}></Grid>}
                            <Grid item xs={6}>
                                {labels.tryAgainAdding} {<LinkField onClick={() => {
                                    if(additionalDataPanelRef) {
                                        additionalDataPanelRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                                    }
                                    setIsAdditionalDataPanelOpen(true);
                                }}>{labels.additionalDataLabel}</LinkField>}
                            </Grid>
                        </Grid>
                        </AccordionDetails>}
                </Accordion>
            })}
        </AccordionDetails>
    </Accordion>
}