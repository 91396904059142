import { Chip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AccXOnboardingTopicGroupResult, AccXTopicResult, OnboardingApi } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import ActionGridButton from "../../../../components/ActionGridButton";
import GridInfo from "../../../../components/GridInfo";
import PredefinedTag from "../../../../components/PredefinedTag";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import useFilters from "../../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../../models/ActionDefinition";
import AccountingPolicyTopicGroupPopup from "./AccountingPolicyTopicGroupPopup";

export default function AccountingPolicyTopicGroups() {

    const [topicGroups, setTopicGroups] = useState<AccXOnboardingTopicGroupResult[]>([]);
    const [topics, setTopics] = useState<AccXTopicResult[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedTopicGroups, setSelectedTopicGroups] = useState<string[]>([]);
    const [openNewPopup, setOpenNewPopup] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);

    const {request, fetchTopics} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const [filtersData, addFilter] = useFilters();
    const onboardingApi = new OnboardingApi();

    useEffect(() => {
        fetchTopicGroups();

        fetchTopics().then((response) => {
            setTopics(response);
        })
    }, []);

    function fetchTopicGroups() {
        setIsLoadingData(true);

        request(onboardingApi.onboardingTopicGroupsGet()).then((response) => {
            if (response.ok && response.data) {
                setTopicGroups(response.data);
                setIsLoadingData(false);
            }
        })
    }

    const visibleOnboardingGroups = useMemo(() => {

        var temp = [...topicGroups];

        if (temp.length > 0 && filtersData) {

            if (filtersData.searchText && filtersData.searchText !== '') {
                temp = temp.filter(parameter => parameter.searchText?.toLowerCase().includes(filtersData.searchText.toLowerCase()));
            };
        }
        
        return temp;

    }, [topicGroups, filtersData]);

    var columns = [
        {header: labels.name, field: 'topicGroupName', customTemplate: (row: any) => {

            const isPredefined = row['isPredefined'];
            const topicGroupName = row['topicGroupName'];

            var element = <span>{topicGroupName}</span>; 
            
            if (isPredefined) {
                element = <PredefinedTag text={topicGroupName}></PredefinedTag>
            }

            return <a style={{textDecoration: 'underline'}} onClick={() => setOpenEditPopup(true)}>{element}</a>
        }},
        {header: labels.key, field: 'topicGroupKey'},
        {header: labels.topics, field: 'topicIds', customTemplate: (row: any) => {

            const topicIds = row['topicIds'];
            const topicNames: string[] = [];

            (topicIds as number[]).forEach((x) => {
                const topic = topics.find((y) => y.entityId === x);

                if (topic) {
                    topicNames.push(topic?.topicName ?? '');
                }
            });

            return <>
                {topicNames.map((topicName, index) => {
                    return <Chip style={{margin: '1px'}} key={index} label={topicName}/>
                })}
            </>
        }},
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.SelectionIndependent,
            definition: <ActionGridButton onClick={() => setOpenNewPopup(true)}>
                {labels.add}
            </ActionGridButton>
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => setOpenEditPopup(true)}>
                {labels.edit}
            </ActionGridButton>,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteTopicGroup()}>
                {labels.delete}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
            dependOnColumnNegation: true,
        },
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteTopicGroup()}>
                {labels.setDefault}
            </ActionGridButton>,
            dependOnColumn: 'isPredefined',
        },
    ];

    function deleteTopicGroup() {

        setIsLoading(true);

        const topicGroupKey = selectedTopicGroups[0];

        request(onboardingApi.onboardingTopicGroupsTopicGroupKeyDelete(topicGroupKey)).then((response) => {
            setIsLoading(false);

            if (response.ok) {
                fetchTopicGroups();
                showMessage(labels.topicGroupDeleted);
            }
            else {
                showMessage(labels.topicGroupDeletingError, MessageType.Error);
            }
        });
    }

    function closePopups(refresh: boolean) {
        setOpenNewPopup(false);
        setOpenEditPopup(false);

        if(refresh) {
            fetchTopicGroups();
        }
    }

    return <>
        {openNewPopup && <AccountingPolicyTopicGroupPopup allData={topicGroups} onClose={closePopups}/>}
        {openEditPopup && <AccountingPolicyTopicGroupPopup topicGroupKey={selectedTopicGroups[0]} onClose={closePopups}/>}
        <GridInfo columns={columns} 
            data={visibleOnboardingGroups} 
            hasSearchBar={true}
            filtersData={filtersData}
            addFilter={addFilter}
            actions={actions} 
            onSelect={(selected) => setSelectedTopicGroups(selected as string[])}
            isLoading={isLoadingData}
            />
    </>
}