export const deTranslations : ITranslations = {
    aboutProjectButtonLabel: 'Über das Projekt',
    accountForCategoryLabel: 'Kontobuchhaltung',
    accountingCategories: 'Buchhaltungskategorien',
    accountingPolicy: 'Buchhaltungsrichtlinie',
    accountingRules: 'Buchhaltungsregeln',
    add: 'Hinzufügen',
    addAlternativeProductNameAction: 'Alternative Produktname hinzufügen',
    addAlternativeProductNameError: 'Fehler beim Hinzufügen des alternativen Produktnamens aufgetreten',
    addAlternativeProductNamePopupTitle: 'Alternative Produktname hinzufügen',
    addAlternativeProductNameSuccess: 'Alternativer Produktname wurde hinzugefügt',
    addCategoryLabel: 'Neue Kategorie hinzugefügt',
    addCategoryRule: 'Buchhaltungsregel hinzufügen',
    addExampleErrorLabel: 'Fehler beim Hinzufügen des Beispiels aufgetreten',
    addExampleLabel: 'Neues Beispiel hinzugefügt',
    additionalData: 'Zusätzliche Daten',
    additionalDataLabel: 'Zusätzliche Daten',
    additionalDataPanel: 'Zusätzliche Daten',
    additionalInfoContains: 'Zusätzliche Informationen enthalten',
    additionalInfoNotContains: 'Zusätzliche Informationen nicht enthalten',
    additionalInformation: 'Zusätzliche Informationen',
    addNewDecree: 'Neuer Erlass',
    addPurchasePurposeLabel: 'Neuer Einkaufszweck hinzugefügt',
    addTag: 'Tag hinzufügen',
    addVatRegisterLabel: 'Neues MwSt-Register hinzugefügt',
    admin: 'Admin',
    all: 'Alle',
    alternativeNames: 'Alternative Namen',
    alternativeProductName: 'Alternativer Produktname',
    api: 'API',
    apiDocumentation: 'API-Dokumentation',
    apiDocumentationMarketing: 'Für den Zugriff auf die API-Dokumentation kontaktieren Sie uns bitte unter:',
    appErrorOccurred: 'Ein App-Fehler ist aufgetreten',
    appName: 'AccountantX',
    attachUserToExistingCustomerAction: 'Anhängen',
    attachUserToExistingCustomerPageName: 'Benutzer an vorhandenes Unternehmen anhängen',
    attachUserToNewCustomerAction: 'Erstellen',
    attachUserToNewCustomerPageName: 'Aktivierung des "Sandkastens" für Kunden',
    bcPrediction: 'Branchenprognose',
    BCPredictionLoading: 'Firmendaten können derzeit nicht gespeichert werden - Branchenprognose läuft',
    bestPrediction: 'Am besten passendes Produkt',
    betterDecreeResultWithFullInfo: 'Um die Genauigkeit der Prognose zu erhöhen, geben Sie bitte den Inhalt des Artikels sowie den Namen oder die USt-ID des Verkäufers ein',
    bool: 'Ja / Nein',
    businessClassification: 'Geschäftsklassifikation',
    businessClassificationExplain: 'Wenn folgende Geschäftsklassifikation gewählt wäre:',
    buyerRuleInfo: 'Die Regel betrifft den Käufer, dessen',
    cantEditCategory: 'Vordefinierte Kategorie kann nicht bearbeitet werden',
    category: 'Kategorie AX',
    categoryAccount: 'Kontobuchhaltung',
    categoryAccountNonDeductible: 'Nicht abziehbares Konto',
    categoryCostDeduction: 'Kostenabzug',
    categoryDeletingSuccess: 'Kategorie wurde gelöscht',
    categoryName: 'Name',
    categoryPanelTitle: 'Kontokategorie',
    categoryRuleAdded: 'Regel wurde hinzugefügt',
    categoryRuleAddError: 'Fehler beim Hinzufügen der Buchhaltungsregel aufgetreten',
    categoryRuleChanged: 'Änderungen an der Regel wurden gespeichert',
    categoryRuleChangeError: 'Fehler beim Ändern der Buchhaltungsregel aufgetreten',
    categoryRuleDeleted: 'Regel wurde gelöscht',
    categoryRuleDeletingError: 'Fehler beim Löschen der Regel aufgetreten',
    categoryRuleHaveToSetSomething: 'Die Regel muss etwas festlegen',
    categoryRuleNameRequired: 'Regelname ist erforderlich',
    categoryRuleProcessingOrderInfo: 'Je niedriger der Wert, desto früher wird die Regel ausgeführt',
    categoryVatDeduction: 'MwSt-Abzüge',
    categoryVatRegister: 'MwSt-Register',
    change: 'Ändern',
    clean: 'Löschen',
    closeActionConfirmationPopupTitle: 'Abbrechen',
    cnCode: 'CN-Code',
    companyConfiguration: 'Unternehmenskonfiguration',
    companyConfigurationSettingButton: 'Unternehmenskonfiguration',
    companyData: 'Unternehmensdaten',
    companyName: 'Firmenname',
    companyName_optional: 'Firmenname',
    companyTaxId: 'USt-ID',
    conditionsBuyer: 'Bedingungen - Käufer',
    conditionsMaterialsAndGoods: 'Bedingungen - Materialien/Waren',
    conditionsParameters: 'Bedingungen - Parameter',
    conditionsPositions: 'Bedingungen - Positionen',
    conditionsPurposes: 'Bedingungen - Zwecke des Kaufs/Fahrzeuge/Sonstiges',
    conditionsSeller: 'Bedingungen - Verkäufer',
    conditionsTopics: 'Bedingungen - Themen',
    confirmActionConfirmationPopupTitle: 'Speichern',
    confirmationPopupText: 'Die Änderung der Branche kann dazu führen, dass andere mit der Branche verbundene Werte im Abschnitt Buchhaltungsrichtlinie festgelegt werden',
    confirmationPopupTitle: 'Achtung, die Branche wurde geändert',
    contactUsToDeleteAccount: 'Um das Konto zu löschen, kontaktieren Sie uns bitte unter',
    contains: 'Enthält',
    contractorPanelTitle: 'Kundendaten',
    costDeduction_100: '100% KUP',
    costDeduction_20: '20% KUP',
    costDeduction_75: '75% KUP',
    costDeduction_NKUP: 'NKUP',
    costDeduction_noInfo: 'Keine Informationen',
    costDeduction_ToClarify: 'Zu klären',
    costDeductionLabel: 'Kostenabzug',
    customerManagement: 'Kundenverwaltung',
    decree: 'Erlass',
    decreePageButtonLabel: 'Erlass',
    defaultValue: 'Standardwert',
    delete: 'Löschen',
    deleteAlternativeProductNameAction: 'Alternativen Produktnamen löschen',
    deleteAlternativeProductNamePopupTitle: 'Alternativen Produktnamen löschen',
    deleteExampleErrorLabel: 'Beim Löschen des Beispiels ist ein Fehler aufgetreten',
    deleteExampleLabel: 'Beispiel gelöscht',
    deletingError: 'Beim Löschen ist ein Fehler aufgetreten',
    edit: 'Bearbeiten',
    editCategory: 'Kategorie bearbeiten',
    editCategoryLabel: 'Kategorie bearbeitet',
    editCategoryRule: 'Buchungsregel bearbeiten',
    editParameter: 'Parameter bearbeiten',
    editProduct: 'Produkt bearbeiten',
    editPurchasePurposeLabel: 'Kaufzweck geändert',
    editPurchaseTarget: 'Kaufziel bearbeiten',
    editTopicGroup: 'Themengruppe bearbeiten',
    editVatRegister: 'Umsatzsteuerregister bearbeiten',
    editVatRegisterLabel: 'Umsatzsteuerregister geändert',
    email: 'Email',
    empty: 'Leer',
    endConfigurationButtonLabel: 'Konfiguration abschließen',
    enterBCDetails: 'Geben Sie die Details des Auftragnehmers ein',
    enterCompanyDetails: 'Firmendaten eingeben',
    enterInvoiceDataToDecree: 'Geben Sie die Rechnungsdaten zur Dekretierung ein',
    enterInvoiceDetails: 'Rechnungsdetails eingeben',
    enterTestData: 'Waren und Materialien testen',
    enterUserDataToAttachExistingCustomer: 'Benutzerkonto mit Unternehmen verknüpfen',
    enterUserDataToAttachNewCustomer: 'Benutzerkonto in Unternehmen umwandeln - "Sandbox" aktivieren',
    equal: 'Gleich',
    errorLabel: 'Ein unerwarteter Fehler ist aufgetreten',
    errorOccurred: 'Ein Fehler ist aufgetreten',
    errorSaveAttachUserToCustomerMessage: 'Beim Einrichten von Datenstrukturen für den Kunden ist ein Fehler aufgetreten',
    errorSaveAttachUserToExistingCustomerMessage: 'Beim Anfügen des Benutzers an den Kunden ist ein Fehler aufgetreten',
    exampleName: 'Beispielname',
    examples: 'Beispiele',
    exclamationElementPanelTitle: 'Mögliche andere Buchungskategorien',
    explainModeLabel: 'Arbeiten im Präsentationsmodus, der verschiedene Buchungsoptionen zeigt',
    externalId: 'Lieferantenproduktkennung',
    fieldRequired: 'Feld erforderlich',
    filledIn: 'Ausgefüllt',
    general: 'Allgemein',
    greaterOrQqual: 'Größer oder gleich',
    greaterThan: 'Größer als',
    gtinCode: 'GTIN-Code',
    gtu: 'GTU-Code',
    gtuCode: 'GTU',
    gtuCode_optional: 'GTU-Code',
    gusName: 'Firmenname in GUS',
    hasRegNum: 'Fahrzeugregistrierungsnummer',
    hasWarehouse: 'Mein Unternehmen hat Lagerhäuser',
    history: 'Verlauf',
    historyIsEmpty: 'Keine Einträge',
    idxTagContains: 'Tag enthält',
    idxTagNotContains: 'Tag enthält nicht',
    ignore: 'Ignorieren',
    indeksomat: 'Indeksomat',
    indexContains: 'Index enthält',
    indexNotContains: 'Index enthält nicht',
    invoiceTopic: 'Thema',
    isActive: 'Ist aktiv',
    isCommodity: 'Ist Ware?',
    isFixedAsset: 'Ist Anlagevermögen?',
    isGtuEmpty: 'Ist GTU leer',
    isIntangibleAsset: 'Ist immateriell?',
    isItemTextEmpty: 'Ist Artikel leer',
    isLowValueEquipment: 'Ist geringwertig?',
    isPredefined: 'Ist vordefiniert',
    isPurchasePurposeEmpty: 'Ist der Kaufzweck leer',
    isStoppingProcessing: 'Stoppt die Verarbeitung?',
    isTaxIdEmpty: 'Ist die Steuer-ID leer',
    isVehicleUsagePurposeEmpty: 'Ist der Verwendungszweck des Fahrzeugs leer',
    itemTextContains: 'Artikel enthält',
    itemTextNotContains: 'Artikel enthält nicht',
    key: 'Schlüssel',
    keywords: 'Stichworte:',
    ksefFileLoadingError: 'Beim Laden der Datei ist ein Fehler aufgetreten. Rechnung im XML-Format erforderlich',
    ksefInvoicePosition: 'KSeF-Rechnungsposten',
    ksefInvoicePositionNotFound: 'Die Rechnung enthält keine Position mit der angegebenen Nummer',
    lackOf: 'Fehlen von',
    lessOrQqual: 'Kleiner oder gleich',
    lessThan: 'Kleiner als',
    likeIt: 'Gefällt mir',
    limitExceededTryLater: 'Limit überschritten, melden Sie sich an oder dekretieren Sie erneut in:',
    loadedXMLFile: 'Geladene XML-Datei',
    loadKSeFInvoice: 'Rechnung laden',
    marketingIDXText: 'Sie haben keinen aktiven Indeksomat. Kontaktieren Sie uns',
    materialsAndGoods: 'Waren und Materialien',
    materialsAndGoodsMapping: 'Mapping von Waren und Materialien',
    myCompany: 'Mein Unternehmen',
    name: 'Name',
    nameContains: 'Name enthält',
    nameNotContains: 'Name enthält nicht',
    negativeRate: 'Sie haben negativ bewertet',
    newCategory: 'Neue Kategorie',
    newParameter: 'Parameter hinzufügen',
    newProduct: 'Neues Produkt',
    newPurchaseTarget: 'Neues Kaufziel',
    newTopicGroup: 'Themengruppe hinzufügen',
    newVatRegister: 'Neues Umsatzsteuerregister',
    nip: 'Steuernummer des Verkäufers',
    no: 'Nein',
    noAuthority: 'Sie haben keine Berechtigung',
    noData: 'Keine Daten',
    noDataResultInfo: 'Keine Ergebnisse',
    noDataResultMessage: 'Ein Fehler ist aufgetreten',
    noResult: 'Keine Ergebnisse',
    notBusinessClassification: 'Kein Geschäft',
    notContains: 'Enthält nicht',
    note: 'Hinweis',
    notEqual: 'Nicht gleich',
    notFilledIn: 'Nicht ausgefüllt',
    notGtu: 'Kein GTU-Code',
    notPurchasePurposeId: 'Kein Kaufzweck',
    notTopicGroupKey: 'Rechnungsthema nicht in Themengruppe',
    notTopicIds: 'Rechnungsthema ist nicht',
    number: 'Nummer',
    ok: 'Ok',
    onBoardingExplain: 'Wenn der folgende Parameter ausgewählt wäre:',
    operatorType: 'Parameterfunktion',
    overwrite: 'Setzen',
    parameter: 'Parameter',
    parameter1: 'Parameter 1',
    parameter2: 'Parameter 2',
    parameterAdded: 'Parameter wurde hinzugefügt',
    parameterAddingError: 'Parameter wurde nicht hinzugefügt - ein Fehler ist aufgetreten',
    parameterAddingErrorNotUniqueKey: 'Parameter-Schlüssel muss eindeutig sein',
    parameterChanged: 'Parameter wurde geändert',
    parameterChangingError: 'Parameter wurde nicht geändert - ein Fehler ist aufgetreten',
    parameterDeleted: 'Parameter wurde gelöscht',
    parameterDeletingError: 'Ein Fehler ist beim Löschen des Parameters aufgetreten',
    parameters: 'Parameter',
    phone: 'Telefon',
    pkd: 'PKD',
    pkdTitle: 'PKD-Name',
    pkwiu: 'PKWiU',
    pkwiuCode: 'PKWiU-Code',
    popupTitle: 'Firmendaten',
    position: 'Rechnungsposition',
    positionInfo: 'Positionsdaten',
    positionMeetsConditionsInfo: 'Position erfüllt Bedingungen',
    positiveRate: 'Sie haben das Ergebnis positiv bewertet',
    potentialCategoryNameExplainElementTitle: 'Potenzielle Kategorie',
    predefinedIconTooltip: 'Vordefiniert',
    predictContractorButtonLabel: 'Branche erkennen',
    predictedTopic: 'Erkanntes Thema:',
    predictions: 'Vorhersagen',
    processingOrder: 'Verarbeitungsreihenfolge',
    product: 'Produkt',
    productAddError: 'Ein Fehler ist beim Hinzufügen des Produkts aufgetreten',
    productAddSuccess: 'Produkt wurde hinzugefügt',
    productAlternativeName: 'Alternativer Produktname',
    productAlternativeNameDeleted: 'Alternativer Produktname wurde gelöscht',
    productAlternativeNameDeletingError: 'Ein Fehler ist beim Löschen des alternativen Produktnamens aufgetreten',
    productDeleted: 'Produkt wurde gelöscht',
    productDeletingError: 'Ein Fehler ist beim Löschen des Produkts aufgetreten',
    productEditError: 'Ein Fehler ist beim Bearbeiten des Produkts aufgetreten',
    productEditSuccess: 'Produkt wurde bearbeitet',
    productGroupContains: 'Produktgruppe enthält',
    productGroupName: 'Produktgruppe',
    productGroupNameProductPanelLabel: 'Produktgruppe',
    productGroupNotContains: 'Produktgruppe enthält nicht',
    productHasNoAlternativeNames: 'Produkt hat keine alternativen Namen',
    productId: 'Produktkennung',
    productIndex: 'Index',
    productIndexNotUniqueError: 'Index ist nicht eindeutig',
    productIndexProductPanelLabel: 'Produktindex',
    productName: 'Produktname',
    productNameNotUniqueError: 'Produktname ist nicht eindeutig',
    productPanelTitle: 'Produkt',
    productsData: 'Produkte',
    productService: 'Art des Produkts/Dienstleistung',
    productTags: 'Produkt-Tags',
    productType: 'Produkttyp',
    purchasePurpose: 'Kaufzwecke',
    purchasePurposeDeletingSuccess: 'Kaufzweck gelöscht',
    purchasePurposeId: 'Kaufzweck',
    purchasePurposeName: 'Name',
    purchasePurposeNameNotUnique: 'Kaufzweck ist nicht eindeutig',
    purchasePurposes: 'Kaufzwecke',
    purchaseTargetCode: 'Code',
    purchaseTargetDefaultCategory: 'Standardkategorie',
    purchaseTargetExplain: 'Wenn der folgende Kaufzweck ausgewählt wäre:',
    purchaseTargetName: 'Name',
    purchaseTargetNote: 'Hinweis',
    purchaseTargets: 'Kaufzwecke',
    rateUs: 'Bewerten Sie unser Ergebnis',
    report: 'Problem melden',
    reset: 'Zurücksetzen',
    resultNotSureLabel: 'Ergebnis unsicher',
    returnStopMessage: 'Nachricht anstelle des Ergebnisses zurückgeben',
    ruleName: 'Regelname',
    ruleNameExplain: 'Regelname:',
    ruleNameExplainElementTitle: 'Zusätzliche Bedingung für die Auswahl dieser Kategorie',
    rules: 'Regeln:',
    save: 'Speichern',
    saveExample: 'Beispiel speichern',
    saveSuccessMessage: 'Firmendaten wurden gespeichert',
    search: 'Suche',
    selectProduct: 'Wählen Sie das richtige Produkt aus',
    selectTopic: 'Wählen Sie das richtige Thema aus',
    sellerIndex: 'Verkäuferindex',
    sellerRuleInfo: 'Die Regel betrifft den Verkäufer, dessen',
    sendFeedback: 'Senden',
    service: 'Dienst',
    sessionExpired: 'Deine Sitzung ist abgelaufen - du wurdest ausgeloggt',
    set: 'Setzen',
    setDefault: 'Standard setzen',
    signIn: 'Anmelden',
    signInMarketing: 'Anmelden',
    signInMarketingDescription: 'Nach der Anmeldung haben Sie Zugriff auf zusätzliche Funktionen und können Ihre eigenen Einstellungen vornehmen',
    signInMarketingMoreOptions: ' und erhalten Sie mehr Möglichkeiten.',
    signInMarketingQuestion: 'Möchten Sie die vorgeschlagene Buchungsmethode sehen?',
    signOut: 'Abmelden',
    signOutSuccessfully: 'Sie wurden erfolgreich abgemeldet',
    stopMessage: 'Stoppnachricht',
    successSaveAttachUserToCustomerMessage: 'Datenstrukturen für den Kunden wurden erstellt',
    successSaveAttachUserToExistingCustomerMessage: 'Benutzer dem Kunden zugeordnet',
    successSaveMessage: 'Firmendaten wurden gespeichert',
    systemIsUpdating: 'System wird aktualisiert, bitte warten',
    tag: 'Tag',
    tagRelated: 'Regel betrifft Tags auf der Rechnung',
    targetValue: 'Wert',
    taxId: 'Steuernummer',
    taxIdToAttach: 'Kundensteuernummer, um Benutzer anzuhängen',
    test: 'Test',
    testing: 'Testen',
    text: 'Text',
    thanks: 'Danke',
    topicAndProductPrediction: 'Themen- und Produktvorhersage',
    topicExplain: 'Wenn das Kaufthema lautet: ',
    topicGroupAdded: 'Themengruppe wurde hinzugefügt',
    topicGroupAddingError: 'Fehler beim Hinzufügen der Themengruppe aufgetreten',
    topicGroupAddingErrorNotUniqueKey: 'Gruppenschlüssel muss eindeutig sein',
    topicGroupChanged: 'Themengruppe wurde geändert',
    topicGroupChangingError: 'Fehler beim Ändern der Themengruppe aufgetreten',
    topicGroupDeleted: 'Themengruppe wurde gelöscht',
    topicGroupDeletingError: 'Fehler beim Löschen der Themengruppe aufgetreten',
    topicGroupExplain: ' würde der Gruppe zugeordnet werden:',
    topicGroupKey: 'Rechnungsthema in Themengruppe',
    topicGroups: 'Themen im Zusammenhang mit Waren und Materialien',
    topicIds: 'Rechnungsthema ist',
    topicPanelTitle: 'Rechnungsthema',
    topicPrediction: 'Themenvorhersage',
    topicRelated: 'Regel betrifft Themen',
    topics: 'Themen',
    topicsRelatedToMaterialsAndGoods: 'Themen im Zusammenhang mit Waren und Materialien',
    topicTagContains: 'Rechnungstag enthält',
    topicTagNotContains: 'Rechnungstag enthält nicht',
    trainProcuctPredictionError: 'Fehler während des Trainings aufgetreten',
    trainProductPredictionSuccess: 'Danke! Die Produktzuordnung wird dank Ihnen effektiver sein',
    tryAgainAdding: 'Versuchen Sie es erneut, indem Sie eingeben',
    type: 'Typ',
    unempty: 'Nicht leer',
    unit: 'Einheit',
    unitNetPrice: 'Netto-Einheitspreis - Betrag',
    unitPriceGeValueLabel: 'Einheitskosten >= [PLN]',
    unitPriceGTExplain: 'Wenn der eingegebene Einheitspreis größer oder gleich ist:',
    unitPriceLTExplain: 'Wenn der eingegebene Einheitspreis kleiner ist als:',
    unitPriceLtValueLabel: 'Einheitskosten < [PLN]',
    unitPriceParameter: 'Parameter',
    unitPriceParameterLabel: 'Parameter',
    unitPriceType: 'Typ',
    unitPriceValue: 'Wert',
    userLoginAttachUserToCustomer: 'Benutzeranmeldung',
    value: 'Wert',
    vat: 'MwSt.',
    vatDeduction_0: '0% MwSt.-Abzug',
    vatDeduction_100: '100% MwSt.-Abzug',
    vatDeduction_50: '50% MwSt.-Abzug',
    vatDeduction_noInfo: 'Keine Informationen',
    vatDeduction_ToClarify: 'Zur Klärung',
    vatDeductionLabel: 'MwSt.-Abzug',
    vatId: 'Steuernummer',
    vatRate: 'MwSt.-Satz',
    vatRegister_ComodityImport:'Warenimport',
    vatRegister_ComodityInside:'Innerer Warenkauf',
    vatRegister_CountryBuy: 'Inlandskauf',
    vatRegister_CountryBuy_ST:'Inlandskauf - Sachanlagen',
    vatRegister_ServiceImport:'Dienstleistungsimport',
    vatRegister_ToClarify: 'Zur Klärung',
    vatRegisterAccount: 'Konto',
    vatRegisterAccountLabel: 'MwSt.-Registrierkonto',
    vatRegisterDeletingSuccess: 'MwSt.-Register erfolgreich gelöscht',
    vatRegisterLabel: 'MwSt.-Register',
    vatRegisterName: 'Name',
    vatRegisterNameNotUnique: 'Name des MwSt.-Registers ist nicht eindeutig',
    vatRegisters: 'MwSt.-Register',
    vehiclePurposeExplain: 'Wenn der folgende Verwendungszweck für das Fahrzeug ausgewählt würde:',
    vehicleRealted: 'Fahrzeugbezogener Kauf',
    vehicles: 'Fahrzeuge',
    vehicleUsagePurpose: 'Fahrzeug verwendet als',
    vehicleUsagePurposeId: 'Verwendungszweck des Fahrzeugs',
    whatRuleSets: 'Was die Regel festlegt',
    yes: 'Ja',
}
