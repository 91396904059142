import { List, Paper, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import MenuListItemButton from "../../components/MenuListItemButton";
import { pagesNames } from "../../constants/pageNavigationConstans";
import { useAppContext } from "../../contexts/AppContext";
import { useUserContext } from "../../contexts/UserContext";
import MasterLayout from "../../layouts/MasterLayout";

export default function CompanyConfigurationPage() {

    const navigate = useNavigate();
    const {isMobile, labels} = useAppContext();
    const {customerProfile} = useUserContext();

    const action = <ActionButton style={{marginLeft: '20px'}} className="actionButton" onClick={() => navigate('/')}>
      {labels.endConfigurationButtonLabel}
    </ActionButton>

    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
        setCurrentPageName(mapUrlToPageName(window.location.pathname));
    }, [url]);

    const [currentPageName, setCurrentPageName] = useState('');
    
    function mapUrlToPageName(url: string){
        switch(url){
            case `/${pagesNames.companyConfiguration}/${pagesNames.companyData}`:
                return labels.companyData;
            case `/${pagesNames.companyConfiguration}/${pagesNames.vatRegisters}`:
                return labels.vatRegisters;
            case `/${pagesNames.companyConfiguration}/${pagesNames.accountingPolicy}`:
                return labels.accountingPolicy;
            case `/${pagesNames.companyConfiguration}/${pagesNames.purchaseTargets}`:
                return labels.purchaseTargets;
            case `/${pagesNames.companyConfiguration}/${pagesNames.materialsAndGoods}`:
                return labels.materialsAndGoods;
            case `/${pagesNames.companyConfiguration}/${pagesNames.accountingCategories}`:
                return labels.accountingCategories;
            case `/${pagesNames.companyConfiguration}/${pagesNames.accountingRules}`:
                return labels.accountingRules;

            case `/${pagesNames.companyConfiguration}/${pagesNames.materialsAndGoodsMapping}`:
                return labels.materialsAndGoodsMapping;
            case `/${pagesNames.companyConfiguration}/${pagesNames.topicPrediction}`:
                return labels.topicPrediction;
            case `/${pagesNames.companyConfiguration}/${pagesNames.topicAndProductPrediction}`:
                return labels.topicAndProductPrediction;
            case `/${pagesNames.companyConfiguration}/${pagesNames.bcPrediction}`:
                    return labels.bcPrediction;

            case `/${pagesNames.companyConfiguration}/${pagesNames.apiDocumentation}`:
                return labels.apiDocumentation;
        }
        return '';
    }

    function mapLocalizationToPageName(to: string, parentLocation: string){
        let url = `/${parentLocation}/${to}`;
        return mapUrlToPageName(url);
    }

    return <MasterLayout navMenuAction={action} pageName={currentPageName}>
        {!isMobile && <Paper style={{padding: '10px', alignSelf:'start', position:'sticky', 
            //top:'74px'
            top:'110px'
            }} >
            <Typography fontWeight='bold'>{labels.companyConfiguration}</Typography>
            <List>
                <MenuListItemButton to={pagesNames.companyData} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.companyData, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.accountingPolicy} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.accountingPolicy, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.vatRegisters} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.vatRegisters, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.purchaseTargets} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.purchaseTargets, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.materialsAndGoods} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.materialsAndGoods, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.accountingCategories} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.accountingCategories, pagesNames.companyConfiguration)}/>
                <MenuListItemButton to={pagesNames.accountingRules} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.accountingRules, pagesNames.companyConfiguration)}/>
            </List>
            <Typography fontWeight='bold'>{labels.testing}</Typography>
            <List>
                <MenuListItemButton to={pagesNames.materialsAndGoodsMapping} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.materialsAndGoodsMapping, pagesNames.companyConfiguration)}/>
                {(customerProfile?.isAdmin ?? false) && <MenuListItemButton to={pagesNames.bcPrediction} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.bcPrediction, pagesNames.companyConfiguration)}/>}    
                <MenuListItemButton to={pagesNames.topicPrediction} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.topicPrediction, pagesNames.companyConfiguration)}/>
                {(customerProfile?.isAdmin ?? false) && <MenuListItemButton to={pagesNames.topicAndProductPrediction} parentLocation={pagesNames.companyConfiguration} text={mapLocalizationToPageName(pagesNames.topicAndProductPrediction, pagesNames.companyConfiguration)}/>}    
            </List>
            <Typography fontWeight='bold'>{labels.api}</Typography>
            <List>
                <MenuListItemButton to={pagesNames.swagger} target='_blank' sndLevel={true} text={mapLocalizationToPageName(pagesNames.apiDocumentation, pagesNames.companyConfiguration)}/>
            </List>
        </Paper>}
        <Outlet/>
    </MasterLayout>
}