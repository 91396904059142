import { Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccXCategoryRule, AccXCategoryRuleOperatorEnum, AccXCategoryRuleProductServiceEnum, AccXUnitPriceType, CategoryRuleApi } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import AccXTabs, { AccXTabInfo } from "../../../../components/tabs/AccXTabs";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import { useUserContext } from "../../../../contexts/UserContext";
import CategoryRuleBuyerTab from "./tabs/CategoryRulePopupBuyerTab";
import CategoryRuleGeneralTab from "./tabs/CategoryRulePopupGeneralTab";
import CategoryRuleMaterialAndGoodsTab from "./tabs/CategoryRulePopupMaterialAndGoodsTab";
import CategoryRuleParametersTab from "./tabs/CategoryRulePopupParametersTab";
import CategoryRulePositionsTab from "./tabs/CategoryRulePopupPositionsTab";
import CategoryRulePurposesTab from "./tabs/CategoryRulePopupPurposesTab";
import CategoryRuleSellerTab from "./tabs/CategoryRulePopupSellerTab";
import CategoryRuleSetsTab from "./tabs/CategoryRulePopupSetsTab";
import CategoryRuleTopicsTab from "./tabs/CategoryRulePopupTopicRelatedTab";

type Props = {
    categoryRuleId?: number,
    onClose: (refresh: boolean) => void,
}

export type CategoryRuleTabProps = {
    categoryRule: AccXCategoryRule,
    setCategoryRule: React.Dispatch<React.SetStateAction<AccXCategoryRule>>,
    categoryRuleErrors?: any,
}

export default function CategoryRulePopup({categoryRuleId, onClose} : Props) {
    
    const {labels, setIsLoading, showMessage} = useAppContext();

    const isEditMode = categoryRuleId && true;
    const popupTitle = isEditMode 
        ? labels.editCategoryRule : labels.addCategoryRule; 

    const categoryRuleTabs = {
        general: 'general',
        sets: 'sets',
        topics: 'topics',
        purposes: 'purposes',
        positions: 'positions',
        seller: 'seller',
        buyer: 'buyer',
        parameters: 'parameters',
        materialAndGoods: 'materialAndGoods',
    };

    const initValues: AccXCategoryRule = {
        general: {
            categoryRuleId: 0,
            categoryRuleName: '',
            isEnabled: true,
            isStoppingProcessing: true,
            processingOrder: 1,
        },
        conditions: {
            topicRelated: {
                topicIdsSpecified: false,
                topicIds: [],
                notTopicIdsSpecified: false,
                notTopicIds: [],
                topicGroupKeySpecified: false,
                topicGroupKey: '',
                notTopicGroupKeySpecified: false,
                notTopicGroupKey: '',
                productServiceSpecified: false,
                productService: AccXCategoryRuleProductServiceEnum.Product,
                isCommoditySpecified: false,
                isCommodity: false,
                isFixedAssetSpecified: false,
                isFixedAsset: false,
                isLowValueEquipmentSpecified: false,
                isLowValueEquipment: false,
                isIntangibleAssetSpecified: false,
                isIntangibleAsset: false,
                topicTagContainsSpecified: false,
                topicTagContains: [],
                topicTagNotContainsSpecified: false,
                topicTagNotContains: [],
            },
            purposesAndOther: {
                purchasePurposeIdSpecified: false,
                purchasePurposeId: 0,
                notPurchasePurposeIdSpecified: false,
                notPurchasePurposeId: 0,
                isPurchasePurposeEmptySpecified: false,
                isPurchasePurposeEmpty: false,
                vehicleUsagePurposeIdSpecified: false,
                vehicleUsagePurposeId: 0,
                isVehicleUsagePurposeEmptySpecified: false,
                isVehicleUsagePurposeEmpty: false,
                additionalInfoContainsSpecified: false,
                additionalInfoContains: [],
                additionalInfoNotContainsSpecified: false,
                additionalInfoNotContains: [],
            },
            itemRelated: {
                unitPriceLtSpecified: false,
                unitPriceLt: '',
                unitPriceLtType: AccXUnitPriceType.Parameter,
                unitPriceGeSpecified: false,
                unitPriceGe: '',
                unitPriceGeType: AccXUnitPriceType.Parameter,
                itemTextContainsSpecified: false,
                itemTextContains: [],
                itemTextNotContainsSpecified: false,
                itemTextNotContains: [],
                isItemTextEmptySpecified: false,
                isItemTextEmpty: false,
                gtuSpecified: false,
                gtu: '',
                notGtuSpecified: false,
                notGtu: '',
                vatRateSpecified: false,
                vatRate: '',
            },
            sellerRelated: {
                businessClassificationIdSpecified: false,
                businessClassificationId: 0,
                notBusinessClassificationIdSpecified: false,
                notBusinessClassificationId: 0,
                nameContainsSpecified: false,
                nameContains: [],
                nameNotContainsSpecified: false,
                nameNotContains: [],
                taxIdSpecified: false,
                taxId: '',
                isTaxIdEmptySpecified: false,
                isTaxIdEmpty: true,
            },
            buyerRelated: {
                businessClassificationIdSpecified: false,
                businessClassificationId: 0,
                notBusinessClassificationIdSpecified: false,
                notBusinessClassificationId: 0,
            },
            idxRelated: {
                indexContainsSpecified: false,
                indexContains: [],
                indexNotContainsSpecified: false,
                indexNotContains: [],
                productGroupContainsSpecified: false,
                productGroupContains: [],
                productGroupNotContainsSpecified: false,
                productGroupNotContains: [],
                idxTagContainsSpecified: false,
                idxTagContains: [],
                idxTagNotContainsSpecified: false,
                idxTagNotContains: [],
            },
            onboarding: {
                paramRule1: {
                    onboardingParamKey: '',
                    operatorType: AccXCategoryRuleOperatorEnum.Unknown,
                    targetValue: '',
                },
                paramRule2: {
                    onboardingParamKey: '',
                    operatorType: AccXCategoryRuleOperatorEnum.Unknown,
                    targetValue: '',
                },
            },
        },
        set: {
            categoryId: 0,
            tagsSpecified: false,
            tag: '',
            stopMessageSpecified: false,
            stopMessage: '',
        }
    }

    const errorInitValues = {
        generalHasErrors: false,
        general: {
            categoryRuleNameError: '',
        },
        setHasErrors: false,
        set: {
            errorMessage: '',
            categoryIdError: '',
            stopMessageError: '',
            tagError: '',
        }
    }

    const [categoryRule, setCategoryRule] = useState<AccXCategoryRule>(initValues);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedTab, setSelectedTab] = useState(categoryRuleTabs.general);
    const [categoryRuleErrors, setCategoryRuleErrors] = useState(errorInitValues);

    useEffect(() => {
        if(isEditMode) {
            setIsLoadingData(true);
            request(categoryRuleApi.categoryRuleCategoryRuleIdGet(categoryRuleId)).then((response) => {
                if (response.ok && response.data) {
                    setCategoryRule(response.data);
                    setIsLoadingData(false);
                }
            });
        }
    }, []);

    const {customerProfile} = useUserContext();

    const {request} = useApiContext();
    const categoryRuleApi = new CategoryRuleApi();

    function onTabChange(id: string) {
        setSelectedTab(id);
    }

    function getSelectedTab() {

        switch(selectedTab) {
            default:
            case categoryRuleTabs.general:
                return <CategoryRuleGeneralTab categoryRule={categoryRule} setCategoryRule={setCategoryRule} categoryRuleErrors={categoryRuleErrors}/>
            case categoryRuleTabs.sets:
                return <CategoryRuleSetsTab categoryRule={categoryRule} setCategoryRule={setCategoryRule} categoryRuleErrors={categoryRuleErrors}/>
            case categoryRuleTabs.topics:
                return <CategoryRuleTopicsTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.purposes:
                return <CategoryRulePurposesTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.positions:
                return <CategoryRulePositionsTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.seller:
                return <CategoryRuleSellerTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.buyer:
                return <CategoryRuleBuyerTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.parameters:
                return <CategoryRuleParametersTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
            case categoryRuleTabs.materialAndGoods:
                return <CategoryRuleMaterialAndGoodsTab categoryRule={categoryRule} setCategoryRule={setCategoryRule}/>
        }
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        
        if (!validateCategoryRuleData()) {
            return;
        }

        setIsLoading(true);

        if (isEditMode) {
            request(categoryRuleApi.categoryRulePut(categoryRule)).then((response) => {
                setIsLoading(false);

                if(response.ok) {
                    onClose(true);
                    showMessage(labels.categoryRuleChanged);
                } else {
                    showMessage(labels.categoryRuleChangeError, MessageType.Error);
                }
            });
        } else {
            request(categoryRuleApi.categoryRulePost(categoryRule)).then((response) => {
                setIsLoading(false);
                
                if(response.ok) {
                    onClose(true);
                    showMessage(labels.categoryRuleAdded);
                } else {
                    showMessage(labels.categoryRuleAddError, MessageType.Error);
                }
            });
        }
    }

    function validateCategoryRuleData() : boolean {

        var hasErrors = false;

        setCategoryRuleErrors(errorInitValues);

        if ((categoryRule.general?.categoryRuleName ?? '').trim() === '') {
            setCategoryRuleErrors((prevState) => ({...prevState, 
                generalHasErrors: true, 
                general: {...prevState.general, 
                    categoryRuleNameError: labels.fieldRequired,
                }}));
            hasErrors = true;
        }

        if (!categoryRule.set?.categoryIdSpecified 
            && !categoryRule.set?.tagsSpecified
            && !categoryRule.set?.stopMessageSpecified) {
            
            setCategoryRuleErrors((prevState) => ({...prevState, 
                setHasErrors: true, 
                set: {...prevState.set, 
                    errorMessage: labels.categoryRuleHaveToSetSomething
                }}));
            hasErrors = true;
        }
        else {
            if (categoryRule.set?.categoryIdSpecified && (categoryRule.set?.categoryId === 0 || categoryRule.set?.categoryId === null)) {
                setCategoryRuleErrors((prevState) => ({...prevState, 
                    setHasErrors: true, 
                    set: {...prevState.set,
                        categoryIdError: labels.fieldRequired,
                    }}));
                hasErrors = true;
            }

            if (categoryRule.set.stopMessageSpecified && (categoryRule.set.stopMessage ?? '').trim() === '') {
                setCategoryRuleErrors((prevState) => ({...prevState, 
                    setHasErrors: true, 
                    set: {...prevState.set, 
                        stopMessageError: labels.fieldRequired,
                    }}));
                hasErrors = true;
            }

            if (categoryRule.set.tagsSpecified && (categoryRule.set.tag ?? '').trim() === '') {
                setCategoryRuleErrors((prevState) => ({...prevState, 
                    setHasErrors: true, 
                    set: {...prevState.set,
                        tagError: labels.fieldRequired, 
                    }}));
                hasErrors = true;
            }
        }

        return !hasErrors;
    }

    function getPopupTabs() : AccXTabInfo[] {
        var result: AccXTabInfo[] = [
            {id: categoryRuleTabs.general, label: labels.general, hasErrors: categoryRuleErrors.generalHasErrors},
            {id: categoryRuleTabs.sets, label: labels.whatRuleSets, hasErrors: categoryRuleErrors.setHasErrors},
            {id: categoryRuleTabs.topics, label: labels.conditionsTopics},
            {id: categoryRuleTabs.purposes, label: labels.conditionsPurposes},
            {id: categoryRuleTabs.positions, label: labels.conditionsPositions},
            {id: categoryRuleTabs.seller, label: labels.conditionsSeller},
            {id: categoryRuleTabs.buyer, label: labels.conditionsBuyer},
            {id: categoryRuleTabs.parameters, label: labels.conditionsParameters},
        ];

        if (customerProfile?.hasIDX ?? false) {
            result.push({id: categoryRuleTabs.materialAndGoods, label: labels.conditionsMaterialsAndGoods});
        }
        
        return result;
    }

    return <Popup title={popupTitle} onClose={onClose} top='50%' isLoading={isLoadingData}>
        <AccXTabs selectedTab={selectedTab} onTabChange={onTabChange} tabs={getPopupTabs()}/>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} height={'50vh'} overflow={'auto'}>
                    {getSelectedTab()}
                </Grid>
                <Grid item xs={12}>
                    <ActionButton style={{float: 'right'}} type={'submit'}>{labels.save}</ActionButton>
                </Grid>
            </Grid>
        </form>
    </Popup>
}