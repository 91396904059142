import React, { useEffect, useMemo, useState } from 'react';
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import EditForm from '../../../components/EditForm';
import SideElementLayout from '../../../layouts/SideElementLayout';
import ActionButton from '../../../components/ActionButton';
import { useApiContext } from '../../../contexts/ApiContext';

import ComboBoxField from '../../../components/fields/ComboBoxField';
import { AccXBCPrediction, AccXBCPredictionDataResult, AccXBCPredictionDataResultAPIDataResponse, AccXBusinessClassificationResult, AccXCustomerProfile, BusinessClassificationApi, BusinessClassificationPredictionApi, OnboardingApi } from '../../../api';
import AccXTextField from '../../../components/fields/AccXTextField';
import { useAppContext } from '../../../contexts/AppContext';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import { Box } from '@mui/system';
import { useUserContext } from '../../../contexts/UserContext';
import { MessageType } from '../../../components/snackbar/MessageType';
import CheckboxField from '../../../components/fields/CheckboxField';

export default function CompanyDataPanel() {

    const { customerProfile, refreshCustomerProfile } = useUserContext();

    const initValues: AccXCustomerProfile = {
        companyName: customerProfile?.companyName ?? '',
        companyTaxId: customerProfile?.companyTaxId ?? '',
        businessClassificationId: customerProfile?.businessClassificationId ?? 0,
        explainMode: customerProfile?.explainMode ?? false,
        phone: customerProfile?.phone ?? '',
        email: customerProfile?.email ?? '',
    };

    const [customerProfileData, setCustomerProfileData] = useState(initValues);

    const {request, fetchBusinessClassifications} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();

    const [isBusinessClasificationsLoading, setIsBusinessClassificationsLoading] = useState(true);
    const [isBCPredictionLoading, setIsBCPredictionLoading] = useState(false);
    const [businessClassifications, setBusinessClassifications] = useState<AccXBusinessClassificationResult[]>();
    const onboardingApi = new OnboardingApi();

    const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] = useState(false);

    const businessClassificationPredictionApi = new BusinessClassificationPredictionApi();

    useEffect(() => {
        refreshCustomerProfile();
        fetchBusinessClassifications().then((response) => {
            setBusinessClassifications(response);
            setIsBusinessClassificationsLoading(false);
        })
    }, []);


    function textChange(id: string, value: string) {
        setCustomerProfileData({...customerProfileData, [id]: value})
    }

    function businessClassificationChange (event: any, newValue: any) {
        setCustomerProfileData({...customerProfileData, businessClassificationId: newValue});
    }

    function hasExplainModeChange(id: string, checked: boolean) {
        setCustomerProfileData({...customerProfileData, explainMode: checked})
    }

    const onBlurVatId = () => {
        setIsBCPredictionLoading(true);
        var accXBCPrediction:AccXBCPrediction = {
            taxId: customerProfileData.companyTaxId,
            contractorName: customerProfileData.companyName,
        }
        request(businessClassificationPredictionApi.businessClassificationPredictionPredictPost(accXBCPrediction)).then((response => {
            if (response.ok && response.data) {
                let bcId = getBusinessClassificationFromPrediction(response.data);
                setCustomerProfileData({...customerProfileData, businessClassificationId: bcId});
            }
            setIsBCPredictionLoading(false);
        }));
    }

    function getBusinessClassificationFromPrediction(dataResponse: AccXBCPredictionDataResult){
        if(dataResponse && dataResponse.accXBCPredictionResults && dataResponse.accXBCPredictionResults.length > 0){
            var bcId = dataResponse.accXBCPredictionResults[0].businessClassificationId;
            if(bcId){
                return bcId;
            }
        }
        return customerProfileData?.businessClassificationId;
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(customerProfile?.businessClassificationId != customerProfileData?.businessClassificationId){
            setIsConfirmationPopupVisible(true);
        }else{
            submitEditForm(customerProfileData)
        }
    }

    const submitEditFormFromPopup = (customerProfileData: AccXCustomerProfile) => {
        submitEditForm(customerProfileData);
        setIsConfirmationPopupVisible(false);
    }

    const submitEditForm = (customerProfileData: AccXCustomerProfile) => {

        if (isBCPredictionLoading) {
            showMessage(labels.BCPredictionLoading, MessageType.Warning);
            return;
        }

        setIsLoading(true);
        request(onboardingApi.onboardingProfilesPut(customerProfileData)).then((response) => {
            if(response.ok) {
                showMessage(labels.successSaveMessage);
                refreshCustomerProfile();
                setIsLoading(false);
            }
        })
    }

    const closePopup = () => {
        setIsConfirmationPopupVisible(false);
    }

    const reset = (resetData = true) => {
        if(resetData) {
            setCustomerProfileData(initValues);
        }
    }

    return <SideElementLayout>
        <EditForm isLoading={isBusinessClasificationsLoading}>
            <h4>{labels.enterCompanyDetails}</h4>
            <Divider style={{marginBottom: '20px'}}></Divider>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <AccXTextField id='companyName' 
                            value={customerProfileData?.companyName}
                            label={labels.companyName} 
                            onChange={textChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <AccXTextField id='companyTaxId'
                            value={customerProfileData?.companyTaxId}
                            label={labels.vatId}
                            onBlur={() => onBlurVatId()}
                            onChange={textChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ComboBoxField id='businessClassificationId'
                            label={labels.businessClassification}
                            value={customerProfileData?.businessClassificationId}
                            items={businessClassifications}
                            //itemLabelField='businessClassificationName'
                            onChange={businessClassificationChange}
                            isLoading={isBCPredictionLoading}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AccXTextField id='email'
                            value={customerProfileData?.email}
                            label={labels.email}
                            onChange={textChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <AccXTextField id='phone'
                            value={customerProfileData?.phone}
                            label={labels.phone}
                            onChange={textChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <CheckboxField id='explainMode'
                            isSwitch
                            checked={customerProfileData?.explainMode}
                            label={labels.explainModeLabel}
                            onChange={hasExplainModeChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionButton type='submit' 
                            style={{float: 'right'}}>
                            {labels.save}
                        </ActionButton>
                        <ActionButton onClick={() => reset()} className="resetButton">
                            {labels.clean}
                        </ActionButton>
                    </Grid>
                </Grid>
            </form>
        </EditForm>
        {isConfirmationPopupVisible && <ConfirmationPopup onClose={closePopup} 
                    onActionButtonCloseClick={closePopup} 
                    onActionButtonConfirmationClick={() => submitEditFormFromPopup(customerProfileData)}
                    popupText={labels.confirmationPopupText} 
                    closeTitle={labels.closeActionConfirmationPopupTitle}
                    confirmationTitle={labels.confirmActionConfirmationPopupTitle}
                    title={labels.confirmationPopupTitle}></ConfirmationPopup>}
    </SideElementLayout> 
}
