import { CommonApi } from "../api";
import { useAppContext } from "../contexts/AppContext";

const commonApi = new CommonApi();

export function handleResponse(response: any, setIsUpdating: any) {
    if (response && !response.ok) {
        if (response.status === 'SystemUpdate') {
            setIsUpdating(true);
            var intervalID = setInterval(() => {
                    commonApi.commonStatusGet().then((response) => {
                        if (response.ok && response.data) {
                            clearInterval(intervalID);
                            setIsUpdating(false);
                        }
                    });
              }, 30 * 1000);
            return;
        }

        if (response.message) {
            console.log(response.message);
        }
    }

    return response;
};

