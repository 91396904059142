import { useEffect, useState } from 'react';
import { Menu, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faMicrosoft, faLinkedin } from '@fortawesome/free-brands-svg-icons';

type Props = {
  setAnchorEl: (value: any) => void;
  anchorElement: any,
  isOpen: boolean,
}

export default function LoginOptionMenu({setAnchorEl, anchorElement, isOpen}: Props) {

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Menu anchorEl={anchorElement}
              open={isOpen}
              onClose={handleClose}
              >
              <form action='auth/signIn?provider=Google' method='post' style={{width: '100%'}}>
                <Button className='loginProvider' type='submit'>
                  <FontAwesomeIcon icon={faGoogle} style={{marginRight: '10px'}}/>
                    Google
                </Button>
              </form>
              {/* <form action='auth/signIn?provider=Facebook' method='post' style={{width: '100%'}}>
                <Button className='loginProvider' type='submit'>
                  <FontAwesomeIcon icon={faFacebook} style={{marginRight: '10px'}}/>
                    Facebook
                </Button>
              </form>    */}
              <form action='auth/signIn?provider=Microsoft' method='post' style={{width: '100%'}}>
                <Button className='loginProvider' type='submit'>
                  <FontAwesomeIcon icon={faMicrosoft} style={{marginRight: '10px'}}/>
                  Microsoft
                </Button>
              </form>
              {/* <form action='auth/signIn?provider=LinkedIn' method='post' style={{width: '100%'}}>
                <Button className='loginProvider' type='submit'>
                  <FontAwesomeIcon icon={faLinkedin} style={{marginRight: '10px'}}/>
                  LinkedIn
                </Button>
              </form>    */}
            </Menu>  
}
