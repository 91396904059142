import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXCategoryResult, CategoryApi } from "../../../../../api";
import EditForm from "../../../../../components/EditForm";
import AccXTextField from "../../../../../components/fields/AccXTextField";
import CheckboxField from "../../../../../components/fields/CheckboxField";
import ComboBoxField from "../../../../../components/fields/ComboBoxField";
import { useApiContext } from "../../../../../contexts/ApiContext";
import { CategoryRuleTabProps } from "../CategoryRulePopup";
import { useAppContext } from "../../../../../contexts/AppContext";

export default function CategoryRuleSetsTab({categoryRule, setCategoryRule, categoryRuleErrors} : CategoryRuleTabProps) {
    
    const setData = categoryRule.set;

    const [categories, setCategories] = useState<AccXCategoryResult[]>([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(true);

    const isLoadingData = isLoadingCategories;

    const {request} = useApiContext();
    const {labels} = useAppContext();

    const categoryApi = new CategoryApi();

    useEffect(() => {
        request(categoryApi.categoryAllGet()).then((response) => {
            if (response.ok && response.data) {
                setCategories(response.data);
            }
            setIsLoadingCategories(false);
        });
    }, []);


    function handleCheckboxChange(id: string, checked: boolean) {
        setCategoryRule({...categoryRule, set: {...categoryRule.set, [id]: checked}});
    }

    function handleCategoryIdChange(id: string, value: any) {
        setCategoryRule({...categoryRule, set: {...categoryRule.set, categoryId: value}});
    }

    function textChange(id: string, value: string) {
        setCategoryRule({...categoryRule, set: {...categoryRule.set, [id]: value}});
    }

    return <EditForm isLoading={isLoadingData}>
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <CheckboxField id='categoryIdSpecified'
                    checked={setData?.categoryIdSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <ComboBoxField id='categoryId'
                    value={setData?.categoryId}
                    label={labels.category}
                    items={categories}
                    onChange={handleCategoryIdChange}
                    disabled={!setData?.categoryIdSpecified}
                    error={categoryRuleErrors.set.categoryIdError !== ''}
                    helperText={categoryRuleErrors.set.categoryIdError}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='tagsSpecified'
                    checked={setData?.tagsSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <AccXTextField id='tag'
                    value={setData?.tag}
                    label={labels.addTag}
                    onChange={textChange}
                    disabled={!setData?.tagsSpecified}
                    error={categoryRuleErrors.set.tagError !== ''}
                    helperText={categoryRuleErrors.set.tagError}/>
            </Grid>
            <Grid item xs={1}>
                <CheckboxField id='stopMessageSpecified'
                    checked={setData?.stopMessageSpecified}
                    onChange={handleCheckboxChange}/>
            </Grid>
            <Grid item xs={11}>
                <AccXTextField id='stopMessage'
                    label={labels.returnStopMessage}
                    value={setData?.stopMessage}
                    onChange={textChange}
                    disabled={!setData?.stopMessageSpecified}
                    error={categoryRuleErrors.set.stopMessageError !== ''}
                    helperText={categoryRuleErrors.set.stopMessageError}/>
            </Grid>
            {categoryRuleErrors.set?.errorMessage !== undefined && <>
                <Grid item xs={1}/>
                <Grid item xs={11}>
                    <Typography style={{color: 'var(--error)'}}>{categoryRuleErrors.set.errorMessage}</Typography>
                </Grid>
            </>}
        </Grid>
    </EditForm>
}