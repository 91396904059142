import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Stack } from '@mui/system';
import { AccXCategoryPredictionResult } from '../../../api';
import { useAppContext } from '../../../contexts/AppContext';

type Props = {
    categoryPrediction: AccXCategoryPredictionResult,
    topicPredictionCertaintyLevel?: number,
    logs?: string[] 
}
export default function CategoryPanel({categoryPrediction, logs, topicPredictionCertaintyLevel} : Props) {

    const {labels, isMobile} = useAppContext();

    return <Accordion style={{marginTop: '10px'}}>
        <AccordionSummary
        expandIcon={!isMobile && <FontAwesomeIcon icon={faChevronDown}/>}
        aria-controls="panel1a-content"
        id="panel1a-header">
            <div style={{display: 'block'}}>
                <Typography>{<FontAwesomeIcon icon={faBook}/>} {labels.categoryPanelTitle} </Typography>
                <div style={{width: 'fit-content'}}>
                    {categoryPrediction.category && <Typography fontWeight='bold'> {categoryPrediction.category?.categoryName}</Typography>}
                    {categoryPrediction.stopMessage && <Typography fontWeight='bold' color='red'> {categoryPrediction.stopMessage}</Typography>}
                    {categoryPrediction.tags && categoryPrediction.tags.map((globalTag: any, globalTagIndex: number) => {
                        return <span className='tag small' style={{backgroundColor: 'var(--details)'}} key={globalTagIndex}>{globalTag}</span>
                    })}
                    {topicPredictionCertaintyLevel != null && <div className={getCertaintyLevelClass(topicPredictionCertaintyLevel)}></div>}
                </div>
                <div style={{marginTop:'10px'}}>
                    {categoryPrediction.category && categoryPrediction.category.account && <span className='categoryInfoTag account'>{labels.accountForCategoryLabel}: {categoryPrediction.category.account}</span>}
                    {categoryPrediction.category && categoryPrediction.category.accountNonDeductible && <span className='categoryInfoTag account'>{labels.categoryAccountNonDeductible}: {categoryPrediction.category.accountNonDeductible}</span>}
                    {categoryPrediction.category && categoryPrediction.category.costDeduction != null && <span className='categoryInfoTag'>{labels.costDeductionLabel}: {categoryPrediction.category.costDeduction}%</span>}
                    {categoryPrediction.category && categoryPrediction.category.vatRegisterInfo && 
                    <>
                        {categoryPrediction.category.vatRegisterInfo.account && <span className='categoryInfoTag account'>{labels.vatRegisterAccountLabel}: {categoryPrediction.category.vatRegisterInfo.account}</span>}
                        {categoryPrediction.category.vatRegisterInfo.vatRegisterName && <span className='categoryInfoTag'>{labels.vatRegisterLabel}: {categoryPrediction.category.vatRegisterInfo.vatRegisterName}</span>}
                    </>}
                    {categoryPrediction.category && categoryPrediction.category.vatDeduction != null && <span className='categoryInfoTag'>{labels.vatDeductionLabel}: {categoryPrediction.category.vatDeduction}%</span>}
                </div>
            </div>
        </AccordionSummary>
        {!isMobile && <AccordionDetails>
            {/* {categoryPrediction.category && categoryPrediction.category.vatDeduction != null && <span className='categoryInfoTag'>{labels.vatDeductionLabel}: {categoryPrediction.category.vatDeduction}%</span>}
            {categoryPrediction.category && categoryPrediction.category.vatRegister && <span className='categoryInfoTag'>{labels.vatRegisterLabel}: {categoryPrediction.category.vatRegister}</span>}
            {categoryPrediction.category && categoryPrediction.category.costDeduction != null && <span className='categoryInfoTag'>{labels.costDeductionLabel}: {categoryPrediction.category.costDeduction}%</span>}
            {categoryPrediction.category && categoryPrediction.category.account && <span className='categoryInfoTag'>{labels.accountForCategoryLabel}: {categoryPrediction.category.account}</span>} */}
            {logs && <div style={{marginBottom: '10px'}}></div>}
            {logs && logs.map((reason: any, reasonIndex: number) => {
                return <Typography key={reasonIndex} style={{fontSize:'12px'}}>{reason}</Typography>
            })}
        </AccordionDetails>}
    </Accordion>
}

function getCertaintyLevelClass(certaintyLevel: number) {
    if (certaintyLevel > 0) {
        return "tag bg-green";
    } 
    if (certaintyLevel === 0) {
        return "tag bg-yellow";
    } 
    return "tag bg-red";
}