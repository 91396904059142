


export type ActionDefinition = {
    visibilityType: ActionVisibilityType,
    definition: JSX.Element,
    dependOnColumn?: string,
    dependOnColumnNegation?: boolean,
}

export enum ActionVisibilityType {
    SelectionIndependent = 0,
    OnlyOneSelectedItem = 1,
    OneOrMoreSelectedItems = 2,
    MoreThanOneSelectedItems = 3,
    NothingSelected = 4,
}