import { Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccXProduct, AccXProductAlternative, ProductApi } from "../../../../api";
import ActionButton from "../../../../components/ActionButton";
import ActionGridButton from "../../../../components/ActionGridButton";
import AccXTextField from "../../../../components/fields/AccXTextField";
import GridInfo from "../../../../components/GridInfo";
import Popup from "../../../../components/Popup";
import { MessageType } from "../../../../components/snackbar/MessageType";
import { useApiContext } from "../../../../contexts/ApiContext";
import { useAppContext } from "../../../../contexts/AppContext";
import useFilters from "../../../../helpers/FiltersHelper";
import { ActionVisibilityType } from "../../../../models/ActionDefinition";

type Props = {
    productId?: number,
    onClose: (refresh: boolean) => void,
}

export default function ProductAlternativeNameDeletePopup({productId, onClose} : Props) {

    const {request} = useApiContext();
    const {labels, setIsLoading, showMessage} = useAppContext();
    const [filtersData, addFilter] = useFilters(false);
    const [selectedAlternativeName, setSelectedAlternativeName] = useState<string[]>([]);
    const productApi = new ProductApi();

    const initValues: AccXProductAlternative [] = []

    const initValuesProduct: AccXProduct= {
        productId: 0,
        productIndex: '',
        productName: '',
        sellerIndex: '',
        productGroupName: '0',
        productTags: [],
        invoiceTopicId: undefined,
        notes: '',
        unit: '',
        vatRate: '',
        gtu: '0',
        pkWiUCode: '',
        cnCode: '',
        gtinCode: '',
        additionalData: '',
        hasAlternativeNames: false,
        alternativeNames: [],
    };

    const [productData, setProductData] = useState(initValuesProduct);
    const [alternativeNamesData, setAlternativeNamesData] = useState(initValues);
    const [isLoadingData, setIsLoadingData] = useState(false);

    useEffect(() => {
        fetchAlternativeNamesData();
    }, [filtersData]);


    function fetchAlternativeNamesData() {
        setIsLoadingData(true);

        request(productApi.productProductIdGet(productId ?? 0)).then((response) => {
                if (response.ok && response.data) {
                    setProductData(response.data);
                    prepareAlternativeNamesData(response.data);
                }
            setIsLoadingData(false);
        });
    }
    
    function prepareAlternativeNamesData(product: AccXProduct){
        if(product && product.hasAlternativeNames){
            let searchText = filtersData['searchText'];
            let alternativeNames : Array<AccXProductAlternative> = [];
            product.alternativeNames?.forEach(element => {
                if(!searchText || (searchText && element.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)){
                    let alternativeName = {} as AccXProductAlternative;
                    alternativeName.productId = product.productId;
                    alternativeName.alternativeName = element;
                    alternativeNames.push(alternativeName);
                }
            });
            setAlternativeNamesData(alternativeNames);
        }
    }
    

    function deleteAlternativeName() {
        setIsLoadingData(true);

        let alternativeToDelete = alternativeNamesData.find(alternativeData => {
            return alternativeData.alternativeName == selectedAlternativeName[0];
        });
        let leftAlternativeNames = alternativeNamesData.length;
        if(alternativeNamesData != null){
            request(productApi.productAlternativeDeletePost(alternativeToDelete)).then((response) => {
                setIsLoadingData(false);
    
                if (response.ok) {
                    showMessage(labels.productAlternativeNameDeleted);
                    fetchAlternativeNamesData();
                    if(leftAlternativeNames - 1 == 0){
                        onClose(true);
                    }
                }
                else {
                    showMessage(labels.productAlternativeNameDeletingError, MessageType.Error);
                }
            })
        }else{
            showMessage(labels.productAlternativeNameDeletingError, MessageType.Error);
        }
    }

    var columns = [
        {header: labels.alternativeProductName, field: 'alternativeName'}, 
    ];

    var actions = [
        { 
            visibilityType: ActionVisibilityType.OnlyOneSelectedItem,
            definition: <ActionGridButton onClick={() => deleteAlternativeName()}>
                {labels.delete}
            </ActionGridButton>,
        },
    ];

    var popupTitle =  <span>{labels.deleteAlternativeProductNamePopupTitle} <b>{productData?.productName ?? ''}</b></span>;
    var hasAlternativeNames = productData && productData.alternativeNames && productData.alternativeNames.length > 0;

    return <Popup title={popupTitle} onClose={onClose} width={'800px'} isLoading={isLoadingData}>
        {hasAlternativeNames && <GridInfo columns={columns} 
                data={alternativeNamesData} 
                actions={actions} 
                hasSearchBar={true} 
                selectField={'alternativeName'}
                onSelect={(selected) => setSelectedAlternativeName(selected as string[])}
                isLoading={isLoadingData}
                filtersData={filtersData}
                addFilter={addFilter}/>}
        {!hasAlternativeNames && <Typography style={{padding: '0px 10px'}}>{labels.productHasNoAlternativeNames}</Typography>}
    </Popup>
}