import { PropsWithChildren } from "react";
import ActionButton from "./ActionButton";

type Props = {
    className?: string,
    type?: any,
    style?: any,
    onClick?: (e: any) => void,
}

export default function ActionGridButton({className='', type, style, onClick, children}: PropsWithChildren<Props>) {
    return <ActionButton className={className} isFromGrid={true} onClick={onClick} style={style} type={type}>
        {children}
    </ActionButton>
}