import React from 'react';
import './loader.css';

export default function Loader({isFullOverlay = false, text=''}) {
    return <div  className={"overlay " + (isFullOverlay ? 'fullOverlay' : '')}>
        <div>
        <div className='loader'>
            <div className='inner one'></div>
            <div className='inner two'></div>
            <div className='inner three'></div>
        </div>
        {text != '' && <div style={{position: 'absolute', top: '50%', textAlign: 'center', color: 'white', width: '100%'}}>{text}</div>}
        </div>
    </div>
}